import { DragOverlay } from '@dnd-kit/core'
import React, { useContext, useEffect, useRef } from 'react'

import getDropanimation from './dropAnimation'
import SortableTreeItem from './SortableTreeItem'
import { ProjectedContext } from './SortableTreesContext'
import { FlattenedItem, TreeItemComponentType } from './types'

export interface CustomDragOverlayProps<T, TElement extends HTMLElement> {
  item?: FlattenedItem<T>
  indentationWidth: number
  Item: TreeItemComponentType<T, TElement>
}

const CustomDragOverlay = <T, TElement extends HTMLElement>({
  item,
  indentationWidth,
  Item,
}: CustomDragOverlayProps<T, TElement>) => {
  const { projected } = useContext(ProjectedContext)
  const deltaX = useRef(0)

  useEffect(() => {
    if (item) deltaX.current = projected ? (projected.depth - item.depth) * indentationWidth : item.depth
  }, [item, projected])

  return (
    <DragOverlay adjustScale={false} dropAnimation={getDropanimation(deltaX)}>
      {item ? (
        <SortableTreeItem<T, TElement>
          key={item.id}
          id={item.id}
          item={item}
          dragOverlay
          Item={Item}
          indentationWidth={indentationWidth}
        />
      ) : null}
    </DragOverlay>
  )
}

export default CustomDragOverlay
