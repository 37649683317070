import React from 'react'

import classMerge from '../../utils/classMerge'

const UniversalSearchListResult = ({ className, children, ...props }: React.ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      role="listitem"
      className={classMerge(
        'mx-1 hover:cursor-pointer hover:bg-neutral-75 px-2 py-1 rounded-md flex items-center font-medium text-neutral-700',
        className
      )}
    >
      {children}
    </div>
  )
}

export default UniversalSearchListResult
