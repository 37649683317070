import { Question } from '@packages/types'
import React from 'react'

import ActivatableTextInput from 'builder/build/common/components/ActivatableTextInput'
import { useDispatch } from 'cms/hooks'

import { patchQuestion } from '../../actions'

interface DescriptionSectionProps {
  question: Question
}

const DescriptionSection = ({ question }: DescriptionSectionProps) => {
  const dispatch = useDispatch()

  const updateQuestion = (field: string, value: boolean | string) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  return (
    <div className="relative px-4 py-6">
      <ActivatableTextInput
        id="question-description"
        isRichText
        titleClassName="font-medium"
        title="Description"
        value={question.description!}
        isActive={question.showDescription}
        onChange={e => updateQuestion('description', e.target.value)}
        onActivationChange={checked => updateQuestion('showDescription', checked)}
        textarea
      />
    </div>
  )
}

export default DescriptionSection
