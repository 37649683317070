import { Alert } from '@packages/sk8/alert'
import React from 'react'

import AddOnlineStoreButton from 'cms/onlineStores/components/AddOnlineStoreButton'

const InstallKickflipAlert = () => {
  return (
    <Alert variant="warning" className="w-full">
      <Alert.Body>
        <Alert.Title>Install Kickflip app</Alert.Title>
        <Alert.Details>Kickflip needs to be installed on an ecommerce to activate your subscription.</Alert.Details>
      </Alert.Body>

      <Alert.Actions>
        <AddOnlineStoreButton>Install app</AddOnlineStoreButton>
      </Alert.Actions>
    </Alert>
  )
}

export default InstallKickflipAlert
