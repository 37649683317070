import { Button } from '@packages/sk8/button'
import { RadioGroup } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import React, { useState } from 'react'

interface ExportProductModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  productName: string
  hasDraft?: boolean
  onExport: (field: 'live' | 'draft') => void
  onClose: () => void
}

const ExportProductModal = ({ productName, hasDraft, onExport, onClose, ...modalProps }: ExportProductModalProps) => {
  const [versionToExport, setVersionToExport] = useState<'live' | 'draft'>('live')

  return (
    <Modal onBackdropClick={onClose} {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Export product</Modal.Title>
      <Modal.Details>
        <p className="mb-4">
          Which version of <em>{productName}</em> do you want to export?
        </p>
        <RadioGroup
          horizontal
          name="versionToExport"
          options={[
            {
              id: 'live',
              label: 'Published',
              value: 'live',
              checked: versionToExport === 'live',
              onChange: () => setVersionToExport('live'),
            },
            {
              id: 'draft',
              label: 'Draft',
              value: 'draft',
              checked: versionToExport === 'draft',
              disabled: !hasDraft,
              onChange: () => setVersionToExport('draft'),
            },
          ]}
        />
      </Modal.Details>
      <Modal.Actions>
        <Button variant="default" className="px-4" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="px-4"
          aria-label="confirm export"
          onClick={() => {
            onExport(versionToExport)
            onClose()
          }}
        >
          Export
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ExportProductModal
