import { Button } from '@packages/sk8/button'
import { useModal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { ActivityLogType, ShopifyOnlineStore, StartingPoint } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import React from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import { trpc } from 'common/hooks/trpc'
import CheckCircle from 'icons/custom/check-circle.svg'
import OpenLink from 'icons/custom/open-link.svg'
import ShopifyLogo from 'icons/custom/shopify-icon.svg'

import DisconnectModal from '../modals/DisconnectModal'

interface ShopifyListItemProps {
  startingPoint: StartingPoint
  onlineStore: ShopifyOnlineStore
  productId: string
}

const ShopifyListItem = ({ startingPoint, onlineStore, productId }: ShopifyListItemProps) => {
  const queryClient = useQueryClient()
  const { openGenericErrorToast, openToast } = useToast()
  const disconnectModal = useModal()
  const onlineStoreService = useOnlineStoreService()
  const trpcUtils = trpc.useContext()

  const startingPointName = startingPoint.name || 'starting point'

  const startingPointBindings = onlineStore.productsData?.[productId]?.startingPointsBindings

  const startingPointBinding = startingPointBindings?.find(
    startingPointBinding => startingPointBinding.startingPointId == startingPoint.id
  )

  const { data: availability, isLoading: isLoadingAvailability } = useQuery(
    [...onlineStoreService.fetchAvailability.queryKeys, onlineStore.id, productId, startingPoint.id],
    () => onlineStoreService.fetchAvailability(onlineStore.id, productId, startingPoint.id),
    {
      enabled: !!startingPointBinding,
      staleTime: 60000, // 1 min
      refetchOnMount: false,
    }
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.listLatest.invalidate(),
  })

  const { mutate: connect, isLoading: isConnecting } = useMutation(
    () => onlineStoreService.addStartingPoint(onlineStore.id, productId, { startingPointId: startingPoint.id }),
    {
      onSuccess: () => {
        openToast(`Successfully connected ${startingPointName}`, ToastType.success)
        createActivityLog({ type: ActivityLogType.AddStartingPointToStore })
        queryClient.invalidateQueries([...onlineStoreService.fetchAll.queryKeys, 'installed'])
      },
      onError: () => {
        openGenericErrorToast(`Failed to connect ${startingPointName}`)
      },
    }
  )

  const { mutate: disconnect, isLoading: isDisconnecting } = useMutation(
    () => onlineStoreService.removeStartingPoint(onlineStore.id, productId, startingPoint.id),
    {
      onSuccess: () => {
        openToast(`Successfully disconnected ${startingPointName}`, ToastType.success)
        queryClient.refetchQueries([...onlineStoreService.fetchAll.queryKeys, 'installed'])
        queryClient.invalidateQueries([
          ...onlineStoreService.fetchAvailability.queryKeys,
          onlineStore.id,
          productId,
          startingPoint.id,
        ])
      },
      onError: () => {
        openGenericErrorToast(`Failed to disconnect ${startingPointName}`)
      },
    }
  )

  return (
    <>
      <div className="bg-neutral-75 flex rounded-md items-center py-2 pl-4 pr-2">
        <div className="flex items-center w-2/5">
          <ShopifyLogo className="w-4 h-4" />
          <div className="flex flex-col ml-4">
            <span className="font-medium">{onlineStore.name}</span>
            <span className="font-medium text-xs">{onlineStore.domain}</span>
          </div>
        </div>

        <div className="flex flex-col justify-center w-2/5">
          {startingPointBinding && (
            <>
              <span className="font-medium text-xs">Linked to Shopify product</span>
              {isLoadingAvailability ? (
                <span className="rounded-lg bg-neutral-200 w-40 h-4 animate-pulse" aria-label="Product name" />
              ) : (
                <div className="flex items-center">
                  <Tooltip
                    content={
                      availability?.available
                        ? 'This product is available on a Shopify\nsales channel'
                        : 'You have not made this product available\non a Shopify sales channel yet'
                    }
                  >
                    <CheckCircle
                      className={classNames('w-3.5 h-3.5 ', {
                        'fill-success-default': availability?.available,
                        'fill-warning-default': !availability?.available,
                      })}
                    />
                  </Tooltip>
                  <a
                    className="flex items-center"
                    href={`https://${onlineStore.domain}/admin/products/${startingPointBinding.shopifyProductId}`}
                    target="_blank"
                  >
                    <span className="font-medium text-xs mx-2">{startingPoint.name}</span>
                    <OpenLink className="w-3 h-3" />
                  </a>
                </div>
              )}
            </>
          )}
        </div>

        <div className="w-1/5 flex justify-end">
          {startingPointBinding ? (
            <Button
              variant="subtle"
              disabled={isDisconnecting}
              isLoading={isDisconnecting}
              onClick={disconnectModal.open}
            >
              Disconnect
            </Button>
          ) : (
            <Button disabled={isConnecting} isLoading={isConnecting} onClick={() => connect()}>
              Connect
            </Button>
          )}
        </div>
      </div>
      {disconnectModal.isVisible && (
        <DisconnectModal
          onDisconnect={disconnect}
          onClose={disconnectModal.close}
          startingPointId={startingPoint.id}
          productId={productId}
          onlineStore={onlineStore}
          isDisconnecting={isDisconnecting}
          {...disconnectModal.modalProps}
        />
      )}
    </>
  )
}

export default ShopifyListItem
