import React from 'react'

import classMerge from '../../utils/classMerge'

const ModalActionsMessage = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div className={classMerge('text-xs text-neutral-500 font-medium mr-auto', props.className)}>{props.children}</div>
  )
}

export default ModalActionsMessage
