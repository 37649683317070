import { Button } from '@packages/sk8/button'
import { Tooltip } from '@packages/sk8/tooltip'
import { ECommerce } from '@packages/types'
import React from 'react'

import AlertCircle from 'icons/regular/01-Interface Essential/14-Alerts/alert-circle.svg'

export interface ConnectButtonProps {
  eCommerce: ECommerce.Prestashop | ECommerce.Woocommerce
  handleConnect: () => void
  handleDisconnect: () => void
  isLoading: boolean
  isConnecting: boolean
  isDisconnecting: boolean
  isOffline: boolean
  isConnected: boolean
}

const wikiLocalShopUrls = {
  [ECommerce.Woocommerce]: 'https://help.mycustomizer.com/en/articles/5571488-woocommerce-developing-locally',
  [ECommerce.Prestashop]: 'https://help.mycustomizer.com/en/articles/5423077-prestashop-developing-locally',
}

const ConnectButton = ({
  eCommerce,
  handleConnect,
  handleDisconnect,
  isLoading,
  isDisconnecting,
  isConnecting,
  isOffline,
  isConnected,
}: ConnectButtonProps) => {
  if (isOffline) {
    return (
      <a href={wikiLocalShopUrls[eCommerce]} target="_blank">
        <Tooltip
          containerClassName="flex items-center text-warning-dark absolute right-4 top-0 h-full"
          content={`We can't establish connection with your online store. You can copy the sync code and use it in Kickflip app settings on your ${
            eCommerce == ECommerce.Woocommerce ? 'WooCommerce' : 'PrestaShop'
          } admin.`}
        >
          <AlertCircle className="w-3.5 h-3.5 mr-2" />
          <span>Can't establish connection</span>
        </Tooltip>
      </a>
    )
  }

  if (isConnected) {
    return (
      <Button
        aria-label="disconnect"
        variant="subtle"
        onClick={handleDisconnect}
        isLoading={isDisconnecting}
        disabled={isDisconnecting}
      >
        Disconnect
      </Button>
    )
  }

  return (
    <Button
      aria-label="connect"
      onClick={handleConnect}
      isLoading={isConnecting || isLoading}
      disabled={isConnecting || isLoading}
    >
      Connect
    </Button>
  )
}

export default ConnectButton
