import { Button } from '@packages/sk8/button'
import { Tooltip } from '@packages/sk8/tooltip'
import { CustomOnlineStore, StartingPoint } from '@packages/types'
import React, { useContext } from 'react'

import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import TenantContext from 'common/tenant/TenantContext'
import CustomEcommerceIcon from 'icons/bold/09-Shopping-Ecommerce/02-E-Commerce/e-commerce-shop.svg'
import CopyFile from 'icons/custom/duplicate.svg'

interface CustomListItemProps {
  startingPoint: StartingPoint
  onlineStore: CustomOnlineStore
}

const CustomListItem = ({ startingPoint, onlineStore }: CustomListItemProps) => {
  const copyTextToClipboard = useCopyToClipboard()
  const tenant = useContext(TenantContext)

  const iFrameCodeValue = `<iframe id="mczrProductIframe" style="border: none" src="${
    location.protocol
  }//${tenant}.${DOMAIN}${location.port ? `:${location.port}` : ''}/customize/startingpoint/${
    startingPoint.id
  }?shopid=${onlineStore.id}" allow="clipboard-write"/>`

  const handleSyncCodeClick = () => copyTextToClipboard(iFrameCodeValue)

  return (
    <div className="bg-neutral-75 flex rounded-md items-center justify-between py-2 pl-4 pr-2">
      <div className="flex items-center">
        <CustomEcommerceIcon className="h-4 w-4" />
        <div className="flex flex-col ml-4">
          <span className="font-medium">{onlineStore.name}</span>
          <span className="font-medium text-xs">{onlineStore.domain}</span>
        </div>
      </div>

      <Tooltip content={iFrameCodeValue}>
        <Button
          variant="subtle"
          icon={<CopyFile className="w-3.5 fill-current text-current" />}
          onClick={handleSyncCodeClick}
        >
          iFrame code
        </Button>
      </Tooltip>
    </div>
  )
}

export default CustomListItem
