import { ECommerce, OnlineStoreStatus, ShopifyThemeType } from '@packages/types'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useContext } from 'react'

import TenantContext from 'common/tenant/TenantContext'
import getSemanticVersionInfos from 'utils/getSemanticVersionInfos'

import useOnlineStoreService from './useOnlineStoreService'
import useShopifyProxyService from './useShopifyProxyService'

const useOnlineStoreHasWarning = (onlineStoreId?: string) => {
  const tenant = useContext(TenantContext)

  const onlineStoreService = useOnlineStoreService()
  const shopifyProxyService = useShopifyProxyService()

  const { data: activeShopifyOnlineStores = [] } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll,
    {
      select: data =>
        data.filter(
          ({ eCommerce, status }) => eCommerce === ECommerce.Shopify && status === OnlineStoreStatus.Installed
        ),
      enabled: !!tenant && !onlineStoreId,
    }
  )

  const themeQueries = useQueries({
    queries: onlineStoreId
      ? [
          {
            queryKey: [...shopifyProxyService.fetchInstalledThemes.queryKeys, onlineStoreId],
            queryFn: () => shopifyProxyService.fetchInstalledThemes(onlineStoreId),
            enabled: !!onlineStoreId,
          },
        ]
      : activeShopifyOnlineStores.map(onlineStore => ({
          queryKey: [...shopifyProxyService.fetchInstalledThemes.queryKeys, onlineStore.id],
          queryFn: () => shopifyProxyService.fetchInstalledThemes(onlineStore.id),
          enabled: !!onlineStore,
        })) || [],
  })

  const themeAssetVersions = useQueries({
    queries:
      themeQueries?.map(({ data: themes }, index) => {
        const mainTheme = themes?.find(theme => theme.role === 'main')

        return {
          queryKey: [
            ...shopifyProxyService.fetchThemeAssetVersions.queryKeys,
            onlineStoreId || activeShopifyOnlineStores[index].id,
            mainTheme?.id,
          ],
          queryFn: () =>
            shopifyProxyService.fetchThemeAssetVersions(
              onlineStoreId || activeShopifyOnlineStores[index].id,
              mainTheme!.id
            ),
          enabled: !!themes && !!mainTheme,
        }
      }) || [],
  })

  const mainThemeIds = themeQueries?.flatMap(
    ({ data }) => data?.filter(theme => theme.role === 'main').map(theme => String(theme.id)) || []
  )

  const filteredThemes = mainThemeIds
    .map((themeId, index) => {
      const storeId = onlineStoreId
        ? activeShopifyOnlineStores.find(store => store.id === onlineStoreId)?.id
        : activeShopifyOnlineStores[index]?.id

      return storeId ? { themeId, storeId } : null
    })
    .filter((theme): theme is { themeId: string; storeId: string } => !!theme)

  const themes = useQueries({
    queries: filteredThemes.map(({ themeId, storeId }) => ({
      queryKey: [...shopifyProxyService.fetchTheme.queryKeys, themeId],
      queryFn: () => shopifyProxyService.fetchTheme(storeId, themeId),
      enabled: true,
    })),
  })

  const isAnyAppEmbedDisabled = themes.some(({ data: theme, isLoading }) => {
    return !isLoading && theme?.type === ShopifyThemeType.AppEmbedBlock && theme?.appEmbed?.disabled !== false
  })

  if (isAnyAppEmbedDisabled) return 'warning:appEmbed'

  if (!themeAssetVersions || themeAssetVersions.some(({ isLoading }) => isLoading)) return

  for (const themeAssetVersion of themeAssetVersions) {
    const { data } = themeAssetVersion
    if (!data) continue
    const currentVersionInfos = getSemanticVersionInfos(data.version)
    const lastVersionInfos = getSemanticVersionInfos(data.lastVersion)

    if (currentVersionInfos?.major !== lastVersionInfos?.major) return 'warning'

    if (currentVersionInfos?.minor !== lastVersionInfos?.minor) return 'info'
  }

  return null
}

export default useOnlineStoreHasWarning
