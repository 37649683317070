import { Input, InputField } from '@packages/sk8/input'
import {
  Equation,
  EquationLine,
  EquationLineType,
  NormalizedCustomizerProduct,
  QuestionInputType,
} from '@packages/types'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React from 'react'

interface EquationModalAnswersProps {
  line: EquationLine
  customizerProduct: NormalizedCustomizerProduct
  nameBase?: string
}

const EquationModalAnswers = ({ line, customizerProduct, nameBase }: EquationModalAnswersProps) => {
  const formik = useFormikContext<Equation>()
  const question = customizerProduct.questions[line.value]

  if (
    line.type !== EquationLineType.Question ||
    question?.inputType === QuestionInputType.Text ||
    question?.inputType === QuestionInputType.Number ||
    question?.answers == null
  )
    return null

  return (
    <div className="gap-1 mt-2 mb-3 flex flex-col text-xs text-neutral-500">
      {question?.answers.map((answer, answerIndex) => {
        if (!customizerProduct.answers[answer]) return null
        const name = `${nameBase}.${answer}`

        return (
          <InputField className="!flex-row items-center w-[284px]" key={answerIndex}>
            <label className="basis-48 px-2">{customizerProduct.answers[answer].name}</label>
            <Input
              type="number"
              min={0}
              placeholder="Enter value"
              className="w-auto"
              inputClassName="text-xs"
              small
              id={name}
              name={name}
              value={get(formik.values, name) ?? ''}
              onChange={e => {
                formik.setFieldValue(name, e.target.value || null)
                formik.setFieldTouched(name, true)
              }}
              onBlur={formik.handleBlur}
              hasError={!!get(formik.touched, name) && !!get(formik.errors, name)}
            />
          </InputField>
        )
      })}
    </div>
  )
}

export default EquationModalAnswers
