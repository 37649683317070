import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import React from 'react'

interface DeleteAdditionnalPricingModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  type: 'equation' | 'extraprice'
  onClose: () => void
  onDelete: () => void
}

const DeleteAdditionnalPricingModal = ({
  type,
  onClose,
  onDelete,
  ...modalProps
}: DeleteAdditionnalPricingModalProps) => {
  return (
    <Modal {...modalProps} onBackdropClick={onClose}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Delete {type === 'extraprice' ? 'extra price' : 'equation'}</Modal.Title>
      <Modal.Details>
        Are you sure you want to delete this {type === 'extraprice' ? 'extra price' : 'equation'}?
      </Modal.Details>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="error" onClick={onDelete}>
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
export default DeleteAdditionnalPricingModal
