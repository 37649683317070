import React from 'react'

import { BulkSelection } from '../../hooks/useBulkSelection'
import classMerge from '../../utils/classMerge'
import Checkbox from './../input/Checkbox'

export interface BulkSelectionHeader {
  bulkSelection: BulkSelection
  disabled?: boolean
  children: React.ReactNode
  className?: string
}

const TableBulkSelectionHeader = ({
  className = '',
  bulkSelection,
  disabled = false,
  children,
}: BulkSelectionHeader) => {
  return (
    <div className={classMerge(className, 'relative')}>
      <div
        className={classMerge(
          'absolute -left-2 -top-[5px] whitespace-nowrap rounded-md border flex items-center pl-[29px] h-[26px] z-[2]',
          {
            'bg-neutral-50 border-neutral-100 shadow-[30px_0px_20px_4px_rgba(255,255,255,1)]':
              bulkSelection.selectedCount() > 0,
            'border-transparent': bulkSelection.areNoneSelected(),
            'pr-2': children == null,
          }
        )}
      >
        <div
          className={classMerge('flex items-center h-full', {
            'shadow-xs': bulkSelection.selectedCount() > 0,
          })}
        >
          {bulkSelection.selectedCount() > 0 && (
            <>
              <span
                className={classMerge('flex text- items-center h-full normal-case font-normal text-xs pr-2', {
                  'border-r border-neutral-100 mr-1': children != null,
                })}
              >
                {bulkSelection.selectedCount()} selected
              </span>
              {children}
            </>
          )}
        </div>
      </div>
      <div className="relative z-[3] flex">
        <Checkbox
          aria-label="Select all"
          indeterminate={bulkSelection.selectedCount() > 0 && !bulkSelection.areAllSelected()}
          checked={bulkSelection.selectedCount() > 0 && bulkSelection.areAllSelected()}
          disabled={disabled}
          onChange={e => (e.target.checked ? bulkSelection.selectAll() : bulkSelection.deselectAll())}
        />
      </div>
    </div>
  )
}

export default TableBulkSelectionHeader
