import { Card } from '@packages/sk8/card'
import React from 'react'

export interface VariantsTableCardProps {
  children: React.ReactNode
}

const VariantsTableCard = ({ children }: VariantsTableCardProps) => {
  return (
    <Card className="w-full overflow-visible bg-white mb-8">
      <div className="flex flex-col grow relative w-[800px]">{children}</div>
    </Card>
  )
}

export default VariantsTableCard
