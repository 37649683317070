import React from 'react'

import classMerge from '../../utils/classMerge'

const UniversalSearchListSupportingText = ({ className, children, ...props }: React.ComponentProps<'span'>) => {
  return (
    <span {...props} className={classMerge('ml-auto text-xs text-neutral-300 max-w-[66%]', className)}>
      {children}
    </span>
  )
}

export default UniversalSearchListSupportingText
