import { Button } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

import DownArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'

interface TopBarPublishDiscardButtonProps {
  disabled: boolean
  isPublishing: boolean
  isPublished: boolean
  onPublish: () => void
  onDiscard: () => void
  texts?: {
    publish?: string
    published?: string
    discard?: string
  }
}

const TopBarPublishDiscardButton = ({
  disabled,
  isPublishing,
  isPublished,
  onPublish,
  onDiscard,
  texts,
}: TopBarPublishDiscardButtonProps) => {
  const dropdownButton = usePopover({ placement: 'bottom-end' })

  const handleDiscard = () => {
    onDiscard()
    dropdownButton.close()
  }

  return (
    <div className="flex">
      <Button
        className="rounded-r-none"
        disabled={disabled}
        onClick={onPublish}
        isLoading={isPublishing}
        variant="green"
      >
        {isPublished ? texts?.published || 'Published' : texts?.publish || 'Publish'}
      </Button>
      <div className="h-4 w-[1px] mt-2 bg-white mr-[-1px] z-[11]" />
      <Button
        className="rounded-l-none focus:ring-0"
        disabled={disabled}
        variant="green"
        aria-label="More options"
        {...dropdownButton.referenceProps}
      >
        <DownArrow className="w-2.5 fill-current" />
      </Button>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
        <Popover.Action className="text-tertiary-red-700" onClick={handleDiscard}>
          {texts?.discard || 'Discard'}
        </Popover.Action>
      </Popover>
    </div>
  )
}

export default TopBarPublishDiscardButton
