import { Quote, QuoteStatus } from '@packages/types'
import qs from 'qs'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'

import { trpc, RouterOutputs } from 'common/hooks/trpc'

import { defaultPersistenceState } from '../utils'

type UseFetchQuoteOptions = {
  persistence?: typeof defaultPersistenceState
  quoteId: string
  baseUrl: string
  isRevision?: boolean
  setIsEditing: (isEditing: boolean) => void
}

export type QuoteSiblings = {
  previous: null | RouterOutputs['quote']['list']['results'][0]
  next: null | RouterOutputs['quote']['list']['results'][0]
}

export const useFetchQuote = ({ persistence, quoteId, baseUrl, isRevision, setIsEditing }: UseFetchQuoteOptions) => {
  const history = useHistory()
  const trpcUtils = trpc.useContext()

  const {
    data,
    hasPreviousPage,
    hasNextPage,
    fetchPreviousPage,
    fetchNextPage,
    isLoading: isLoadingInifiniteQuotes,
    isFetching: isFetchingInifiniteQuotes,
  } = trpc.quote.list.useInfiniteQuery(
    { ...persistence },
    {
      enabled: !!persistence,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialCursor: persistence?.lastIndex,
      getPreviousPageParam: currentPage => {
        const { lastIndex, resultSize } = currentPage.pagination
        const firstIndex = lastIndex - resultSize - persistence!.count
        return firstIndex >= 0 ? firstIndex : undefined
      },
      getNextPageParam: currentPage => {
        const { lastIndex, collectionSize } = currentPage.pagination
        return lastIndex < collectionSize ? lastIndex : undefined
      },
    }
  )

  const selectQuote = useCallback(
    (
      data: RouterOutputs['quote']['getById']
    ):
      | Quote
      | {
          id: string
          quoteId: string
          free: boolean
          __redacted: true
        } => {
      if ('__redacted' in data) return data

      return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
        expireOn: data.expireOn ? new Date(data.expireOn) : undefined,
      } as Quote
    },
    []
  )

  const { data: quote, isLoading: isLoadingDirectQuote } = trpc.quote.getById.useQuery(quoteId, {
    select: selectQuote,
  })

  const isLoadingQuotes = persistence ? isLoadingInifiniteQuotes : isLoadingDirectQuote

  const invalidateQueries = () => {
    trpcUtils.quote.list.invalidate()
    trpcUtils.quote.getById.invalidate(quoteId)
    trpcUtils.quote.count.invalidate({ status: QuoteStatus.Draft })
  }

  const handleGoBack = () => {
    if (persistence) {
      const currentPageIndex = (data?.pages || []).findIndex(page => page.results.find(({ id }) => id === quoteId))
      const lastIndex = data?.pageParams?.[currentPageIndex] || persistence.lastIndex
      const query = qs.stringify({ ...persistence, lastIndex }, { encode: false, skipNulls: true })
      history.push(`${baseUrl}/quotes?${query}`)
    } else {
      history.push(`${baseUrl}/quotes`)
    }
  }

  const getSiblings = () => {
    if (!persistence) return { previous: null, next: null }

    const allQuotes = (data?.pages || []).reduce(
      (result: typeof page.results, page) => [...result, ...page.results],
      []
    )
    const currentPosition = allQuotes.findIndex(({ id }) => id === quoteId)

    return {
      previous: allQuotes[currentPosition - 1],
      next: allQuotes[currentPosition + 1],
    }
  }

  const quoteSiblings: QuoteSiblings = getSiblings()

  useEffect(() => {
    if (!quoteSiblings.previous && hasPreviousPage) fetchPreviousPage()
    if (!quoteSiblings.next && hasNextPage) fetchNextPage()
  })

  if (quote && '__redacted' in quote) {
    return {
      quote,
      quoteSiblings,
      isLoadingQuotes,
      isFetchingQuotes: isFetchingInifiniteQuotes,
      handleGoBack,
      invalidateQueries,
    }
  }

  if (quote && isRevision && quote.status === QuoteStatus.Open) {
    quote.status = QuoteStatus.Draft
    const quoteIdParts = quote.quoteId.split('-')
    quote.quoteId = `${quoteIdParts[0]}-${Number(quoteIdParts[1]) + 1}`
    setIsEditing(true)
  }

  return {
    quote,
    quoteSiblings,
    isLoadingQuotes,
    isFetchingQuotes: isFetchingInifiniteQuotes,
    handleGoBack,
    invalidateQueries,
  }
}
