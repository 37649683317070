import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import React from 'react'

interface RegistryPluginCardProps {
  name: string
  isInstalled?: boolean
  onInstall: () => void
}

const RegistryPluginCard = ({ name, isInstalled, onInstall }: RegistryPluginCardProps) => {
  return (
    <Card className="mb-6 flex flex-col w-[320px]">
      <Card.Section className="flex md:flex-col">
        <h2>{name}</h2>
      </Card.Section>
      <Card.Footer>
        {isInstalled && (
          <Button variant="text" disabled>
            Installed
          </Button>
        )}
        {!isInstalled && (
          <Button variant="primary" onClick={onInstall}>
            Install
          </Button>
        )}
      </Card.Footer>
    </Card>
  )
}

RegistryPluginCard.Bone = () => <Card className="mb-6 flex flex-col w-[320px] h-[120px] animate-pulse" />

export default RegistryPluginCard
