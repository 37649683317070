import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { CheckboxGroup, HelperText, Input, InputField, Label } from '@packages/sk8/input'
import { ToastType, useToast } from '@packages/sk8/toast'
import { ApiKeyScope } from '@packages/types'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { RouterInputs, trpc } from 'common/hooks/trpc'
import useGoBack from 'common/hooks/useGoBack'

type PermissionOption = { label: string; id: string; value: RouterInputs['token']['create']['scopes'][number] }

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
  scopes: yup.array().min(1, 'Please select at least 1 scope'),
})

const permissionOptions: PermissionOption[] = [
  { label: 'Full read and write access', value: ApiKeyScope.Admin, id: 'admin' },
  { label: 'Full read access', value: ApiKeyScope.Read, id: 'read' },
  { label: 'Access content of orders', value: ApiKeyScope.OrdersRead, id: 'orders:read' },
  { label: 'Access content of designs', value: ApiKeyScope.DesignsRead, id: 'designs:read' },
  { label: 'Access content of inventory', value: ApiKeyScope.InventoryRead, id: 'inventory:read' },
]

const CreateToken = () => {
  const goBack = useGoBack()
  const { openToast, openGenericErrorToast } = useToast()

  const { mutate: createToken } = trpc.token.create.useMutation({
    onSuccess: () => {
      openToast('API key successfully created!', ToastType.success)
      goBack()
    },
    onError: () => {
      openGenericErrorToast('API key has not been created.')
    },
  })

  const formik = useFormik<RouterInputs['token']['create']>({
    initialValues: { name: '', scopes: [] },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => createToken(values, { onSettled: () => setSubmitting(false) }),
  })

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Page.Header>
            <h1>Create API key</h1>
            <div className="flex relative">
              <Button type="button" onClick={goBack} className="mr-3">
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                isLoading={formik.isSubmitting}
              >
                Create
              </Button>
            </div>
          </Page.Header>

          <Page.Section>
            <Page.Aside
              title="Key details"
              description="API keys allow reading, modifying or deleting your data within defined scopes. When creating a new key, you can choose broader or narrower scopes."
            />
            <Page.Content>
              <Card className="flex flex-1 flex-col p-6">
                <InputField className="mb-6">
                  <Label htmlFor="name">Key name</Label>
                  <Input
                    placeholder="Enter your text here"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.name && formik.errors.name != null}
                  />
                  {formik.touched.name && formik.errors.name != null && (
                    <HelperText hasError>{formik.errors.name}</HelperText>
                  )}
                </InputField>
                <InputField className="mb-6">
                  <Label>Permission scope</Label>
                  <CheckboxGroup
                    name="scopes"
                    options={permissionOptions}
                    onChange={e => {
                      formik.handleChange(e)
                      formik.setFieldTouched('scopes', true, false)
                    }}
                  />
                  {formik.touched.scopes && formik.errors.scopes != null && (
                    <HelperText hasError>{formik.errors.scopes}</HelperText>
                  )}
                </InputField>
              </Card>
            </Page.Content>
          </Page.Section>
        </form>
      </Page>
    </main>
  )
}

export default CreateToken
