import { Toast } from '@packages/sk8/toast'
import React from 'react'
import { toast } from 'react-hot-toast'

import KickflipLogo from 'img/3d-skater-logo.png'

interface SubscriptionToastProps {
  id: string
  visible?: boolean
}

const SubscriptionConfirmationToast = ({ visible, id }: SubscriptionToastProps) => {
  return (
    <Toast visible={visible} className="p-2 pr-4 w-[423px]">
      <img src={KickflipLogo} className="w-14 h-14 mr-4" />
      <div className="flex flex-col">
        <span className="text-sm font-bold leading-5">Congrats, you're officially in! 🎉</span>
        <span className="text-xs leading-[18px]">
          We hope you'll enjoy our app and feel free to chat with our support if you need help!
        </span>
      </div>
      <Toast.CloseButton onClick={() => toast.dismiss(id)} />
    </Toast>
  )
}

export default SubscriptionConfirmationToast
