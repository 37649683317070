import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

import EquationIcon from 'icons/custom/equation.svg'
import ExtraPriceIcon from 'icons/custom/extraprice.svg'

export interface EmptyPricingProps {
  tab?: 'extraprices' | 'equations'
  isReallyEmpty?: boolean
}

const EmptyAdditionnalPricing = ({ tab, isReallyEmpty }: EmptyPricingProps) => {
  const Wrap = ({ children }: { children: React.ReactNode }) => (
    <div className="flex justify-center pt-8 pb-4">{children}</div>
  )

  if (tab === 'extraprices') {
    return (
      <Wrap>
        <BlankState className="w-[320px]">
          <BlankState.Icon className="p-3" Icon={ExtraPriceIcon} />
          <BlankState.Title>
            {isReallyEmpty ? 'No extra price created' : 'No extra price matches the current filters'}
          </BlankState.Title>
          <BlankState.Details>
            {isReallyEmpty
              ? 'Create extra price for specific answers.'
              : 'Change the filters to see other extra prices, or create a new one.'}
          </BlankState.Details>
        </BlankState>
      </Wrap>
    )
  }

  if (tab === 'equations') {
    return (
      <Wrap>
        <BlankState className="w-[320px]">
          <BlankState.Icon className="p-3" Icon={EquationIcon} />
          <BlankState.Title>
            {isReallyEmpty ? 'No equation created' : 'No equation matches the current filters'}
          </BlankState.Title>
          <BlankState.Details>
            {isReallyEmpty
              ? 'Create your first equation to add extra price based on mathematical operations.'
              : 'Change the filters to see other equations, or create a new one.'}
          </BlankState.Details>
        </BlankState>
      </Wrap>
    )
  }

  return (
    <Wrap>
      <BlankState className="w-[320px]">
        <BlankState.Details>Use the tabs above to view extra prices or equations for this product.</BlankState.Details>
      </BlankState>
    </Wrap>
  )
}

export default EmptyAdditionnalPricing
