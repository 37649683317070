import { FilterGroup } from '@packages/sk8/filter'
import { Variant } from '@packages/types'
import { keyBy } from 'lodash'

import type { CombinationFilters, Group, VariantIdsByCombination } from 'common/variants/types/variant'

import type { InventoryDataTable } from './types/dataTable'
import type { InventoryFormValues } from './types/form'

export const getHasNoAppliedFilters = (dataTable: InventoryDataTable) => {
  return (
    dataTable.search === '' &&
    dataTable.combinations.length === 0 &&
    dataTable.stock?.value == null &&
    dataTable.committed?.value == null &&
    dataTable.available?.value == null
  )
}

export const getClearAllFilters = (dataTable: InventoryDataTable) => () => {
  dataTable.setSearch('')
  dataTable.clearFilters('combinations')
  dataTable.clearFilters('stock')
  dataTable.clearFilters('available')
  dataTable.clearFilters('committed')
}

export const variantsToVirtuosoData = (
  items: Variant[],
  variantIdsByCombination: VariantIdsByCombination
): [Variant[], Group[], number[]] => {
  const addedGroupKeys: string[] = []
  const rows: Variant[] = []
  const groups: Group[] = []
  const groupCounts = []

  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const groupKey = JSON.stringify({
      productId: item.product,
      questionIds: item.answersRefs.map(answerRef => answerRef.questionId),
    })

    if (!addedGroupKeys.includes(groupKey)) {
      addedGroupKeys.push(groupKey)
      groupCounts.push(0)

      groups.push({
        productName: item.productName,
        questionNames: item.combinationName,
        key: groupKey,
        variantIds:
          variantIdsByCombination.find(
            idGroup =>
              JSON.stringify({
                productId: idGroup._id.productId,
                questionIds: idGroup._id.questionIds,
              }) === groupKey
          )?.ids ?? [],
      })
    }

    groupCounts[addedGroupKeys.indexOf(groupKey)]++

    rows.push(item)
  }

  return [rows, groups, groupCounts]
}

export const getCombinationFilters = (combinationFiltersData: CombinationFilters) =>
  combinationFiltersData.reduce((filters: FilterGroup[], group) => {
    const groupFilterIndex = filters.findIndex(filter => filter.id === group._id.productId)

    if (groupFilterIndex < 0)
      return [
        ...filters,
        {
          id: group._id.productId,
          name: group.productName,
          items: [
            {
              text: group.questionNames,
              name: JSON.stringify(group._id),
            },
          ],
        },
      ]

    return [
      ...filters.slice(0, groupFilterIndex),
      {
        ...filters[groupFilterIndex],
        items: [
          ...filters[groupFilterIndex].items,
          {
            text: group.questionNames,
            name: JSON.stringify(group._id),
          },
        ],
      },
      ...filters.slice(groupFilterIndex + 1, filters.length),
    ]
  }, [])

export const formValuesToMutationInput = (
  locationId: string,
  values: InventoryFormValues,
  initialValues: InventoryFormValues
) => ({
  locationId,
  updates: Object.values(values).reduce(
    (
      updatedInventoryItems: {
        id: string
        stock: number | null
      }[],
      { id, stock }: { id: string; stock: number | null }
    ) => {
      if (initialValues[id].stock !== stock) return [...updatedInventoryItems, { id, stock }]

      return updatedInventoryItems
    },
    []
  ),
})

export const variantsToFormValues = (locationId: string, variants: Variant[]): InventoryFormValues =>
  keyBy(
    variants?.map(variant => ({
      ...variant.inventoryItem,
      ...variant.inventoryItem.inventoryLevelByLocationId[locationId],
    })),
    'id'
  )
