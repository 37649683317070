import { InventoryManagementStrategy } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React from 'react'

import type { CombinationFilters } from 'common/variants/types/variant'

import type { InventoryDataTable } from './../../types/dataTable'
import type { InventoryFormikContext } from './../../types/form'
import KickflipInventoryTableFilterList from './KickflipInventoryTableFilterList'
import ShopifyInventoryTableFilterList from './ShopifyInventoryTableFilterList'

export interface InventoryTableFilterListProps {
  formik: InventoryFormikContext
  dataTable: InventoryDataTable
  combinationFiltersData: CombinationFilters
  children?: React.ReactNode
}

const InventoryTableFilterList = (props: InventoryTableFilterListProps) => {
  const flags = useFlags(['inventory_management'])

  if (flags.inventory_management.enabled && flags.inventory_management.value === InventoryManagementStrategy.Shopify)
    return <ShopifyInventoryTableFilterList {...props} />

  return <KickflipInventoryTableFilterList {...props} />
}

export default InventoryTableFilterList
