import { Tabs } from '@packages/sk8/tab'
import React from 'react'

import { InventoryDataTable } from 'builder/variants/types/dataTable'
import UnsavedChangesTooltip from 'common/components/filters/UnsavedChangesTooltip'

import { FormikVariants, VariantFormValue } from '../types'

export interface VariantsTabsProps {
  formik: FormikVariants
  variants: VariantFormValue[]
  dataTable: InventoryDataTable
}

const VariantsTabs = ({ dataTable, variants, formik }: VariantsTabsProps) => {
  const hasNewVariant = !!variants.find(variant => variant.isNewFromPublish)

  return (
    <div className="flex items-center px-6 pt-2 border-b border-neutral-100">
      <Tabs className="mr-6 border-b-0 !h-8">
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <Tabs.Tab
            isSelected={dataTable.isNewFromPublish != true}
            onClick={() => dataTable.setFilter('isNewFromPublish', undefined)}
            disabled={formik.dirty}
          >
            All
          </Tabs.Tab>
        </UnsavedChangesTooltip>
        <UnsavedChangesTooltip disabled={!formik.dirty}>
          <Tabs.Tab
            isSelected={dataTable.isNewFromPublish === true}
            onClick={() => dataTable.setFilter('isNewFromPublish', true)}
            disabled={formik.dirty}
          >
            <div className="flex space-x-1 items-center">
              <span>New</span>
              {dataTable.isNewFromPublish !== true && hasNewVariant && (
                <div className="bg-tertiary-green-200 h-1.5 w-1.5 rounded-full animate-pulse absolute -right-2 top-1.5" />
              )}
            </div>
          </Tabs.Tab>
        </UnsavedChangesTooltip>
      </Tabs>
    </div>
  )
}

export default VariantsTabs
