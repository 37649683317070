import { LogoPart, LogoQuestion, Question, QuestionInputType } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React, { Suspense, useState } from 'react'

import MaskSection from 'builder/build/common/components/maskSection/MaskSection'
import Panel from 'builder/build/common/components/panels/Panel'
import useThemeSection from 'builder/build/core/useThemeSection'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as partsActions from 'builder/build/parts/actions'
import DisplaySettingsSection from 'builder/build/parts/components/DisplaySettingsSection'
import { useDispatch, useSelector } from 'cms/hooks'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'

import { patchQuestion } from '../actions'
import { linkedQuestionsSelector } from '../selectors'
import useIsClosing from './../hooks/useIsClosing'
import AnswerListSection from './sections/AnswerListSection'
import BlendingOptionSection from './sections/BlendingOptionSection'
import DescriptionSection from './sections/DescriptionSection'
import DisplayTypeSection from './sections/displayTypeSection/DisplayTypeSection'
import FiltersSection from './sections/FiltersSection'
import HeaderSection from './sections/HeaderSection'
import InputFileAnswerSection from './sections/InputFileAnswerSection'
import LinkedQuestionSection from './sections/LinkedQuestionSection'
import OrderSheetSettingsSection from './sections/OrderSheetSettingsSection'
import PrintAreaSection from './sections/PrintAreaSection'
import QuestionSettingsSection from './sections/QuestionSettingsSection'
import SwitchViewSection from './sections/SwitchViewSection'
import TitleSection from './sections/TitleSection'

interface LogoQuestionPanelProps {
  part: LogoPart
  question: LogoQuestion
}

const LogoQuestionPanel = ({ part, question }: LogoQuestionPanelProps) => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const isClosing = useIsClosing(question.id)
  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const isMainAndLinkedQuestion = useSelector(state => linkedQuestionsSelector(state, question.id)).length > 0
  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')
  const flags = useFlags(['audio_video_uploader'])

  const handleMaskApply = (maskIds: string[]) => dispatch(partsActions.setMask(part.id, maskIds))

  const updateQuestion = (field: keyof Question, value: any) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} showPrintTab />
      <div className="overflow-y-auto h-full">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="border-neutral-100" />
            <AnswerListSection question={question} />
            <hr className="border-neutral-100" />
            {InputSection && (
              <Suspense fallback={null}>
                <InputSection
                  question={question}
                  settings={
                    flags.audio_video_uploader.enabled &&
                    question.inputType === QuestionInputType.File && (
                      <InputFileAnswerSection question={question} updateQuestion={updateQuestion} />
                    )
                  }
                />
              </Suspense>
            )}
            <hr className="border-neutral-100" />
            <DisplayTypeSection
              question={question}
              settings={
                <>
                  <DisplaySettingsSection part={part} />
                  <hr className="border-neutral-100" />
                  <FiltersSection part={part} isDisabled={isEditing} />
                  <hr className="border-neutral-100" />
                  {!part.printArea && (
                    <>
                      <div className="relative px-4 py-6">
                        <MaskSection
                          partId={part.id}
                          onApply={handleMaskApply}
                          entityMasks={part.modifiers?.masks || []}
                          isDisabled={isEditing}
                          maskMessage={`Your logo will be masked if it goes \nout of the selected layers.`}
                        />
                      </div>
                      <hr className="border-neutral-100" />
                    </>
                  )}
                  <BlendingOptionSection
                    part={part}
                    isDisabled={isEditing}
                    multiplyMessage="This blends the logo with the image under it."
                  />
                </>
              }
            >
              <PrintAreaSection part={part} question={question} isClosing={isClosing} />
            </DisplayTypeSection>
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />

            <hr className="border-neutral-100" />
            {ThemeSettingsSection && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="border-neutral-100" />
              </>
            )}
            <LinkedQuestionSection
              disabled={isMainAndLinkedQuestion}
              question={question}
              linkedQuestionMessage="Link this question to another question so both have the same answer. The main question's answer will be displayed."
            />
            <hr className="border-neutral-100" />
            <SwitchViewSection entity={question} />
            <hr className="border-neutral-100" />
            <QuestionSettingsSection question={question} />
            <hr className="border-neutral-100" />
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default LogoQuestionPanel
