import { NormalizedCustomizerProduct } from '@packages/types'
import React from 'react'

import { getCombinationName } from 'builder/variants/utils'

export interface CombinationTitleProps {
  combination: string[]
  customizerProduct: NormalizedCustomizerProduct
}

const CombinationTitle = ({ combination, customizerProduct }: CombinationTitleProps) => {
  const combinationName = getCombinationName(combination, customizerProduct)

  return <h2 className="mb-4 h-8 flex items-center">{combinationName}</h2>
}

export default CombinationTitle
