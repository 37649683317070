import { Card } from '@packages/sk8/card'
import { useModal } from '@packages/sk8/modal'
import React from 'react'

import EntreprisePlanModal from '../modals/EntreprisePlanModal'

const EntreprisePlanCard = () => {
  const entrepriseModal = useModal()

  return (
    <>
      <Card
        className="bg-transparent border border-solid border-neutral-100 items-center justify-between p-3 w-full"
        shadow={false}
      >
        <span className="font-p1 text-xs mr-4" aria-label="entreprise plan short description">
          Need to integrate Kickflip with another ecommerce platform?
        </span>
        <div
          className="font-medium text-neutral-400 underline decoration-dashed underline-offset-2 hover:cursor-pointer w-max whitespace-nowrap"
          onClick={entrepriseModal.open}
        >
          Learn more
        </div>
      </Card>
      {entrepriseModal.isVisible && (
        <EntreprisePlanModal onClose={entrepriseModal.close} {...entrepriseModal.modalProps} />
      )}
    </>
  )
}

export default EntreprisePlanCard
