import { Button } from '@packages/sk8/button'
import { ModalType, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import { ToastType, useToast } from '@packages/sk8/toast'
import { BrandUserRole, User } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import * as yup from 'yup'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'
import { trpc } from 'common/hooks/trpc'
import * as usersUtils from 'common/users/utils'
import DownArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'

import useUserService from './../hooks/useUserService'
import type { UserInviteFormValues } from './../types/form'
import DeleteInviteModal from './DeleteInviteModal'
import InviteUserModal from './InviteUserModal'
import UsersActions from './UsersActions'
import VerifyInvitesModal from './VerifyInvitesModal'

const validationSchema = yup.object().shape({
  emails: yup.array().of(yup.string().email('Please enter a valid email').required('Please enter an email')),
})

const Users = () => {
  const history = useHistory()
  const match = useRouteMatch()

  const [userToDelete, setUserToDelete] = useState<User | null>(null)

  const addEmailsModal = useModal()
  const verifyInviteModal = useModal()
  const deleteInviteModal = useModal()

  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const userService = useUserService()
  const dropdownButton = usePopover({ placement: 'bottom-end' })
  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    refetch: refetchUsers,
  } = trpc.user.list.useQuery()

  const {
    data: invitedUsers,
    isLoading: isLoadingInvite,
    isFetching: isFetchingInvite,
  } = useQuery(userService.fetchInvitedUsers.queryKeys, userService.fetchInvitedUsers)

  const { mutate: deleteUserInvite, isLoading: isDeletingUserInvite } = useMutation<void, NetworkError, void>(
    () => userService.deleteInvitedUser(userToDelete!.id!),
    {
      onSuccess: () => {
        setUserToDelete(null)
        queryClient.invalidateQueries(userService.fetchInvitedUsers.queryKeys)
        openToast('Invitation was successfully deleted!', ToastType.success)
      },
      onError: () => {
        setUserToDelete(null)
        openGenericErrorToast('Invitation has not been deleted.')
      },
    }
  )

  const { mutate: deleteUser, isLoading: isDeletingUser } = trpc.user.delete.useMutation({
    onSuccess: () => {
      setUserToDelete(null)
      refetchUsers()
      openToast('User was successfully deleted!', ToastType.success)
    },
    onError: (error: NetworkError) => {
      setUserToDelete(null)
      if (error.status === 409) {
        openToast('Could not delete user. There must be at least one user.', ToastType.warning)
      } else {
        openGenericErrorToast('User has not been deleted.')
      }
    },
  })

  const { mutate: sendInvite } = useMutation<object, NetworkError, string>(value => userService.updateToken(value), {
    onSuccess: () => {
      openToast('Invite has been sent!', ToastType.success)
    },
    onError: () => {
      openGenericErrorToast('Invite has not been sent.')
    },
  })

  const { mutate: inviteUsers, isLoading: isSending } = useMutation<User, NetworkError, UserInviteFormValues>(
    values => userService.invite(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(userService.fetchInvitedUsers.queryKeys)
        openToast('Invite has been sent!', ToastType.success)
        verifyInviteModal.close()
        formik.resetForm()
      },
      onError: error => {
        queryClient.invalidateQueries(userService.fetchInvitedUsers.queryKeys)
        if (error.status === 409) {
          openToast(`An email is already in use by another user`, ToastType.warning)
        } else {
          openGenericErrorToast('We’re not able to send all your invites.')
        }
      },
    }
  )
  const data = users?.concat(invitedUsers!)
  const formik = useFormik<UserInviteFormValues>({
    initialValues: {
      emails: [''],
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const emailErrors: string[] | null = []
      const lowerCaseEmails = values.emails.map(email => email.toLowerCase())
      lowerCaseEmails.forEach((result, index) => {
        if (data?.some(user => user.email === result)) {
          emailErrors[index] = 'Email is already in use by another user.'
        }
      }),
        setErrors({ emails: emailErrors })
      if (Object.keys(emailErrors).length > 0) {
        setSubmitting(false)
      } else {
        verifyInviteModal.open()
        addEmailsModal.close()
      }
    },
  })
  const handleClose = (modalToClose: ModalType) => {
    modalToClose.close()
  }

  const isLoading = isLoadingInvite || isLoadingUsers
  const isFetching = isFetchingInvite || isFetchingUsers
  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <Page.Header>
          <h1>Users</h1>
          <div className="flex">
            <Button className="rounded-r-none" variant="primary" onClick={addEmailsModal.open}>
              Invite
            </Button>
            <div className="h-4 w-[1px] mt-2  bg-white mr-[-1px] z-[11] rounded" />
            <Button
              className="rounded-l-none focus:ring-0"
              variant="primary"
              aria-label="More users options"
              {...dropdownButton.referenceProps}
            >
              <DownArrow className="w-2.5 fill-current" />
            </Button>
            <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
              <Popover.Action className="text-tertiary-black-700" onClick={() => history.push(`${match.url}/create`)}>
                Create
              </Popover.Action>
            </Popover>
          </div>
        </Page.Header>

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell className="w-3/12">
                <span>Name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-6/12">
                <span>Email</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-3/12">
                <span>Role</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-[48px] min-w-[48px]" />
            </Table.HeaderRow>
          </Table.Header>

          {isLoading && <Table.BodyLoader numberOfColumns={4} numberOfRows={10} />}

          {!isLoading && (
            <Table.Body>
              {isFetching && <Table.Loader colSpan={4} />}
              {users?.map(user => (
                <Table.Row
                  key={user.id}
                  className="cursor-pointer"
                  onClick={() => history.push(`${match.url}/${user.id}`)}
                >
                  <Table.Cell>
                    <div className="min-w-fit">{usersUtils.getFullName(user)}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="min-w-fit">{user.email}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="min-w-fit">
                      <Tag className="bg-tertiary-green-100">{user.role}</Tag>
                    </div>
                  </Table.Cell>
                  <Table.Cell width={48}>
                    <div className="flex justify-center">
                      <UsersActions
                        email={user.email}
                        isInvited={false}
                        onDelete={() => {
                          setUserToDelete(user)
                          deleteInviteModal.open()
                        }}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
              {invitedUsers?.map(user => (
                <Table.Row key={user.id}>
                  <Table.Cell>
                    <div className="min-w-fit">-</div>
                  </Table.Cell>
                  <Table.Cell className="flex space-x-5">
                    <div className="min-w-fit">{user.email}</div>
                    <Tag className="bg-secondary-orange-75">Pending</Tag>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="min-w-fit">
                      <Tag className="bg-tertiary-green-75">admin</Tag>
                    </div>
                  </Table.Cell>
                  <Table.Cell width={48}>
                    <div className="flex justify-center">
                      <UsersActions
                        email={user.email}
                        isInvited
                        onDelete={() => {
                          setUserToDelete(user)
                          deleteInviteModal.open()
                        }}
                        resendInvite={() => sendInvite(user.email)}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </Page>
      {addEmailsModal.isVisible && (
        <InviteUserModal formik={formik} onClose={() => handleClose(addEmailsModal)} {...addEmailsModal.modalProps} />
      )}
      {verifyInviteModal.isVisible && (
        <VerifyInvitesModal
          handleModalOne={addEmailsModal}
          formik={formik}
          onClose={() => handleClose(verifyInviteModal)}
          onInvite={emails => inviteUsers({ emails })}
          isLoadingInvite={isSending}
          {...verifyInviteModal.modalProps}
        />
      )}
      {deleteInviteModal.isVisible && (
        <DeleteInviteModal
          {...deleteInviteModal.modalProps}
          userToDelete={userToDelete}
          isLoading={isDeletingUserInvite || isDeletingUser}
          onClose={deleteInviteModal.close}
          onDelete={() => {
            userToDelete?.role === BrandUserRole.Admin ? deleteUser(userToDelete.id) : deleteUserInvite()
            deleteInviteModal.close()
          }}
        />
      )}
    </main>
  )
}

export default Users
