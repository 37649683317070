import React from 'react'

import classMerge from '../../utils/classMerge'

export interface ModalImageProps {
  children?: React.ReactNode
  className?: string
}

const ModalImage = ({ children, className }: ModalImageProps) => (
  <div className={classMerge('text-neutral-600 overflow-auto', className)}>{children}</div>
)

export default ModalImage
