import { Card } from '@packages/sk8/card'
import { Price } from '@packages/sk8/price'
import { Design } from '@packages/types'
import classNames from 'classnames'
import { sumBy } from 'lodash'
import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import ExpandIcon from 'icons/regular/01-Interface Essential/52-Expand-Retract/expand-6.svg'

type OrderDetailsCardProps = {
  design?: Design
  quantity: number
  isMultiDesign: boolean
  isLoading: boolean
  currency: string
  discounts: { amount: number }[]
  orderTotal: number
}

const OrderCardHeader = ({
  design,
  quantity,
  isMultiDesign,
  isLoading,
  currency,
  discounts,
  orderTotal,
}: OrderDetailsCardProps) => {
  const match = useRouteMatch<{ brandName: string }>()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const totalDiscount = sumBy(discounts, 'amount')

  return (
    <Card.Section className={classNames('print:rounded-t-xl', { 'bg-neutral-75': isMultiDesign })}>
      <div className="w-full md:flex print:flex">
        <div className="md:flex print:flex justify-between flex-[2] w-1/2 md:border-r print:border-r border-neutral-100 mb-6 md:mb-0 print:mb-0">
          <div className="flex flex-col flex-[2] mb-6 md:mb-0 print:mb-0">
            <span className="text-xs font-medium text-neutral-600 mb-4">Product</span>
            {isLoading && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
            {!isLoading && design && <span>{design.productName}</span>}
          </div>

          <div className="flex flex-col flex-[2] pr-5 md:text-right print:text-right">
            <span className="text-xs font-medium text-neutral-600 mb-4">Design id</span>
            {isLoading && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
            {!isLoading && design && (
              <Link
                to={`${baseUrl}/designs/${design.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex md:justify-end print:justify-end"
              >
                {design.designId} <ExpandIcon className="ml-2 w-4 h-4 print:hidden" />
              </Link>
            )}
          </div>
        </div>

        <div className="flex flex-col flex-1 md:pl-5 print:pl-5 pr-20 md:border-r print:border-r border-neutral-100 mb-6 md:mb-0 print:mb-0">
          <span className="text-xs font-medium text-neutral-600 mb-4">Price details</span>
          {isLoading && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
          {!isLoading && design && (
            <div className="flex">
              {quantity} x <Price className="pl-1" amount={design.price} currency={currency} />
              {totalDiscount > 0 && (
                <Price className="text-warning-dark pl-1" amount={-totalDiscount} currency={currency} />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col md:pl-10 print:pl-10 md:text-right">
          <span className="text-xs font-medium text-neutral-600 mb-4">{isMultiDesign ? 'Product Total' : 'Total'}</span>
          {isLoading && <div className="animate-pulse h-6 bg-neutral-75 rounded max-w-xs w-full" />}
          {!isLoading && design && (
            <Price amount={isMultiDesign ? quantity * design.price - totalDiscount : orderTotal} currency={currency} />
          )}
        </div>
      </div>
    </Card.Section>
  )
}

export default OrderCardHeader
