import { Checkbox } from '@packages/sk8/input'
import React, { useRef, useState } from 'react'

import useToggleWithDelay from 'builder/build/core/useToggleWithDelay'
import useObserveDimensionsOnResize from 'common/hooks/useObserveDimensionsOnResize'
import classMerge from 'utils/classMerge'

export interface ActivatableInputProps {
  id?: string
  title: React.ReactNode
  Icon?: React.ReactNode
  children: React.ReactNode
  onActivationChange: (isActive: boolean) => void
  isActive?: boolean
  titleClassName?: string
}

const DisclosurePanel = ({ children, isOpen }: { children: React.ReactNode; isOpen: boolean }) => {
  const [height, setHeight] = useState<null | number>(null)
  const ref = useRef<HTMLDivElement>(null)

  useObserveDimensionsOnResize(ref, ({ height }) => {
    setHeight(height)
  })

  return (
    <div
      style={{
        maxHeight: isOpen ? (height == null ? 'auto' : height) : 0,
        overflow: isOpen ? 'visible' : 'hidden',
        transition: 'max-height 0.2s ease-in-out',
      }}
    >
      <div
        ref={ref}
        style={{
          transition: 'opacity 0.2s ease-in-out',
          opacity: isOpen ? 1 : 0,
        }}
      >
        {children}
      </div>
    </div>
  )
}

const ActivatableInput = ({
  Icon,
  title,
  titleClassName,
  onActivationChange,
  isActive,
  children,
  id,
}: ActivatableInputProps) => {
  const [internalIsActive, onInternalIsActiveChange] = useToggleWithDelay(isActive, onActivationChange, 300)

  return (
    <div className="flex flex-col">
      <div className="flex">
        {Icon}
        <label htmlFor={id} className={classMerge('mr-auto hover:cursor-pointer', titleClassName)}>
          {title}
        </label>
        <Checkbox id={id} onChange={onInternalIsActiveChange} checked={internalIsActive} />
      </div>

      <DisclosurePanel isOpen={internalIsActive}>
        <div className="pt-4">{children}</div>
      </DisclosurePanel>
    </div>
  )
}

export default ActivatableInput
