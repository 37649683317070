import { FilterList, SearchFilter, TagsFilter } from '@packages/sk8/filter'
import React from 'react'

import { VariantsTableFilterListProps } from '../types'

const ShopifyVariantsTableFilterList = ({
  formik,
  customizerProduct,
  filters,
  variantsCombination,
}: VariantsTableFilterListProps) => (
  <FilterList>
    <SearchFilter
      name="Search"
      setSearch={filters.setSearchFilter}
      search={filters.searchFilter}
      disabled={formik.dirty}
    />

    {variantsCombination.map(questionId => (
      <TagsFilter
        name={customizerProduct.questions[questionId].name}
        toggleFilter={answerId => filters.toggleAnswersFilters(questionId, answerId)}
        activeFilters={filters.answersFilters?.[questionId] ?? []}
        clearFilter={() => filters.clearAnswersFilters(questionId)}
        filters={customizerProduct.questions[questionId].answers.map(answerId => ({
          name: answerId,
          text: customizerProduct.answers[answerId]?.name,
          className: 'bg-neutral-100',
        }))}
        disabled={formik.dirty}
      />
    ))}
  </FilterList>
)

export default ShopifyVariantsTableFilterList
