import { TextArea } from '@packages/sk8/input'
import { QuestionType, QuestionInputType, TextPart, TextQuestion } from '@packages/types'
import React, { Suspense, useState } from 'react'

import MaskSection from 'builder/build/common/components/maskSection/MaskSection'
import Panel from 'builder/build/common/components/panels/Panel'
import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import useThemeSection from 'builder/build/core/useThemeSection'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as partsActions from 'builder/build/parts/actions'
import DisplaySettingsSection from 'builder/build/parts/components/DisplaySettingsSection'
import useSelectPartAnswer from 'builder/build/parts/hooks/useSelectPartAnswer'
import { useDispatch, useSelector } from 'cms/hooks'
import * as customizerProductsUtils from 'common/customizerProducts/utils'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'

import { patchQuestion } from '../actions'
import { linkedQuestionsSelector } from '../selectors'
import useIsClosing from './../hooks/useIsClosing'
import AddQuestionButton from './sections/AddQuestionButton'
import AnswerListSection from './sections/AnswerListSection'
import BlendingOptionSection from './sections/BlendingOptionSection'
import DescriptionSection from './sections/DescriptionSection'
import DisplayTypeSection from './sections/displayTypeSection/DisplayTypeSection'
import EngravingSection from './sections/EngravingSection'
import HeaderSection from './sections/HeaderSection'
import InputTextAnswerSection from './sections/InputTextAnswerSection'
import LinkedQuestionSection from './sections/LinkedQuestionSection'
import OrderSheetSettingsSection from './sections/OrderSheetSettingsSection'
import PrintAreaSection from './sections/PrintAreaSection'
import QuestionSettingsSection from './sections/QuestionSettingsSection'
import SwitchViewSection from './sections/SwitchViewSection'
import TitleSection from './sections/TitleSection'

export interface TextQuestionPanelProps {
  question: TextQuestion
  part: TextPart
}

const TextQuestionPanel = ({ question, part }: TextQuestionPanelProps) => {
  const dispatch = useDispatch()
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const isClosing = useIsClosing(question.id)
  const isEditing = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.isEditingSelector)
  const isMainAndLinkedQuestion = useSelector(state => linkedQuestionsSelector(state, question.id)).length > 0

  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')

  const updateQuestion = (field: keyof TextQuestion, value: any) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  const handleMaskApply = (maskIds: string[]) => {
    dispatch(partsActions.setMask(part.id, maskIds))
  }

  const [colorAnswer] = useSelectPartAnswer(part?.color)
  const [fontAnswer] = useSelectPartAnswer(part?.font)
  const [fontSizeAnswer] = useSelectPartAnswer(part?.fontSize)
  const [outlineAnswer] = useSelectPartAnswer(part?.outline)
  const [positionAnswer] = useSelectPartAnswer(part?.position)
  const isMultiline = customizerProductsUtils.isMultilineTextAnswer(positionAnswer)

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} showPrintTab />
      <div className="overflow-y-auto h-full">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="border-neutral-100" />

            {![QuestionInputType.None, QuestionInputType.Text].includes(question.inputType) && (
              <>
                <AnswerListSection question={question} />
                <hr className="border-neutral-100" />
              </>
            )}
            {question.inputType === QuestionInputType.Text && (
              <>
                <div className="relative px-4 py-6">
                  <div className="flex flex-col space-y-4">
                    <label className="font-medium">Placeholder</label>
                    {isMultiline && (
                      <TextArea
                        placeholder="Enter placeholder"
                        value={question.placeholder || ''}
                        onChange={value => updateQuestion('placeholder', value)}
                        className="py-[5px]"
                      />
                    )}
                    {!isMultiline && (
                      <PatchTextInput
                        placeholder="Enter placeholder"
                        value={question.placeholder || ''}
                        onChange={e => updateQuestion('placeholder', e.target.value)}
                      />
                    )}
                  </div>
                </div>

                <hr className="border-neutral-100" />
              </>
            )}
            {InputSection && (
              <Suspense fallback={null}>
                <InputSection
                  question={question}
                  settings={
                    question.inputType === QuestionInputType.Text && (
                      <InputTextAnswerSection question={question} updateQuestion={updateQuestion} />
                    )
                  }
                />
              </Suspense>
            )}
            <hr className="border-neutral-100" />
            <DisplayTypeSection
              question={question}
              settings={
                <>
                  <DisplaySettingsSection part={part} />
                  <hr className="border-neutral-100" />
                  {!part.printArea && (
                    <>
                      <div className="relative px-4 py-6">
                        <MaskSection
                          partId={part.id}
                          entityMasks={part.modifiers?.masks || []}
                          onApply={handleMaskApply}
                          isDisabled={isEditing}
                          maskMessage={`Your text will be masked if it goes \nout of the selected layers.`}
                        />
                      </div>
                      <hr className="border-neutral-100" />
                    </>
                  )}
                  <EngravingSection part={part} />
                  <hr className="border-neutral-100" />
                  <BlendingOptionSection
                    part={part}
                    isDisabled={isEditing}
                    multiplyMessage="This blends the text with the image under it."
                    allowNeon
                    neonMessage="Adds neon effect to your text. (Neon effect won't be applied in print files.)"
                  />
                </>
              }
            >
              <PrintAreaSection part={part} question={question} isClosing={isClosing} />
            </DisplayTypeSection>
            {(!!colorAnswer || !!fontAnswer || !!fontSizeAnswer || !!outlineAnswer) && (
              <>
                <hr className="border-neutral-100" />
                <div className="py-6">
                  <div className="font-medium mb-4 mx-4">Create</div>
                  <div className="mx-2 flex flex-col space-y-4">
                    {colorAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.Color} menuPosition="bottom-end" />
                    )}
                    {fontAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.Font} menuPosition="bottom-end" />
                    )}
                    {fontSizeAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.FontSize} menuPosition="bottom-end" />
                    )}
                    {outlineAnswer != null && (
                      <AddQuestionButton part={part} type={QuestionType.Outline} menuPosition="bottom-end" />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />
            <hr className="border-neutral-100" />
            {ThemeSettingsSection && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="border-neutral-100" />
              </>
            )}
            <LinkedQuestionSection
              disabled={isMainAndLinkedQuestion}
              question={question}
              linkedQuestionMessage="Link this question to another question so both have the same answer. The main question's answer will be displayed."
            />
            <hr className="border-neutral-100" />
            <SwitchViewSection entity={question} />

            <hr className="border-neutral-100" />
            <QuestionSettingsSection question={question} />
            <hr className="border-neutral-100" />
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default TextQuestionPanel
