import { Button } from '@packages/sk8/button'
import classNames from 'classnames'
import React from 'react'

import { useDispatch, useSelector } from 'cms/hooks'
import RulesIcon from 'icons/core-solid/interface-essential/interface-hierarchy-10.svg'

import { setBuilderMode } from '../actions'
import { builderModeSelector } from '../selectors'
import { BuilderMode } from './../types/builderMode'

const BuilderNavigator = () => {
  const dispatch = useDispatch()
  const builderMode = useSelector(builderModeSelector)

  return (
    <div className="flex items-center h-full mr-2">
      <Button
        className={classNames({
          'text-primary-600': builderMode === BuilderMode.logic,
        })}
        icon={<RulesIcon className={classNames('w-3.5 h-3.5 fill-current')} />}
        onClick={() =>
          dispatch(setBuilderMode(builderMode === BuilderMode.logic ? BuilderMode.customizer : BuilderMode.logic))
        }
      >
        Logic
      </Button>
    </div>
  )
}

export default BuilderNavigator
