import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

import OrderIcon from 'icons/regular/07-Work-Office-Companies/07-Office-Files/office-file-sheet.svg'

const OrdersEmptyBlankState = () => (
  <div className="flex items-center justify-center py-32 ">
    <BlankState className="max-w-xs">
      <BlankState.Icon Icon={OrderIcon} />
      <BlankState.Title>Customers haven't created orders, yet.</BlankState.Title>
      <BlankState.Details>Orders will appear here as soon as your customers buy your products.</BlankState.Details>
    </BlankState>
  </div>
)

export default OrdersEmptyBlankState
