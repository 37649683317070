import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { FormikProps } from 'formik'
import React from 'react'

import AddUsersEmails from './AddUsersEmails'

interface InviteUserModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  formik: FormikProps<{ emails: string[] }>
  onClose: () => void
}

const InviteUserModal = ({ formik, onClose, ...modalProps }: InviteUserModalProps) => {
  return (
    <main>
      <Modal size="medium" {...modalProps}>
        <Modal.Title>Invite new user</Modal.Title>
        <AddUsersEmails formik={formik} />
        <Modal.Actions>
          <Button
            onClick={() => {
              onClose()
              formik.resetForm()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => {
              formik.handleSubmit()
            }}
            type="submit"
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    </main>
  )
}

export default InviteUserModal
