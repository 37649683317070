import { BulkOrder } from '@packages/types'
import React from 'react'

import FolderSettingsTab from '../folder/FolderSettingsTab'
import BulkOrderLabelSettingsSection from './BulkOrderLabelSettingsSection'
import BulkOrderQuantitySection from './BulkOrderQuantitySection'

interface BulkOrderSettingsTabProps {
  bulkOrderGroup: BulkOrder
}

const BulkOrderSettingsTab = ({ bulkOrderGroup }: BulkOrderSettingsTabProps) => {
  return (
    <>
      <FolderSettingsTab group={bulkOrderGroup} />
      <hr className="border-neutral-100" />
      <BulkOrderQuantitySection bulkOrderGroup={bulkOrderGroup} />
      <hr className="border-neutral-100" />
      <BulkOrderLabelSettingsSection bulkOrderGroup={bulkOrderGroup} />
    </>
  )
}

export default BulkOrderSettingsTab
