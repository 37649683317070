import { RichTextEditor } from '@packages/sk8/rich-text-editor'
import React from 'react'

import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import { PatchTextInputChangeEvent } from 'common/components/PatchTextInput'

import ActivatableInput, { ActivatableInputProps } from './ActivatableInput'

export interface ActivatableTextInputProps extends Omit<ActivatableInputProps, 'children'> {
  textarea?: boolean
  onChange: (e: PatchTextInputChangeEvent) => void
  value: string
  isRichText?: boolean
  children?: React.ReactNode
}

const ActivatableTextInput = ({
  id,
  title,
  value,
  onChange,
  onActivationChange,
  isActive,
  textarea,
  Icon,
  isRichText = false,
  titleClassName,
}: ActivatableTextInputProps) => {
  return (
    <ActivatableInput
      id={id}
      Icon={Icon}
      title={title}
      onActivationChange={onActivationChange}
      isActive={isActive}
      titleClassName={titleClassName}
    >
      {isRichText && textarea ? (
        <RichTextEditor value={value} onChange={onChange} />
      ) : (
        <PatchTextInput value={value} onChange={onChange} textarea={textarea} />
      )}
    </ActivatableInput>
  )
}

export default ActivatableTextInput
