import { Button, IconButton } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Tooltip } from '@packages/sk8/tooltip'
import { QuestionType, AnswerType, Part, Answer, OutlineView } from '@packages/types'
import { merge, throttle } from 'lodash'
import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import * as answersActions from 'builder/build/answers/actions'
import Panel from 'builder/build/common/components/panels/Panel'
import PatchSketchPicker from 'builder/build/common/components/PatchSketchPicker'
import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import * as builderConstants from 'builder/build/common/constants'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import { useDispatch } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'
import OutlineIcon from 'icons/custom/outline.svg'
import { min } from 'utils/validators'

import AddQuestionButton from './AddQuestionButton'

import './DefaultOutlineSection.scss'

const defaultAnswer = constants.answers.default[AnswerType.Outline] as OutlineView

interface DefaultOutlineSectionProps {
  part: Part
  answer?: Answer
  disabled?: boolean
  questionId?: string
}

const DefaultOutlineSection = ({ part, answer, disabled, questionId }: DefaultOutlineSectionProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const dropdownButton = usePopover({ placement: 'bottom' })
  const currentView = useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const answerView = (answer?.views?.[currentView] as OutlineView | undefined) || defaultAnswer
  const [width, setWidth] = useState(answerView.width)
  const isDisabled = disabled || !!questionId

  const handleOpenQuestionClick = () => history.push(`/questions/${questionId}`)

  const updateOutlineWidth = useCallback(
    (newWidth: number) => {
      {
        if (!isDisabled) {
          dispatch(answersActions.patchAnswer(answer, { views: { [currentView]: { width: newWidth } } }))
        }
      }
    },
    [answer?.id, currentView, isDisabled]
  )

  const updateOutlineWidthThrottled = useCallback(throttle(updateOutlineWidth, 200), [updateOutlineWidth])

  const updateOutlineColor = (newColor: string) => {
    dispatch(answersActions.patchAnswerViews(answer, { color: newColor }))
  }

  useEffect(() => {
    if (!isDisabled && answer && width !== answerView.width) updateOutlineWidthThrottled(width)
  }, [width])

  useEffect(() => {
    if (answer && answerView.width !== width) setWidth(answerView.width)
  }, [answer?.id, currentView])

  return (
    <>
      <Tooltip
        content={
          !!questionId ? (
            <div className="flex items-center space-x-2">
              <span>Outlines are handled by a question.</span>
              <Button onClick={handleOpenQuestionClick} variant="text">
                Open
              </Button>
            </div>
          ) : (
            'Outline'
          )
        }
      >
        <IconButton variant="subtle" disabled={isDisabled} Icon={OutlineIcon} {...dropdownButton.referenceProps} />
      </Tooltip>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen} className="w-48">
        <Panel side="right" className="builder">
          <div className="default-outline-section">
            <div className="panel__row">
              <PatchTextInput
                className="w-16 mr-2 px-1.5"
                inputClassName="appearance-number-none"
                value={width}
                onChange={e => setWidth(+e.target.value)}
                type="number"
                min="0"
                validator={min(0)}
                rightAddon="px"
              />
              <input type="range" min="0" max="100" value={width} onChange={e => setWidth(+e.target.value)} />
            </div>
          </div>
        </Panel>
        <div className="px-4">
          <PatchSketchPicker
            styles={{
              default: merge(builderConstants.colorPickerStyle, {
                saturation: {
                  marginLeft: '-1rem',
                  marginRight: '-1rem',
                  width: 'calc(100% + 2rem)',
                  paddingBottom: '75%',
                  position: 'relative',
                  overflow: 'hidden',
                } as CSSProperties,
              }),
            }}
            disableAlpha
            color={answerView.color}
            onChange={color => updateOutlineColor(color.hex)}
            presetColors={[]}
          />
        </div>
        <div className="px-2 py-4">
          <AddQuestionButton onClick={dropdownButton.close} part={part} type={QuestionType.Outline} />
        </div>
      </Popover>
    </>
  )
}

export default DefaultOutlineSection
