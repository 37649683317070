import { OnlineStore, ShopifyStartingPointBinding, StartingPoint } from '@packages/types'

import useOnlineStores from '../common/connection/useOnlineStores'

const useShopifyOnlineStores = () => {
  const isProductConnected = (store: OnlineStore, defaultStartingPoint: StartingPoint) => {
    const productId = defaultStartingPoint?.productId
    const bindings = (store.productsData[productId]?.startingPointsBindings || []) as ShopifyStartingPointBinding[]
    const defaultBinding = bindings.find(binding => binding.startingPointId === defaultStartingPoint?.id)
    return defaultBinding?.shopifyProductId != null
  }

  return useOnlineStores({ isProductConnected })
}

export default useShopifyOnlineStores
