import classNames from 'classnames'
import React from 'react'

import AddIcon from '../../icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import SubtractIcon from '../../icons/bold/01-Interface Essential/43-Remove-Add/subtract.svg'

export interface PlusMinusToggleProps {
  value?: number
  onValueChange?: (value: number) => void
  disabled?: boolean
  small?: boolean
}

const PlusMinusToggle = ({ value, onValueChange, disabled, small }: PlusMinusToggleProps) => {
  const isValueNegative = value && value < 0
  const isValuePositive = value && value >= 0
  const isValueZero = value === 0

  const handleToggle = () => {
    if (value && onValueChange) {
      onValueChange(-value)
    }
  }

  return (
    <div
      className={classNames('h-8 flex border-neutral-50 shadow-xs rounded-lg', {
        'bg-neutral-50': disabled || isValueZero,
      })}
    >
      <button
        className={classNames('rounded-lg focus:outline-none', {
          'ring-1 ring-neutral-200 bg-neutral-50': isValuePositive,
          'fill-neutral-300': disabled || isValueZero,
          'px-3': !small,
          'px-2.5': small,
        })}
        onClick={handleToggle}
        disabled={isValuePositive || isValueZero || disabled}
        aria-label="plus"
      >
        <AddIcon className={classNames('w-3.5', { 'p-0.5': small })} />
      </button>
      <button
        className={classNames('rounded-lg focus:outline-none', {
          'ring-1 ring-neutral-200 bg-neutral-50': isValueNegative,
          'fill-neutral-300': disabled || isValueZero,
          'px-3': !small,
          'px-2.5': small,
        })}
        onClick={handleToggle}
        disabled={isValueNegative || isValueZero || disabled}
        aria-label="minus"
      >
        <SubtractIcon className={classNames('w-3.5', { 'p-0.5': small })} />
      </button>
    </div>
  )
}

export default PlusMinusToggle
