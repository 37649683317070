import React, { useEffect, useState } from 'react'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'

import classMerge from '../../utils/classMerge'

interface TextAreaProps extends Omit<TextareaAutosizeProps, 'onChange' | 'onBlur'> {
  value?: string
  hasError?: boolean
  onChange: (value: string) => void
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ hasError, disabled, className, value, onChange, ...props }, ref) => {
    const [internalValue, setInternalValue] = useState(value || '')

    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => setInternalValue(e.target.value)

    const handleBlur = () => onChange(internalValue)

    useEffect(() => {
      if (value !== internalValue) setInternalValue(value || '')
    }, [value])

    return (
      <TextareaAutosize
        ref={ref}
        role="textbox"
        disabled={disabled}
        className={classMerge(
          'relative flex items-center',
          'px-2 py-1.5 w-full font-input text-neutral-800 placeholder-neutral-300',
          'border-neutral-100 rounded-lg border-solid border',
          'focus:outline-none focus:border-primary-200 focus:ring focus:ring-primary-100',
          {
            'bg-neutral-50 text-neutral-200 border-transparent': disabled,
            'border-error-default': hasError,
          },
          className
        )}
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
    )
  }
)

export default TextArea
