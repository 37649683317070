import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { HelperText, InputField, Label, PasswordInput } from '@packages/sk8/input'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as yup from 'yup'

import type { PasswordFormValues } from 'cms/users/types/form'
import PublicFormFooter from 'common/components/PublicFormFooter'

import useAuthService from './../hooks/useAuthService'
import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'
import AuthLink from './AuthLink'

const formValidation = yup.object().shape({
  password: yup
    .string()
    .required('Please fill the password field')
    .min(5, 'Password should be at least 5 characters long'),
  passwordConfirmation: yup
    .string()
    .required('Please fill the confirm password field')
    .equals([yup.ref('password')], 'The specified password does not match'),
})

interface RouteParams {
  token: string
}

export interface ChangePasswordFormProps extends RouteComponentProps<RouteParams> {
  initialValues?: Partial<PasswordFormValues>
}

const ChangePasswordForm = ({ initialValues, match, history }: ChangePasswordFormProps) => {
  const { token } = match.params
  const authService = useAuthService()

  const { isSuccess: isTokenValid } = useQuery(
    authService.validatePasswordResetToken.queryKeys,
    () => authService.validatePasswordResetToken(token),
    {
      onError: () => history.replace('/login/resetpassword-error'),
      retry: false,
    }
  )

  const { mutate: changePassword, isError } = useMutation(
    (values: PasswordFormValues) => authService.changePassword(token, values.password),
    {
      onSuccess: () => history.push(`/login/resetpassword-success`),
    }
  )

  const formik = useFormik<PasswordFormValues>({
    initialValues: { password: '', passwordConfirmation: '', ...initialValues },
    validationSchema: formValidation,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => changePassword(values, { onSettled: () => setSubmitting(false) }),
  })

  if (!isTokenValid) {
    return null
  }

  return (
    <AuthFormContainer>
      <AuthFormTitle>Change password</AuthFormTitle>

      {isError && (
        <Alert variant="error" className="mb-8">
          <Alert.Body>
            <Alert.Title>Oops… Something went wrong.</Alert.Title>
            <Alert.Details>
              <p>Try to refresh the page.</p>
              <p className="mt-1">
                If the problem persists, please contact our
                <a className="ml-1 text-primary-500" href="mailto:help@gokickflip.com" target="_blank">
                  customer support team
                </a>
                .
              </p>
            </Alert.Details>
          </Alert.Body>
        </Alert>
      )}

      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField className="mb-6">
          <Label htmlFor="password">New password</Label>
          <PasswordInput
            id="password"
            name="password"
            placeholder="Enter password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.password && formik.errors.password != null}
          />
          {formik.touched.password && formik.errors.password != null && (
            <HelperText hasError>{formik.errors.password}</HelperText>
          )}
        </InputField>
        <InputField className="mb-6">
          <Label htmlFor="passwordConfirmation">Confirm password</Label>
          <PasswordInput
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder="Enter password"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null}
          />
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null && (
            <HelperText hasError>{formik.errors.passwordConfirmation}</HelperText>
          )}
        </InputField>
        <PublicFormFooter>
          <Button
            id="submit-button"
            isLoading={formik.isSubmitting}
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className="w-full mb-4 lg:w-fit lg:mb-0"
          >
            Change password
          </Button>
          <AuthLink to="/login">Back to login</AuthLink>
        </PublicFormFooter>
      </form>
    </AuthFormContainer>
  )
}

export default ChangePasswordForm
