import { useQuery } from '@tanstack/react-query'

import { trpc } from 'common/hooks/trpc'

import useSubscriptionService from './useSubscriptionService'

const useIsSubscribedToAddon = () => {
  const subscriptionService = useSubscriptionService()

  const { data: availableAddons } = trpc.addon.listAvailable.useQuery()

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)

  if (!subscription || !availableAddons) return null

  const subscribedAddons = subscription.addons ?? []
  const additionalSubscribedAddons = subscribedAddons.filter(
    addon => !availableAddons.find(availableAddon => availableAddon.addonId === addon.addonId)
  )

  const allAddons = [...availableAddons, ...additionalSubscribedAddons]

  return allAddons.map(addon => ({
    isSubscribed: subscription.addons.find(subscribedAddon => subscribedAddon.addonId === addon.addonId) != null,
    addon,
  }))
}

export default useIsSubscribedToAddon
