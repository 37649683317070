import { Alert } from '@packages/sk8/alert'
import { Card } from '@packages/sk8/card'
import { FullPageModal, Modal } from '@packages/sk8/modal'
import { Addon, AddonId } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import { trpc } from 'common/hooks/trpc'

import ActivateOrChangePlanCard from '../cards/ActivateOrChangePlanCard'
import StripeActivationFeedbackCard from '../cards/StripeActivationFeedbackCard'
import StripeChangePlanFeedbackCard from '../cards/StripeChangePlanFeedbackCard'
import SubscriptionCheckList from '../SubscriptionCheckList'
import SubscriptionQuestionAndAnswer from '../SubscriptionQuestionAndAnswer'
import useSubscriptionService from './../../hooks/useSubscriptionService'
import AddonsList from './../cards/AddonsList'
import EntreprisePlanCard from './../cards/EntreprisePlanCard'
import EstimatePriceCard from './../cards/EstimatePriceCard'
import PlanCard from './../cards/PlanCard'
import { adminTools, customizationExperiencesAdvantages, questionAnswerTuples } from './subscriptionPageData'

export interface FullPageBillingPlansProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
  selectAddonIds?: AddonId[]
}

export enum TypeOfModalPage {
  stripe = 'stripe',
  stripeConfirmation = 'stripe_confirmation',
  default = 'default',
}

const SubscriptionFullPageModal = ({ onClose, selectAddonIds = [], ...modalProps }: FullPageBillingPlansProps) => {
  const [showStripeActivationFeedBack, setShowStripeActivationFeedBack] = useState(false)
  const [showStripeChangePlanFeedBack, setShowStripeChangePlanFeedBack] = useState(false)

  const [addonsSubscribed, setAddonsSubscribed] = useState<Addon[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)

  const applyInitialSelectAddons = useRef<boolean>(true)

  const subscriptionService = useSubscriptionService()
  const onlineStoreService = useOnlineStoreService()

  const { data: availablePlans } = trpc.plan.listAvailable.useQuery()
  const { data: availableAddons } = trpc.addon.listAvailable.useQuery()
  const { data: subscription, refetch: refetchSubscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne,
    {
      onSuccess: data => {
        if (!initialized) {
          setAddonsSubscribed(addons => [
            ...addons,
            ...data.addons.filter((addon: Addon) => !addons.find(addedAddon => addedAddon.addonId === addon.addonId)),
          ])
          setInitialized(true)
        }
      },
    }
  )
  const { data: onlineStores = [] } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll)

  useEffect(() => {
    if (availableAddons && selectAddonIds.length > 0 && applyInitialSelectAddons.current) {
      const selectedAddons = availableAddons.filter(addon => selectAddonIds.includes(addon.addonId))
      setAddonsSubscribed(addons => [
        ...addons,
        ...selectedAddons.filter((addon: Addon) => !addons.find(addedAddon => addedAddon.addonId === addon.addonId)),
      ])
      applyInitialSelectAddons.current = false
    }
  }, [selectAddonIds, availableAddons])

  const handleClose = () => {
    refetchSubscription()
    onClose()
  }

  if (!subscription || !availablePlans || !availableAddons || !onlineStores) return null

  const plan = availablePlans[0]
  const isPlanAvailable = !subscription.plan.custom && subscription.plan.planId === plan.planId

  return (
    <FullPageModal className="flex" onClose={handleClose} {...modalProps}>
      <div className="flex max-w-4xl items-start w-[410px] md:w-auto">
        {!showStripeActivationFeedBack && !showStripeChangePlanFeedBack && (
          <div className="flex-col">
            {!isPlanAvailable && (
              <Alert aria-label="legacy plan warning" variant="warning" className="my-4">
                We are no longer offering your plan. It can't be recovered if you change plans.
              </Alert>
            )}
            <div className="flex items-start space-x-6 mt-4">
              <div className="w-full flex flex-col">
                <div className="w-full items-center md:items-start md:flex-row flex md:space-x-6 md:space-y-0 flex-col space-y-6">
                  <PlanCard
                    plan={plan}
                    addonsSubscribed={addonsSubscribed}
                    addons={
                      <AddonsList
                        availableAddons={availableAddons}
                        subscriptionAddons={addonsSubscribed}
                        onAdd={addon => {
                          setAddonsSubscribed([...addonsSubscribed, addon])
                        }}
                        onRemove={addon => {
                          setAddonsSubscribed(addonsSubscribed.filter(a => a.addonId !== addon.addonId))
                        }}
                      />
                    }
                  />
                  <div className="w-full ">
                    <EstimatePriceCard plan={plan} addons={addonsSubscribed} />

                    <div className="pt-8">
                      <ActivateOrChangePlanCard
                        onlineStores={onlineStores}
                        plan={plan}
                        subscription={subscription}
                        addons={addonsSubscribed}
                        onWixSuccess={handleClose}
                        onStripeActivateSuccess={() => setShowStripeActivationFeedBack(true)}
                        onStripeChangePlanSuccess={() => setShowStripeChangePlanFeedBack(true)}
                      />
                    </div>
                    <div className="flex flex-col space-y-3 pt-8 px-4 font-p2">
                      <span>* All prices are in US dollars</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-8 space-y-16">
              <EntreprisePlanCard />

              <Card shadow={false} className="flex-col border border-neutral-100 w-full">
                <Card.Section>
                  <h1>What's included</h1>
                </Card.Section>
                <Card.Section className="pt-2">
                  <div className="grid grid-cols-2 gap-12 w-full">
                    <SubscriptionCheckList
                      list={customizationExperiencesAdvantages.checklist}
                      title={customizationExperiencesAdvantages.title}
                    />
                    <SubscriptionCheckList list={adminTools.checklist} title={adminTools.title} />
                  </div>
                </Card.Section>
              </Card>
              <div>
                <h1 className="pb-5">Frequently asked questions</h1>
                <div className="grid grid-cols-2 gap-12 pb-20">
                  {questionAnswerTuples.map((item, index) => {
                    return <SubscriptionQuestionAndAnswer key={index} question={item.question} answer={item.answer} />
                  })}
                </div>
              </div>
            </div>
          </div>
        )}

        {showStripeActivationFeedBack && <StripeActivationFeedbackCard />}
        {showStripeChangePlanFeedBack && <StripeChangePlanFeedbackCard />}
      </div>
    </FullPageModal>
  )
}

export default SubscriptionFullPageModal
