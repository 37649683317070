import './Select.css'

import React from 'react'
import { components, GroupBase, OptionProps, Theme as SelectTheme } from 'react-select'
import { AsyncPaginate as ReactSelectAsync, AsyncPaginateProps } from 'react-select-async-paginate'

import tailwindConfig from '../../../../tailwind.config'
import Remove from '../../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from '../../../utils/classMerge'

export interface SelectAsyncProps<T>
  extends Omit<AsyncPaginateProps<T, GroupBase<T>, { page: number }, false>, 'isDisabled'> {
  className?: string
  disabled?: boolean
  hasError?: boolean
  optimize?: boolean
}

const getSelectTheme = (providedTheme: SelectTheme): SelectTheme => ({
  ...providedTheme,
  colors: {
    ...providedTheme.colors,
    primary: tailwindConfig.theme.colors.primary[500],
    primary75: tailwindConfig.theme.colors.primary[800],
    primary50: tailwindConfig.theme.colors.primary[100],
    primary25: tailwindConfig.theme.colors.neutral[100],
    danger: tailwindConfig.theme.colors.error.default,
    dangerLight: tailwindConfig.theme.colors.error.light,
    neutral0: tailwindConfig.theme.colors.white,
    neutral5: tailwindConfig.theme.colors.neutral[50],
    neutral10: tailwindConfig.theme.colors.neutral[100],
    neutral20: tailwindConfig.theme.colors.neutral[200],
    neutral30: tailwindConfig.theme.colors.neutral[300],
    neutral60: tailwindConfig.theme.colors.neutral[600],
    neutral70: tailwindConfig.theme.colors.neutral[700],
    neutral80: tailwindConfig.theme.colors.neutral[800],
    neutral90: tailwindConfig.theme.colors.neutral[900],
  },
  spacing: { ...providedTheme.spacing, controlHeight: 28, baseUnit: 4 },
  borderRadius: 8,
})

function OptimizedOption<T>(props: OptionProps<T>) {
  const { innerProps, isFocused, ...otherProps } = props
  const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps
  const newProps = { innerProps: { ...otherInnerProps }, ...otherProps, isFocused: false }
  return <components.Option {...newProps}>{props.children}</components.Option>
}

function SelectAsync<T = unknown>({ optimize = false, className, disabled, hasError, ...rest }: SelectAsyncProps<T>) {
  const overrideComponents = optimize ? { Option: OptimizedOption } : {}

  return (
    <ReactSelectAsync
      debounceTimeout={300}
      isDisabled={disabled}
      theme={getSelectTheme}
      classNamePrefix="react-select"
      className={classMerge(className, 'h-8', { 'react-select-error': hasError })}
      components={{
        MultiValueRemove: ({ innerProps }) => (
          <div {...innerProps} className="px-1 cursor-pointer fill-neutral-600 flex justify-center items-center">
            <Remove width={8} />
          </div>
        ),
        ...overrideComponents,
      }}
      additional={{ page: 1 }}
      {...rest}
      loadOptionsOnMenuOpen
    />
  )
}

export default SelectAsync
