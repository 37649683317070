import { Button } from '@packages/sk8/button'
import { Variant } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { trpc } from 'common/hooks/trpc'
import type { PaginatedVariants } from 'common/variants/types/variant'

import type { InventoryFormikContext } from './../types/form'
import type { GetVariantsInput } from './../types/queries'

const SaveRowButton = ({
  locationId,
  variant,
  formik,
  getVariantsInput,
}: {
  locationId: string
  variant: Variant
  formik: InventoryFormikContext
  getVariantsInput: GetVariantsInput
}) => {
  const trpcContext = trpc.useContext()
  const { mutate: updateInventoryLevels, isLoading } = trpc.inventory.item.updateStocks.useMutation({
    onSuccess: (_data, { updates }) => {
      trpcContext.variant.get.setInfiniteData(
        getVariantsInput,
        (oldData: { pages: PaginatedVariants[]; pageParams: unknown[] } | undefined) => {
          if (!oldData) return
          return {
            ...oldData,
            pages: oldData.pages.map(page => ({
              ...page,
              results: page.results.map(existingVariant =>
                existingVariant.inventoryItem.id === variant.inventoryItem.id
                  ? {
                      ...existingVariant,
                      inventoryItem: {
                        ...existingVariant.inventoryItem,
                        inventoryLevelByLocationId: {
                          ...existingVariant.inventoryItem.inventoryLevelByLocationId,
                          [locationId]: {
                            ...existingVariant.inventoryItem.inventoryLevelByLocationId[locationId],
                            stock: updates[0].stock,
                          },
                        },
                      },
                    }
                  : existingVariant
              ),
            })),
          }
        }
      )
    },
    onError: () => {},
  })

  const inventoryItem = formik.values[variant.inventoryItem.id]

  const dirty =
    formik.values?.[variant.inventoryItem.id] &&
    formik.initialValues?.[variant.inventoryItem.id]?.stock !== formik.values?.[variant.inventoryItem.id]?.stock

  return (
    <Button
      small
      disabled={!dirty || isLoading}
      className={classNames('text-primary-500', { 'opacity-0': !dirty })}
      isLoading={isLoading}
      onClick={() => {
        updateInventoryLevels({
          locationId,
          updates: [{ id: inventoryItem.id, stock: inventoryItem?.stock }],
        })
      }}
    >
      Save
    </Button>
  )
}

export default SaveRowButton
