import * as normalize from '@packages/normalizer'
import {
  BulkOrder,
  DenormalizedCustomizableBulkOrder,
  DenormalizedCustomizableQuestion,
  GroupType,
} from '@packages/types'
import { omit } from 'lodash'
import { createSelector } from 'reselect'

import type { CustomizationState } from 'customizer/customization/reducer/reducer'
import { customizationSelector, groupsSelector } from 'customizer/customization/selectors'
import type { RootState } from 'customizer/store'

import type { BulkOrderRow, DenormalizedBulkOrderRow } from './reducer'

const { Denormalizer } = normalize.customizerProduct

const showInteractionRequiredWarningsSelector = (state: RootState) => {
  return state.questionPanel.showInteractionRequiredWarnings
}

export const bulkOrderGroupSelector = (state: RootState) => {
  return Object.values(state.customization.groups).find(group => group.type === GroupType.BulkOrder) as BulkOrder
}

export const bulkOrderDenormalizedGroupSelector = createSelector(groupsSelector, groups => {
  return Object.values(groups).find(group => group.type === GroupType.BulkOrder) as
    | DenormalizedCustomizableBulkOrder
    | undefined
})

export const bulkOrderIsFormVisibleSelector = ({ bulkOrder }: RootState) => bulkOrder?.isFormVisible

export const bulkOrderSelector = (state: RootState) => state.bulkOrder

export const bulkOrderRowsSelector = (state: RootState) => state.bulkOrder.rows

export const bulkOrderTotalQuantitySelector = (state: RootState) => {
  return state.bulkOrder.rows
    .map(row => (Number.isInteger(row.quantity) ? row.quantity : 0))
    .reduce((prev, next) => prev + next, 0)
}

export const isBulkOrderTotalQuantityMoreThanMaxSelector = createSelector(
  bulkOrderGroupSelector,
  bulkOrderTotalQuantitySelector,
  (bulkOrderGroup, bulkOrderTotalItems) => {
    if (!bulkOrderGroup) return false

    const { max } = bulkOrderGroup.quantity

    return bulkOrderTotalItems > max && max !== 0
  }
)

export const isBulkOrderTotalQuantityEqualOrMoreThanMaxSelector = createSelector(
  bulkOrderGroupSelector,
  bulkOrderTotalQuantitySelector,
  (bulkOrderGroup, bulkOrderTotalItems) => {
    if (!bulkOrderGroup) return false

    const { max } = bulkOrderGroup.quantity

    return bulkOrderTotalItems >= max && max !== 0
  }
)

export const isBulkOrderTotalQuantityValidSelector = createSelector(
  bulkOrderGroupSelector,
  bulkOrderTotalQuantitySelector,
  (bulkOrderGroup, bulkOrderTotalItems) => {
    if (!bulkOrderGroup) return false

    const { min, max } = bulkOrderGroup.quantity

    return bulkOrderTotalItems >= min && (bulkOrderTotalItems <= max || max === 0)
  }
)

export const denormalizedBulkOrderQuestionsSelector = createSelector(
  customizationSelector,
  bulkOrderSelector,
  (customization, bulkOrder) => {
    return bulkOrder.rows.map(row => {
      return {
        ...omit(row, 'values'),
        questions: row.values.map(value => {
          return Denormalizer.run(
            {
              groups: {},
              answers: {},
              parts: {},
              questions: {},
              rules: {},
              printAreas: {},
              ...(customization as Partial<RootState['customization']>),
            },
            { ...customization.questions[value.id], ...value },
            'questions'
          ) as DenormalizedCustomizableQuestion
        }),
      } as DenormalizedBulkOrderRow
    })
  }
)

export const bulkOrderRowCustomizationSelector = (customization: CustomizationState, row: BulkOrderRow) => {
  return row?.values?.reduce<CustomizationState>(
    (state, bulkOrderRow) => ({
      ...state,
      questions: { ...state.questions, [bulkOrderRow.id]: { ...state.questions[bulkOrderRow.id], ...bulkOrderRow } },
    }),
    customization
  )
}

export const bulkOrderTableShownRowSelector = (state: RootState) => state.bulkOrder.shownRowIndex

export const bulkOrderDeletingIndexSelector = (state: RootState) => state.bulkOrder.deletingIndex

export const bulkOrderTablePreviewedRowSelector = (state: RootState) => state.bulkOrder.previewedRowIndex

export const bulkOrderHasRequiredErrorsSelector = createSelector(denormalizedBulkOrderQuestionsSelector, rows => {
  return rows.some(row => row.questions.some(question => question.hasRequiredError))
})

export const bulkOrderHasInteractionRequiredErrorsSelector = createSelector(
  showInteractionRequiredWarningsSelector,
  denormalizedBulkOrderQuestionsSelector,
  (showInteractionRequiredWarnings, rows) => {
    return rows.some(row =>
      row.questions.some(
        question => showInteractionRequiredWarnings && !question.hadInteraction && question.isInteractionRequired
      )
    )
  }
)

export const bulkOrderHasFilteredWordsSelector = createSelector(denormalizedBulkOrderQuestionsSelector, rows => {
  return rows.some(row => row.questions.some(question => question.hasFilteredWord))
})
