import { CheckboxFilter, FilterList, NumberFilter, SearchFilter, TagsFilter } from '@packages/sk8/filter'
import React from 'react'

import UnsavedChangesTooltip from 'common/components/filters/UnsavedChangesTooltip'

import { VariantsTableFilterListProps } from '../types'

const KickflipVariantsTableFilterList = ({
  formik,
  customizerProduct,
  filters,
  variantsCombination,
}: VariantsTableFilterListProps) => (
  <FilterList>
    <UnsavedChangesTooltip disabled={!formik.dirty}>
      <SearchFilter
        name="Search"
        setSearch={filters.setSearchFilter}
        search={filters.searchFilter}
        disabled={formik.dirty}
      />
    </UnsavedChangesTooltip>
    {variantsCombination.map(questionId => (
      <UnsavedChangesTooltip key={`filter-${questionId}-tooltip`} disabled={!formik.dirty}>
        <TagsFilter
          name={customizerProduct.questions[questionId].name}
          toggleFilter={answerId => filters.toggleAnswersFilters(questionId, answerId)}
          activeFilters={filters.answersFilters?.[questionId] ?? []}
          clearFilter={() => filters.clearAnswersFilters(questionId)}
          filters={customizerProduct.questions[questionId].answers.map(answerId => ({
            name: answerId,
            text: customizerProduct.answers[answerId]?.name,
            className: 'bg-neutral-100',
          }))}
          disabled={formik.dirty}
        />
      </UnsavedChangesTooltip>
    ))}

    <UnsavedChangesTooltip disabled={!formik.dirty}>
      <CheckboxFilter
        name="Continue selling"
        toggleFilter={filters.toggleStatusFilters}
        activeFilters={filters.statusFilters}
        clearFilter={filters.clearStatusFilters}
        filters={[
          {
            name: 'continue-selling',
            text: 'Continue selling',
            className: 'text-primary-900',
          },
          {
            name: 'stop-selling',
            text: 'Stop selling',
          },
        ]}
        disabled={formik.dirty}
      />
    </UnsavedChangesTooltip>
    <UnsavedChangesTooltip disabled={!formik.dirty}>
      <NumberFilter
        activeFilter={filters.stockFilter}
        setFilter={filters.setStockFilter}
        clearFilter={filters.clearStockFilter}
        name="Total"
        customFilters={[{ id: 'na', name: 'is N/A', defaultValue: 'N/A' }]}
        disabled={formik.dirty}
      />
    </UnsavedChangesTooltip>
  </FilterList>
)

export default KickflipVariantsTableFilterList
