import React from 'react'

import Remove from '../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from '../../utils/classMerge'
import Tag from '../tag/Tag'

export interface BrandsFilterTagProps {
  className?: string
  containerClassName?: string
  iconClassName?: string
  onClick: () => void
  isSelected: boolean
  children: React.ReactNode
  disabled?: boolean
}

const TagButton: React.FC<BrandsFilterTagProps> = ({
  className,
  onClick,
  isSelected,
  children,
  iconClassName,
  containerClassName,
  disabled = false,
  ...rest
}) => (
  <button
    onClick={onClick}
    className={classMerge(containerClassName, 'flex items-center font-p2 min-w-fit')}
    disabled={disabled}
    {...rest}
  >
    <Tag className={classMerge('rounded items-center p-1.5', className)}>
      <span>{children}</span>
      {isSelected && (
        <Remove
          className={classMerge('ml-1 fill-neutral-800 h-2.5 w-2.5 scale-75 origin-center', iconClassName, {
            '!text-neutral-200 !fill-neutral-200': disabled,
          })}
          disabled={disabled}
        />
      )}
    </Tag>
  </button>
)

export default TagButton
