import React from 'react'

import classMerge from '../../utils/classMerge'

const UniversalSearchListSection = ({ className, children, ...props }: React.ComponentProps<'div'>) => {
  return (
    <div
      {...props}
      className={classMerge(
        'flex flex-col space-y-2 border-b border-neutral-100 last:border-b-0 pb-2 last:pb-0 mb-2 last:mb-0',
        className
      )}
    >
      {children}
    </div>
  )
}

export default UniversalSearchListSection
