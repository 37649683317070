import { UniversalSearchList } from '@packages/sk8/universal-search'
import React from 'react'

import * as navigationActions from 'builder/build/navigation/actions'
import { LeftPanel } from 'builder/build/navigation/types/leftPanel'
import { useDispatch } from 'cms/hooks'
import ArrowRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import SettingsIcon from 'icons/core-solid/interface-essential/interface-setting-cog.svg'

interface QuickActionUpdateCustomizerTitleProps {
  onClick: () => void
  query: string
}

const QuickActionUpdateCustomizerTitle = ({ onClick, query }: QuickActionUpdateCustomizerTitleProps) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(navigationActions.setLeftPanel(LeftPanel.settings, { initialFocusInputName: 'customizerTitle' }))
    onClick()
  }

  return (
    <UniversalSearchList.Result onClick={handleClick} className="text-primary-500">
      <UniversalSearchList.HighlightableText text="Edit customizer title" highlight={query} />
      <div className="ml-auto">
        <div className="flex space-x-1 text-xs text-neutral-300 items-center">
          <SettingsIcon className="h-2.5 w-2.5 fill-neutral-300" aria-label="Settings icon" />
          <ArrowRight className="h-2 w-2 fill-neutral-300" /> <div>Customizer title</div>
        </div>
      </div>
    </UniversalSearchList.Result>
  )
}

export default QuickActionUpdateCustomizerTitle
