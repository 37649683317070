import { BlankState } from '@packages/sk8/blank-state'
import { Button, IconButton } from '@packages/sk8/button'
import { CheckboxFilter, SearchFilter } from '@packages/sk8/filter'
import { ToastType, useToast } from '@packages/sk8/toast'
import { NormalizedCustomizerProduct, Rule, RuleThenType } from '@packages/types'
import { deburr, flattenDeep, uniq } from 'lodash'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'

import * as selectors from 'builder/build/core/selectors'
import * as navigationActions from 'builder/build/navigation/actions'
import { BuilderMode } from 'builder/build/navigation/types/builderMode'
import { default as useQuestionsAnswersFilters } from 'builder/common/hooks/useQuestionsAnswersFilters'
import { useDispatch, useSelector } from 'cms/hooks'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import ArrowLeft from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left.svg'
import ScrollIndicator from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-up.svg'
import RulesIcon from 'icons/core-solid/interface-essential/interface-hierarchy-10.svg'

import { addRule, updateRule } from '../actions'
import doesRuleContainsArchivedAnswers from '../doesRuleContainsArchivedAnswers'
import isValidRule from '../isValidRule'
import { rulesAsNaturalLanguageSelector } from '../selectors'
import { generateNewRule } from '../utils'
import AddRule from './AddRule'
import EditedRule from './EditedRule'
import ReadOnlyRule from './ReadOnlyRule'

const cleanText = (text = '') => deburr(text.trim().toLowerCase())

const ListComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className="divide-y divide-neutral-100 px-6">
        {children}
      </div>
    )
  }
)

const ItemComponent = (
  props: Pick<
    Pick<
      React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      'key' | keyof React.HTMLAttributes<HTMLDivElement>
    > & { ref?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined },
    'style' | 'children'
  > & {
    'data-index': number
    'data-item-index': number
    'data-item-group-index'?: number | undefined
    'data-known-size': number
  } & { context?: any }
): JSX.Element => <div {...props} className="relative last:mb-8 mx-[18px]" />

const HeaderComponent = () => <div className="h-4" />

const Rules = () => {
  const [isScrolledToTop, setIsScrolledToTop] = useState(false)
  const [numberOfInvalidRules, setNumberOfInvalidRules] = useState(0)
  const [numberOfWarningRules, setNumberOfWarningRules] = useState(0)
  const dispatch = useDispatch()
  const { openToast } = useToast()
  const listRef = useRef<VirtuosoHandle>(null)
  const addedItemIndex = useRef<number>()
  const [duplicatedRuleId, setDuplicatedRuleId] = useState<string>()
  const [editedRuleId, setEditedRuleId] = useState<string>()
  const [newRule, setNewRule] = useState<Rule>()
  const [filterValue, setFilterValue] = useState('')
  const rules: Rule[] = useSelector(selectors.rulesAsArraySelector)
  const customizerProduct = useSelector(selectors.normalizedCustomizerProductSelector) as NormalizedCustomizerProduct
  const rulesAsNaturalLanguage = useSelector(rulesAsNaturalLanguageSelector)

  const rulesQuestions: Record<string, string[]> = useMemo(
    () =>
      Object.values(customizerProduct.rules).reduce(
        (rulesQuestions, rule) => {
          rulesQuestions[rule.id] = [
            ...rule.when.map(when => when.path[1]),
            ...rule.then.map(then => then.questionId),
          ].filter(questionId => customizerProduct.questions[questionId] != null) as string[]

          return rulesQuestions
        },
        {} as Record<string, string[]>
      ),
    [customizerProduct.rules]
  )

  const rulesAnswers: Record<string, string[]> = useMemo(
    () =>
      Object.values(customizerProduct.rules).reduce(
        (rulesAnswers, rule) => {
          const values = flattenDeep<string | undefined>([
            ...rule.when.map(when => when.value),
            ...rule.then.map(then => [then.answerId, then.payload]),
          ])

          rulesAnswers[rule.id] = values.filter(
            answerId => answerId != null && customizerProduct.answers[answerId] != null
          ) as string[]

          return rulesAnswers
        },
        {} as Record<string, string[]>
      ),
    [customizerProduct.rules]
  )

  const questionIdsInRules = uniq(flattenDeep(Object.values(rulesQuestions)))
  const answerIdsInRules = uniq(flattenDeep(Object.values(rulesAnswers)))

  const { questionFilterProps, answerFilterProps, applyQuestionFilter, applyAnswerFilter, clearAllFilters } =
    useQuestionsAnswersFilters(questionIdsInRules, answerIdsInRules, customizerProduct)

  const extendedRules = useMemo(() => {
    const unorderedExtendedRules = rules.reduce<Rule[]>(
      (rules, rule) => [
        ...rules,
        ...(rule.id === duplicatedRuleId && newRule ? [newRule] : []),
        ...(cleanText(rulesAsNaturalLanguage[rule.id]).includes(cleanText(filterValue)) &&
        applyQuestionFilter(rulesQuestions[rule.id]).length > 0 &&
        applyAnswerFilter(rulesAnswers[rule.id]).length > 0
          ? [rule]
          : []),
      ],
      []
    )

    const groupedRulesByPriority = unorderedExtendedRules.reduce<{
      error: Rule[]
      warning: Rule[]
      info: Rule[]
      valid: Rule[]
    }>(
      (result, rule) => {
        if (!isValidRule({ rule, groups: customizerProduct.groups, questions: customizerProduct.questions })) {
          return { ...result, error: [...result.error, rule] }
        }

        const question = customizerProduct.questions[rule.then[0].questionId]
        if (!!question?.linkedQuestionId && rule.then[0].type !== RuleThenType.DisableQuestion) {
          return { ...result, warning: [...result.warning, rule] }
        }

        if (doesRuleContainsArchivedAnswers(rule, customizerProduct.answers)) {
          return { ...result, info: [...result.info, rule] }
        }

        return { ...result, valid: [...result.valid, rule] }
      },
      { error: [], warning: [], info: [], valid: [] }
    )

    setNumberOfWarningRules(groupedRulesByPriority.info.length + groupedRulesByPriority.warning.length)
    setNumberOfInvalidRules(groupedRulesByPriority.error.length)

    return [
      ...groupedRulesByPriority.error,
      ...groupedRulesByPriority.warning,
      ...groupedRulesByPriority.info,
      ...groupedRulesByPriority.valid,
    ]
  }, [
    rules,
    rulesAsNaturalLanguage,
    newRule,
    duplicatedRuleId,
    filterValue,
    questionFilterProps.activeFilters,
    answerFilterProps.activeFilters,
  ])

  const isEditingARule = editedRuleId != undefined
  const isAddingNewRule = newRule != undefined

  const cancelAddRule = () => {
    setDuplicatedRuleId(undefined)
    setNewRule(undefined)
  }

  const cancelEditRule = () => setEditedRuleId(undefined)

  const handleAddRule = (rule: Rule, index?: number) => {
    addedItemIndex.current =
      duplicatedRuleId && index
        ? rules
            .map(rule => {
              return rule.id
            })
            .indexOf(duplicatedRuleId) + 1
        : rules.length

    setDuplicatedRuleId(undefined)
    setNewRule(undefined)
    dispatch(addRule(rule, addedItemIndex.current))
    openToast('Rule was successfully added!', ToastType.success)
  }

  const handleUpdateRule = (rule: Rule) => {
    setEditedRuleId(undefined)
    dispatch(updateRule(rule))
  }

  useLayoutEffect(() => {
    if (rules.length === 0) {
      clearAllFilters()
      setFilterValue('')
    }

    if (addedItemIndex.current != undefined) {
      setTimeout(() => {
        listRef?.current?.scrollToIndex({
          index: addedItemIndex.current || 0,
          align: 'start',
          behavior: 'auto',
        })
        addedItemIndex.current = undefined
      }, 60)
    }
  }, [rules.length])

  const areFiltersEmpty =
    filterValue === '' && questionFilterProps.activeFilters.length === 0 && answerFilterProps.activeFilters.length === 0

  return (
    <>
      <div className="fixed w-[252px] right-0">
        <div className="h-[52px] flex items-center bg-white border-b border-neutral-75 border-solid px-10" />
        <div className="h-[52px] flex items-center bg-white border-b border-neutral-75 border-solid px-10 " />
      </div>
      <div className="h-[52px] shrink-0 flex items-center bg-white border-b border-neutral-75 border-solid px-10">
        <IconButton
          className="mr-2 z-10"
          Icon={ArrowLeft}
          onClick={() => dispatch(navigationActions.setBuilderMode(BuilderMode.customizer))}
          smallIcon
        />
        <span className="font-medium">Logic</span>
        {(!!numberOfInvalidRules || !!numberOfWarningRules) && (
          <span className="absolute w-full left-0 flex justify-center text-xs">
            <span className="text-error-default">{numberOfInvalidRules} invalid rule(s)</span>, {numberOfWarningRules}{' '}
            warning(s)
          </span>
        )}
        <Button
          className="ml-auto"
          variant="primary"
          onClick={() => setNewRule(generateNewRule())}
          disabled={isEditingARule || isAddingNewRule}
          icon={<AddIcon className="w-2.5 h-2.5 fill-current" />}
        >
          Add rule
        </Button>
      </div>
      {rules.length !== 0 && (
        <div className="min-h-[52px] shrink-0  flex pt-2 bg-white border-b border-neutral-75 border-solid px-10">
          <div className="flex items-center flex-wrap">
            <div className="mr-2 mb-2">
              <SearchFilter
                name="Search"
                aria-label="Search rules"
                setSearch={search => setFilterValue(search)}
                search={filterValue}
              />
            </div>
            <div className="mr-2 mb-2">
              <CheckboxFilter {...questionFilterProps} />
            </div>
            <div className="mr-2 mb-2">
              <CheckboxFilter {...answerFilterProps} />
            </div>
          </div>
          <div className="mb-2 ml-auto flex items-center">
            <Button
              variant="text"
              onClick={() => {
                clearAllFilters()
                setFilterValue('')
              }}
              disabled={areFiltersEmpty}
              icon={<Remove className="w-2.5 h-2.5 fill-current" />}
            >
              Clear filters
            </Button>
          </div>
        </div>
      )}
      <div className="h-[calc(100%_-_53px)] overflow-hidden flex flex-col px-0 pt-0 pb-4">
        <div className="h-full">
          {isAddingNewRule && !duplicatedRuleId && (
            <AddRule
              rule={newRule}
              addRule={handleAddRule}
              cancel={cancelAddRule}
              customizerProduct={customizerProduct}
            />
          )}
          {extendedRules.length === 0 && areFiltersEmpty && !isAddingNewRule && (
            <div className="flex items-center justify-center w-full mt-[20%]">
              <BlankState className="w-full md:w-[400px]">
                <BlankState.Icon Icon={RulesIcon} />
                <BlankState.Title>There are no logic rules, yet</BlankState.Title>
                <BlankState.Details>
                  Create your first logic rule to add conditional behavior to your customizer
                </BlankState.Details>
                <BlankState.Action
                  aria-label="add rule"
                  onClick={() => setNewRule(generateNewRule())}
                  disabled={isEditingARule || isAddingNewRule}
                >
                  Add rule
                </BlankState.Action>
              </BlankState>
            </div>
          )}
          {extendedRules.length === 0 && !areFiltersEmpty && !isAddingNewRule && (
            <div className="flex items-center justify-center w-full mt-[20%]">
              <BlankState className="w-full md:w-[400px]">
                <BlankState.Icon Icon={RulesIcon} />
                <BlankState.Title>No rules to display with the specified filters</BlankState.Title>
                <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
                <BlankState.Action
                  aria-label="clear all filters"
                  onClick={() => {
                    clearAllFilters()
                    setFilterValue('')
                  }}
                >
                  Clear all filters
                </BlankState.Action>
              </BlankState>
            </div>
          )}
          <div className="h-full pb-0">
            <Virtuoso
              ref={listRef}
              components={{
                List: ListComponent,
                Item: ItemComponent,
                Header: HeaderComponent,
              }}
              totalCount={extendedRules.length}
              atTopStateChange={setIsScrolledToTop}
              itemContent={index => {
                const rule = extendedRules[index]
                const question = customizerProduct.questions[rule.then[0].questionId]

                if (duplicatedRuleId === rule.id && newRule)
                  return (
                    <AddRule
                      rule={newRule}
                      index={index}
                      addRule={handleAddRule}
                      cancel={cancelAddRule}
                      customizerProduct={customizerProduct}
                    />
                  )

                if (editedRuleId === rule.id) {
                  return (
                    <EditedRule
                      updateRule={handleUpdateRule}
                      cancel={cancelEditRule}
                      customizerProduct={customizerProduct}
                      rule={rule}
                    />
                  )
                }

                return (
                  <ReadOnlyRule
                    questionsToHighlight={questionFilterProps.activeFilters}
                    answersToHighlight={answerFilterProps.activeFilters}
                    customizerProduct={customizerProduct}
                    isAddingNewRule={isAddingNewRule}
                    isEditingARule={isEditingARule}
                    question={question}
                    rule={rule}
                    setDuplicatedRuleId={setDuplicatedRuleId}
                    setEditedRuleId={setEditedRuleId}
                    setNewRule={setNewRule}
                  />
                )
              }}
            />
          </div>
        </div>
      </div>
      {!isScrolledToTop && (
        <button
          className="absolute left-[calc(50%_-_28px)] shadow-[0px_3px_5px_-1px_rgb(0_0_0_/_20%),0px_6px_10px_0px_rgb(0_0_0_/_14%),0px_1px_18px_0px_rgb(0_0_0_/_12%)] w-[26px] h-[26px] flex items-center justify-center rounded-[50%] bottom-8 bg-neutral-900"
          onClick={() => {
            listRef.current?.scrollToIndex({
              index: 0,
              align: 'start',
              behavior: 'auto',
            })
          }}
        >
          <ScrollIndicator className="h-3 fill-white" />
        </button>
      )}
    </>
  )
}

export default Rules
