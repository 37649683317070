import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { HelperText, Input, InputField, Label } from '@packages/sk8/input'
import { ToastType, useToast } from '@packages/sk8/toast'
import { TopBar } from '@packages/sk8/top-bar'
import { DenormalizedProduct, StartingPoint } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Field, FieldProps, FormikHelpers, FormikProvider, useFormik } from 'formik'
import React, { useContext, useRef } from 'react'
import { RouteComponentProps } from 'react-router'
import * as yup from 'yup'

import GlobalRouterContext, { MatchParams } from 'builder/common/GlobalRouterContext'
import TopBarBackButton from 'builder/topBar/components/TopBarBackButton'
import TopBarProductActions from 'builder/topBar/components/TopBarProductActions'
import TopBarProductName from 'builder/topBar/components/TopBarProductName'
import TopBarTabs from 'builder/topBar/components/TopBarTabs'
import useAssetService from 'common/assets/hooks/useAssetService'
import TopBarEnvTag from 'common/components/topBar/TopBarEnvTag'
import useProductService from 'common/products/hooks/useProductService'
import TenantContext from 'common/tenant/TenantContext'
import * as customizerStartingPointsActions from 'customizer/startingPoints/actions'
import dataURItoBlob from 'utils/dataURItoBlob'

import useStartingPointService from '../hooks/useStartingPointService'

interface StartingPointData {
  imageData: string
  configuration: Record<string, string | string[] | null>
}

const getStartingPointNameSchema = (restrictedStartingPointsNames: string[]) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Please enter a name for your starting point')
      .notOneOf(restrictedStartingPointsNames, 'This name is already used')
      .max(255, 'Name must be under 255 characters'),
  })

interface FormValues {
  name: string
}

const StartingPointEditor = ({ location, history, match }: RouteComponentProps<MatchParams>) => {
  const { openToast, openGenericErrorToast } = useToast()
  const customizerRef = useRef<HTMLIFrameElement>(null)
  const tenant = useContext(TenantContext)
  const productService = useProductService()
  const startingPointService = useStartingPointService()
  const assetService = useAssetService()
  const queryClient = useQueryClient()

  const { productId, startingPointId, brandName } = match.params

  const { data: product } = useQuery([...productService.fetch.queryKeys, productId, 'startingPoints'], async () => {
    const result = await productService.fetch(productId!, { params: { fields: ['startingPoints'] } })
    return result as DenormalizedProduct
  })

  const startingPoints = product?.startingPoints
  const startingPoint = startingPoints?.find(startingPoint => startingPoint.id === startingPointId)

  const restrictedStartingPointsNames =
    startingPoints?.reduce((result, { name }) => {
      if (name && (!startingPoint || name !== startingPoint.name)) {
        result.push(name)
      }
      return result
    }, [] as string[]) || []

  const { mutateAsync: uploadImage } = useMutation(assetService.upload)

  const { mutate: createStartingPoint } = useMutation(startingPointService.create, {
    onSuccess: () => {
      openToast('Starting point was successfully created!', ToastType.success)
      queryClient.invalidateQueries([
        ...productService.fetch.queryKeys,
        match.params.productId,
        { fields: ['live', 'startingPoints'] },
      ])
      cancel()
    },
  })

  const { mutate: updateStartingPoint } = useMutation(
    (values: Partial<StartingPoint>) => startingPointService.update(startingPointId!, values),
    {
      onSuccess: () => {
        openToast('Starting point was successfully created!', ToastType.success)
        queryClient.invalidateQueries([
          ...productService.fetch.queryKeys,
          match.params.productId,
          { fields: ['live', 'startingPoints'] },
        ])
        cancel()
      },
    }
  )

  const onSubmit = async (values: FormValues, { resetForm, setSubmitting }: FormikHelpers<FormValues>) => {
    if (!customizerRef?.current || typeof customizerRef.current == 'string') {
      openGenericErrorToast('Unable to get starting point data')
      setSubmitting(false)
      return
    }

    const { imageData, ...startingPointData }: StartingPointData =
      await customizerRef.current.contentWindow?.customizerApp?.store.dispatch(
        customizerStartingPointsActions.createStartingPoint() as any
      )

    const file = dataURItoBlob(imageData)

    const assets = await uploadImage([file])

    if (startingPoint) {
      return updateStartingPoint(
        { ...values, ...startingPointData, previewImage: assets[0].url, upToDate: true },
        {
          onSettled: () => {
            resetForm()
            setSubmitting(false)
          },
        }
      )
    }

    return createStartingPoint(
      {
        ...values,
        ...startingPointData,
        previewImage: assets[0].url,
        customizerProductId: product?.live?.id || (product?.live as unknown as string),
        productId: product?.id,
      },
      {
        onSettled: () => {
          resetForm()
          setSubmitting(false)
        },
      }
    )
  }

  const initialValues: FormValues = { name: startingPoint?.name ? startingPoint.name : `${product?.name} - ` }
  const isNameInvalid = restrictedStartingPointsNames.includes(initialValues.name)

  const formik = useFormik<FormValues>({
    initialValues,
    initialErrors: isNameInvalid ? { name: 'This name is already used' } : undefined,
    validationSchema: getStartingPointNameSchema(restrictedStartingPointsNames),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit,
  })

  const getIframeSrc = () => {
    if (!product) return undefined

    const baseUrl = `${window.location.origin}/startingpoint`
    const url = startingPoint ? `${baseUrl}/edit/${startingPoint.id}` : `${baseUrl}/create/${product?.id}`
    return !window.location.origin.split('.').includes(tenant!) ? `${url}?tenant=${tenant!}` : url
  }

  const cancel = () => {
    const baseUrl = brandName ? `/brands/${brandName}` : ''
    history.push(`${baseUrl}/products/${productId}/connect`)
  }

  if (!product) return null

  return (
    <GlobalRouterContext.Provider value={{ history, location, match }}>
      <FormikProvider value={formik}>
        <form className="flex flex-col h-screen" onSubmit={formik.handleSubmit}>
          <TopBar>
            <TopBarEnvTag />
            <TopBar.Section className="pl-5">
              <TopBarBackButton />
              <TopBarProductName />
              <TopBarProductActions />
            </TopBar.Section>
            <TopBarTabs selectedTab="connect" />
            <TopBar.Section right className="mr-4 space-x-2">
              <Button type="button" onClick={cancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
                isLoading={formik.isSubmitting}
              >
                {startingPoint ? 'Save' : 'Create'}
              </Button>
            </TopBar.Section>
          </TopBar>
          <div className="flex justify-center w-full pt-12 px-4 flex-1 mt-[52px]">
            <div className="max-w-7xl w-full flex flex-col">
              <h1 className="mb-4">{startingPoint ? 'Edit starting point' : 'Create starting point'}</h1>
              <Alert variant="info" className="mb-4">
                <Alert.Body>
                  <span className="font-medium">File upload</span> and <span className="font-medium">text</span> inputs
                  are disabled in the customizer when creating starting points.
                </Alert.Body>
              </Alert>
              <hr className="h-[1px] w-full border-neutral-100 mb-4" />
              <Field name="name">
                {({ field }: FieldProps) => (
                  <InputField>
                    <Label className="font-medium mb-4">Starting point name</Label>
                    <Input
                      {...field}
                      hasError={formik.touched.name && formik.errors.name != null}
                      className="w-[260px]"
                    />

                    {formik.touched.name && formik.errors.name != null && (
                      <HelperText hasError>{formik.errors.name}</HelperText>
                    )}
                  </InputField>
                )}
              </Field>

              <div className="mt-8 mb-14 flex-1">
                <iframe
                  ref={customizerRef}
                  src={getIframeSrc()}
                  className="shadow rounded-lg w-full h-full"
                  allow="clipboard-write"
                />
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
    </GlobalRouterContext.Provider>
  )
}

export default StartingPointEditor
