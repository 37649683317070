import { Card } from '@packages/sk8/card'
import React from 'react'

const PaymentCardSkeleton = () => {
  return (
    <Card className="flex flex-1 flex-col">
      <Card.Section className="space-x-6">
        <div className="flex flex-col flex-grow space-y-4">
          <span className="text-neutral-400 text-xs leading-5">eCommerce platform</span>
          <span className="animate-pulse bg-neutral-50 h-5 w-20 rounded-lg" />
        </div>
      </Card.Section>
      <Card.Separator />
      <Card.Section className="space-x-6">
        <div className="flex flex-col flex-grow space-y-4">
          <span className="text-neutral-400 text-xs leading-5">Online store</span>
          <span className="animate-pulse bg-neutral-50 h-5 w-20 rounded-lg" />
        </div>
      </Card.Section>
    </Card>
  )
}

export default PaymentCardSkeleton
