import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { HelperText, Input, InputField, Label, PasswordInput, Select } from '@packages/sk8/input'
import { BrandUserRole, UserRole } from '@packages/types'
import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import Page from 'cms/layout/page/Page'

import type { UserFormValues } from './../types/form'

interface CreateUserFormProps {
  roleOptions: { label: string; value: UserRole }[]
  onCancel: () => void
  onSubmit: (values: UserFormValues, helpers: FormikHelpers<UserFormValues>) => void
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  email: yup.string().required('Please enter your email').email('Please enter a valid email'),
  role: yup.string().required('Please choose a role'),
  password: yup.string().min(5, 'Password must be at least 5 characters long').required('Please enter a password'),
  passwordConfirmation: yup
    .string()
    .required('Please confirm your password')
    .equals([yup.ref('password')], 'The specified password does not match'),
})

const CreateUserForm = ({ roleOptions, onCancel, onSubmit }: CreateUserFormProps) => {
  const formik = useFormik<UserFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: BrandUserRole.Admin,
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Page.Header>
        <h1>Create user</h1>
        <div className="flex relative">
          <Button type="button" onClick={onCancel} disabled={formik.isSubmitting} className="mr-3">
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            isLoading={formik.isSubmitting}
          >
            Create
          </Button>
        </div>
      </Page.Header>

      <Page.Section>
        <Page.Aside title="Account details" description="Generic informations, we won't show this to anyone" />
        <Page.Content>
          <Card className="flex flex-1 flex-col">
            <div className="p-6">
              <InputField className="mb-6">
                <Label htmlFor="firstName">First name</Label>
                <Input
                  placeholder="First name"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.firstName && formik.errors.firstName != null}
                />
                {formik.touched.firstName && formik.errors.firstName != null && (
                  <HelperText hasError>{formik.errors.firstName}</HelperText>
                )}
              </InputField>
              <InputField className="mb-6">
                <Label htmlFor="lastName">Last name</Label>
                <Input
                  placeholder="Last name"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.lastName && formik.errors.lastName != null}
                />
                {formik.touched.lastName && formik.errors.lastName != null && (
                  <HelperText hasError>{formik.errors.lastName}</HelperText>
                )}
              </InputField>
              <InputField className="mb-6">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="true"
                  placeholder="email@example.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.email && formik.errors.email != null}
                />
                {formik.touched.email && formik.errors.email != null && (
                  <HelperText hasError>{formik.errors.email}</HelperText>
                )}
              </InputField>
              <InputField className="mb-6">
                <Label htmlFor="role">Role</Label>
                <Select
                  id="role"
                  name="role"
                  menuPlacement="auto"
                  onChange={option => formik.setFieldValue('role', option?.value)}
                  value={roleOptions.find(({ value }) => value === formik.values.role)}
                  options={roleOptions}
                  hasError={formik.touched.role && formik.errors.role != null}
                />
                {formik.touched.role && formik.errors.role != null && (
                  <HelperText hasError>{formik.errors.role}</HelperText>
                )}
              </InputField>
            </div>
          </Card>
        </Page.Content>
      </Page.Section>

      <Page.Separator />

      <Page.Section className="flex flex-1 mb-10 mt-10">
        <Page.Aside title="Create your password" description="Make sure your password is strong enough 💪" />
        <Page.Content>
          <Card className="flex flex-1 flex-col">
            <div className="p-6">
              <InputField className="mb-6">
                <Label htmlFor="password">Password</Label>
                <PasswordInput
                  id="password"
                  name="password"
                  placeholder="Enter password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.password && formik.errors.password != null}
                />
                {formik.touched.password && formik.errors.password != null && (
                  <HelperText hasError>{formik.errors.password}</HelperText>
                )}
              </InputField>
              <InputField>
                <Label htmlFor="passwordConfirmation">Confirm password</Label>
                <PasswordInput
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  placeholder="Enter password"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null}
                />
                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null && (
                  <HelperText hasError>{formik.errors.passwordConfirmation}</HelperText>
                )}
              </InputField>
            </div>
          </Card>
        </Page.Content>
      </Page.Section>
    </form>
  )
}

export default CreateUserForm
