import { Button } from '@packages/sk8/button'
import { useModal } from '@packages/sk8/modal'
import { AddonId } from '@packages/types'
import React from 'react'

import SubscriptionFullPageModal from 'cms/subscription/components/modals/SubscriptionFullPageModal'

export type AddonCardVariants = 'default' | 'small' | 'button'

export interface AddonCardProps {
  small?: boolean
  addonId: AddonId
}

const AddonButton = ({ addonId, small }: AddonCardProps) => {
  const subscriptionModal = useModal()

  return (
    <>
      <Button
        className="text-tertiary-red-400 bg-transparent bg-gradient-to-tr from-white to-white/70"
        onClick={subscriptionModal.open}
        small={small}
      >
        Upgrade
      </Button>
      {subscriptionModal.isVisible && (
        <SubscriptionFullPageModal
          onClose={subscriptionModal.close}
          selectAddonIds={[addonId]}
          {...subscriptionModal.modalProps}
        />
      )}
    </>
  )
}

export default AddonButton
