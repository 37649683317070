import { OnlineStore, StartingPointBinding, StartingPointBindingAvailability } from '@packages/types'
import { useContext } from 'react'

import { ProductPricingWithId } from 'builder/pricing/types'
import apiClient from 'common/api/apiClient'
import createQuery from 'common/api/createQuery'
import TenantContext from 'common/tenant/TenantContext'

const useOnlineStoreService = () => {
  const tenant = useContext(TenantContext)

  return {
    fetchAll: createQuery([tenant, 'fetch-online-stores'], async () => {
      const { data } = await apiClient.get<OnlineStore[]>(`/brands/${tenant}/onlinestores`)

      return data
    }),
    fetch: createQuery([tenant, 'fetch-online-store'], async (id: string) => {
      const { data } = await apiClient.get<OnlineStore>(`/brands/${tenant}/onlinestores/${id}`)
      return data
    }),
    fetchConnectionString: createQuery([tenant, 'fetch-online-store-connection-string'], async (id: string) => {
      const {
        data: { connectionString },
      } = await apiClient.get<{ connectionString: string }>(`/brands/${tenant}/onlinestores/${id}/connection-string`)
      return connectionString
    }),
    fetchAvailability: createQuery(
      [tenant, 'fetch-product-starting-points-availability'],
      async (onlineStoreId: string, productId: string, startingPointId: string) => {
        const { data } = await apiClient.get<StartingPointBindingAvailability>(
          `/brands/${tenant}/onlinestores/${onlineStoreId}/products/${productId}/startingpoints/${startingPointId}/availability`
        )
        return data
      }
    ),
    create: async (body: Partial<OnlineStore>) => {
      const { data } = await apiClient.post<OnlineStore>(`/brands/${tenant}/onlinestores`, body)
      return data
    },
    update: async (onlineStoreId: string, body: Partial<OnlineStore>) => {
      const { data } = await apiClient.post<OnlineStore>(`/brands/${tenant}/onlinestores/${onlineStoreId}`, body)
      return data
    },
    addStartingPoint: async (id: string, productId: string, body: Partial<StartingPointBinding>) => {
      const { data } = await apiClient.post<OnlineStore>(
        `/brands/${tenant}/onlinestores/${id}/products/${productId}/startingpoints`,
        body
      )
      return data
    },
    removeStartingPoint: async (id: string, productId: string, startingPointId: string) => {
      const { data } = await apiClient.delete<OnlineStore>(
        `/brands/${tenant}/onlinestores/${id}/products/${productId}/startingpoints/${startingPointId}`
      )
      return data
    },
    bulkUpdatePricing: async (productId: string, body: ProductPricingWithId[]) => {
      await apiClient.post<ProductPricingWithId[]>(`/brands/${tenant}/onlinestores/products/${productId}`, body)
    },
  }
}

export default useOnlineStoreService
