import { AnswerRef } from '@packages/types'
import { createSelector } from 'reselect'

import { BulkOrderRow } from 'customizer/bulkOrder/reducer'
import { bulkOrderRowCustomizationSelector, bulkOrderRowsSelector } from 'customizer/bulkOrder/selectors'
import type { CustomizationState } from 'customizer/customization/reducer/reducer'
import { customizationSelector } from 'customizer/customization/selectors'
import { RootState } from 'customizer/store'

import { StocksState } from './reducer'
import { Stock } from './types'

export const stocksSelector = ({ stocks }: RootState) => stocks

export const selectedAnswerRefsSelector = (customization: CustomizationState) => {
  const selectedAnswers: AnswerRef[] = []

  if (customization.questions) {
    Object.values(customization.questions).forEach(question => {
      if (question.isMultiAnswer && question.selectedAnswers) {
        question.selectedAnswers.forEach(selectedAnswer => {
          selectedAnswers.push({ questionId: question.id, answerId: selectedAnswer })
        })
      } else if (question.selectedAnswer) {
        selectedAnswers.push({ questionId: question.id, answerId: question.selectedAnswer })
      }
    })
  }

  return selectedAnswers
}

export const questionStocksSelector = (stocks: StocksState, questionId: string) => {
  return Object.values(stocks).filter(stock => !!stock.answersRefs.find(ref => ref.questionId === questionId))
}

const getOutOfStockAnswers = (
  allSelectedAnswersRefs: AnswerRef[],
  questionId: string,
  availableAnswerIds: string[],
  questionStocks: Stock[]
): string[] => {
  const isQuestionLast = (answersRefs: AnswerRef[]) => {
    const lastQuestionId = answersRefs.reduce<string>((lastQuestionId, ref) => {
      if (!lastQuestionId) return ref.questionId

      return allSelectedAnswersRefs.findIndex(answerRef => answerRef.questionId === lastQuestionId) >
        allSelectedAnswersRefs.findIndex(answerRef => answerRef.questionId === ref.questionId)
        ? lastQuestionId
        : ref.questionId
    }, '')

    return lastQuestionId === questionId
  }

  return availableAnswerIds.reduce<string[]>((outOfStockAnswerIds, answerId) => {
    const matchingStocks = questionStocks.filter(stock =>
      stock.answersRefs.every(answerRef =>
        answerRef.questionId === questionId
          ? answerRef.answerId === answerId
          : allSelectedAnswersRefs.some(
              selRef => selRef.questionId === answerRef.questionId && selRef.answerId === answerRef.answerId
            )
      )
    )

    if (matchingStocks.some(stock => stock.stock === 0 && isQuestionLast(stock.answersRefs))) {
      return [...outOfStockAnswerIds, answerId]
    }
    return outOfStockAnswerIds
  }, [])
}

export const answersOutOfStockForBulkOrderRowSelector = createSelector(
  stocksSelector,
  customizationSelector,
  bulkOrderRowsSelector,
  (_: RootState, rowIndex: number, questionId: string) => ({ rowIndex, questionId }),
  (
    stocks: StocksState,
    customization: CustomizationState,
    bulkOrderRows: BulkOrderRow[],
    { rowIndex, questionId }: { rowIndex: number; questionId: string }
  ): string[] => {
    const row = bulkOrderRows[rowIndex]
    if (!row) return []

    const rowCustomization = bulkOrderRowCustomizationSelector(customization, row)
    const rowQuestions = rowCustomization.questions || {}
    const allSelectedAnswersRefs = selectedAnswerRefsSelector(rowCustomization)

    const questionStocks = questionStocksSelector(stocks, questionId)
    if (!questionStocks.length) return []

    const availableAnswerIds = rowQuestions[questionId].answers || []
    return getOutOfStockAnswers(allSelectedAnswersRefs, questionId, availableAnswerIds, questionStocks)
  }
)

export const answersOutOfStockForQuestionSelector = createSelector(
  stocksSelector,
  customizationSelector,
  (_state: RootState, questionId: string) => questionId,
  (stocks: StocksState, customization: CustomizationState, questionId: string) => {
    const questionStocks = questionStocksSelector(stocks, questionId)
    if (!questionStocks.length) return []

    const questionAnswerIds = customization.questions[questionId].answers || []
    const selectedAnswersRefs = selectedAnswerRefsSelector(customization)
    return getOutOfStockAnswers(selectedAnswersRefs, questionId, questionAnswerIds, questionStocks)
  }
)

export const answersOutOfStockForQuestionsSelector = (state: RootState, questionIds: string[]) => {
  return questionIds.reduce((answersOutOfStock, id) => {
    return [...answersOutOfStock, ...answersOutOfStockForQuestionSelector(state, id)]
  }, [] as string[])
}

export const isQuestionOutOfStockSelector = (state: RootState, questionId: string) => {
  const question = state.customization.questions[questionId]

  if (!question) return false

  const outOfStockAnswers = answersOutOfStockForQuestionSelector(state, question.id)

  const selectedAnswerIds =
    question.isMultiAnswer && question.selectedAnswers
      ? question.selectedAnswers
      : question.selectedAnswer
        ? [question.selectedAnswer]
        : []

  return selectedAnswerIds.some(answerId => outOfStockAnswers.includes(answerId))
}

export const isQuestionOverAvailableStocksSelector = (state: RootState, questionId: string) => {
  const question = state.customization.questions[questionId]
  if (!question) return false

  const stocksWithAnswersOverAvailable = stocksWithAnswersOverAvailableSelector(state)

  return !!stocksWithAnswersOverAvailable.find(
    ({ answersRefs }) => !!answersRefs.find(answersRef => questionId === answersRef.questionId)
  )
}

const isBulkOrderRowsSelectionOutOfStock = (
  customization: CustomizationState,
  stocks: StocksState,
  bulkOrderRows: BulkOrderRow[]
) => {
  return bulkOrderRows.some((_, index) => {
    const rowCustomization = bulkOrderRowCustomizationSelector(customization, bulkOrderRows[index])
    const rowSelectedAnswers = selectedAnswerRefsSelector(rowCustomization)

    return Object.values(stocks).some(stock => {
      const filteredRow = rowSelectedAnswers.filter(selRef =>
        stock.answersRefs.some(ref => ref.questionId === selRef.questionId && ref.answerId === selRef.answerId)
      )

      const isOutOfStock = filteredRow.length === stock.answersRefs.length && stock.stock === 0

      return isOutOfStock && !stock.continueSelling
    })
  })
}

export const isSelectionOutOfStockSelector = createSelector(
  customizationSelector,
  stocksSelector,
  bulkOrderRowsSelector,
  (customization: CustomizationState, stocks: StocksState, bulkOrderRows: BulkOrderRow[]) => {
    const selectedAnswers = selectedAnswerRefsSelector(customization)

    const selectedAnswerStocks = Object.values(stocks).filter(stock =>
      stock.answersRefs.every(ref =>
        selectedAnswers.find(selRef => selRef.answerId === ref.answerId && selRef.questionId === ref.questionId)
      )
    )

    const isOutOfStock = selectedAnswerStocks.some(({ stock }) => stock === 0)
    const continueSelling = !selectedAnswerStocks.find(({ continueSelling }) => !continueSelling)

    const isSelectionOutOfStock = isOutOfStock && !continueSelling
    const isOutOfStockBulkOrderRows = isBulkOrderRowsSelectionOutOfStock(customization, stocks, bulkOrderRows)

    return isSelectionOutOfStock || isOutOfStockBulkOrderRows
  }
)

export const stocksWithAnswersOverAvailableSelector = createSelector(
  customizationSelector,
  stocksSelector,
  bulkOrderRowsSelector,
  (customization, stocks, bulkOrderRows) => {
    const quantityByVariantsId: Record<string, { quantity: number }> = {}

    bulkOrderRows.forEach(row => {
      const rowCustomization = bulkOrderRowCustomizationSelector(customization, row)

      const selectedAnswers = selectedAnswerRefsSelector(rowCustomization)
      const selectedAnswerStocks = Object.values(stocks).filter(stock =>
        stock.answersRefs.every(ref =>
          selectedAnswers.find(selRef => selRef.answerId === ref.answerId && selRef.questionId === ref.questionId)
        )
      )

      selectedAnswerStocks.forEach(selectedAnswerStock => {
        quantityByVariantsId[selectedAnswerStock.id] = {
          quantity: quantityByVariantsId[selectedAnswerStock.id]
            ? quantityByVariantsId[selectedAnswerStock.id].quantity + row.quantity
            : row.quantity,
        }
      })
    })

    return Object.keys(quantityByVariantsId)
      .filter(key => {
        return (
          quantityByVariantsId[key].quantity > stocks[key].stock &&
          !stocks[key].continueSelling &&
          stocks[key].stock > 0
        )
      })
      .map(key => stocks[key])
  }
)
