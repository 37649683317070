import { Toast, ToastType } from '@packages/sk8/toast'
import React from 'react'
import { toast } from 'react-hot-toast'

interface ProductLimitReachedToastProps {
  id: string
  visible?: boolean
  onUpgradePlan: () => void
}

const ProductLimitReachedToast = ({ id, visible, onUpgradePlan }: ProductLimitReachedToastProps) => {
  return (
    <Toast visible={visible}>
      <Toast.Icon type={ToastType.warning} />
      <Toast.Message>
        You've reached your plan's product limit. Upgrade your subscription plan to add more products.
      </Toast.Message>
      <Toast.Action
        onClick={() => {
          toast.dismiss(id)
          onUpgradePlan()
        }}
      >
        Upgrade plan
      </Toast.Action>
      <Toast.CloseButton onClick={() => toast.dismiss(id)} />
    </Toast>
  )
}

export default ProductLimitReachedToast
