import { Tag } from '@packages/sk8/tag'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'

const textByEnv = {
  development: 'dev',
  staging: 'stg',
  qa: 'qa',
  test: 'test',
}

const classNamesByEnv = {
  development: 'bg-[#ffb421]',
  staging: 'bg-[#ff21e1]',
  qa: 'bg-[#2ff476]',
  test: 'bg-[#21a1ff]',
}

const TopBarEnvTag = () => {
  const runAwayTimeoutRef = useRef<NodeJS.Timeout>()
  const ref = useRef<HTMLDivElement>(null)
  const [mock, setMock] = useState(false)

  const runAway = () => {
    if (!ref.current) return

    if (!mock) setMock(true)

    const el = ref.current as HTMLElement

    clearTimeout(runAwayTimeoutRef.current)

    const direction = Math.random() > 0.5 ? -1 : 1

    const { y } = el.getBoundingClientRect()

    el.style.translate = '0px ' + (y + 20 + Math.random() * 40 * direction) + 'px'
  }

  const runBack = () => {
    if (!ref.current) return

    const el = ref.current as HTMLElement

    runAwayTimeoutRef.current = setTimeout(() => {
      el.style.translate = ''
      setMock(false)
    }, 1000)
  }

  if (CURRENT_ENV === 'production') return null

  return (
    <div
      ref={ref}
      className="absolute left-0 -rotate-90 transition-[translate_ease_in_out_0.2s] -translate-x-1/3 flex top-1/2 -translate-y-1/2 z-[3]"
      onMouseEnter={runAway}
      onMouseLeave={runBack}
    >
      {mock && <div className="animate-bounce absolute top-4 right-4">🤪</div>}
      <Tag className={classNames(classNamesByEnv[CURRENT_ENV])}>{textByEnv[CURRENT_ENV]}</Tag>
    </div>
  )
}

export default TopBarEnvTag
