import { Button } from '@packages/sk8/button'
import { NumberInput, Slider } from '@packages/sk8/input'
import { AnswerType, MaterialView } from '@packages/types'
import { isEqual } from 'lodash'
import React from 'react'

import ActivatableInput from 'builder/build/common/components/ActivatableInput'
import WithInformation from 'common/components/WithInformation'
import { answers } from 'common/customizerProducts/constants'

import './LightingSection.scss'

export interface LightingSectionProps {
  view: MaterialView
  onUpdate: (patch: Partial<MaterialView>) => void
}

const defaultView = answers.default[AnswerType.Material] as MaterialView

const defaultValues = {
  lightIntensity: defaultView.lightIntensity,
  lightThreshold: defaultView.lightThreshold,
}

const LightingSection = ({ view, onUpdate }: LightingSectionProps) => {
  return (
    <div className="relative px-4 py-6">
      <ActivatableInput
        id="lighting"
        title="Lighting"
        isActive={view.lighting}
        onActivationChange={val => onUpdate({ lighting: val })}
        titleClassName="font-medium"
      >
        <div className="flex flex-col space-y-4">
          <div>
            <WithInformation tooltip="A higher value will render whiter, more intense lighting.">
              Light strength
            </WithInformation>
            <div className="flex justify-between items-center">
              <Slider
                min={0}
                max={1}
                step={0.01}
                value={view.lightIntensity}
                onChange={value => onUpdate({ lightIntensity: value })}
                gradient={['#0021F5', '#BFD8FF']}
              />
              <div className="w-16 ml-4">
                <NumberInput
                  aria-label="light intensity"
                  small
                  min={0}
                  max={1}
                  increment={0.01}
                  value={view.lightIntensity}
                  onValueChange={value => onUpdate({ lightIntensity: value })}
                />
              </div>
            </div>
          </div>
          <div>
            <WithInformation tooltip="A higher value will render more light on your component.">
              Light amount
            </WithInformation>
            <div className="flex justify-between items-center">
              <Slider
                min={0}
                max={255}
                value={255 - view.lightThreshold}
                onChange={value => onUpdate({ lightThreshold: 255 - value })}
                hasNumberInput
                gradient={['#0021F5', '#BFD8FF']}
              />
              <div className="w-16 ml-4">
                <NumberInput
                  aria-label="light threshold"
                  small
                  min={0}
                  max={255}
                  value={255 - view.lightThreshold}
                  onValueChange={value => value != null && onUpdate({ lightThreshold: 255 - value })}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="text"
            onClick={() => onUpdate(defaultValues)}
            disabled={isEqual(
              { lightThreshold: view.lightThreshold, lightIntensity: view.lightIntensity },
              defaultValues
            )}
          >
            Reset to default
          </Button>
        </div>
      </ActivatableInput>
    </div>
  )
}

export default LightingSection
