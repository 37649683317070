import React from 'react'

const FilterList = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  return (
    <div className="flex flex-grow flex-wrap pt-2">
      {Array.isArray(children) ? (
        children.flat().map((child, i) => (
          <div className="flex items-center mr-2 h-12 -mt-2" key={`filter-wrapper-${i}`}>
            {child}
          </div>
        ))
      ) : (
        <div className="flex items-center mr-2 h-12 -mt-2">{children}</div>
      )}
    </div>
  )
}

export default FilterList
