import React from 'react'

import { Tooltip } from '../Tooltip'

const TableHeaderCellTooltip = ({ children, ...props }: React.ComponentProps<typeof Tooltip>) => {
  return (
    <Tooltip {...props}>
      <span className="border-b-2 border-dotted border-neutral-200">{children}</span>
    </Tooltip>
  )
}

export default TableHeaderCellTooltip
