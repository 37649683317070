import React, { useState, useRef } from 'react'

import Panel, { PanelProps } from './Panel'

interface ResizablePanelProps extends PanelProps {
  defaultWidth: number
  maxWidth?: number
  minWidth?: number
  onDragEnd?: (width: number) => void
  preventDrag?: boolean
}

const ResizablePanel = ({
  children,
  side,
  defaultWidth,
  maxWidth,
  minWidth,
  onDragEnd,
  preventDrag = false,
}: ResizablePanelProps) => {
  const [width, setWidth] = useState(defaultWidth)
  const [isDragging, setIsDragging] = useState(false)

  const initialClientX = useRef(0)
  const initialWidth = useRef(width)

  const handleDrag: React.MouseEventHandler<HTMLDivElement> = e => {
    const widthDiff = e.clientX - initialClientX.current
    const newWidth = initialWidth.current + widthDiff

    if (maxWidth != null && newWidth > maxWidth) return setWidth(maxWidth)
    if (minWidth != null && newWidth < minWidth) return setWidth(minWidth)

    setWidth(newWidth)
  }

  const startDrag: React.MouseEventHandler<HTMLDivElement> = e => {
    initialClientX.current = e.clientX
    initialWidth.current = width
    setIsDragging(true)
  }

  const finishDrag = () => {
    setIsDragging(false)
    onDragEnd?.(width)
  }

  return (
    <Panel side={side} style={{ width: `${width}px` }}>
      {children}
      {!preventDrag && (
        <div
          aria-label="drag handle"
          className="absolute opacity-0 h-full w-[3px] z-[2] -right-1 cursor-col-resize"
          onMouseDown={startDrag}
        />
      )}

      {isDragging && (
        <div
          className="fixed z-[5] w-full h-full cursor-col-resize inset-0"
          onMouseUp={finishDrag}
          onMouseLeave={finishDrag}
          onMouseMove={handleDrag}
        />
      )}
    </Panel>
  )
}

export default ResizablePanel
