import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import React from 'react'

import UpdateCount from 'common/components/UpdateCount'
import { trpc } from 'common/hooks/trpc'

import { FormikVariants } from '../types'

interface KickflipContinueSellingModalProps {
  ids: string[]
  handleClose: () => void
  modalProps: Omit<React.ComponentProps<typeof Modal>, 'children'>
  formik: FormikVariants
  onSuccess: () => void
}

const KickflipContinueSellingModal = ({
  ids,
  onSuccess,
  handleClose,
  modalProps,
}: KickflipContinueSellingModalProps) => {
  const { openToast } = useToast()

  const { mutate: bulkUpdate, isLoading: isSaving } = trpc.variant.bulkUpdateContinueSelling.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Variants was successfully saved.', ToastType.success)
    },
    onError: () => {
      openToast('Could not save variant please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    return bulkUpdate({ ids, continueSelling: true }, { onSettled: handleClose })
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Continue selling when out of stock</Modal.Title>
      <Modal.Details>
        All variants tagged with continue selling will still be available on your customizer even when their stock is 0
        or below.
      </Modal.Details>
      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="variant" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving} isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default KickflipContinueSellingModal
