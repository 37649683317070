import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import React from 'react'
import { Link } from 'react-router-dom'

import PublicFormFooter from 'common/components/PublicFormFooter'

import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'

const ChangePasswordSuccess = () => {
  return (
    <AuthFormContainer>
      <AuthFormTitle>Change password</AuthFormTitle>
      <Alert variant="success">Your password has been changed successfully!</Alert>
      <PublicFormFooter>
        <Link to="/login" className="w-full">
          <Button variant="primary" className="w-full">
            Back to login
          </Button>
        </Link>
      </PublicFormFooter>
    </AuthFormContainer>
  )
}

export default ChangePasswordSuccess
