import { Button } from '@packages/sk8/button'
import { Input } from '@packages/sk8/input'
import { Popover, usePopover } from '@packages/sk8/popover'
import { BulkOrderItemDimension, QuestionBulkConfig } from '@packages/types'
import React from 'react'

import LargeIcon from 'icons/custom/large-width.svg'
import MediumIcon from 'icons/custom/medium-width.svg'
import SmallIcon from 'icons/custom/small-width.svg'
import XLargeIcon from 'icons/custom/xlarge-width.svg'

import './BulkOrderElementCardConfig.scss'

const getCustomSizeIcon = (size: string, disabled: boolean) => {
  const customStyle = disabled ? '[&>*]:fill-neutral-300' : '[&>*]:fill-black'

  switch (size) {
    case BulkOrderItemDimension.Small:
      return <SmallIcon className={customStyle} />

    case BulkOrderItemDimension.Medium:
      return <MediumIcon className={customStyle} />

    case BulkOrderItemDimension.Large:
      return <LargeIcon className={customStyle} />

    default:
      return <XLargeIcon className={customStyle} />
  }
}

export interface BulkOrderElementCardConfigProps {
  bulkConfig?: QuestionBulkConfig
  onDimensionSelect: (value: BulkOrderItemDimension) => void
  onLabelChange: (value: string) => void
  placeholder?: string
  disabled?: boolean
}

const BulkOrderElementCardConfig = ({
  bulkConfig,
  placeholder,
  onDimensionSelect,
  onLabelChange,
  disabled = false,
}: BulkOrderElementCardConfigProps) => {
  const widthDropdownButton = usePopover({ placement: 'bottom-end', offsetConfig: 4 })

  const handleDimensionChange = (value: BulkOrderItemDimension) => {
    widthDropdownButton.close()
    onDimensionSelect(value)
  }

  const dimension = bulkConfig?.dimension ?? BulkOrderItemDimension.XLarge

  return (
    <div className="flex items-center space-x-2" onClick={e => e.stopPropagation()}>
      <Input
        disabled={disabled}
        placeholder={placeholder}
        value={bulkConfig?.label || ''}
        onChange={e => onLabelChange(e.target.value)}
      />

      <Button {...widthDropdownButton.referenceProps} aria-label={dimension} disabled={disabled}>
        {getCustomSizeIcon(dimension, disabled)}
      </Button>
      <Popover isOpen={widthDropdownButton.isOpen} {...widthDropdownButton.floatingProps}>
        <Popover.Action
          className="flex items-center space-x-2"
          key="small"
          onClick={() => handleDimensionChange(BulkOrderItemDimension.Small)}
        >
          <SmallIcon /> <span>Short</span>
        </Popover.Action>
        <Popover.Action
          className="flex items-center space-x-2"
          key="medium"
          onClick={() => handleDimensionChange(BulkOrderItemDimension.Medium)}
        >
          <MediumIcon /> <span>Medium</span>
        </Popover.Action>
        <Popover.Action
          className="flex items-center space-x-2"
          key="large"
          onClick={() => handleDimensionChange(BulkOrderItemDimension.Large)}
        >
          <LargeIcon /> <span>Large</span>
        </Popover.Action>
        <Popover.Action
          className="flex items-center space-x-2"
          key="xlarge"
          onClick={() => handleDimensionChange(BulkOrderItemDimension.XLarge)}
        >
          <XLargeIcon /> <span>Extra Large</span>
        </Popover.Action>
      </Popover>
    </div>
  )
}

export default BulkOrderElementCardConfig
