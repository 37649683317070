import { ToastType, useToast } from '@packages/sk8/toast'
import { Asset, FontType, FontView, FontAnswer } from '@packages/types'
import React from 'react'
import { SingleValue } from 'react-select'

import { useDispatch } from 'cms/hooks'
import AsyncFontSelect from 'common/assets/components/AsyncFontSelect'
import type { FontOption } from 'common/assets/types/font'
import { formatFontAssetsOption, getFontValue } from 'common/assets/utils/formatFont'
import { trpc } from 'common/hooks/trpc'
import { downloadResource } from 'utils/forceDownload'

import { patchAnswer, updateAssets, updateFont } from '../actions'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface FontAnswerPanelProps {
  answer: FontAnswer
}

export const FontAnswerPanel = ({ answer }: FontAnswerPanelProps) => {
  const view = (answer.views?.[0] || {}) as FontView
  const { fontType, assets } = view

  const trpcUtils = trpc.useContext()
  const dispatch = useDispatch()
  const { openToast } = useToast()

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) =>
    dispatch(patchAnswer(answer, { [field]: value }))

  const handleChange = (option: SingleValue<FontOption>) => {
    dispatch(updateFont(answer, option?.value ?? null, option?.type, option?.asset))

    if (!answer.name || value?.label === answer.name || answer.name.includes('Untitled answer')) {
      dispatch(patchAnswer(answer, { name: option?.label }))
    }
  }

  const handleUpload = async (asset: Asset | null) => {
    if (!asset) return

    await dispatch(updateAssets(answer, [asset]))
    await dispatch(updateFont(answer, getFontValue(asset), FontType.Custom, asset))
    trpcUtils.asset.listCustomFonts.invalidate()
    openToast(`${asset.originalFilename} was successfully uploaded`, ToastType.success)
  }

  const value = formatFontAssetsOption(assets, fontType)

  return (
    <div aria-label="Font answer panel">
      <TitleSection answer={answer} />

      <hr className="border-neutral-100" />

      <div className="relative px-4 py-6">
        <div className="mb-4 flex justify-between items-center">
          <label className="font-medium">Fonts</label>
        </div>

        <div className="flex w-full">
          <AsyncFontSelect
            onUpload={handleUpload}
            onChange={handleChange}
            fontType={fontType}
            value={value}
            className="w-full"
          />
        </div>

        {value && fontType !== FontType.Google && (
          <button className="text-primary-600 mt-3" onClick={() => downloadResource(value!.asset!.url)}>
            Download font
          </button>
        )}
      </div>

      <hr className="border-neutral-100" />

      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default FontAnswerPanel
