import { Select } from '@packages/sk8/input'
import { DemoProduct, Product } from '@packages/types'
import { groupBy } from 'lodash'
import React from 'react'

interface ProductSelectControlProps extends Omit<React.ComponentProps<typeof Select<string>>, 'options' | 'value'> {
  products: (Product | DemoProduct)[]
  selectedProductId?: string | null
}

const getOptionsFromProducts = (products?: Product[]) => {
  if (!products) return []

  return (
    products
      .filter(product => !!product.live)
      .sort((a, b) => new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime())
      .map(product => ({ label: product.name, value: product.id })) || []
  )
}

const getOptionsFromDemoProducts = (products?: DemoProduct[]) => {
  if (!products) return []

  return products.map(product => ({ label: product.name, value: product.id })) || []
}

const ProductSelectControl = ({ products, selectedProductId, ...rest }: ProductSelectControlProps) => {
  const groupedProducts = groupBy(products, product =>
    (product as DemoProduct).demoAttributes ? 'demo' : 'active'
  ) as { active: Product[]; demo: DemoProduct[] }

  const groupedOptions = [
    { label: 'Active', options: getOptionsFromProducts(groupedProducts.active) },
    { label: 'Demo', options: getOptionsFromDemoProducts(groupedProducts.demo) },
  ]

  const selectedOption = [...groupedOptions[0].options, ...groupedOptions[1].options].find(
    ({ value }) => value === selectedProductId
  )

  return <Select options={groupedOptions} value={selectedOption} {...rest} />
}

export default ProductSelectControl
