import { CheckboxFilter, FilterList, SearchFilter } from '@packages/sk8/filter'
import { uniq, without } from 'lodash'
import React from 'react'

import UnsavedChangesTooltip from 'common/components/filters/UnsavedChangesTooltip'

import { getCombinationFilters } from '../../utils'
import { InventoryTableFilterListProps } from './InventoryTableFilterList'

const ShopifyInventoryTableFilterList = ({
  formik,
  dataTable,
  combinationFiltersData,
  children = null,
}: InventoryTableFilterListProps) => {
  const combinationFilters = getCombinationFilters(combinationFiltersData)

  return (
    <FilterList>
      {children}
      <UnsavedChangesTooltip disabled={!formik.dirty}>
        <SearchFilter name="Search" setSearch={dataTable.setSearch} search={dataTable.search} disabled={formik.dirty} />
      </UnsavedChangesTooltip>
      <UnsavedChangesTooltip disabled={!formik.dirty}>
        <CheckboxFilter
          activeFilters={dataTable.combinations}
          filters={combinationFilters}
          toggleFilter={name => dataTable.toggleFilter('combinations', name)}
          activateFilters={names => dataTable.setFilter('combinations', uniq([...dataTable.combinations, ...names]))}
          deactivateFilters={names => {
            dataTable.setFilter('combinations', without(dataTable.combinations, ...names))
          }}
          clearFilter={() => dataTable.clearFilters('combinations')}
          name="Product"
          searchable
          selectableGroups
          disabled={formik.dirty}
        />
      </UnsavedChangesTooltip>
    </FilterList>
  )
}

export default ShopifyInventoryTableFilterList
