import { Button } from '@packages/sk8/button'
import { Quote, WixOnlineStore } from '@packages/types'
import React from 'react'

import useCopyToClipboard from 'common/hooks/useCopyToClipboard'
import CopyIcon from 'icons/regular/01-Interface Essential/27-Link-Unlink/hyperlink-3.svg'

interface QuoteWixCheckoutLinkProps {
  quote: Omit<Quote, 'store'> & { store: WixOnlineStore }
}

const QuoteWixCheckoutLink = ({ quote }: QuoteWixCheckoutLinkProps) => {
  const copyToClipboard = useCopyToClipboard()

  const checkoutUrl = quote.integration?.wix?.checkoutUrl

  if (!checkoutUrl) return null

  return (
    <span className="flex justify-center items-center divide-x">
      <span>Wix checkout link:</span>
      <Button
        icon={<CopyIcon height={14} width={14} />}
        className="ml-1 text-xs font-medium text-primary-600 -mr-1"
        onClick={() => copyToClipboard(checkoutUrl)}
        iconPosition="right"
        variant="text"
        type="button"
        small
      >
        Copy link
      </Button>
    </span>
  )
}

export default QuoteWixCheckoutLink
