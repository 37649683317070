import { OnlineStore, OnlineStoreStatus, StartingPoint } from '@packages/types'
import { useContext, useEffect, useState } from 'react'

import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import { trpc } from 'common/hooks/trpc'

const useOnlineStores = ({
  isProductConnected,
}: {
  isProductConnected: (store: OnlineStore, defaultStartingPoint: StartingPoint) => boolean
}) => {
  const { match } = useContext(GlobalRouterContext)
  const productId = match.params.productId
  const [selectedOnlineStores, setSelectedOnlineStores] = useState<string[]>([])

  const { data: onlineStores = [], isLoading: isLoadingOnlineStores } = trpc.onlineStore.list.useQuery(undefined, {
    select: (data: OnlineStore[]) => data.filter(store => store.status === OnlineStoreStatus.Installed),
  })

  const { data: defaultStartingPoint, isLoading: isLoadingStartingPoint } =
    trpc.startingPoint.getDefaultForProduct.useQuery(productId)

  useEffect(() => {
    if (onlineStores.length > 0 && defaultStartingPoint)
      setSelectedOnlineStores(
        onlineStores.filter(store => isProductConnected(store, defaultStartingPoint)).map(store => store.id)
      )
  }, [onlineStores, defaultStartingPoint])

  return {
    onlineStores,
    connectedOnlineStores: onlineStores.filter(store => isProductConnected(store, defaultStartingPoint!)),
    selectedOnlineStores,
    setSelectedOnlineStores,
    isLoading: isLoadingOnlineStores || isLoadingStartingPoint,
  }
}

export default useOnlineStores
