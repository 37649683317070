import { OutlineAnswer, OutlineView } from '@packages/types'
import React from 'react'
import { SketchPickerProps } from 'react-color'

import * as builderConstants from 'builder/build/common/constants'
import PatchSketchPicker from 'builder/build/common/components/PatchSketchPicker'
import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import ViewButtons from 'builder/build/customizer/components/ViewButtons'
import { useDispatch } from 'cms/hooks'
import { min } from 'utils/validators'

import { patchAnswer, patchAnswerViews } from '../actions'
import GeneralSettingsSection from './sections/GeneralSettingsSection'
import TitleSection from './sections/TitleSection'

interface OutlineAnswerPanelProps {
  answer: OutlineAnswer
}

export const OutlineAnswerPanel = ({ answer }: OutlineAnswerPanelProps) => {
  const dispatch = useDispatch()
  const currentView = customizerHooks.useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const { width, color } = (answer.views?.[currentView] as OutlineView | undefined) || {}

  const updateAnswer: GeneralSettingsSection['onUpdate'] = (field, value) => {
    dispatch(patchAnswer(answer, { [field]: value }))
  }

  const updateAnswerViews = (values: Partial<OutlineView>) => dispatch(patchAnswerViews(answer, { ...values }))

  const updateOutlineWidth = (value: number) => {
    dispatch(patchAnswer(answer, { views: { [currentView]: { width: value } } }))
  }

  return (
    <div aria-label="Outline answer panel">
      <TitleSection answer={answer} />
      <hr className="border-neutral-100" />
      <div className="relative px-4 py-6">
        <div className="mb-4">
          <ViewButtons currentView={currentView} label={<label className="font-medium mr-auto">Width in view</label>} />
        </div>
        <PatchTextInput
          className="outline-width__input"
          filled={false}
          value={width ?? 0}
          onChange={event => updateOutlineWidth(event.target.valueAsNumber)}
          type="number"
          min="0"
          validator={min(0)}
          rightAddon="px"
        />
      </div>
      <hr className="border-neutral-100" />
      <div className="relative px-4 py-6">
        <PatchSketchPicker
          styles={builderConstants.colorPickerStyle as SketchPickerProps['styles']}
          disableAlpha
          color={color || ''}
          onChange={color => updateAnswerViews({ color: color.hex })}
        />
      </div>
      <hr className="border-neutral-100" />
      <GeneralSettingsSection answer={answer} onUpdate={updateAnswer} />
    </div>
  )
}

export default OutlineAnswerPanel
