import { IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { Table } from '@packages/sk8/table'
import React from 'react'
import { useHistory, useLocation } from 'react-router'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import { QuoteSiblings } from 'cms/quotes/hooks/useFetchQuote'
import { defaultPersistenceState } from 'cms/quotes/utils'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import RightArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'

import QuoteAddonCard from '../Quotes/QuoteAddonCard'

export interface RedactedQuoteProps {
  quote: { quoteId: string }
  handleGoBack: () => void
  baseUrl: string
  quoteSiblings: QuoteSiblings
}

const RedactedQuote = ({ baseUrl, quote, handleGoBack, quoteSiblings }: RedactedQuoteProps) => {
  const location = useLocation<{ persistence?: typeof defaultPersistenceState }>()
  const persistence = location.state?.persistence
  const history = useHistory()

  return (
    <main>
      <Header />
      <SideMenu />
      <Page data-testid="quote page">
        <div className="mb-12 w-80 absolute z-10 left-1/2 -translate-x-1/2 top-52">
          <QuoteAddonCard variant="small" />
        </div>
        <Page.Header>
          <div className="flex">
            <IconButton
              Icon={LeftArrow}
              onClick={handleGoBack}
              className="print:hidden mr-2"
              aria-label="Go back"
              data-testid="go-back-button"
              type="button"
            />

            <h1>
              <div className="flex-col">
                <div className="flex items-center mb-2">
                  <div className="pl-2 pr-3 border-neutral-100 flex items-center">
                    QT{quote.quoteId}
                    <span className="ml-3 pt-0 flex"></span>
                  </div>
                </div>
              </div>
            </h1>
          </div>
          <div className="flex space-x-3">
            {!!persistence && (
              <>
                <div className="flex space-x-2">
                  <IconButton
                    type="button"
                    Icon={LeftArrow}
                    className="print:hidden"
                    aria-label="Previous quote"
                    data-testid="previous-quote-button"
                    disabled={!quoteSiblings.previous}
                    onClick={() =>
                      quoteSiblings.previous &&
                      history.replace(`${baseUrl}/quotes/${quoteSiblings.previous.id}`, location.state)
                    }
                  />
                  <IconButton
                    type="button"
                    Icon={RightArrow}
                    className="print:hidden"
                    aria-label="Next quote"
                    data-testid="next-quote-button"
                    disabled={!quoteSiblings.next}
                    onClick={() =>
                      quoteSiblings.next &&
                      history.replace(`${baseUrl}/quotes/${quoteSiblings.next.id}`, location.state)
                    }
                  />
                </div>
              </>
            )}
          </div>
        </Page.Header>
        <div className="flex items-start blur-sm">
          <Card className="w-full flex flex-col flex-1  mr-8 print:shadow-[none] pb-4">
            <div className="print:clear-both print:border print:border-neutral-100 print:rounded-xl">
              <Card.Section className="md:flex-col lg:flex-row" data-testid="quote-card-header">
                <div className="w-[4rem] min-w-[4rem] h-14 bg-neutral-75 rounded max-w-xs" />

                <div className="w-full lg:flex print:flex justify-stretch">
                  <div className="flex flex-col border-b border-neutral-100 lg:border-r lg:border-b-0 py-4 lg:py-0 px-4 lg:w-2/4">
                    <span className="text-xs font-medium text-neutral-600 mb-4">Product</span>
                    Product name
                  </div>

                  <div className="flex flex-col lg:text-right print:text-right py-4 lg:py-0 lg:w-2/4">
                    <span className="text-xs font-medium text-neutral-600 mb-4">Design id</span>
                    Design id
                  </div>
                </div>
              </Card.Section>
              <Card.Separator />
              <Card.Section className="p-0">
                <Table data-testid="quote-summary">
                  <Table.Header className="h-11">
                    <Table.HeaderRow>
                      <Table.HeaderCell className="w-6/12 py-3 px-6">
                        <span className="font-medium text-neutral-600">DETAILS</span>
                      </Table.HeaderCell>
                      <Table.HeaderCell className="w-2/12">
                        <span className="font-medium text-neutral-600">QTY</span>
                      </Table.HeaderCell>
                      <Table.HeaderCell className="w-2/12">
                        <span className="font-medium text-neutral-600">UNIT PRICE</span>
                      </Table.HeaderCell>
                      <Table.HeaderCell className="w-2/12 text-right px-6">
                        <span className="font-medium text-neutral-600">AMOUNT</span>
                      </Table.HeaderCell>
                    </Table.HeaderRow>
                  </Table.Header>
                  <Table.Body>
                    {Array.from(new Array(5)).map(() => (
                      <Table.Row>
                        <Table.Cell className="w-6/12 py-3 px-6">
                          <span className="font-medium text-neutral-600">redacted</span>
                        </Table.Cell>
                        <Table.Cell className="w-2/12">
                          <span className="font-medium text-neutral-600">redacted</span>
                        </Table.Cell>
                        <Table.Cell className="w-2/12">
                          <span className="font-medium text-neutral-600">redacted</span>
                        </Table.Cell>
                        <Table.Cell className="w-2/12 text-right px-6">
                          <span className="font-medium text-neutral-600">redacted</span>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Card.Section>
            </div>
          </Card>
          <Card className="w-[263px] flex-col">
            <Card.Section className="md:flex-col">
              <span className="text-xs font-medium text-neutral-600 pb-2">Customer name</span>
            </Card.Section>
            <Card.Separator />
            <Card.Section className="md:flex-col">
              <span className="text-xs font-medium text-neutral-600 pb-2">Email</span>
            </Card.Section>
            <Card.Separator />
            <Card.Section className="md:flex-col">
              <span className="text-xs font-medium text-neutral-600 pb-2">Message</span>
            </Card.Section>
          </Card>
        </div>
      </Page>
    </main>
  )
}

export default RedactedQuote
