import { Button } from '@packages/sk8/button'
import { HelperText, Input, InputField, Label } from '@packages/sk8/input'
import { useMutation } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import * as yup from 'yup'

import type { ForgotPasswordFormValues } from 'cms/users/types/form'
import PublicFormFooter from 'common/components/PublicFormFooter'

import useAuthService from './../hooks/useAuthService'
import AuthFormContainer from './AuthFormContainer'
import AuthFormDescription from './AuthFormDescription'
import AuthFormTitle from './AuthFormTitle'
import AuthLink from './AuthLink'

const formValidation = yup.object().shape({
  email: yup.string().required('Please fill the email field').email('Please enter a valid email'),
})

export interface ForgotPasswordFormProps extends RouteComponentProps {
  initialValues?: Partial<ForgotPasswordFormValues>
}

const ForgotPasswordForm = ({ initialValues, history }: ForgotPasswordFormProps) => {
  const authService = useAuthService()

  const { mutate, isSuccess, isError } = useMutation(
    (values: ForgotPasswordFormValues) => authService.sendResetPasswordRequest(values.email),
    {
      onSuccess: (_data, { email }) => history.push(`/login/forgot/email-sent/${email}`),
    }
  )

  const formik = useFormik({
    initialValues: { email: '', ...initialValues },
    validationSchema: formValidation,
    validateOnMount: true,
    onSubmit: (values: ForgotPasswordFormValues, { setSubmitting }) =>
      mutate(values, { onSettled: () => setSubmitting(false) }),
  })

  return (
    <AuthFormContainer>
      <AuthFormTitle>Forgot password</AuthFormTitle>
      <AuthFormDescription>
        We will send you an email with instructions on how to reset your password.
      </AuthFormDescription>

      <form onSubmit={formik.handleSubmit} noValidate className="mt-8">
        <InputField className="mb-6">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="true"
            placeholder="email@example.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={isError || (formik.touched.email && formik.errors.email != null)}
          />
          {(isError || (formik.touched.email && formik.errors.email != null)) && (
            <HelperText hasError>
              {isError ? "We couldn't find your email. Please try again." : formik.errors.email}
            </HelperText>
          )}
        </InputField>
        <PublicFormFooter>
          <Button
            id="submit-button"
            isLoading={formik.isSubmitting}
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || isSuccess}
            className="w-full mb-4 lg:w-fit lg:mb-0"
          >
            Send reset link
          </Button>
          <AuthLink to="/login">Back to login</AuthLink>
        </PublicFormFooter>
      </form>
    </AuthFormContainer>
  )
}

export default ForgotPasswordForm
