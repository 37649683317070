import React from 'react'

import classMerge from '../../utils/classMerge'

export interface ModalActionsProps {
  children: React.ReactNode
  className?: string
}

const ModalActions = ({ children, className }: ModalActionsProps) => (
  <div
    className={classMerge(
      'px-6 py-3 flex space-x-3 items-center justify-end border-t border-t-neutral-75 bg-white',
      className
    )}
  >
    {children}
  </div>
)

export default ModalActions
