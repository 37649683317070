"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundBy = exports.doPolygonsIntersect = exports.isInverted = exports.computeHeightFromPoints = exports.computeWidthFromPoints = exports.getRotationFromPoints = exports.getDistanceFromPoints = exports.getCenterFromPoints = exports.toDegs = exports.toRads = void 0;
exports.rotatePoint = rotatePoint;
const toRads = (degs) => {
    return (degs * Math.PI) / 180;
};
exports.toRads = toRads;
const toDegs = (rads) => {
    return (rads * 180) / Math.PI;
};
exports.toDegs = toDegs;
const getCenterFromPoints = (points) => {
    const sumOfX = points.reduce((acc, point) => acc + point.x, 0);
    const sumOfY = points.reduce((acc, point) => acc + point.y, 0);
    return { x: sumOfX / points.length, y: sumOfY / points.length };
};
exports.getCenterFromPoints = getCenterFromPoints;
const getDistanceFromPoints = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};
exports.getDistanceFromPoints = getDistanceFromPoints;
const getRotationFromPoints = (points) => Math.atan2(points[1].y - points[0].y, points[1].x - points[0].x);
exports.getRotationFromPoints = getRotationFromPoints;
function rotatePoint(point, rads = 0, center = { x: 0, y: 0 }) {
    const tempX = point.x - center.x;
    const tempY = point.y - center.y;
    const rcos = Math.cos(rads);
    const rsin = Math.sin(rads);
    return { x: tempX * rcos - tempY * rsin + center.x, y: tempY * rcos + tempX * rsin + center.y };
}
const computeWidthFromPoints = (points) => {
    const rotation = (0, exports.getRotationFromPoints)(points);
    const center = (0, exports.getCenterFromPoints)(points);
    const point0 = rotatePoint(points[0], -rotation, center);
    const point1 = rotatePoint(points[1], -rotation, center);
    return Math.abs(point1.x - point0.x);
};
exports.computeWidthFromPoints = computeWidthFromPoints;
const computeHeightFromPoints = (points) => {
    const rotation = (0, exports.getRotationFromPoints)(points);
    const center = (0, exports.getCenterFromPoints)(points);
    const point0 = rotatePoint(points[0], -rotation, center);
    const point1 = rotatePoint(points[3], -rotation, center);
    return Math.abs(point1.y - point0.y);
};
exports.computeHeightFromPoints = computeHeightFromPoints;
const isInverted = (points) => {
    const rotation = (0, exports.getRotationFromPoints)(points);
    const center = (0, exports.getCenterFromPoints)(points);
    const rotatedPoints = points.map(point => rotatePoint(point, -rotation, center));
    return !(rotatedPoints[0].x <= rotatedPoints[1].x &&
        rotatedPoints[1].y <= rotatedPoints[2].y &&
        rotatedPoints[2].x >= rotatedPoints[3].x &&
        rotatedPoints[3].y >= rotatedPoints[0].y);
};
exports.isInverted = isInverted;
const doPolygonsIntersect = (polygonA, polygonB) => {
    const polygons = [polygonA, polygonB];
    let minA, maxA, projected, i, i1, j, minB, maxB;
    for (i = 0; i < polygons.length; i++) {
        const polygon = polygons[i];
        for (i1 = 0; i1 < polygon.length; i1++) {
            const i2 = (i1 + 1) % polygon.length;
            const p1 = polygon[i1];
            const p2 = polygon[i2];
            const normal = { x: p2.y - p1.y, y: p1.x - p2.x };
            minA = maxA = undefined;
            for (j = 0; j < polygonA.length; j++) {
                projected = normal.x * polygonA[j].x + normal.y * polygonA[j].y;
                if (minA == null || projected < minA) {
                    minA = projected;
                }
                if (maxA == null || projected > maxA) {
                    maxA = projected;
                }
            }
            minB = maxB = undefined;
            for (j = 0; j < polygonB.length; j++) {
                projected = normal.x * polygonB[j].x + normal.y * polygonB[j].y;
                if (minB == null || projected < minB) {
                    minB = projected;
                }
                if (maxB == null || projected > maxB) {
                    maxB = projected;
                }
            }
            if (maxA < minB || maxB < minA) {
                return false;
            }
        }
    }
    return true;
};
exports.doPolygonsIntersect = doPolygonsIntersect;
const roundBy = (num, unit) => {
    if (!unit)
        return num;
    const reverseUnit = 1 / unit;
    return Math.round(num / unit) / reverseUnit;
};
exports.roundBy = roundBy;
