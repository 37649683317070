import { toRads } from '@packages/math'
import { MoveableManagerInterface, MoveableProps, Renderer } from 'react-moveable'

import * as pathUtils from '../path'
import { getOffsetBezier } from '../utils'

const Infoable = {
  name: 'info-viewer',
  props: ['info'],
  events: [],
  render(moveable: MoveableManagerInterface<MoveableProps & { info: React.ReactNode }, any>, React: Renderer) {
    const rect = moveable.getRect()

    const { target } = moveable.getState()

    let top = rect.height

    if (target?.tagName.toLowerCase() === 'path') {
      const bezier = pathUtils.pathToBezier(target.getAttributeNS(null, 'd'))
      const offsetBezier = getOffsetBezier(bezier, moveable.getState())

      top = pathUtils.bezierMaxY(offsetBezier)
    } else if (moveable.props.rotatable) {
      const { pos1, pos2 } = moveable.state
      const y = (pos1[1] + pos2[1]) / 2 + Math.cos(toRads(rect.rotation)) * -40
      top = top > y ? top : y
    }

    return (
      <div
        key="info-viewer"
        className="absolute -translate-x-1/2 z-10"
        style={{
          left: `${rect.width / 2}px`,
          top: `${top + 20}px`,
          willChange: 'transform',
          opacity: !!moveable.enabledAbles.find(({ name }) => moveable.isDragging(name)) ? 0 : 1,
        }}
      >
        {moveable.props.info}
      </div>
    )
  },
}

export default Infoable
