import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

export interface FiltersBlankStateProps {
  handleClearFiltersClick: (e: React.MouseEvent) => void
}

const FiltersBlankState = ({ handleClearFiltersClick: handleClearFilters }: FiltersBlankStateProps) => {
  return (
    <div className="flex absolute w-full justify-center pt-12 h-min">
      <div className="max-w-sm">
        <BlankState className="max-w-sm">
          <BlankState.Title>No variants to display with the specified filters</BlankState.Title>
          <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
          <BlankState.Action onClick={handleClearFilters}>Clear all filters</BlankState.Action>
        </BlankState>
      </div>
    </div>
  )
}

export default FiltersBlankState
