import { Answer, Group, Part, PrintArea, Question } from '@packages/types'
import { matchPath } from 'react-router'

import type { RootState } from 'cms/store'
import * as constants from 'common/customizerProducts/constants'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import type { RootState as CustomizerRootState } from 'customizer/store'

export const isPathDemoProduct = (pathname: string) => {
  const demoMatch = matchPath<{ productId: string }>(pathname, { path: '*/demos/:productId' })
  return !!demoMatch
}

export const getEntityById = (
  productBuilder: RootState['productBuilder'],
  id: string
): Part | Question | Answer | Group | PrintArea => {
  return (
    productBuilder.parts[id] ||
    productBuilder.questions[id] ||
    productBuilder.answers[id] ||
    productBuilder.groups[id] ||
    productBuilder.printAreas[id]
  )
}

export const getPartFromQuestion = (questionId: string, parts: Part[]) => {
  return parts.find(part => {
    const fields = constants.parts.questionFields[part.type]
    return fields.find(({ field }) => part[field] === questionId)
  })
}

export const getDeletionUpdatedForceView = (deletedIndex: number, targetIndex: number | null | undefined) => {
  if (targetIndex != null) {
    if (targetIndex === deletedIndex) return null
    if (targetIndex > deletedIndex) return targetIndex - 1
  }

  return targetIndex
}

export const getReorderingUpdatedForceView = (
  originalIndex: number,
  newIndex: number,
  targetIndex: number | null | undefined
) => {
  const isMovingBelow = originalIndex < newIndex
  if (targetIndex != null) {
    if (targetIndex === originalIndex) return newIndex

    if (isMovingBelow && targetIndex > originalIndex && targetIndex <= newIndex) {
      return targetIndex - 1
    }
    if (!isMovingBelow && targetIndex < originalIndex && targetIndex >= newIndex) {
      return targetIndex + 1
    }

    return targetIndex
  }

  return null
}

export const generateThumbnail = async (customizationState: {
  [P in keyof CustomizerRootState]?: Partial<CustomizerRootState[P]>
}) => {
  const thumbnail = await twoDDisplayerActions.generateProductImage(0)(
    () => {},
    () => customizationState as CustomizerRootState
  )

  return thumbnail
}
