import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { InventoryManagementStrategy, NormalizedCustomizerProduct } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import { isEqual } from 'lodash'
import React, { useState } from 'react'

import { getCombinationName } from '../../utils'
import { combinationExists } from '../utils'
import SelectQuestions from './SelectQuestions'
import useTotalVariantsCount, { maximumAllowedVariants } from './useTotalVariantsCount'

export interface EditVariantsModalProps {
  isSaving: boolean
  onCreate: (variantsCombinations: string[][]) => void
  onCancelClick: (e: React.MouseEvent) => void
  customizerProduct: NormalizedCustomizerProduct
  combination: string[]
  existingCombinations: string[][]
  modalProps: Omit<React.ComponentProps<typeof Modal>, 'children'>
}

const EditVariantsModal = ({
  isSaving,
  onCancelClick,
  customizerProduct,
  onCreate,
  combination,
  existingCombinations,
  modalProps,
}: EditVariantsModalProps) => {
  const flags = useFlags(['inventory_management'])
  const [newCombination, setNewCombination] = useState<(string | undefined)[]>(combination)

  const { totalCount, isOverMax } = useTotalVariantsCount(
    newCombination.filter(questionId => questionId != null) as string[],
    customizerProduct
  )

  const existingCombinationIndex = existingCombinations.findIndex(existingCombination =>
    isEqual(existingCombination, combination)
  )

  const combinationAlreadyExists = combinationExists(newCombination, existingCombinations)

  return (
    <Modal {...modalProps}>
      <Modal.Title>Change questions: {getCombinationName(combination, customizerProduct)}</Modal.Title>
      <Modal.Details>
        {flags.inventory_management.value === InventoryManagementStrategy.Shopify ? (
          <Alert variant="warning">
            <Alert.Title>Some variants might be connected to Shopify</Alert.Title>
            <Alert.Details>
              If some of the previous variants were linked to a Shopify product, they will be permanently deleted. You
              will need to re-connect them and all of you previous work will be lost.
            </Alert.Details>
          </Alert>
        ) : (
          <Alert variant="warning">
            <Alert.Details>
              Be careful, updating your variants will delete all the data that does not include the new questions. This
              action is not reversible.
            </Alert.Details>
          </Alert>
        )}
        <div className="mt-4">
          Select which questions should be combined to create your variants. We will create a variant for every
          combination of answers from the selected questions. <br />
          <br />A maximum of {maximumAllowedVariants} variants can be created for a combination.
        </div>
      </Modal.Details>
      <div role="form">
        <SelectQuestions
          totalVariantCount={totalCount}
          isOverMaxVariantCount={isOverMax}
          customizerProduct={customizerProduct}
          questionIds={newCombination}
          setQuestionIds={setNewCombination}
        />
        <Modal.Actions>
          {combinationAlreadyExists && (
            <Modal.ActionsMessage className="text-error-default">This combination already exists</Modal.ActionsMessage>
          )}
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button
            variant="primary"
            isLoading={isSaving}
            disabled={
              combinationAlreadyExists ||
              isOverMax ||
              isSaving ||
              newCombination.filter(Boolean).length !== newCombination.length ||
              isEqual(combination, newCombination)
            }
            onClick={() => {
              const newCombinations = [...existingCombinations]
              newCombinations[existingCombinationIndex] = newCombination as string[]

              onCreate(newCombinations)
            }}
          >
            Change
          </Button>
        </Modal.Actions>
      </div>
    </Modal>
  )
}

export default EditVariantsModal
