import React from 'react'

import TableBody from './TableBody'

export interface TableBodyLoaderProps {
  numberOfRows: number
  numberOfColumns: number
}

const TableBodyLoader = ({ numberOfRows, numberOfColumns }: TableBodyLoaderProps) => {
  return (
    <TableBody>
      {Array.from({ length: numberOfRows }, (_, i) => (
        <tr key={String(i)} style={{ opacity: 1 - i * (0.75 / numberOfRows) }} className="border-b border-white">
          {Array.from({ length: numberOfColumns }, (_, j) => (
            <td key={String(j)} className="px-1 py-2">
              <div className="animate-pulse h-6 bg-neutral-75 rounded w-9/12" />
            </td>
          ))}
        </tr>
      ))}
    </TableBody>
  )
}

export default TableBodyLoader
