import classNames from 'classnames'
import React, { useRef } from 'react'
import ReactDOM from 'react-dom'

import useMovablePanel, { SnappedStatus } from './useMovablePanel'

import './MovablePanel.scss'

const portal = document.createElement('div')
portal.className = 'builder'
document.body.appendChild(portal)

const computeSnappedPosition = (snappedStatus: SnappedStatus) => {
  return {
    right: snappedStatus === 'next-to-panel' ? '260px' : '8px',
    top: '62px',
    maxHeight: 'calc(100vh - 86px)',
  }
}

const MovablePanel = ({ dragRef, children }: { dragRef?: HTMLDivElement; children: React.ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null)

  const {
    isDragging,
    handleResizeHandleMouseDown,
    snappedStatus,
    canSnapNextToRightPanel,
    canSnapOverRightPanel,
    initialPosition,
  } = useMovablePanel(ref, dragRef)

  const position = snappedStatus !== 'none' ? computeSnappedPosition(snappedStatus) : initialPosition
  return ReactDOM.createPortal(
    <>
      {isDragging && <div className="drag-scrim" />}
      {canSnapNextToRightPanel && <div className="snap-indicator snap-indicator--next-to-panel" />}
      {canSnapOverRightPanel && <div className="snap-indicator snap-indicator--over-panel" />}
      <div>
        <div
          ref={ref}
          className={classNames('rounded-md shadow-lg bg-white overflow-hidden h-full w-[252px] z-10')}
          style={{ position: 'fixed', maxHeight: '90vh', ...position }}
        >
          <>
            {children}
            {snappedStatus === 'none' && (
              <div
                className="absolute w-[70px] h-1 bg-neutral-300 bottom-1.5 left-[91px] rounded-3xl hover:cursor-ns-resize"
                onMouseDown={handleResizeHandleMouseDown}
              />
            )}
          </>
        </div>
      </div>
    </>,
    portal
  )
}

export default MovablePanel
