import {
  Scalable as ReactMoveableScalable,
  ScalableProps as ReactMoveableScalableProps,
  Renderer,
  MoveableManagerInterface,
} from 'react-moveable'

import ResizeIcon from '../../../../icons/bold/01-Interface Essential/52-Expand-Retract/expand-3.svg'
import PathControl from './PathControl'

export interface PathScalableProps extends ReactMoveableScalableProps {
  scalable: boolean
}

const PathScalable = {
  ...ReactMoveableScalable,
  render: (moveable: MoveableManagerInterface<PathScalableProps>, React: Renderer) => {
    if (!moveable.props.scalable) return null

    const renderDirection = 'se'

    const { width, height } = moveable.getState()

    return (
      <PathControl
        key={`direction-${renderDirection}`}
        className="moveable-resizable-container"
        moveableState={moveable.state}
      >
        <div
          className="rounded-full bg-white w-6 h-6 flex items-center justify-center moveable-control-base moveable-scalable moveable-scalable-control moveable-direction moveable-se"
          data-rotation={135}
          data-direction={renderDirection}
          style={{ opacity: moveable.isDragging() ? 0 : 1, pointerEvents: moveable.isDragging() ? 'none' : 'all' }}
          aria-disabled={!width || !height}
        >
          <ResizeIcon className="w-3 h-3 rotate-90 fill-current" />
        </div>
      </PathControl>
    )
  },
}

export default PathScalable
