import React from 'react'

import classMerge from '../../utils/classMerge'

interface TabProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isSelected?: boolean
  disabled?: boolean
}

const Tab = ({ children, isSelected, disabled, onClick }: TabProps) => {
  return (
    <div
      role="tab"
      aria-selected={isSelected ? 'true' : 'false'}
      onClick={disabled ? undefined : onClick}
      className={classMerge(
        'font-medium h-full flex items-center justify-center min-w-[24px] relative hover:cursor-pointer ',
        {
          'text-primary-600 after:absolute after:-bottom-[1px] after:h-0.5 after:w-full after:bg-primary-600':
            isSelected,
          'text-neutral-300': !isSelected,
          'hover:after:absolute hover:after:-bottom-[1px] hover:after:h-0.5 hover:after:w-full hover:after:bg-neutral-300':
            !isSelected && !disabled,
        }
      )}
    >
      {children}
    </div>
  )
}

export default Tab
