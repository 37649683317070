import React, { forwardRef } from 'react'

import classMerge from '../../utils/classMerge'

const TableBody = forwardRef<
  HTMLTableSectionElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableSectionElement>, HTMLTableSectionElement>
>(({ children, className, ...rest }, ref) => (
  <tbody {...rest} className={classMerge('relative', className)} ref={ref} style={{ marginTop: '101px' }}>
    {children}
  </tbody>
))

export default TableBody
