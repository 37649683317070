import { Tooltip } from '@packages/sk8/tooltip'
import classNames from 'classnames'
import React from 'react'

import AlertIcon from 'icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'

export enum RuleWarningType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

export interface RuleWarningProps {
  message: string
  type: RuleWarningType
}

const RuleWarning = ({ message, type }: RuleWarningProps) => (
  <Tooltip content={message}>
    <AlertIcon
      role="presentation"
      aria-label={`rule ${type}`}
      className={classNames('mr-0 w-[14px] h-[14px]', {
        'fill-error-default': type === RuleWarningType.Error,
        'fill-warning-default': type === RuleWarningType.Warning,
      })}
    />
  </Tooltip>
)

export default RuleWarning
