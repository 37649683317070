import { ScrollToTopButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import React from 'react'

const ShopifyVariantsTableFooter = ({
  scrollToTopButtonProps,
  horizontalScrollProps,
}: {
  scrollToTopButtonProps: React.ComponentProps<typeof ScrollToTopButton>
  horizontalScrollProps: {
    outerComponentProps: React.ComponentProps<'div'>
    innerComponentProps: React.ComponentProps<'div'>
  }
}) => (
  <>
    <div
      {...horizontalScrollProps.outerComponentProps}
      style={{ ...horizontalScrollProps.outerComponentProps.style, position: 'sticky', bottom: '0' }}
    >
      <div {...horizontalScrollProps.innerComponentProps} />
    </div>
    <Card.StickyFooter className="p-0 border-none">
      <ScrollToTopButton {...scrollToTopButtonProps} className="bottom-6" />
    </Card.StickyFooter>
  </>
)

export default ShopifyVariantsTableFooter
