import { Modal } from '@packages/sk8/modal'
import React from 'react'

const UpdateCount = ({
  text,
  count = 0,
  actionText = 'will be updated',
}: {
  text: string
  count: number
  actionText?: string
}) => {
  if (count === 0) return null
  return (
    <Modal.ActionsMessage className="text-neutral-500 flex items-center space-x-2">
      <span>
        <span className="text-primary-600">{count}</span> {text}
        {count > 1 ? 's' : ''} {actionText}
      </span>
    </Modal.ActionsMessage>
  )
}

export default UpdateCount
