import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import React from 'react'

import UpdateCount from 'common/components/UpdateCount'
import { trpc } from 'common/hooks/trpc'

import ConnectionModalOnlineStoreSelection from '../common/connection/ConnectionModalOnlineStoreSelection'
import ConnectionModalWarnings from '../common/connection/ConnectionModalWarnings'
import useShopifyOnlineStores from './useShopifyOnlineStores'

interface ShopifyConnectModalProps {
  ids: string[]
  handleClose: () => void
  modalProps: Omit<React.ComponentProps<typeof Modal>, 'children'>
  onSuccess: () => void
}

const ShopifyConnectModal = ({ ids, onSuccess, handleClose, modalProps }: ShopifyConnectModalProps) => {
  const { openToast } = useToast()
  const { onlineStores, connectedOnlineStores, selectedOnlineStores, setSelectedOnlineStores, isLoading } =
    useShopifyOnlineStores()

  const { mutate: bulkConnect, isLoading: isConnecting } = trpc.variant.bulkConnect.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Variants were successfully connected.', ToastType.success)
    },
    onError: () => {
      openToast('Could not connect variant please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    return bulkConnect({ ids, onlineStoreIds: selectedOnlineStores }, { onSettled: handleClose })
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Variants connection to shopify</Modal.Title>
      <Modal.Details>All variants connected with your store will appear in the store inventory.</Modal.Details>

      <ConnectionModalWarnings
        onlineStores={onlineStores}
        connectedOnlineStores={connectedOnlineStores}
        isLoading={isLoading}
      />

      <ConnectionModalOnlineStoreSelection
        onlineStores={onlineStores}
        connectedOnlineStores={connectedOnlineStores}
        selectedOnlineStores={selectedOnlineStores}
        setSelectedOnlineStores={setSelectedOnlineStores}
        title="Select which Online store you want to connect the selected variants with."
      />

      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="variant" actionText="will be connected to your online store" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isLoading || isConnecting || selectedOnlineStores.length === 0}
            isLoading={isLoading || isConnecting}
          >
            Connect
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ShopifyConnectModal
