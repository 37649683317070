import { useToast, ToastType } from '@packages/sk8/toast'
import { CustomizerProduct, StartingPoint, Product, ActivityLogType } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import React, { useState } from 'react'

import * as coreSelectors from 'builder/build/core/selectors'
import * as coreUtils from 'builder/build/core/utils'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import useStartingPointService from 'builder/connect/hooks/useStartingPointService'
import { useSelector, useDispatch } from 'cms/hooks'
import useAssetService from 'common/assets/hooks/useAssetService'
import TopBarPublishDiscardButton from 'common/components/topBar/TopBarPublishDiscardButton'
import { trpc } from 'common/hooks/trpc'
import useProductService from 'common/products/hooks/useProductService'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import * as twoDDisplayerSelectors from 'customizer/2dDisplayer/selectors'
import * as customizationActions from 'customizer/customization/actions'
import { startingPointConfigurationSelector } from 'customizer/startingPoints/selectors'
import dataURItoBlob from 'utils/dataURItoBlob'

import { syncWithPublishedVersion } from '../actions'
import { buildCustomizationState } from '../utils'
import UpdateVariantResultToast from './UpdateVariantResultToast'

interface PublishDiscardProps {
  productId: string
}

const PublishDiscard = ({ productId }: PublishDiscardProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const { openToast, openCustomToast, openGenericErrorToast } = useToast()
  const [isPublishing, setIsPublishing] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const productService = useProductService()
  const startingPointService = useStartingPointService()
  const assetService = useAssetService()
  const isPublished = !useSelector(coreSelectors.isDraftSelector)
  const isDirty = useSelector(coreSelectors.isDirtySelector)
  const editedPrintArea = customizerHooks.useCustomizerSelector(twoDDisplayerSelectors.editedPrintAreaSelector)
  const trpcUtils = trpc.useContext()

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.listLatest.invalidate(),
  })

  const { mutateAsync: uploadImage } = useMutation(assetService.upload)

  const { mutateAsync: updateStartingPoint } = useMutation((values: { id: string; body: Partial<StartingPoint> }) =>
    startingPointService.update(values.id, values.body)
  )

  const validateStartingPoints = (customizerProduct: CustomizerProduct, startingPoints: StartingPoint[]) => {
    return Promise.all(
      startingPoints.reduce<Promise<StartingPoint>[]>((updates, startingPoint) => {
        if (startingPoint.isDefault) return updates

        const customizationState = buildCustomizationState(customizerProduct, startingPoint.configuration)
        const configurationWithRulesApplied = startingPointConfigurationSelector(customizationState as any)

        return [
          ...updates,
          updateStartingPoint({
            id: startingPoint.id,
            body: { ...startingPoint, upToDate: isEqual(configurationWithRulesApplied, startingPoint.configuration) },
          }),
        ]
      }, [])
    )
  }

  const { mutate: updateProduct } = useMutation((values: Partial<Product>) => productService.update(productId, values))

  const { mutate: updateVariants } = useMutation(() => productService.updateVariants(productId), {
    onSuccess: data => {
      if (data.exceededMaxVariantsCount)
        openCustomToast(props => (
          <UpdateVariantResultToast {...props} message="Too many variants for a combination" type={ToastType.warning} />
        ))
      else if (data.hasNewVariants)
        openCustomToast(props => (
          <UpdateVariantResultToast {...props} message="You have new variants" type={ToastType.default} />
        ))
    },
  })

  const { mutateAsync: publishProduct } = useMutation(['product_publish'], () => productService.publish(productId))

  const updateProductThumbnail = async (customizerProduct: CustomizerProduct) => {
    const customizationState = buildCustomizationState(customizerProduct, customizerProduct.defaultConfiguration)
    const thumb = await coreUtils.generateThumbnail(customizationState)
    const assets = await uploadImage([dataURItoBlob(thumb)])
    if (assets?.[0]?.url) updateProduct({ thumbnail: assets[0].url })
  }

  const handlePublish = async () => {
    setIsPublishing(true)
    try {
      const publishedProduct = await publishProduct()

      dispatch(syncWithPublishedVersion({ customizerProduct: publishedProduct.live }))

      validateStartingPoints(publishedProduct.live, publishedProduct.startingPoints)
      updateProductThumbnail(publishedProduct.live)

      updateVariants()

      openToast('The draft was successfully published', ToastType.success)
      queryClient.invalidateQueries([...productService.fetch.queryKeys, productId])
      createActivityLog({ type: ActivityLogType.PublishCustomizerProduct, details: { productId } })
    } catch (error) {
      openGenericErrorToast('The product was not published.')
    } finally {
      setIsPublishing(false)
    }
  }

  const { mutate: discard, isLoading: isDiscarding } = useMutation(() => productService.discard(productId), {
    onSuccess: () => {
      openToast('The draft was successfully discarded', ToastType.success)
      queryClient.invalidateQueries([...productService.fetch.queryKeys, productId])
    },
    onError: () => openGenericErrorToast('The draft was not discarded.'),
  })

  const handleDiscard = async () => {
    customizerDispatch(customizationActions.stopEditPart())
    if (editedPrintArea) customizerDispatch(twoDDisplayerActions.editPrintArea(null))
    discard()
  }

  return (
    <TopBarPublishDiscardButton
      disabled={isDirty || isDiscarding || isPublishing || isPublished}
      isPublished={isPublished}
      isPublishing={isPublishing}
      onPublish={handlePublish}
      onDiscard={handleDiscard}
    />
  )
}

export default PublishDiscard
