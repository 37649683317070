import React from 'react'

import Remove from '../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import classMerge from '../../utils/classMerge'

export interface ModalCloseButtonProps {
  onClick: () => void
  className?: string
}

const ModalCloseButton = ({ onClick, className }: ModalCloseButtonProps) => (
  <button
    role="button"
    aria-label="modal close"
    type="button"
    onClick={onClick}
    className={classMerge('p-2 absolute right-4 top-4', className)}
  >
    <Remove className="fill-black" width={10} height={10} />
  </button>
)

export default ModalCloseButton
