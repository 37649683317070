import { BetaTag } from '@packages/sk8/tag'
import React from 'react'
import { useParams } from 'react-router'

import useOnlineStoreHasWarning from 'cms/onlineStores/hooks/useOnlineStoreHasWarning'
import useSubscriptionHasError from 'cms/subscription/hooks/useSubscriptionHasError'
import useCurrentUser from 'common/users/hooks/useCurrentUser'

import SideMenuLink from './SideMenuLink'
import SideMenuNotificationIndicator from './SideMenuNotificationIndicator'

const SettingsSideMenu = () => {
  const { isMCZRUser } = useCurrentUser()
  const subscriptionHasError = useSubscriptionHasError()
  const onlineStoreHasWarning = useOnlineStoreHasWarning()
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  return (
    <div className="flex flex-col justify-between w-64 top-[52px] bottom-0 fixed pt-7 bg-white z-[1] border-neutral-100 border-r">
      <div>
        <nav className="pl-9 space-y-4">
          <h2 className="py-2">Settings</h2>
          <SideMenuLink to={`${baseUrl}/onlinestores`}>
            Online stores{onlineStoreHasWarning && <SideMenuNotificationIndicator variant={onlineStoreHasWarning} />}
          </SideMenuLink>
          <SideMenuLink to={`${baseUrl}/business`}>Business</SideMenuLink>
          <SideMenuLink to={`${baseUrl}/wordfilter`}>Bad words</SideMenuLink>
          <SideMenuLink to={`${baseUrl}/translations`}>Translations</SideMenuLink>
          <SideMenuLink to={`${baseUrl}/users`}>Users</SideMenuLink>
          <SideMenuLink to={`${baseUrl}/subscription`}>
            Billing & subscription{subscriptionHasError && <SideMenuNotificationIndicator variant="error" />}
          </SideMenuLink>
          <SideMenuLink to={`${baseUrl}/tokens`}>
            API keys
            <BetaTag className="ml-2" />
          </SideMenuLink>
          {isMCZRUser && <SideMenuLink to={`${baseUrl}/plugins`}>Plugins</SideMenuLink>}
        </nav>
      </div>
    </div>
  )
}

export default SettingsSideMenu
