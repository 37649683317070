import React, { useCallback } from 'react'

import { InventoryDataTable } from 'builder/variants/types/dataTable'

import BlankState from './blankStates/BlankState'
import useVariantsFilters from './useVariantsFilters'

export interface useVariantsTableEmptyPlaceholderProps {
  filters: ReturnType<typeof useVariantsFilters>
  dataTable: InventoryDataTable
  onChangeCombinationClick: (e: React.MouseEvent) => void
}

const useVariantsTableEmptyPlaceholderRender = ({
  filters,
  dataTable,
  onChangeCombinationClick,
}: useVariantsTableEmptyPlaceholderProps) => {
  return useCallback(
    () => (
      <div className="flex sticky left-1/2 w-80 -translate-x-1/2 justify-center pt-12">
        <div className="max-w-sm">
          <BlankState
            areFiltersEmpty={filters.areFiltersEmpty}
            isNewTabSelected={dataTable.isNewFromPublish === true}
            handleChangeQuestionsClick={onChangeCombinationClick}
            handleViewAllVariantsClick={() => dataTable.setFilter('isNewFromPublish', undefined)}
            handleClearFiltersClick={filters.clearAllFilters}
          />
        </div>
      </div>
    ),
    [filters.areFiltersEmpty, dataTable.isNewFromPublish]
  )
}

export default useVariantsTableEmptyPlaceholderRender
