import { BlankState } from '@packages/sk8/blank-state'
import { ToastType, useToast } from '@packages/sk8/toast'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'
import PluginIcon from 'icons/bold/04-Programing-Apps-Websites/02-Plugins-Modules/module-four.svg'
import { pluginRegistry } from 'plugins/plugins'
import type { Plugin } from 'plugins/types/plugin'

import usePluginService from './../hooks/usePluginService'
import InstalledPluginCard from './InstalledPluginCard'
import RegistryPluginCard from './RegistryPluginCard'

const Plugins = () => {
  const { openToast, openGenericErrorToast } = useToast()
  const location = useLocation()
  const queryClient = useQueryClient()
  const pluginService = usePluginService()

  const { data: installedPlugins, isLoading } = useQuery(pluginService.fetchAll.queryKeys, pluginService.fetchAll)

  const { mutate: installPlugin } = useMutation(pluginService.create, {
    onSuccess: () => {
      openToast('Plugin was successfully installed!', ToastType.success)
      queryClient.invalidateQueries(pluginService.fetchAll.queryKeys)
    },
    onError: () => openGenericErrorToast('Plugin has not been installed.'),
  })

  const { mutate: uninstallPlugin } = useMutation(pluginService.delete, {
    onSuccess: () => {
      openToast('Plugin was successfully uninstalled!', ToastType.success)
      queryClient.invalidateQueries(pluginService.fetchAll.queryKeys)
    },
    onError: () => openGenericErrorToast('Plugin has not been uninstalled.'),
  })

  const { mutate: updatePlugin } = useMutation<Plugin, NetworkError, Plugin>(
    plugin => pluginService.update(plugin.id, plugin),
    {
      onSuccess: () => {
        openToast('Plugin was successfully updated!', ToastType.success)
        queryClient.invalidateQueries(pluginService.fetchAll.queryKeys)
      },
      onError: () => openGenericErrorToast('Plugin has not been updated.'),
    }
  )

  const handleUpdate = (plugin: Plugin) => {
    const storePlugin = pluginRegistry.find(({ name }) => name === plugin.name)!
    const updatedPlugin = storePlugin.onPluginUpdate(storePlugin, plugin)
    updatePlugin(updatedPlugin)
  }

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <Page.Header>
          <h1>Plugins</h1>
        </Page.Header>

        <div>
          <h2 className="mb-6">Installed plugins</h2>
          {!isLoading && installedPlugins && installedPlugins.length > 0 && (
            <div className="flex space-x-6">
              {installedPlugins.map(plugin => {
                const registryPlugin = pluginRegistry.find(({ name }) => name === plugin.name)

                return (
                  <Link key={plugin.name} to={`${location.pathname}/${plugin.id}`}>
                    <InstalledPluginCard
                      plugin={plugin}
                      isOutdated={registryPlugin?.version !== plugin.version}
                      onUninstall={() => uninstallPlugin(plugin.id)}
                      onUpdate={() => handleUpdate(plugin)}
                    />
                  </Link>
                )
              })}
            </div>
          )}

          {!isLoading && installedPlugins && installedPlugins.length === 0 && (
            <div className="flex justify-center mt-8 mb-12">
              <BlankState>
                <BlankState.Icon Icon={PluginIcon} />
                <BlankState.Title>No installed plugins</BlankState.Title>
                <BlankState.Details>Installed plugins will appear here</BlankState.Details>
              </BlankState>
            </div>
          )}

          {isLoading && <InstalledPluginCard.Bone />}
        </div>

        <Page.Separator />

        <div>
          <h2 className="mb-6">Plugin store</h2>
          {!isLoading && installedPlugins && (
            <div className="flex space-x-6">
              {pluginRegistry.map(plugin => {
                const isInstalled = !!installedPlugins.find(({ name }) => name === plugin.name)

                return (
                  <RegistryPluginCard
                    key={plugin.name}
                    name={plugin.name}
                    isInstalled={isInstalled}
                    onInstall={() => installPlugin({ ...plugin, active: true })}
                  />
                )
              })}
            </div>
          )}

          {isLoading && <RegistryPluginCard.Bone />}
        </div>
      </Page>
    </main>
  )
}

export default Plugins
