import { IconButton } from '@packages/sk8/button'
import { Tooltip } from '@packages/sk8/tooltip'
import { Part } from '@packages/types'
import React from 'react'

import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import useCustomizerSelector from 'builder/build/customizer/hooks/useCustomizerSelector'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import * as partsActions from 'builder/build/parts/actions'
import * as printAreasSelectors from 'builder/build/printAreas/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'
import AddGridIcon from 'icons/core-solid/interface-essential/interface-edit-grid.svg'
import CenterIcon from 'icons/core-solid/interface-essential/interface-layout-border-center.svg'

interface ExtraSettingsProps {
  part: Part
  visibility?: boolean
}

const ExtraSettings = ({ part, visibility }: ExtraSettingsProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = useCustomizerDispatch()
  const currentView = useCustomizerSelector(customizerSelectors.currentViewSelector) ?? 0
  const isGridDisplayed = useCustomizerSelector(state => state.displayer.displayGrid)
  const printAreas = useSelector(printAreasSelectors.printAreasSelector)
  const printArea = part.printArea ? printAreas[part.printArea] : null

  return (
    <>
      <div className="flex">
        <Tooltip content={isGridDisplayed ? 'Hide grid' : 'Show grid'}>
          <IconButton
            aria-label={isGridDisplayed ? 'Hide grid' : 'Show grid'}
            variant={isGridDisplayed ? 'toggle-selected' : 'toggle'}
            onClick={() => customizerDispatch(twoDDisplayerActions.displayGrid(!isGridDisplayed))}
            disabled={!visibility}
            Icon={AddGridIcon}
          />
        </Tooltip>

        {printArea && (
          <Tooltip content="Center in print area">
            <IconButton
              aria-label="Center in print area"
              variant="subtle"
              onClick={() => dispatch(partsActions.centerPartPrintAreaPosition(part, printArea))}
              disabled={printArea?.productPreview.designView !== currentView}
              Icon={CenterIcon}
            />
          </Tooltip>
        )}
      </div>
    </>
  )
}

export default ExtraSettings
