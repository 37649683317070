import currencies from '@packages/data/currencies.json'
import { Button, IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { HelperText, Input, InputField, Label, Select } from '@packages/sk8/input'
import { ToastType, useToast } from '@packages/sk8/toast'
import { CustomOnlineStore, ECommerce, OnlineStore } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'
import useGoBack from 'common/hooks/useGoBack'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'

import useOnlineStoreService from './../hooks/useOnlineStoreService'
import type { CreateOnlineStoreFormValues } from './../types/form'

const getOnlineStoreValidationSchema = (onlineStores: CustomOnlineStore[]) =>
  yup.object().shape({
    name: yup.string().required('Please enter your online store name.'),
    currency: yup.string().oneOf(Object.keys(currencies)).required('Please choose a currency.'),
    domain: yup
      .string()
      .required("Please enter your store's url.")
      .notOneOf(
        onlineStores.map(onlineStore => onlineStore.domain),
        'A store with that url already exists.'
      ),
  })

const currencyOptions = Object.entries(currencies).map(([key, currency]) => ({
  value: key,
  label: `${currency.symbol} ${key}`,
}))

const CreateOnlineStore = () => {
  const { openToast, openGenericErrorToast } = useToast()
  const goBack = useGoBack()
  const queryClient = useQueryClient()
  const onlineStoreService = useOnlineStoreService()

  const { data: onlineStores = [] } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll)

  const { mutate: create } = useMutation<OnlineStore, NetworkError, CreateOnlineStoreFormValues>(
    onlineStoreService.create,
    {
      onSuccess: () => {
        openToast('Store was successfully created!', ToastType.success)
        queryClient.invalidateQueries(onlineStoreService.fetchAll.queryKeys)
        goBack()
      },
      onError: () => openGenericErrorToast('Store has not been created.'),
    }
  )

  const formik = useFormik<CreateOnlineStoreFormValues>({
    initialValues: { name: '', currency: '', domain: '', eCommerce: ECommerce.CustomStore },
    validationSchema: getOnlineStoreValidationSchema(onlineStores as CustomOnlineStore[]),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => create(values, { onSettled: () => setSubmitting(false) }),
  })

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page>
        <Page.Header>
          <div className="flex items-center">
            <IconButton Icon={LeftArrow} onClick={goBack} className="mr-2" aria-label="Go back" />
            <h1>Add your online store</h1>
          </div>
        </Page.Header>

        <form onSubmit={formik.handleSubmit} noValidate>
          <Page.Section>
            <Page.Aside title="Brand" description="This is the information of your ecommerce." />

            <Page.Content>
              <Card className="flex flex-col flex-1">
                <Card.Section className="md:flex-col space-y-6">
                  <InputField>
                    <Label htmlFor="name">Online store name</Label>
                    <Input
                      placeholder="Online store name"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      hasError={formik.touched.name && formik.errors.name != null}
                    />
                    {formik.touched.name && formik.errors.name != null && (
                      <HelperText hasError>{formik.errors.name}</HelperText>
                    )}
                  </InputField>

                  <InputField>
                    <Label htmlFor="domain">eCommerce URL</Label>
                    <Input
                      placeholder="eCommerce URL"
                      id="domain"
                      name="domain"
                      value={formik.values.domain}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      hasError={formik.touched.domain && formik.errors.domain != null}
                    />
                    {formik.touched.domain && formik.errors.domain != null && (
                      <HelperText hasError>{formik.errors.domain}</HelperText>
                    )}
                  </InputField>

                  <InputField>
                    <Label htmlFor="currency">Currency</Label>
                    <Select
                      id="currency"
                      name="currency"
                      placeholder="Currency"
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      value={currencyOptions.find(({ value }) => value === formik.values.currency) || null}
                      options={currencyOptions}
                      onChange={option => formik.setFieldValue('currency', option?.value)}
                      isSearchable
                      defaultValue={null}
                    />
                    {formik.touched.currency && formik.errors.currency != null && (
                      <HelperText hasError>{formik.errors.currency}</HelperText>
                    )}
                  </InputField>
                </Card.Section>

                <Card.Footer>
                  <Button
                    type="button"
                    className="w-fit"
                    onClick={() => formik.resetForm()}
                    disabled={!formik.dirty || formik.isSubmitting}
                  >
                    Discard
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-fit"
                    disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                    isLoading={formik.isSubmitting}
                  >
                    Create
                  </Button>
                </Card.Footer>
              </Card>
            </Page.Content>
          </Page.Section>
        </form>
      </Page>
    </main>
  )
}

export default CreateOnlineStore
