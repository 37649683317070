import React from 'react'

import classMerge from '../../utils/classMerge'

const TableFooterRow = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => {
  return (
    <tr {...rest} className={classMerge('border-t border-t-neutral-100', className)}>
      {children}
    </tr>
  )
}

export default TableFooterRow
