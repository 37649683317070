import React from 'react'

import classMerge from '../../utils/classMerge'
import ToastAction from './ToastAction'
import ToastCloseButton from './ToastCloseButton'
import ToastIcon from './ToastIcon'
import ToastMessage from './ToastMessage'

interface ToastProps {
  visible?: boolean
  className?: string
  children: React.ReactNode
}

const Toast = ({ visible, className, children }: ToastProps) => {
  return (
    <div
      role="alert"
      className={classMerge(
        'max-w-xl bg-white shadow-lg rounded-lg px-4',
        {
          'animate-toast-enter': visible,
          'animate-toast-leave': !visible,
        },
        className
      )}
    >
      <div role="document" tabIndex={0} className="flex items-center">
        {children}
      </div>
    </div>
  )
}

Toast.Icon = ToastIcon
Toast.Message = ToastMessage
Toast.Action = ToastAction
Toast.CloseButton = ToastCloseButton

export default Toast
