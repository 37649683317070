import React, { forwardRef } from 'react'

import classMerge from '../../utils/classMerge'

const TableHeaderRow = forwardRef<
  HTMLTableRowElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>
>(({ children, className, ...rest }, ref) => (
  <tr {...rest} className={classMerge('text-neutral-700 uppercase shadow-border', className)} ref={ref}>
    {children}
  </tr>
))

export default TableHeaderRow
