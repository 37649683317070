import React from 'react'

import classMerge from '../../utils/classMerge'
import TopBarBackButton from './TopBarBackButton'
import TopBarSection from './TopBarSection'

const TopBar = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      className={classMerge(
        'fixed w-full flex bg-white border-b border-b-neutral-100 h-[52px] basis-[52px] shrink-0 grow-0 z-10 top-0 align-center',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

TopBar.BackButton = TopBarBackButton
TopBar.Section = TopBarSection

export default TopBar
