import React from 'react'

import classMerge from '../../utils/classMerge'

interface TopBarSectionProps {
  className?: string
  right?: boolean
  children?: React.ReactNode
}

const TopBarSection = ({ className, children, right = false }: TopBarSectionProps) => {
  return <div className={classMerge('flex items-center z-[2]', { 'ml-auto': right }, className)}>{children}</div>
}

export default TopBarSection
