import React from 'react'

import classMerge from '../../utils/classMerge'

export interface BlankStateTitleProps {
  className?: string
  children?: React.ReactNode
}

const BlankStateTitle = ({ children, className }: BlankStateTitleProps) => (
  <h2 className={classMerge('mt-6', className)}>{children}</h2>
)

export default BlankStateTitle
