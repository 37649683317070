export const defaultPersistenceState = {
  products: [],
  startDate: undefined,
  endDate: undefined,
  collectionSize: 0,
  resultSize: 0,
  lastIndex: 0,
  count: 25,
  filter: '',
  sortKey: undefined,
  sortOrder: undefined,
}

export const getDesignPreviewUrl = (tenant: string, designId: number) => {
  const baseUrl = `${window.location.protocol}//${tenant}.${DOCUMENT_DOMAIN}`
  return `${baseUrl}/customize/design/${designId}`
}

export const getDesignPreviewUrlWithProtection = (tenant: string, designId: number, designProtectionToken: string) => {
  const baseUrl = `${window.location.protocol}//${tenant}.${DOCUMENT_DOMAIN}`
  return `${baseUrl}/customize/design/${designId}?token=${designProtectionToken}`
}
