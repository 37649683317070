import { Popover } from '@packages/sk8/popover'
import { BetaTag } from '@packages/sk8/tag'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { useParams } from 'react-router'

import useOnlineStoreHasWarning from 'cms/onlineStores/hooks/useOnlineStoreHasWarning'
import useSubscriptionHasError from 'cms/subscription/hooks/useSubscriptionHasError'
import TrackerContext from 'cms/tracking/components/TrackerContext'
import { ConsentStatus } from 'cms/tracking/types/consent'
import TenantContext from 'common/tenant/TenantContext'
import { FeatureFlag } from 'common/users/components/withFlag'
import useCurrentUser from 'common/users/hooks/useCurrentUser'
import DoubleCogIcon from 'icons/bold/01-Interface Essential/12-Settings/cog-double.svg'
import AnalyticsIcon from 'icons/bold/06-Business-Products/12-Analytics/analytics-pie-1.svg'
import ThemeIcon from 'icons/bold/12-Design/05-Colors-Painting/color-painting-palette.svg'
import ProductIcon from 'icons/custom/bold-skateboard.svg'
import BrandAccessIcon from 'icons/custom/streamlinehq-interface-lock-interface-essential-250.svg'
import SettingsIcon from 'icons/custom/streamlinehq-interface-setting-cog-interface-essential-48.svg'
import UsersIcon from 'icons/custom/streamlinehq-interface-user-multiple-interface-essential-250.svg'
import OrdersIcon from 'icons/custom/streamlinehq-mail-inbox-content-mail-48.svg'
import BrandIcon from 'icons/custom/streamlinehq-money-shopping-online-business-money-shopping-250.svg'

import FreeTrialIndicator from './FreeTrialIndicator'
import ProgressCenter from './progressCenter/ProgressCenter'
import SideMenuCount from './SideMenuCount'
import SideMenuGroup from './sideMenuGroup/SideMenuGroup'
import SideMenuLink from './SideMenuLink'
import SideMenuNotificationIndicator from './SideMenuNotificationIndicator'

const SideMenu = () => {
  const { isMasterUser, isMCZRUser } = useCurrentUser()
  const tenant = useContext(TenantContext)
  const tracking = useContext(TrackerContext)
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  const isAdminApp = window.location.host === `admin.${DOCUMENT_DOMAIN}`
  const subscriptionHasError = useSubscriptionHasError()
  const onlineStoreHasWarning = useOnlineStoreHasWarning()
  const hasCookieBanner = tracking.status !== ConsentStatus.granted && !isMCZRUser

  return (
    <div
      className={classNames(
        'print:hidden flex flex-col justify-between w-64 top-[52px] fixed pt-7 bg-white z-[1] border-neutral-100 border-r',
        {
          'bottom-0': !hasCookieBanner,
          'bottom-[68px]': hasCookieBanner,
        }
      )}
    >
      <div>
        {isAdminApp && !tenant && (
          <nav className="space-y-4 pl-5">
            <SideMenuLink exact to="/brands">
              <BrandIcon className="w-4 h-4 mr-3" /> Brands
            </SideMenuLink>

            {isMasterUser && (
              <>
                <SideMenuLink to="/analytics">
                  <AnalyticsIcon className="w-4 h-4 mr-3" /> Analytics
                </SideMenuLink>
                <SideMenuLink to="/users">
                  <UsersIcon className="w-4 h-4 mr-3" /> Admin users
                </SideMenuLink>
                <SideMenuLink to="/accesses">
                  <BrandAccessIcon className="w-4 h-4 mr-3" /> Accesses
                </SideMenuLink>
                <SideMenuLink to="/jobs">
                  <DoubleCogIcon className="w-4 h-4 mr-3" /> Jobs
                </SideMenuLink>
              </>
            )}
          </nav>
        )}

        <nav className="space-y-4 pl-5">
          {tenant && (
            <>
              <SideMenuGroup paths={['/products', '/inventory']}>
                {({ popover, isActive }) => (
                  <>
                    <SideMenuLink to={`${baseUrl}/products`}>
                      <SideMenuGroup.IconContainer>
                        <ProductIcon
                          className={classNames('w-4 h-4 mr-3', {
                            '!text-primary-600 !fill-primary-600': isActive,
                          })}
                        />
                      </SideMenuGroup.IconContainer>
                      My products
                    </SideMenuLink>

                    <FeatureFlag feature="inventory_management">
                      <>
                        {isActive && (
                          <SideMenuGroup.SecondaryLink to={`${baseUrl}/inventory`}>
                            Inventory
                            <BetaTag className="ml-2" />
                          </SideMenuGroup.SecondaryLink>
                        )}
                      </>
                    </FeatureFlag>

                    <FeatureFlag feature="inventory_management">
                      <>
                        {!isActive && (
                          <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
                            <SideMenuGroup.PopoverLink to={`${baseUrl}/products`}>Products</SideMenuGroup.PopoverLink>
                            <SideMenuGroup.PopoverLink to={`${baseUrl}/inventory`}>
                              Inventory
                              <BetaTag className="ml-2" />
                            </SideMenuGroup.PopoverLink>
                          </Popover>
                        )}
                      </>
                    </FeatureFlag>
                  </>
                )}
              </SideMenuGroup>

              <SideMenuLink to={`${baseUrl}/theme`}>
                <ThemeIcon className="w-4 h-4 mr-3" /> Theme editor
              </SideMenuLink>

              <SideMenuGroup paths={['/orders', '/quotes', '/designs']}>
                {({ popover, isActive }) => (
                  <>
                    <SideMenuLink
                      to={`${baseUrl}/orders`}
                      isActive={() => {
                        return false
                      }}
                    >
                      <SideMenuGroup.IconContainer>
                        <OrdersIcon className="w-4 h-4 mr-3" />
                      </SideMenuGroup.IconContainer>
                      <>
                        Orders & Quotes
                        {!isActive && <SideMenuCount menuType="total" />}
                      </>
                    </SideMenuLink>

                    {isActive && (
                      <>
                        <SideMenuGroup.SecondaryLink to={`${baseUrl}/orders`}>
                          Orders <SideMenuCount menuType="orders" />
                        </SideMenuGroup.SecondaryLink>
                        <SideMenuGroup.SecondaryLink to={`${baseUrl}/quotes`}>
                          Quotes <SideMenuCount menuType="quotes" />
                        </SideMenuGroup.SecondaryLink>
                        <SideMenuGroup.SecondaryLink to={`${baseUrl}/designs`}>Designs</SideMenuGroup.SecondaryLink>
                      </>
                    )}

                    {!isActive && (
                      <Popover {...popover.floatingProps} isOpen={popover.isOpen}>
                        <SideMenuGroup.PopoverLink to={`${baseUrl}/orders`}>Orders</SideMenuGroup.PopoverLink>
                        <SideMenuGroup.PopoverLink to={`${baseUrl}/quotes`}>Quotes</SideMenuGroup.PopoverLink>
                        <SideMenuGroup.PopoverLink to={`${baseUrl}/designs`}>Designs</SideMenuGroup.PopoverLink>
                      </Popover>
                    )}
                  </>
                )}
              </SideMenuGroup>

              <SideMenuLink to={`${baseUrl}/onlinestores`}>
                <SettingsIcon className="w-4 h-4 mr-3" /> Settings
                {subscriptionHasError ? (
                  <SideMenuNotificationIndicator variant="error" />
                ) : (
                  onlineStoreHasWarning && <SideMenuNotificationIndicator variant={onlineStoreHasWarning} />
                )}
              </SideMenuLink>
            </>
          )}
        </nav>
      </div>

      {tenant && (
        <div>
          <ProgressCenter baseUrl={baseUrl} />
          <div className="px-3 pb-6">
            <FreeTrialIndicator />
          </div>
        </div>
      )}
    </div>
  )
}

export default SideMenu
