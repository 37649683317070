import { Alert } from '@packages/sk8/alert'
import { Modal } from '@packages/sk8/modal'
import { OnlineStore } from '@packages/types'
import React from 'react'

const Warning = ({ title, message }: { title: string; message: string }) => {
  const helpLink = 'https://help.gokickflip.com/en/collections/2614249-connecting-kickflip-with-your-ecommerce'

  return (
    <Modal.Details className="mt-0 pt-0">
      <Alert variant="error">
        <Alert.Body>
          <Alert.Title>{title}</Alert.Title>
          <Alert.Details>{message}</Alert.Details>
        </Alert.Body>
        <Alert.Actions>
          <Alert.Link onClick={() => window.open(helpLink, '_blank')}>Learn more</Alert.Link>
        </Alert.Actions>
      </Alert>
    </Modal.Details>
  )
}

const ConnectionModalWarnings = ({
  isLoading,
  onlineStores,
  connectedOnlineStores,
}: {
  isLoading: boolean
  onlineStores: OnlineStore[]
  connectedOnlineStores: OnlineStore[]
}) => {
  if (!isLoading && onlineStores.length === 0)
    return (
      <Warning
        title="No online store found"
        message="You need to connect your store to Kickflip before you can connect your variants."
      />
    )

  if (!isLoading && onlineStores.length >= 1 && connectedOnlineStores.length === 0)
    return <Warning title="Product not connected" message="Your default product must be connected to a store." />

  return null
}

export default ConnectionModalWarnings
