import { AddonId } from '@packages/types'
import { useQuery } from '@tanstack/react-query'

import useSubscriptionService from './useSubscriptionService'

const useIsSubscribedToAddon = ({ addonId }: { addonId: AddonId }) => {
  const subscriptionService = useSubscriptionService()

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)

  if (!subscription) return null

  const isSubscribedToAddon = subscription?.addons.find(addon => addon.addonId === addonId) != null

  return isSubscribedToAddon
}

export default useIsSubscribedToAddon
