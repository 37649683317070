import { CheckboxGroup } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { OnlineStore } from '@packages/types'
import React from 'react'

const ConnectionModalOnlineStoreSelection = ({
  onlineStores,
  connectedOnlineStores,
  selectedOnlineStores,
  setSelectedOnlineStores,
  title,
}: {
  onlineStores: OnlineStore[]
  connectedOnlineStores: OnlineStore[]
  selectedOnlineStores: string[]
  setSelectedOnlineStores: (selectedOnlineStores: string[]) => void
  title: string
}) => {
  if (onlineStores.length <= 1 || connectedOnlineStores.length < 1) return null

  return (
    <Modal.Details className="pt-0 -mt-1">
      <div>{title}</div>
      <div className="flex flex-col space-y-4 mt-4">
        <CheckboxGroup
          name="names"
          options={onlineStores.map(store => ({
            id: store.id,
            label: store.name,
            value: store.id,
            checked: selectedOnlineStores.includes(store.id),
            disabled: !connectedOnlineStores.find(connectedStore => connectedStore.id === store.id),
          }))}
          onChange={e => {
            if (selectedOnlineStores.includes(e.target.value)) {
              setSelectedOnlineStores(selectedOnlineStores.filter(store => store !== e.target.value))
            } else {
              setSelectedOnlineStores([...selectedOnlineStores, e.target.value])
            }
          }}
        />
      </div>
    </Modal.Details>
  )
}

export default ConnectionModalOnlineStoreSelection
