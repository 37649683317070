import { Button } from '@packages/sk8/button'
import { useMutation } from '@tanstack/react-query'
import React from 'react'

import Page from 'cms/layout/page/Page'
import useShopifyProxyService from 'cms/onlineStores/hooks/useShopifyProxyService'
import withFlag from 'common/users/components/withFlag'

interface ShopifyCartFunctionPriceAdjustmentProps {
  onlineStoreId: string
}

const ShopifyCartFunctionPriceAdjustment = ({ onlineStoreId }: ShopifyCartFunctionPriceAdjustmentProps) => {
  const shopifyProxyService = useShopifyProxyService()

  const { mutate: syncPriceAdjustments, isLoading } = useMutation(shopifyProxyService.syncPriceAdjustments)

  return (
    <>
      <Page.Section>
        <Page.Aside title="Shopify Cart Function" description="Sync Cart Function with Market price adjustments." />

        <Page.Content className="flex items-center justify-end">
          <Button className="w-fit" isLoading={isLoading} onClick={() => syncPriceAdjustments(onlineStoreId)}>
            Sync cart
          </Button>
        </Page.Content>
      </Page.Section>
      <Page.Separator />
    </>
  )
}

export default withFlag({
  Component: ShopifyCartFunctionPriceAdjustment,
  feature: 'set_cart_prices_function',
})
