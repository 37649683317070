import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { useFormikContext } from 'formik'
import React from 'react'

import DeleteCount from 'common/components/DeleteCount'

import { ExtraPrice, PricingFormValues } from '../types'

interface DeleteExtraPriceModalProps {
  selectedIds: string[]
  handleClose: () => void
  modalProps: Omit<React.ComponentProps<typeof Modal>, 'children'>
}

const DeleteExtraPriceModal = ({ selectedIds, handleClose, modalProps }: DeleteExtraPriceModalProps) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<PricingFormValues>()
  const handleDelete = () => {
    const updatedExtraPrices = values.extraPrices.filter(
      (extraPrice: ExtraPrice) => !selectedIds.includes(`${extraPrice.questionId}/${extraPrice.answerId}`)
    )
    setFieldValue('extraPrices', updatedExtraPrices)
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Delete extra prices</Modal.Title>
      <Modal.Details>
        Are you sure you want to delete selected extra prices ? This action cannot be undone.
      </Modal.Details>
      <Modal.Actions className="justify-between items-center">
        <DeleteCount text="extra price" count={selectedIds.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="error" onClick={handleDelete} type="submit" form="pricing-form" isLoading={isSubmitting}>
            Delete
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default DeleteExtraPriceModal
