import { MoveableManagerInterface, Renderer } from 'react-moveable'

import ResizeIcon from '../../../../icons/bold/01-Interface Essential/49-Move/direction-button-arrows.svg'
import PathControl from './PathControl'

const PathDraggable = {
  name: 'path-draggable-target',
  props: [],
  events: [],
  render: (moveable: MoveableManagerInterface, React: Renderer) => {
    return (
      <PathControl
        className="moveable-resizable-container absolute"
        moveableState={moveable.state}
        offset={{ x: 0, y: 32 }}
      >
        <div
          className="rounded-full bg-white w-6 h-6 flex items-center justify-center moveable-draggable moveable-control-base moveable-control-drag-target"
          style={{ opacity: moveable.isDragging() ? 0 : 1, pointerEvents: moveable.isDragging() ? 'none' : 'all' }}
        >
          <ResizeIcon className="w-3 h-3 fill-current" />
        </div>
      </PathControl>
    )
  },
}

export default PathDraggable
