import { Select } from '@packages/sk8/input'
import { PrintArea } from '@packages/types'
import React from 'react'

import HorizontalField from 'builder/build/common/components/HorizontalField'
import VerticalFields from 'builder/build/common/components/VerticalFields'
import * as coreSelectors from 'builder/build/core/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import WithInformation from 'common/components/WithInformation'

import { patchPrintArea } from '../../actions'

interface DesignViewSectionProps {
  printArea: PrintArea
  onChangeView: (view: number) => void
}

export const DesignViewSection = ({ printArea, onChangeView }: DesignViewSectionProps) => {
  const dispatch = useDispatch()
  const numberOfViews = useSelector(coreSelectors.viewsSelector)
  const options = Array.from(Array(numberOfViews)).map((_, index) => ({
    label: index + 1,
    value: index + 1,
  }))

  const handleChangeDesignView = (designView: number) => {
    dispatch(
      patchPrintArea(printArea, {
        productPreview: {
          designView,
          views: { [designView]: { hidden: false } },
        },
      })
    )
    onChangeView(designView)
  }

  return (
    <VerticalFields className="mb-4">
      <label className="text-xs text-black/[.54]">The box should be placed approximately on your product.</label>
      <HorizontalField>
        <WithInformation tooltip="The design view refers to the view where customers can upload files and apply transformations.">
          <label>Design view</label>
        </WithInformation>
        <Select
          value={options.find(option => option.value === printArea.productPreview.designView + 1)}
          options={options}
          onChange={option => handleChangeDesignView(option!.value - 1)}
        />
      </HorizontalField>
    </VerticalFields>
  )
}

export default DesignViewSection
