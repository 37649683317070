import { Input } from '@packages/sk8/input'
import { BulkOrder } from '@packages/types'
import React from 'react'

import { useDispatch } from 'customizer/hooks'

import { patchGroup } from '../../actions'

interface BulkOrderLabelSettingsSectionProps {
  bulkOrderGroup: BulkOrder
}

const BulkOrderLabelSettingsSection = ({ bulkOrderGroup }: BulkOrderLabelSettingsSectionProps) => {
  const dispatch = useDispatch()

  const handleAddItemLabelChange = (addItemLabel: string) => dispatch(patchGroup(bulkOrderGroup.id, { addItemLabel }))

  const handleItemLabelChange = (itemLabel: string) => dispatch(patchGroup(bulkOrderGroup.id, { itemLabel }))

  return (
    <div className="relative px-4 py-6 flex flex-col space-y-4">
      <div className="space-y-2">
        <label>Add button label</label>
        <Input
          placeholder="Add another item"
          onChange={e => handleAddItemLabelChange(e.target.value)}
          value={bulkOrderGroup.addItemLabel}
        />
      </div>

      <div className="space-y-2">
        <label>Item label</label>
        <Input
          placeholder="Item"
          onChange={e => handleItemLabelChange(e.target.value)}
          value={bulkOrderGroup.itemLabel}
        />
      </div>
    </div>
  )
}

export default BulkOrderLabelSettingsSection
