import { AddonId } from '@packages/types'
import React from 'react'

import AddonCard, { AddonCardVariants } from 'cms/subscription/components/cards/AddonCard'
import QuoteFormImage from 'img/quote-form.png'
import QuotesImage from 'img/quotes.png'

const QuoteAddonCard = ({ variant }: { variant?: AddonCardVariants }) => {
  return (
    <AddonCard
      variant={variant}
      addonId={AddonId.ConfigurePriceQuote}
      title="Configure price quote"
      description="Unlock the ability to sell custom products with quotes."
      context="First 10 quotes are included, add-on required for unlimited access."
      rightContent={
        <>
          <div className="h-80 overflow-hidden absolute -left-12 top-12 rounded-xl shadow-lg">
            <img src={QuoteFormImage} alt="Quote Form" className="h-full w-auto" />
          </div>

          <div className="h-72 border border-neutral-75 ml-auto rounded-lg overflow-hidden">
            <img src={QuotesImage} alt="Quotes" className="min-w-[500px] w-[800px]" />
          </div>
        </>
      }
    />
  )
}

export default QuoteAddonCard
