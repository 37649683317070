import { Checkbox, Label, Select } from '@packages/sk8/input'
import { Part } from '@packages/types'
import React from 'react'

import HorizontalField from 'builder/build/common/components/HorizontalField'
import VerticalField from 'builder/build/common/components/VerticalField'
import VerticalFields from 'builder/build/common/components/VerticalFields'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import { useSelector, useDispatch } from 'cms/hooks'
import WithInformation from 'common/components/WithInformation'
import * as twoDDisplayerActions from 'customizer/2dDisplayer/actions'

import { patchPart } from '../actions'
import { partsAsArraySelector } from '../selectors'

interface DisplaySettingsSectionProps {
  part: Part
}

const DisplaySettingsSection = ({ part }: DisplaySettingsSectionProps) => {
  const dispatch = useDispatch()
  const customizerDispatch = customizerHooks.useCustomizerDispatch()

  const highlightGroups = useSelector(partsAsArraySelector)
  const highlightGroupOptions = highlightGroups.map(hg => ({
    label: hg.name,
    value: hg.id,
  }))

  const updatePart = (field: keyof Part, value: any) => {
    dispatch(patchPart(part.id, { [field]: value }))
  }

  const updateCustomizerHighlight = (value: boolean) => {
    if (!value) {
      customizerDispatch(twoDDisplayerActions.setForceHighlight(null))
    } else {
      customizerDispatch(twoDDisplayerActions.forceHighlight([part.id]))
    }
  }

  return (
    <div className="p-4">
      <VerticalFields>
        <HorizontalField>
          <Label htmlFor="focusable">
            <WithInformation tooltip="Toggle on to allow your user to interact with this layer on your product.">
              Focusable
            </WithInformation>
          </Label>
          <Checkbox id="focusable" onChange={e => updatePart('focusable', e.target.checked)} checked={part.focusable} />
        </HorizontalField>
        <HorizontalField>
          <Label htmlFor="forceHighlight">
            <WithInformation tooltip="Toggle on to make the layer be highlighted when the question is being interacted with.">
              Force highlight
            </WithInformation>
          </Label>
          <Checkbox
            id="forceHighlight"
            onChange={e => {
              updatePart('forceHighlight', e.target.checked)
              updateCustomizerHighlight(e.target.checked)
            }}
            checked={part.forceHighlight}
          />
        </HorizontalField>
        <VerticalField>
          <label>
            <WithInformation
              tooltip={`You can highlight multiple layers as one by \nassigning them to the same highlight group.`}
            >
              Highlight group
            </WithInformation>
          </label>
          <Select
            menuPosition="fixed"
            className="select--no-margin-bottom"
            value={highlightGroupOptions.find(
              highlightGroupOption => highlightGroupOption.value === part.highlightGroup
            )}
            options={highlightGroupOptions}
            onChange={o => updatePart('highlightGroup', o!.value)}
          />
        </VerticalField>
      </VerticalFields>
    </div>
  )
}

export default DisplaySettingsSection
