import { Part } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import * as partsActions from 'builder/build/parts/actions'
import { useDispatch } from 'cms/hooks'
import Switch from 'common/components/Switch'

interface FiltersSectionProps {
  part: Part
  isDisabled?: boolean
}

const { toggleFilter } = partsActions

const FiltersSection = ({ part, isDisabled }: FiltersSectionProps) => {
  const dispatch = useDispatch()

  const handleToggleFilter = (filter: string) => {
    dispatch(toggleFilter(part.id, filter))
  }

  const hasFilter = (filter: string) => {
    return part.modifiers?.filters?.find(({ type }) => type === filter) != null
  }

  return (
    <div
      className={classNames('px-4 py-6 flex flex-col space-y-4', {
        'pointer-events-none opacity-40': isDisabled,
      })}
    >
      <span className="font-medium">Filters</span>
      <div className="flex items-center justify-between">
        <label>Black and white</label>
        <Switch
          disabled={isDisabled}
          checked={hasFilter('blackAndWhite')}
          onChange={() => handleToggleFilter('blackAndWhite')}
        />
      </div>
      <div className="flex items-center justify-between">
        <label>Transparent</label>
        <Switch
          disabled={isDisabled}
          checked={hasFilter('blackToTransparency')}
          onChange={() => handleToggleFilter('blackToTransparency')}
        />
      </div>
    </div>
  )
}

export default FiltersSection
