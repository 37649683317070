import React, { useEffect, useState } from 'react'

import AddCircle from '../../icons/bold/01-Interface Essential/43-Remove-Add/add-circle-alternate.svg'
import Remove from '../../icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import ArrowCornerIcon from '../../icons/core-solid/interface-essential/interface-arrows-bend-down-right-1.svg'
import classMerge from '../../utils/classMerge'
import Button from '../button/Button'
import IconButton from '../button/IconButton'
import NumberInput from '../input/NumberInput'
import Select from '../input/select/Select'
import Popover from '../popover/Popover'
import usePopover from '../popover/usePopover'

export enum NumberFilterTypes {
  'eq' = 'eq',
  'lt' = 'lt',
  'gt' = 'gt',
  'gtelte' = 'gte-lte',
}

const SingleInput = ({ value, onChange }: { value?: string; onChange: (value: string) => void }) => {
  return (
    <NumberInput
      aria-label="Number filter value"
      value={value == null ? '' : +value}
      onValueChange={value => onChange(value === undefined ? '' : String(value))}
    />
  )
}

const IsBetweenInput = ({ value = [], onChange }: { value?: string[]; onChange: (value: string[]) => void }) => {
  const gteValue = value[0] == null || value[0] == '' ? undefined : +value[0]
  const lteValue = value[1] == null || value[1] == '' ? undefined : +value[1]

  return (
    <>
      <NumberInput
        aria-label="Lower number filter value"
        value={gteValue ?? ''}
        onValueChange={newValue => onChange([newValue === undefined ? '' : String(newValue), value[1]])}
      />
      <span className="text-neutral-600 text-xs">and</span>
      <NumberInput
        aria-label="Upper number filter value"
        value={lteValue ?? ''}
        onValueChange={newValue => onChange([value[0], newValue === undefined ? '' : String(newValue)])}
      />
    </>
  )
}

const IsEqualToSelectedValue = ({ value }: { value: string }) => {
  return <span>{value}</span>
}

const IsBetweenSelectedValue = ({ value = [] }) => {
  return (
    <span>
      {value[0] ?? ''} to {value[1] ?? ''}
    </span>
  )
}

const IsGreaterThanSelectedValue = ({ value = [] }) => {
  return <span>more than {value}</span>
}

const IsLessThanSelectedValue = ({ value = [] }) => {
  return <span>less than {value}</span>
}

const numberFilters: {
  [Property in NumberFilterTypes]: {
    id: Property
    name: string
    Input: Property extends 'eq' | 'lt' | 'gt' ? typeof SingleInput : typeof IsBetweenInput
    SelectedValue: React.ElementType
    defaultValue?: string | string[]
  }
} = {
  eq: {
    id: NumberFilterTypes.eq,
    name: 'is equal to',
    Input: SingleInput,
    SelectedValue: IsEqualToSelectedValue,
  },
  ['gte-lte']: {
    id: NumberFilterTypes.gtelte,
    name: 'is between',
    Input: IsBetweenInput,
    SelectedValue: IsBetweenSelectedValue,
  },
  gt: {
    id: NumberFilterTypes.gt,
    name: 'is more than',
    Input: SingleInput,
    SelectedValue: IsGreaterThanSelectedValue,
  },
  lt: {
    id: NumberFilterTypes.lt,
    name: 'is less than',
    Input: SingleInput,
    SelectedValue: IsLessThanSelectedValue,
  },
}

type SingleValueType = {
  type: NumberFilterTypes.eq | NumberFilterTypes.lt | NumberFilterTypes.gt
  value?: string
}

type MultieValueType = {
  type: NumberFilterTypes.gtelte
  value?: string[]
}

export type NumberFilterValue =
  | {
      type: string
      value?: string | string[]
    }
  | {
      type: NumberFilterTypes.gtelte
      value?: string[]
    }
  | {
      type: NumberFilterTypes.eq | NumberFilterTypes.lt | NumberFilterTypes.gt
      value?: string
    }

type CustomFilterValue = {
  type: string
  value?: string | string[]
}

export type FilterValue<T extends string | NumberFilterTypes> = T extends NumberFilterTypes
  ? T extends NumberFilterTypes.gtelte
    ? MultieValueType
    : SingleValueType
  : CustomFilterValue

type EQUrlParam = { eq: string }
type GTParamString = { gt: string }
type LTParamString = { lt: string }
type GTELTEParamString = { gte: string; lte: string }

type NumberFilterURLParamType = EQUrlParam | GTParamString | LTParamString | GTELTEParamString

const NumberFilterURLParam = {
  encode(value?: NumberFilterValue) {
    switch (value?.type) {
      case 'eq':
      case 'gt':
      case 'lt':
        return { [value.type]: value.value }
      case 'gte-lte':
        return { gte: value.value?.[0], lte: value.value?.[1] }
      default:
        return value
    }
  },

  decode(value?: NumberFilterURLParamType) {
    if (!value) return undefined

    if ('eq' in value) return { type: 'eq', value: value.eq }
    if ('gte' in value && 'lte' in value) return { type: 'gte-lte', value: [value.gte, value.lte] }
    if ('gt' in value) return { type: 'gt', value: value.gt }
    if ('lt' in value) return { type: 'lt', value: value.lt }

    return undefined
  },
}

type CustomFilter = {
  id: string
  name: string
  defaultValue?: any
}

interface NumberFilterProps {
  name: string
  activeFilter?: NumberFilterValue
  setFilter: (filter: NumberFilterValue) => void
  clearFilter: () => void
  Icon?: React.ElementType
  customFilters?: CustomFilter[]
  disabled?: boolean
}

const NumberFilter = ({
  name,
  activeFilter,
  setFilter,
  clearFilter,
  Icon,
  disabled = false,
  customFilters = [],
}: NumberFilterProps) => {
  const popover = usePopover({
    placement: 'bottom',
    useArrow: true,
    offsetConfig: 12,
    autoUpdateOnAnimationFrame: true,
  })

  const id = `add-${name.toLowerCase().split(' ').join('-')}-filter`

  const [innerActiveFilter, setInnerActiveFilter] = useState<NumberFilterValue>(
    activeFilter ?? { type: numberFilters.eq.id }
  )

  const isInnerActiveValueIncomplete =
    innerActiveFilter.value === undefined ||
    innerActiveFilter.value === '' ||
    (Array.isArray(innerActiveFilter.value) &&
      (innerActiveFilter.value.findIndex(el => el == null || el === '') !== -1 || innerActiveFilter.value.length !== 2))

  useEffect(() => {
    if (isInnerActiveValueIncomplete) clearFilter()
    else setFilter(innerActiveFilter)
  }, [innerActiveFilter.type, innerActiveFilter.value, isInnerActiveValueIncomplete])

  useEffect(() => {
    const shouldResetInnerActiveFilter =
      activeFilter == null &&
      (innerActiveFilter.type !== numberFilters.eq.id || innerActiveFilter.value != null) &&
      (!popover.isOpen || !isInnerActiveValueIncomplete)

    if (shouldResetInnerActiveFilter) setInnerActiveFilter({ type: numberFilters.eq.id })
  }, [activeFilter == null, popover.isOpen])

  const filter =
    numberFilters[innerActiveFilter.type as NumberFilterTypes] ??
    customFilters.find(filter => filter.id === innerActiveFilter.type)

  const ValueInput = filter.Input as
    | React.ElementType<{
        value: string | string[] | undefined
        onChange: (value: string | string[]) => void
      }>
    | undefined
  const SelectedValue = filter.SelectedValue

  return (
    <div
      className={classMerge(
        'rounded-xl focus:ring-primary-100 shadow-xs border h-7 font-medium flex items-center overflow-hidden focus-within:border-primary-200 focus-within:ring border-neutral-100',
        {
          'border-dashed': activeFilter == null,
          'border-neutral-50 border border-solid bg-neutral-50 text-neutral-200 fill-neutral-200': disabled,
        }
      )}
    >
      <Button
        variant="text"
        className="!text-xs mx-1.5 text-neutral-400"
        icon={Icon ? <Icon className="w-3 h-3 fill-current" /> : <AddCircle className="w-3 h-3 fill-current" />}
        disabled={disabled}
        {...popover.referenceProps}
      >
        {name}
      </Button>
      {innerActiveFilter.value != null && innerActiveFilter.value.length !== 0 && (
        <>
          <div className="h-full w-[1px] bg-neutral-75" />
          <div className="flex items-center bg-neutral-50 h-full pl-2 pr-0.5 flex-wrap text-xs text-primary-500">
            <div className="flex space-x-0.5">
              {SelectedValue !== undefined ? (
                <SelectedValue value={innerActiveFilter.value} />
              ) : (
                innerActiveFilter.value
              )}
            </div>
            <IconButton
              small
              aria-label="Clear number filter"
              variant="subtle"
              Icon={Remove}
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                clearFilter()
              }}
              disabled={disabled}
            />
          </div>
        </>
      )}
      <Popover isOpen={popover.isOpen} {...popover.floatingProps} aria-labelledby={id}>
        <div id={id} className="px-4 pt-4 mb-1 font-medium">
          Filter by {name.toLowerCase()}
        </div>
        <div className="w-[220px] flex flex-col px-4 pt-2 pb-4 space-y-3">
          <div className="w-full">
            <Select
              aria-label="Number filter type"
              value={{
                value: { id: filter.id, defaultValue: filter.defaultValue },
                label: filter.name,
              }}
              options={[...Object.values(numberFilters), ...customFilters].map(numberFilter => ({
                value: { id: numberFilter.id, defaultValue: numberFilter.defaultValue },
                label: numberFilter.name,
              }))}
              onChange={option => {
                option && setInnerActiveFilter({ type: option.value.id, value: option.value.defaultValue })
              }}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 70 }),
              }}
            />
          </div>
          {ValueInput !== undefined && (
            <div className="flex space-x-2 items-center">
              <ArrowCornerIcon className="ml-1 w-3 h-3 -scale-x-100 -rotate-90 shrink-0 fill-primary-400" />
              <ValueInput
                value={innerActiveFilter?.value}
                onChange={value =>
                  setInnerActiveFilter({
                    type: innerActiveFilter.type,
                    value,
                  } as NumberFilterValue)
                }
              />
            </div>
          )}
        </div>
      </Popover>
    </div>
  )
}

const applyFilter = (numberFilter: NumberFilterValue | undefined, value: number | null) => {
  if (numberFilter === undefined || numberFilter.value === undefined || numberFilter.value === '') return true

  if (value == null) return false

  switch (numberFilter.type) {
    case NumberFilterTypes.eq:
      return value === +numberFilter.value
    case NumberFilterTypes.gt:
      return value > +numberFilter.value
    case NumberFilterTypes.lt:
      return value < +numberFilter.value
    case NumberFilterTypes.gtelte:
      return value >= +numberFilter.value[0] && value <= +numberFilter.value[1]
    default:
      throw new Error('Invalid filter type')
  }
}

NumberFilter.URLParam = NumberFilterURLParam
NumberFilter.applyFilter = applyFilter

export default NumberFilter
