import useCommonVariantsColumns, { ColumnDef } from '../common/useCommonVariantsColumns'
import ShopifyVariantsBulkPopover from './ShopifyVariantsBulkPopover'

const useShopifyVariantsColumns = (
  locationId: string,
  variantsCombination: string[],
  notScrolled: boolean,
  fullyScrolled: boolean
): ColumnDef[] => {
  const baseColumns = useCommonVariantsColumns(
    locationId,
    variantsCombination,
    notScrolled,
    fullyScrolled,
    ShopifyVariantsBulkPopover
  )

  return baseColumns
}

export default useShopifyVariantsColumns
