export const formatSmallDate = (date: string | Date) => {
  const dateObject = new Date(date)

  return dateObject.toLocaleDateString('en', {
    year: dateObject.getFullYear() === new Date().getFullYear() ? undefined : 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export const formatFullDate = (date: string | Date) => {
  const dateObject = new Date(date)

  return `${dateObject.toDateString()} ${dateObject.getHours().toString().padStart(2, '0')}:${dateObject
    .getMinutes()
    .toString()
    .padStart(2, '0')} (Eastern Standard Time)`
}

export const formatDisplayDate = (date: string | Date) => {
  const dateObject = new Date(date)

  return dateObject.toLocaleDateString('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}
