import { Tooltip } from '@packages/sk8/tooltip'
import React from 'react'

const NewTooltip = (props: React.ComponentProps<typeof Tooltip>) => {
  return (
    <Tooltip
      {...props}
      content={
        <span>
          New variants are created when you add answers and <span className="font-medium">publish</span> your product.
        </span>
      }
    />
  )
}

export default NewTooltip
