import { BlankState } from '@packages/sk8/blank-state'
import React from 'react'

import InventoryIcon from 'icons/bold/10-Shipping-Delivery/01-Warehouse-Fullfilment/warehouse-packages.svg'

export interface EmptyBlankStateProps {
  onGoToProductsClick: (e: React.MouseEvent) => void
}

const EmptyBlankState = ({ onGoToProductsClick: handleGoToProductsClick }: EmptyBlankStateProps) => {
  return (
    <div className="flex items-center justify-center py-32">
      <BlankState className="max-w-sm">
        <BlankState.Icon Icon={InventoryIcon} />
        <BlankState.Title>There are no variants, yet</BlankState.Title>
        <BlankState.Details>
          Create variants from a product in the tab Manage. By variants, you will be able to manage their stocks.
        </BlankState.Details>
        <BlankState.Action onClick={handleGoToProductsClick}>Go to products</BlankState.Action>
      </BlankState>
    </div>
  )
}

export default EmptyBlankState
