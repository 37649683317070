import React from 'react'

import classMerge from '../../utils/classMerge'

export interface ModalDividerProps {
  className?: string
}

const ModalDivider = ({ className }: ModalDividerProps) => (
  <hr className={classMerge('w-full border-b border-neutral-75', className)} />
)

export default ModalDivider
