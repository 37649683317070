import React from 'react'

import classMerge from '../../utils/classMerge'
import TableHeaderCell from './TableHeaderCell'

const TableGroupHeaderCell = ({
  className,
  ...rest
}: React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
  <TableHeaderCell {...rest} className={classMerge(className, '!bg-white')} />
)

export default TableGroupHeaderCell
