import classMerge from 'classnames'
import React from 'react'

export interface PanelProps {
  children?: React.ReactNode
  side?: 'left' | 'right'
  style?: React.CSSProperties
  className?: string
}

const Panel = ({ children, side = 'left', style, className }: PanelProps) => {
  return (
    <div
      className={classMerge('bg-white h-full relative flex flex-col border-neutral-100', className, {
        'border-r': side === 'left',
        'border-l': side === 'right',
      })}
      style={style}
    >
      {children}
    </div>
  )
}

export default Panel
