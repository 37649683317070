import { Button, IconButton } from '@packages/sk8/button'
import { Modal, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Question, Part, GroupType, QuestionType, ActivityLogType } from '@packages/types'
import React, { useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import IconTab from 'builder/build/common/components/IconTab'
import PanelHeaderSection from 'builder/build/common/components/PanelHeaderSection'
import Tab from 'builder/build/common/components/Tab'
import * as customizerProductsSelectors from 'builder/build/customizerProducts/selectors'
import * as groupsActions from 'builder/build/groups/actions'
import { addChild, AddLinkedQuestionToSceneException } from 'builder/build/groups/actions'
import { bulkOrderGroupSelector } from 'builder/build/groups/selectors'
import * as partsActions from 'builder/build/parts/actions'
import * as partsSelectors from 'builder/build/parts/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'
import { trpc } from 'common/hooks/trpc'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import ArrowLeft from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left.svg'
import CarretRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import FolderIcon from 'icons/core-solid/interface-essential/interface-folder.svg'
import SettingsIcon from 'icons/core-solid/interface-essential/interface-setting-cog.svg'
import BulkOrderIcon from 'icons/core-solid/interface-essential/interface-user-add.svg'

import { deleteQuestionAndReplaceWithDefaultAnswer, duplicateQuestion } from '../../actions'

const MAIN_QUESTIONS = constants.parts.mainQuestionFields

const { groupParentExceptRootSelector, behindTheSceneQuestionIdsSelector } = customizerProductsSelectors
const { deletePartAndMainQuestion, duplicatePart } = partsActions
const { moveBehindTheScene, moveToQuestionPanel, moveNextTo, AddInputTypeNoneToSceneException } = groupsActions

type Tab = 'question' | 'settings'

interface HeaderSectionProps {
  question: Question
  selectedTab: Tab
  setTab: (tab: Tab) => void
  showPrintTab?: boolean
}

const HeaderSection = ({ question, selectedTab, setTab }: HeaderSectionProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { openToast } = useToast()
  const deleteRef = useRef(false)
  const bulkOrderGroup = useSelector(bulkOrderGroupSelector)
  const part: Part | undefined = useSelector(state => partsSelectors.partFromQuestionIdSelector(state, question.id))
  const mainQuestionId = part?.[MAIN_QUESTIONS[part?.type]]
  const isMainQuestion = mainQuestionId === question.id
  const parent = useSelector(state => groupParentExceptRootSelector(state, question.id))
  const isBehindTheScene = useSelector(behindTheSceneQuestionIdsSelector).includes(question.id)
  const deleteQuestionModal = useModal()
  const dropdownButton = usePopover({ placement: 'bottom-end' })
  const duplicateSubMenu = usePopover({ placement: 'left-start', trigger: 'hover' })

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()

  const closeMenu = (callback: (e: React.MouseEvent) => void) => (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    callback(e)
    dropdownButton.close()
  }

  const handleDelete = () => {
    deleteRef.current = true
    if (isMainQuestion || question.type === QuestionType.Value) {
      history.push(parent?.id ? `/groups/${parent.id}` : '/')
    } else {
      history.push(`/questions/${mainQuestionId}`)
    }
  }

  const handleDuplicateClick = (shouldCopyAnswers: boolean) => {
    const newQuestionId = isMainQuestion
      ? dispatch(duplicatePart(part!.id, shouldCopyAnswers))
      : dispatch(duplicateQuestion(question.id, shouldCopyAnswers))

    if (!isBehindTheScene) dispatch(moveNextTo(newQuestionId, question.id))

    createActivityLog({ type: ActivityLogType.CreateQuestion })
    history.push(`/questions/${newQuestionId}`)
    duplicateSubMenu.close()
    dropdownButton.close()
  }

  const handleMoveBehindTheSchene = () => dispatch(moveBehindTheScene(question.id))

  const handleMoveToQuestionPanel = () => {
    try {
      dispatch(moveToQuestionPanel(question.id))
    } catch (error) {
      if (error instanceof AddInputTypeNoneToSceneException) {
        openToast('Select an input type before adding the item to the question panel!', ToastType.warning)
      } else if (error instanceof AddLinkedQuestionToSceneException) {
        openToast('Linked question cannot be added to the question panel!', ToastType.warning)
      } else {
        openToast('Unable to move the item to the question panel', ToastType.warning)
      }
    }
  }

  useEffect(() => {
    return () => {
      if (deleteRef.current) {
        if (isMainQuestion) {
          dispatch(deletePartAndMainQuestion(part!.id))
        } else {
          dispatch(deleteQuestionAndReplaceWithDefaultAnswer(question))
        }
      }
    }
  }, [])

  return (
    <PanelHeaderSection>
      <div className="flex space-x-3 mr-auto items-center" role="tablist">
        {!!parent && (
          <Button onClick={() => history.push(`/groups/${parent.id}`)}>
            <ArrowLeft className="w-3 fill-current text-current mr-1" />
            {parent.type === GroupType.BulkOrder && <BulkOrderIcon className="w-3.5 fill-current text-current" />}
            {parent.type !== GroupType.BulkOrder && <FolderIcon className="w-3.5 fill-current text-current" />}
          </Button>
        )}

        <Tab isSelected={selectedTab === 'question'} onClick={() => setTab('question')}>
          Question
        </Tab>
        <IconTab isSelected={selectedTab === 'settings'} onClick={() => setTab('settings')} aria-label="Settings">
          <SettingsIcon />
        </IconTab>
      </div>
      <IconButton Icon={MoreIcon} {...dropdownButton.referenceProps} />
      <Popover isOpen={dropdownButton.isOpen} {...dropdownButton.floatingProps}>
        {isBehindTheScene ? (
          <Popover.Action key="move-to-question-panel" onClick={closeMenu(handleMoveToQuestionPanel)}>
            Move to Question panel
          </Popover.Action>
        ) : (
          <Popover.Action key="move-behind-the-scene" onClick={closeMenu(handleMoveBehindTheSchene)}>
            Move behind the scene
          </Popover.Action>
        )}
        <Popover.Action className="flex items-center justify-between" {...duplicateSubMenu.referenceProps}>
          <span>Duplicate</span>
          <CarretRight className="w-2 fill-current text-current" />
          <Popover {...duplicateSubMenu.floatingProps} isOpen={duplicateSubMenu.isOpen}>
            <Popover.Action className="flex flex-col" onClick={() => handleDuplicateClick(false)}>
              <span>Import answers</span>
              <span className="text-xs text-neutral-600">Link the answers to the originals</span>
            </Popover.Action>
            <Popover.Action className="flex flex-col" onClick={() => handleDuplicateClick(true)}>
              <span>Copy answers</span>
              <span className="text-xs text-neutral-600">Separate the answers from the originals</span>
            </Popover.Action>
          </Popover>
        </Popover.Action>

        {bulkOrderGroup && !bulkOrderGroup.children.includes(question.id) && (
          <Popover.Action
            key="add-to-bulk"
            onClick={closeMenu(() => {
              dispatch(addChild(bulkOrderGroup!.id, question.id, bulkOrderGroup!.children.length))
            })}
          >
            Add to bulk order
          </Popover.Action>
        )}

        <Popover.Action
          key="delete"
          className="text-tertiary-red-700"
          onClick={() => {
            dropdownButton.close()
            deleteQuestionModal.open()
          }}
        >
          Delete
        </Popover.Action>
      </Popover>
      {deleteQuestionModal.isVisible && (
        <Modal onBackdropClick={deleteQuestionModal.close} {...deleteQuestionModal.modalProps}>
          <Modal.CloseButton onClick={deleteQuestionModal.close} />
          <Modal.Title>Delete {question.name}</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete the question {question.name}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={deleteQuestionModal.close}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </PanelHeaderSection>
  )
}

export default HeaderSection
