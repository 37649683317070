import { ScrollToTopButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import React from 'react'

import { InventoryTableFooterProps } from './InventoryTableFooter'

const ShopifyInventoryTableFooter = ({ scrollToTopButtonProps, horizontalScrollProps }: InventoryTableFooterProps) => (
  <>
    <div
      {...horizontalScrollProps.outerComponentProps}
      style={{ ...horizontalScrollProps.outerComponentProps.style, position: 'sticky', bottom: '0' }}
    >
      <div {...horizontalScrollProps.innerComponentProps} />
    </div>
    <Card.StickyFooter className="z-[2] p-0 border-none">
      <ScrollToTopButton {...scrollToTopButtonProps} className="bottom-6" />
    </Card.StickyFooter>
  </>
)

export default ShopifyInventoryTableFooter
