import React from 'react'

import ArrowDown from '../../icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'
import ArrowUp from '../../icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-up-1.svg'
import classMerge from '../../utils/classMerge'

export interface TableHeaderFilterProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  sortOrder?: 'ascending' | 'descending'
  isSortable: boolean
  isSorted: boolean
}

const TableHeaderFilter = ({
  sortOrder,
  className,
  isSortable,
  isSorted,
  children,
  ...rest
}: TableHeaderFilterProps) => {
  if (isSortable) {
    return (
      <button className={classMerge('flex items-center font-p4 font-normal text-neutral-500', className)} {...rest}>
        <span className={classMerge({ 'text-neutral-900': isSorted })}>{children}</span>
        {isSorted && sortOrder === 'ascending' && <ArrowDown aria-label="Ascending sort" width={10} className="ml-1" />}
        {isSorted && sortOrder === 'descending' && <ArrowUp aria-label="Descending sort" width={10} className="ml-1" />}
      </button>
    )
  }
  return <span>{children}</span>
}

export default TableHeaderFilter
