import { TopBar } from '@packages/sk8/top-bar'
import React from 'react'

import TopBarBackButton from 'builder/topBar/components/TopBarBackButton'
import TopBarTabs from 'builder/topBar/components/TopBarTabs'
import TopBarEnvTag from 'common/components/topBar/TopBarEnvTag'
import Skater from 'icons/custom/skater-rotate.svg'

const Loader = () => {
  return (
    <div className="h-screen w-screen z-[999] fixed flex flex-col bg-neutral-50 top-0">
      <TopBar>
        <TopBarEnvTag />
        <TopBar.Section className="pl-5">
          <TopBarBackButton />
        </TopBar.Section>
        <TopBarTabs />
      </TopBar>
      <div className="h-full flex flex-col items-center justify-center pb-48">
        <Skater width={40} aria-label="Skater loading" />
      </div>
    </div>
  )
}

export default Loader
