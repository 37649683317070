import { Button } from '@packages/sk8/button'
import { Tooltip } from '@packages/sk8/tooltip'
import { AddonId } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { ArrayParam, DateParam, NumberParam, StringParam, withDefault } from 'use-query-params'

import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useIsSubscribedToAddon from 'cms/subscription/hooks/useIsSubscribedToAddon'
import useDataTableQuery from 'common/hooks/dataTable/persistence/useDataTableQuery'
import useDataTable from 'common/hooks/dataTable/useDataTable'
import useTableDateFilters from 'common/hooks/dataTable/useTableDateFilters'
import useTableFilters from 'common/hooks/dataTable/useTableFilters'
import useTablePagination from 'common/hooks/dataTable/useTablePagination'
import useTableSearch from 'common/hooks/dataTable/useTableSearch'
import useTableSort from 'common/hooks/dataTable/useTableSort'
import { trpc } from 'common/hooks/trpc'
import useProductService from 'common/products/hooks/useProductService'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import { defaultPersistenceState } from '../../utils'
import type { QuotesFilters, QuotesSortKeys } from './../../types/datatable'
import QuoteAddonCard from './QuoteAddonCard'
import QuotesFilterList from './QuotesFilterList'
import QuotesTable from './QuotesTable'

const Quotes = () => {
  const location = useLocation()
  const history = useHistory()
  const { brandName } = useParams<{ brandName?: string }>()
  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()

  const LIMIT = 25

  const persistence = useDataTableQuery(defaultPersistenceState, {
    startDate: DateParam,
    endDate: DateParam,
    expirationStartDate: StringParam,
    expirationEndDate: StringParam,
    lastIndex: withDefault(NumberParam, 0),
    count: withDefault(NumberParam, LIMIT),
    filter: withDefault(StringParam, ''),
    sortKey: StringParam,
    sortOrder: StringParam,
    stores: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
  })

  const baseUrl = brandName ? `/brands/${brandName}` : ''

  const goToFirstPage = () => persistence.setState(state => ({ ...state, lastIndex: 0 }))

  const dataTable = useDataTable(persistence, [
    useTableSearch({ onChange: goToFirstPage }),
    useTableSort<QuotesSortKeys>({ onChange: goToFirstPage }),
    useTablePagination(),
    useTableFilters<QuotesFilters>({ onChange: goToFirstPage }),
    useTableDateFilters({ onChange: goToFirstPage }),
  ])

  const { data: productsCount, isLoading: isLoadingProductsCount } = useQuery(
    [...productService.count.queryKeys, 'active'],
    () => productService.count({ archived: false }),
    {
      select: data => data.count,
    }
  )

  const { data: onlineStoresCount, isLoading: isLoadingOnlineStoresCount } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    () => onlineStoreService.fetchAll(),
    {
      select: data => data.length,
    }
  )

  const { data, isLoading, isFetching } = trpc.quote.list.useQuery(
    {
      ...persistence.state,
      cursor: persistence.state.lastIndex,
      limit: persistence.state.count,
    },
    {
      keepPreviousData: true,
      onSuccess: result => {
        dataTable.setCollectionSize(result.pagination.collectionSize)
        dataTable.setResultSize(result.pagination.resultSize)
      },
    }
  )

  const isSubscribedToAddon = useIsSubscribedToAddon({ addonId: AddonId.ConfigurePriceQuote })

  const handleRowClick = (quoteId: string) =>
    history.push(`${baseUrl}/quotes/${quoteId}`, { persistence: persistence.state })

  const isBlank = !isLoading && !data?.results.length && !location.search

  const isCreateQuoteDisabled =
    isLoadingProductsCount || isLoadingOnlineStoresCount || productsCount === 0 || onlineStoresCount === 0

  const toolTipMessage =
    isLoadingProductsCount || productsCount === 0
      ? 'You have to build a product in order to create a quote.'
      : 'You have to create a store in order to create a quote.'

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        {isSubscribedToAddon === false && (
          <div className="mb-12">
            <QuoteAddonCard />
          </div>
        )}

        <Page.Header>
          <h1>Quotes</h1>
          <Tooltip
            disabled={
              isSubscribedToAddon == null ||
              !isCreateQuoteDisabled ||
              isLoadingProductsCount ||
              isLoadingOnlineStoresCount
            }
            content={toolTipMessage}
          >
            <Button
              aria-label="create quote"
              onClick={() => history.push(`${baseUrl}/quotes/create`)}
              variant="default"
              icon={<AddIcon className="w-3 h-3" />}
              disabled={isCreateQuoteDisabled}
            >
              Quote
            </Button>
          </Tooltip>
        </Page.Header>

        {!isBlank && (
          <QuotesFilterList clearAll={persistence.reset} hasAppliedFilters={!!location.search} {...dataTable} />
        )}

        <QuotesTable
          data={data}
          isLoading={isLoading}
          isFetching={isFetching}
          onClearFilters={persistence.reset}
          hasAppliedFilters={!!location.search}
          onRowClick={handleRowClick}
          {...dataTable}
        />
      </Page>
    </main>
  )
}

export default Quotes
