import React from 'react'

import classMerge from '../../utils/classMerge'

const TableFooterCell = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>) => (
  <td className={classMerge('px-1', className)} {...rest}>
    {children}
  </td>
)

export default TableFooterCell
