import { Question, QuestionInputType } from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import Switch from 'common/components/Switch'
import WithInformation from 'common/components/WithInformation'
import * as constants from 'common/customizerProducts/constants'

import { patchQuestion } from '../../actions'

interface QuestionSettingsSectionProps {
  question: Question
}

const QuestionSettingsSection = ({ question }: QuestionSettingsSectionProps) => {
  const dispatch = useDispatch()

  const updateQuestion = (field: string, value: any) => dispatch(patchQuestion(question.id, { [field]: value }))

  return (
    <>
      <div className="relative px-4 py-6 flex flex-col space-y-8">
        <div className="flex items-center justify-between">
          <label>
            <WithInformation tooltip="Toggle to display this question in the summary.">Summary</WithInformation>
          </label>
          <Switch
            checked={question.isInSummary}
            onChange={() => updateQuestion('isInSummary', !question.isInSummary)}
          />
        </div>
        {(question.inputType === QuestionInputType.Text || question.inputType === QuestionInputType.File) && (
          <div className="flex items-center justify-between">
            <label>
              <WithInformation tooltip="Toggle to require a answer.">Required</WithInformation>
            </label>
            <Switch checked={question.isRequired} onChange={() => updateQuestion('isRequired', !question.isRequired)} />
          </div>
        )}
        {constants.questions.inputTypesWithInteractionRequired.includes(question.inputType) && (
          <div className="flex items-center justify-between">
            <label>
              <WithInformation tooltip="Toggle to require interaction with this question.">
                Interaction required
              </WithInformation>
            </label>
            <Switch
              checked={question.isInteractionRequired}
              onChange={() => updateQuestion('isInteractionRequired', !question.isInteractionRequired)}
            />
          </div>
        )}
        <div className="flex items-center justify-between">
          <label>
            <WithInformation tooltip={`Toggle to hide this question when \nthere is no answer available.`}>
              Hide when empty
            </WithInformation>
          </label>
          <Switch
            checked={question.isHiddenWhenEmpty}
            onChange={() => updateQuestion('isHiddenWhenEmpty', !question.isHiddenWhenEmpty)}
          />
        </div>

        {!question.isMultiAnswer && (
          <div className="flex items-center justify-between">
            <label>
              <WithInformation tooltip="This will compare the extra price of the selected answer with those of the other answers available in the question. Prices are not visible inside the builder, you can preview the product to view them.">
                Display relative prices
              </WithInformation>
            </label>
            <Switch
              checked={question.showRelativePrice}
              onChange={() => updateQuestion('showRelativePrice', !question.showRelativePrice)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default QuestionSettingsSection
