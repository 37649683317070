import { IconButton } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import { QuestionInputType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'

import NewQuestionMenu from './NewQuestionMenu'

const BehindTheSceneContainer = React.forwardRef<HTMLDivElement, React.PropsWithChildren>(({ children }, ref) => {
  const dropdownButton = usePopover({ placement: 'right-end' })
  return (
    <>
      <div
        id="behind-the-scene"
        className="basis-full grow shrink flex-col flex border-t border-neutral-100 pt-4 overflow-hidden"
      >
        <div className="ml-5 items-center flex">
          <span className="mr-auto font-medium text-neutral-600">Behind the scene</span>
          <IconButton
            className="mr-4"
            variant="subtle"
            iconClassName={classNames('transition-transform', { 'rotate-45': dropdownButton.isOpen })}
            Icon={AddIcon}
            smallIcon
            {...dropdownButton.referenceProps}
          />
        </div>
        <div className="mx-5 text-xs text-neutral-400">Not shown in question panel</div>
        <div className="flex flex-col overflow-y-auto grow pt-2" ref={ref} role="list" aria-label="Behind the scene">
          {children}
        </div>
      </div>
      <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
        <NewQuestionMenu
          forcedQuestionType={QuestionInputType.None}
          onClose={() => dropdownButton.close()}
          gifPlacement="right-start"
        />
      </Popover>
    </>
  )
})

export default BehindTheSceneContainer
