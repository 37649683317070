import { Modal } from '@packages/sk8/modal'
import React from 'react'

const DeleteCount = ({ text, count = 0 }: { text: string; count: number }) => {
  if (count === 0) return null
  return (
    <Modal.ActionsMessage className="text-neutral-500 flex items-center space-x-2">
      <span>
        <span className="text-tertiary-red-500">{count}</span> {text}
        {count > 1 ? 's' : ''} will be deleted
      </span>
    </Modal.ActionsMessage>
  )
}

export default DeleteCount
