import { Button, ScrollToTopButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import React from 'react'

import UpdateCount from 'common/components/UpdateCount'

import { FormikVariants } from '../types'

const KickflipVariantsTableFooter = ({
  formik,
  scrollToTopButtonProps,
  dirtyVariantIds,
  horizontalScrollProps,
}: {
  formik: FormikVariants
  dirtyVariantIds: string[]
  scrollToTopButtonProps: React.ComponentProps<typeof ScrollToTopButton>
  horizontalScrollProps: {
    outerComponentProps: React.ComponentProps<'div'>
    innerComponentProps: React.ComponentProps<'div'>
  }
}) => (
  <>
    <div
      {...horizontalScrollProps.outerComponentProps}
      style={{ ...horizontalScrollProps.outerComponentProps.style, position: 'sticky', bottom: '81px' }}
    >
      <div {...horizontalScrollProps.innerComponentProps} />
    </div>
    <Card.StickyFooter>
      <ScrollToTopButton {...scrollToTopButtonProps} />

      <UpdateCount text="variant" count={dirtyVariantIds.length} />
      <div className="flex justify-end px-6 py-3">
        <Button
          type="reset"
          className="mr-2"
          disabled={!formik.dirty || formik.isSubmitting}
          onClick={() => formik.resetForm()}
        >
          Discard
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={() => formik.submitForm()}
          disabled={!formik.dirty || formik.isSubmitting}
          isLoading={formik.isSubmitting}
        >
          Save
        </Button>
      </div>
    </Card.StickyFooter>
  </>
)

export default KickflipVariantsTableFooter
