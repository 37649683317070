import {
  DenormalizedTheme,
  DenormalizedThemeVersion,
  VersionOrigin,
  ThemeVersionOrigin,
  DemoProduct,
} from '@packages/types'
import { capitalize } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { trpc } from 'common/hooks/trpc'
import ProductSelectControl from 'common/preview/components/ProductSelectControl'
import TenantContext from 'common/tenant/TenantContext'

import HistoryManager from './HistoryManager'

const ThemeHistory = () => {
  const history = useHistory()
  const params = useParams<{ brandName?: string; themeId: string }>()
  const tenant = useContext(TenantContext)
  const [productId, setProductId] = useState<string | undefined>(undefined)
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  const goToBuilder = () => history.push(`${baseUrl}/themes/${params.themeId}`)

  const goToTheme = () => history.push(`${baseUrl}/theme`)

  const { data: products, isLoading: isLoadingProducts } = trpc.product.list.useQuery(
    { sortKey: 'updatedAt', sortOrder: 'descending', archived: false, deleted: false },
    { select: data => data?.results }
  )

  const { data: demos, isLoading: isLoadingDemos } = trpc.demoProduct.list.useQuery(
    {},
    { select: data => data?.results }
  )

  const allProducts = [...(products || []), ...(demos || [])]
  const selectedProduct = allProducts.find(({ id }) => id === productId)
  const isDemo = !!(selectedProduct as DemoProduct | undefined)?.demoAttributes

  const { data: theme, isLoading: isLoadingTheme } = trpc.theme.get.useQuery({ id: params.themeId })
  const { mutateAsync: restoreVersion, isLoading: isRestoring } = trpc.theme.restoreVersion.useMutation()
  const isLoading = isLoadingTheme || isLoadingProducts || isLoadingDemos

  const getIframeSrc = (versions: DenormalizedTheme['versions'], selectedVersion: string | null) => {
    const versionId = versions.find(({ id }) => id === selectedVersion)!.themeRevision
    const url = `${window.location.origin}/customize/${isDemo ? 'demos/' : ''}${selectedProduct!.id}?themeRevisionId=${versionId}`
    return params.brandName ? `${url}&tenant=${tenant}` : url
  }

  useEffect(() => {
    if (!isLoadingProducts && !isLoadingDemos && !productId) setProductId(allProducts[0].id)
  }, [isLoadingProducts, isLoadingDemos])

  if (!params.themeId || !selectedProduct) return null

  return (
    <HistoryManager<DenormalizedTheme, VersionOrigin | ThemeVersionOrigin>
      texts={{ item: 'theme', items: 'themes', ref: 'theme editor', extraTitle: capitalize(theme?.name || '') }}
      item={theme}
      restoreVersion={async (themeId, versionId) => await restoreVersion({ themeId, versionId })}
      onReturnAction={goToBuilder}
      onBackAction={goToTheme}
      isVersionLastLive={(version: DenormalizedThemeVersion, ref: DenormalizedThemeVersion) =>
        version?.themeRevision === ref?.themeRevision
      }
      getIframeSrc={getIframeSrc}
      isLoading={isLoading || isRestoring}
      topBarContent={
        <div className="flex justify-center items-center space-x-4">
          <ProductSelectControl
            products={allProducts}
            selectedProductId={selectedProduct.id}
            className="w-56"
            onChange={option => setProductId(option!.value)}
            blurInputOnSelect
            disabled={isLoadingProducts || isLoadingDemos}
            isSearchable
            aria-label="Select a product"
          />
        </div>
      }
    />
  )
}

export default ThemeHistory
