import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { useToast } from '@packages/sk8/toast'
import { CancellationStrategy } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import { trpc } from 'common/hooks/trpc'

import useSubscriptionService from './../../hooks/useSubscriptionService'

export interface CancelFreeTrialModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
}

const CancelFreeTrialModal = ({ onClose, ...modalProps }: CancelFreeTrialModalProps) => {
  const subscriptionService = useSubscriptionService()
  const queryClient = useQueryClient()
  const trpcUtils = trpc.useContext()
  const { openGenericErrorToast } = useToast()

  const { mutate: updateSubscription, isLoading } = useMutation(subscriptionService.cancel, {
    onSuccess: () => {
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      trpcUtils.billingAndSubscription.canContinueFreeTrial.invalidate()
      onClose()
    },
    onError: () => {
      openGenericErrorToast('Your free trial was not cancelled.')
    },
  })

  return (
    <Modal {...modalProps} onBackdropClick={onClose}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Cancel free trial</Modal.Title>

      <Modal.Details>Are you sure you want to cancel your free trial?</Modal.Details>
      <Modal.Actions>
        <Button disabled={isLoading} type="button" variant="default" className="px-4" onClick={onClose}>
          Not now
        </Button>
        <Button
          variant="error"
          className="px-4"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => updateSubscription({ cancellationStrategy: CancellationStrategy.Cancel })}
        >
          Cancel free trial
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CancelFreeTrialModal
