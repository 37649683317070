import { CSS } from '@dnd-kit/utilities'
import type { TreeItemComponentProps } from '@packages/sk8/sortable-tree'
import classNames from 'classnames'
import React, { useEffect } from 'react'

const TreeItem = React.memo(
  React.forwardRef<HTMLLIElement, TreeItemComponentProps<Record<string, any>> & React.PropsWithChildren>(
    (
      {
        dragOverlay,
        dragging,
        active,
        listeners,
        transition,
        transform,
        children,
        depth,
        item,
        willReceiveItem,
        onToggleCollapse,
        indentationWidth,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return
        }

        document.body.style.cursor = 'grabbing'

        return () => {
          document.body.style.cursor = ''
        }
      }, [dragOverlay])

      return (
        <li
          className={classNames('list-none mb-4 w-full flex group/item hover:cursor-pointer', {
            'opacity-40': dragging,
            'opacity-70 ': dragOverlay,
          })}
          style={{
            transition: [transition].filter(Boolean).join(', '),
            transform: CSS.Translate.toString(transform),
            paddingLeft: `${14 * depth}px`,
          }}
          ref={ref}
        >
          <div
            className={classNames('rounded-md bg-white mx-1 overflow-hidden h-auto w-full flex items-center', {
              'ring-2 ring-primary-200': willReceiveItem,
            })}
            {...listeners}
            {...props}
          >
            <div className="flex items-center w-full">{children}</div>
            {willReceiveItem && item.children && !item.children.find(item => item.id === active?.id) && (
              <div className="absolute right-2 font-medium rounded-full bg-primary-400 px-2 py-0.5 text-white text-[11px] h-min">
                +1
              </div>
            )}
          </div>
        </li>
      )
    }
  )
)

export default TreeItem
