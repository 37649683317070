import { FilterContainer } from '@packages/sk8/filter'
import classNames from 'classnames'
import React from 'react'

export interface VariantsTableFilterContainerProps {
  isScrollAtTop: boolean
  children: React.ReactNode
}

const VariantsTableFilterContainer = ({ children, isScrollAtTop }: VariantsTableFilterContainerProps) => {
  return (
    <FilterContainer
      variant="card"
      className={classNames('sticky top-[52px] z-[2] bg-white', {
        'rounded-t-xl': isScrollAtTop,
      })}
    >
      {children}
    </FilterContainer>
  )
}

export default VariantsTableFilterContainer
