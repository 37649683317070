import { ToastType, useToast } from '@packages/sk8/toast'
import { Brand } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import useBrandService from 'cms/brands/hooks/useBrandService'
import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import { NetworkError } from 'common/api/types/error'

import type { BrandUpdateQuery } from './../types/business'
import BrandLogoUploader from './BrandLogoUploader'
import BusinessForm from './BusinessForm'

const Business = () => {
  const brandService = useBrandService()
  const queryClient = useQueryClient()
  const { openToast, openGenericErrorToast } = useToast()

  const { data: brand, isLoading: isBrandLoading } = useQuery(
    brandService.fetchAccount.queryKeys,
    brandService.fetchAccount
  )

  const { mutate: updateBrand, isLoading: isSavingBrand } = useMutation<Brand, NetworkError, BrandUpdateQuery>(
    values => brandService.update(values),
    {
      onSuccess: updatedBrand => {
        queryClient.setQueryData(brandService.fetchAccount.queryKeys, updatedBrand)
        openToast('Brand informations were successfully updated!', ToastType.success)
      },
      onError: () => {
        openGenericErrorToast('Brand informations have not been updated.')
      },
    }
  )

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      {brand && !isBrandLoading && (
        <Page>
          <Page.Header>
            <h1>Business</h1>
          </Page.Header>

          <Page.Section>
            <Page.Aside
              title="Business details"
              description="This will be used for official documents, such as quotes."
            />
            <Page.Content>
              <BusinessForm
                key={brand.id}
                infos={brand?.business || {}}
                onSubmit={updateBrand}
                isSaving={isSavingBrand}
              />
            </Page.Content>
          </Page.Section>

          <Page.Section>
            <Page.Aside title="Brand" description="This will be used for common logo applications, such as quotes." />
            <Page.Content>
              <BrandLogoUploader brandLogo={brand?.logo} onSubmit={updateBrand} isSaving={isSavingBrand} />
            </Page.Content>
          </Page.Section>
        </Page>
      )}
    </main>
  )
}

export default Business
