import { Button } from '@packages/sk8/button'
import { CheckList } from '@packages/sk8/check-list'
import { Modal } from '@packages/sk8/modal'
import React from 'react'

const enterprisePlanAdvantages = ['Shopify', 'Woocommerce', 'Wix (coming soon)']

export interface EntreprisePlanModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
}

const EntreprisePlanModal = ({ onClose, ...modalProps }: EntreprisePlanModalProps) => {
  return (
    <Modal {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Custom ecommerce plan</Modal.Title>
      <Modal.Details className="space-y-4 font-p1">
        <span>
          A custom integration is necessary if you want to integrate Kickflip with an ecommerce platform other than:
        </span>
        <div>
          <CheckList items={enterprisePlanAdvantages} />
        </div>
        <span>Our sales team will be happy to guide you.</span>
      </Modal.Details>
      <Modal.Actions>
        <Button variant="default" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => window.open('https://gokickflip.com/get-started/', '_blank')}>
          Contact sales
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EntreprisePlanModal
