import classNames from 'classnames'
import React from 'react'

export type SideMenuNotificationIndicatorVariant = 'error' | 'warning:appEmbed' | 'warning' | 'info'

export interface SideMenuNotificationIndicatorProps {
  variant: SideMenuNotificationIndicatorVariant
}

const classes: { [variant: string]: { pulse: string; dot: string } } = {
  error: {
    pulse: 'bg-tertiary-red-300',
    dot: 'bg-tertiary-red-400',
  },
  warning: {
    pulse: 'bg-secondary-orange-100',
    dot: 'bg-secondary-orange-200',
  },
  info: {
    pulse: 'bg-neutral-300',
    dot: 'bg-neutral-400',
  },
}

const SideMenuNotificationIndicator = ({ variant }: SideMenuNotificationIndicatorProps) => {
  const normalizedVariant = variant.split(':')[0]

  return (
    <div className="ml-1 mb-2 relative" aria-label={`${normalizedVariant} notification`}>
      <span className="flex h-2 w-2 items-center justify-center">
        <span
          className={classNames(
            'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75',
            classes[normalizedVariant].pulse
          )}
        />
        <span className={classNames('relative inline-flex rounded-full h-1.5 w-1.5', classes[normalizedVariant].dot)} />
      </span>
    </div>
  )
}

export default SideMenuNotificationIndicator
