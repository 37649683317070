import { Button } from '@packages/sk8/button'
import { Modal, ModalType } from '@packages/sk8/modal'
import { FormikProps } from 'formik'
import React from 'react'

interface VerifyInvitesModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  handleModalOne: ModalType
  formik: FormikProps<{ emails: string[] }>
  onClose: () => void
  onInvite: (emails: string[]) => void
  isLoadingInvite: boolean
}

const VerifyInvitesModal = ({
  handleModalOne,
  formik,
  onClose,
  onInvite,
  isLoadingInvite,
  ...modalProps
}: VerifyInvitesModalProps) => {
  const uniqueEmails = [...new Set(formik.values.emails.map(email => email.toLowerCase()))]

  const handleEdit = () => {
    onClose()
    formik.setErrors({})
    handleModalOne.open()
  }

  return (
    <Modal size="medium" {...modalProps} onBackdropClick={onClose}>
      <Modal.Title>Confirm email(s)</Modal.Title>
      <div className="text-neutral-600 px-6 pb-6 pt-3">
        Please double check the email(s) you have entered before sending invite.
      </div>
      <Modal.Divider />
      <div className="text-neutral-600 px-6 pb-1 pt-0">
        {uniqueEmails.map((email, i) => (
          <React.Fragment key={email}>
            {i !== 0 && <Modal.Divider />}
            <div className="text-neutral-600 px-6 pb-2 pt-2 h-10 flex items-center">{email}</div>
          </React.Fragment>
        ))}
      </div>
      <Modal.Actions className="justify-between">
        <Button onClick={handleEdit}>Edit</Button>
        <div className="flex space-x-3">
          <Button
            onClick={() => {
              onClose()
              formik.resetForm()
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" isLoading={isLoadingInvite} onClick={() => onInvite(uniqueEmails)}>
            Send invite
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default VerifyInvitesModal
