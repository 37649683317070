import { Card } from '@packages/sk8/card'
import React from 'react'

import QuoteCardHeader from './QuoteCardHeader'
import QuoteTable from './QuoteTable'

interface QuoteCardProps {
  baseUrl: string
  itemIndex: number
}

const QuoteCard = ({ itemIndex, baseUrl }: QuoteCardProps) => {
  return (
    <Card className="w-full flex flex-col flex-1  mr-8 print:shadow-[none] pb-4">
      <div className="print:clear-both print:border print:border-neutral-100 print:rounded-xl">
        <QuoteCardHeader itemIndex={itemIndex} baseUrl={baseUrl} />
        <Card.Separator />
        <Card.Section className="p-0">
          <QuoteTable itemIndex={itemIndex} />
        </Card.Section>
      </div>
    </Card>
  )
}

export default QuoteCard
