import { Checkbox } from '@packages/sk8/input'
import { Tooltip } from '@packages/sk8/tooltip'
import { Variant } from '@packages/types'
import React from 'react'

import { BulkSelection } from 'common/hooks/useBulkSelection'

import useSharedSkus from './../../hooks/useSharedSkus'

export interface SelectRowCellProps {
  variant: Variant
  bulkSelection: BulkSelection
}

const SelectRowCell = ({ variant, bulkSelection }: SelectRowCellProps) => {
  const sharedSkus = useSharedSkus(variant.inventoryItem.sku)
  return (
    <Tooltip
      content={`Select all variants with SKU ${variant.inventoryItem.sku}`}
      disabled={sharedSkus?.variantIds.length === 0}
    >
      <Checkbox
        aria-label="Select row"
        tabIndex={-1}
        checked={bulkSelection.isSelected(variant.id)}
        onChange={e => {
          const variantIds = sharedSkus?.variantIds ?? [variant.id]

          if (e.target.checked) return bulkSelection.select(variantIds)

          bulkSelection.deselect(variantIds)
        }}
      />
    </Tooltip>
  )
}

export default SelectRowCell
