import { Button } from '@packages/sk8/button'
import { TextArea } from '@packages/sk8/input'
import React, { useContext } from 'react'

import GlobalRouterContext from 'builder/common/GlobalRouterContext'

const RecoverLink: React.FC = () => {
  const { match } = useContext(GlobalRouterContext)

  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''

  return (
    <a className="text-primary-500" href={`${baseUrl}/products/${match.params.productId}/history`}>
      Recover the last version
    </a>
  )
}

interface ErrorBoundaryProps {
  children?: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: any
  userReport: string
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  override state: ErrorBoundaryState = {
    hasError: false,
    error: null,
    userReport: '',
  }

  handleSubmit = async () => {
    if (this.state.hasError) {
      await fetch('/logs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          error: { message: this.state.error?.message, stack: this.state.error?.stack },
          userReport: this.state.userReport,
          tenant: location.hostname.split('.')[0],
          location: location.href,
        }),
      })
    }
  }

  handleSubmitClick = async () => {
    await this.handleSubmit()

    setTimeout(() => window.location.reload(), 10)
  }

  override componentDidMount() {
    window.addEventListener('beforeunload', this.handleSubmit)
  }

  override componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleSubmit)
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error, userReport: '' }
  }

  override render() {
    if (this.state.hasError) {
      return (
        <div className="flex w-screen h-screen p-32">
          <div className="flex flex-col flex-1 justify-center">
            <h1>There was an error with the builder...</h1>
            <p className="mt-4">
              You have stumbled upon a nasty error we are trying to track down. Can you describe what you were doing
              when it happened?
            </p>

            <TextArea
              minRows={4}
              value={this.state.userReport}
              onChange={value => this.setState({ userReport: value })}
              className="mt-8 mb-4"
              aria-label="error report"
            />

            <Button variant="primary" type="button" onClick={this.handleSubmitClick} className="w-fit">
              Submit
            </Button>
            <div className="py-4 border-t border-solid border-neutral-75 space-x-1 mt-4">
              Does the error persist? <RecoverLink />
            </div>
          </div>

          <div className="flex flex-1 justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-40 h-40 text-neutral-200">
              <path
                fill="currentColor"
                d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
              />
              <rect width="176" height="32" x="168" y="320" fill="currentColor" />
              <polygon
                fill="currentColor"
                points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
              />
              <polygon
                fill="currentColor"
                points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
              />
            </svg>
          </div>
        </div>
      )
    }

    const { children } = this.props

    return children
  }
}

export default ErrorBoundary
