import { Placement } from '@floating-ui/react'
import { Popover, usePopover } from '@packages/sk8/popover'
import classNames from 'classnames'
import React from 'react'

import QuestionIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import classMerge from 'utils/classMerge'

interface WithInformationProps {
  className?: string
  iconClassName?: string
  tooltip: string
  position?: Placement
  children: React.ReactNode
}

const WithInformation = ({ tooltip, position = 'top', children, className, iconClassName }: WithInformationProps) => {
  const popover = usePopover({ placement: position, trigger: 'hover', useArrow: true, offsetConfig: 12 })

  return (
    <label className={classNames('flex content-center', className)}>
      {children}
      <span
        className="outline-none flex items-center ml-2"
        tabIndex={1}
        role="note"
        ref={popover.referenceProps.ref}
        onMouseEnter={popover.open}
        onMouseLeave={popover.close}
      >
        <QuestionIcon className={classNames('w-[14px] h-[14px] fill-neutral-400', iconClassName)} />
      </span>
      <Popover
        isOpen={popover.isOpen}
        {...popover.floatingProps}
        arrowProps={{
          ...popover.floatingProps.arrowProps,
          innerClassName: classMerge(popover.floatingProps.arrowProps!.innerClassName, 'bg-neutral-600'),
        }}
        role="tooltip"
        className="bg-neutral-600 text-white px-2 py-1 text-sm leading-snug max-w-[350px] whitespace-pre-wrap"
      >
        {tooltip}
      </Popover>
    </label>
  )
}

export default WithInformation
