import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import KickflipLogoWithGradient from 'icons/custom/kickflip-logo-gradient.svg'

const StripeChangePlanFeedbackCard = () => {
  const history = useHistory()
  const match = useRouteMatch<{ brandName: string }>()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''

  return (
    <Card className="w-[550px] mt-28 flex-col">
      <Card.Section className="flex flex-row items-center">
        <KickflipLogoWithGradient className="w-10" />
        <h1 className="ml-4">Your plan has been updated! 🎉 </h1>
      </Card.Section>
      <div className="mx-6 mb-6 font-medium">
        We hope you’ll enjoy our app, feel free to chat with our support if you need help! Thank you!
      </div>
      <Card.Footer>
        <Button variant="primary" className="w-full mx-1 my-2" onClick={() => history.push(`${baseUrl}/products`)}>
          Go back to kickflip
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default StripeChangePlanFeedbackCard
