import { BoxTransformer } from '@packages/sk8/editor'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import type { ImageNode } from 'customizer/2dDisplayer/types/node'

import { applyLogoEdition, applyPrintAreaLogoEdition } from '../../actions'
import TargetClipContainer from '../containers/TargetClipContainer'
import Image from '../nodes/Image'
import NodeInfo from './NodeInfo'
import { EditorProps } from './types'
import useBoxEditor from './useBoxEditor'

const ImageEditor = ({ node, entity, currentView }: EditorProps<ImageNode>) => {
  const { targetRef, Target, getTransformedBoundingBox, onDrag, onResize, onRotate } = useBoxEditor({
    width: node.boundingBox.width,
    height: node.boundingBox.height,
    x: node.position.x,
    y: node.position.y,
    scale: node.scale,
    rotation: node.position.rotation ?? 0,
  })

  const dispatch = useDispatch()

  const handleTransformEnd = () => {
    const boundingBox = getTransformedBoundingBox()

    'printArea' in entity && entity.printArea
      ? dispatch(applyPrintAreaLogoEdition(entity.id, boundingBox))
      : dispatch(applyLogoEdition(entity.id, boundingBox, currentView))
  }

  return (
    <>
      <TargetClipContainer>
        <Target>
          <Image src={node.image.src} />
        </Target>
      </TargetClipContainer>

      <BoxTransformer
        target={targetRef}
        onDrag={onDrag}
        onResize={onResize}
        onRotate={onRotate}
        onTransformEnd={handleTransformEnd}
        info={<NodeInfo entity={entity} node={node} />}
      />
    </>
  )
}

export default ImageEditor
