const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')

export default {
  content: [`${__dirname}/**/*.{js,jsx,ts,tsx,mdx}`],
  theme: {
    fontSize: {
      ...defaultTheme.fontSize,
      '2xl': '1.75rem',
    },
    screens: {
      sm: '480px',
      md: '768px',
      lg: '976px',
      xl: '1366px',
      '2xl': '1440px',
      '3xl': '1680px',
      '4xl': '1920px',
    },
    colors: {
      shopify: '#4C822A',
      prestashop: '#251B5B',
      woocommerce: '#674399',
      white: '#FFFFFF',
      black: '#000000',
      current: 'currentColor',
      transparent: 'transparent',
      neutral: {
        50: '#F9FAFB',
        75: '#F3F4F6',
        100: '#EBEEF1',
        200: '#D1D5DB',
        300: '#9CA3AF',
        400: '#6B7280',
        500: '#4B5563',
        600: '#374151',
        700: '#1F2937',
        800: '#111827',
        900: '#10131B',
        transparent: 'rgba(0, 0, 0, 0.03)',
      },
      primary: {
        50: '#F5F9FF',
        75: '#E6F5FF',
        100: '#B6D6EF',
        200: '#89BDF7',
        300: '#5693FF',
        400: '#266CE8',
        500: '#1F3FFD',
        600: '#0021F5',
        700: '#0021C4',
        800: '#001FA6',
        900: '#001B87',
      },
      secondary: {
        orange: {
          50: '#FFF9EF',
          75: '#FFECCE',
          100: '#FFD89D',
          200: '#F59856',
          300: '#DA7A35',
          400: '#B75A25',
          500: '#9F4513',
          600: '#863305',
          700: '#6E2800',
          800: '#6E2800',
          900: '#421D09',
        },
        teal: {
          dark: '#3A869B',
          default: '#53AADD',
          light: '#D7F6FF',
          middle: '#AFEEFF',
        },
      },
      tertiary: {
        green: {
          50: '#F1FFF8',
          75: '#D7FDE9',
          100: '#A4E8C3',
          200: '#65CF96',
          300: '#15BE92',
          400: '#25936B',
          500: '#007A5A',
          600: '#145D4A',
          700: '#11473E',
          800: '#133D35',
          900: '#102D28',
        },
        red: {
          50: '#FEF2F2',
          75: '#FDDADA',
          100: '#F8B5B5',
          200: '#F69595',
          300: '#D66E6E',
          400: '#CE4F4F',
          500: '#AF3D3D',
          600: '#932B2B',
          700: '#870000',
          800: '#700000',
          900: '#4E0202',
        },
      },
      success: {
        light: '#CCFFC8',
        default: '#26B117',
        dark: '#1A7C10',
      },
      warning: {
        light: '#FFECCE',
        default: '#FFB421',
        dark: '#A97627',
      },
      error: {
        light: '#FFD2D2',
        default: '#D23E3E',
        dark: '#932B2B',
      },
    },
    fontFamily: {
      sans: ['Lato', 'sans-serif'],
    },
    boxShadow: {
      xs: '0px 1px 2px rgba(0, 0, 0, 0.07)',
      sm: '0px 0px 32px rgba(0, 0, 0, 0.08)',
      DEFAULT: '0px 0px 5px rgba(0, 0, 0, 0.11)',
      lg: '0 0 0 1px rgb(136 152 170 / 10%), 0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%)',
      xl: '0px 1px 8px rgba(0, 0, 0, 0.16)',
      xxl: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: '0 1px 0 #EBEEF1',
      loginImage: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;',
    },
    extend: {
      backgroundImage: {
        'gradient-blue':
          'linear-gradient(115.8deg, #0732AC 1.11%, #1F2DC6 8.22%, #3629DF 31.33%, rgba(74, 37, 245, 0.92) 67.85%, rgba(10, 72, 245, 0.84) 99.64%)',
      },
      keyframes: {
        indeterminateAnimation: {
          '0%': { transform: 'translateX(0) scaleX(0)' },
          '40%': { transform: 'translateX(0) scaleX(0.4)' },
          '100%': { transform: 'translateX(100%) scaleX(1.5)' },
        },
        fadeIn: {
          '0%': { opacity: 0.5 },
          '100%': { opacity: 1 },
        },
        fadeOut: {
          '0%': { opacity: 1 },
          '100%': { opacity: 0 },
        },
        scaleAndFadeIn: {
          '0%': { opacity: 0.5, transform: 'scale(0.85)' },
          '100%': { opacity: 1, transform: 'scale(1)' },
        },
        scaleAndFadeOut: {
          '0%': { opacity: 1, transform: 'scale(1)' },
          '100%': { opacity: 0, transform: 'scale(0.85)' },
        },
      },
      animation: {
        'table-loading': 'indeterminateAnimation 1s infinite linear',
        'popover-show': 'scaleAndFadeIn 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'popover-hide': 'scaleAndFadeOut 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'modal-show': 'scaleAndFadeIn 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'modal-hide': 'scaleAndFadeOut 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'modal-backdrop-show': 'fadeIn 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'modal-backdrop-hide': 'fadeOut 151ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'form-show': 'fadeIn 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        'toast-enter': 'scaleAndFadeIn 251ms ease-out',
        'toast-leave': 'scaleAndFadeOut 150ms ease-in forwards',
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms')({ strategy: 'class' }),
    plugin(function groupPeer({ addVariant }) {
      const pseudoVariants = ['hover'].map(variant => (Array.isArray(variant) ? variant : [variant, `&:${variant}`]))

      for (const [variantName, state] of pseudoVariants) {
        addVariant(`group-peer-${variantName}`, ctx => {
          const result = typeof state === 'function' ? state(ctx) : state
          return result.replace(/&(\S+)/, ':merge(.peer)$1 ~ .group &')
        })
      }
    }),
  ],
}
