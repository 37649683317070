import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import useStartingPointService from 'builder/connect/hooks/useStartingPointService'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useProductService from 'common/products/hooks/useProductService'

interface RemoveStartingPointModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  productId: string
  startingPointId: string
  startingPointName?: string
  onClose: () => void
}

const RemoveStartingPointModal = ({
  startingPointId,
  productId,
  onClose,
  startingPointName = 'starting point',
  ...modalProps
}: RemoveStartingPointModalProps) => {
  const queryClient = useQueryClient()
  const { openGenericErrorToast, openToast } = useToast()
  const startingPointService = useStartingPointService()
  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()

  const { mutate: deleteStartingPoint, isLoading } = useMutation(() => startingPointService.delete(startingPointId), {
    onSuccess: () => {
      openToast(`Successfully deleted ${startingPointName}`, ToastType.success)
      queryClient.invalidateQueries([...startingPointService.fetchAll.queryKeys, productId])
      queryClient.invalidateQueries([...productService.fetch.queryKeys, productId])
      queryClient.invalidateQueries([...onlineStoreService.fetchAll.queryKeys, 'installed'])
      onClose()
    },
    onError: () => {
      openGenericErrorToast(`${startingPointName} was not deleted.`)
      onClose()
    },
  })

  return (
    <Modal {...modalProps} onBackdropClick={onClose}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Delete starting point</Modal.Title>
      <Modal.Details>
        Are you sure you want to delete this starting point? It will also be removed from your online stores that use
        it. This action cannot be undone.
      </Modal.Details>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="error" onClick={() => deleteStartingPoint()} isLoading={isLoading} disabled={isLoading}>
          Remove
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RemoveStartingPointModal
