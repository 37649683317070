import { Modal } from '@packages/sk8/modal'
import { NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import React, { useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'

import ArrowRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right.svg'

import { UpdateTypes, ExtraPrice } from '../types'

interface StockAdjustModalProps {
  value: number
  updateType: UpdateTypes
  selectedExtraPrices: ExtraPrice[]
  customizerProduct: NormalizedCustomizerProduct
  isInvalidUpdate: boolean
}

const ExtraPricesAdjustDetails = ({
  updateType,
  value,
  selectedExtraPrices,
  customizerProduct,
  isInvalidUpdate,
}: StockAdjustModalProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const getNewValue = (extraPrice: string) => {
    if (updateType === UpdateTypes.adjust) return +extraPrice + value

    return value
  }

  const skeletonLength = Math.min(selectedExtraPrices.length, 20)

  return (
    <Modal.Details ref={scrollRef} className="bg-neutral-50 pb-3">
      <Virtuoso
        customScrollParent={scrollRef.current === null ? undefined : scrollRef.current}
        data={selectedExtraPrices}
        overscan={120}
        style={{ minHeight: `${30 * skeletonLength}px` }}
        itemContent={(index, extraPrice) => {
          return (
            <div
              role="li"
              className={classNames('border-neutral-100 py-2 flex items-center', {
                'border-b': index !== selectedExtraPrices.length - 1,
              })}
            >
              <div>
                <span className="text-neutral-500">Question / Answer</span>
                <span> - </span>
                <span className="font-medium">{customizerProduct.questions[extraPrice.questionId]?.name}</span>
                <span className="font-medium"> / {customizerProduct.answers[extraPrice.answerId]?.name}</span>
              </div>
              <div className="ml-auto flex items-center space-x-1">
                <span>{extraPrice.price === '' ? 0 : extraPrice.price}</span>
                {!isInvalidUpdate && (
                  <>
                    <ArrowRight className="w-2 h-2 fill-neutral-600" />
                    <span className="text-tertiary-green-500">{getNewValue(extraPrice.price)}</span>
                  </>
                )}
              </div>
            </div>
          )
        }}
      />
    </Modal.Details>
  )
}

export default ExtraPricesAdjustDetails
