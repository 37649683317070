import { IconButton } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'
import { useSelector } from 'react-redux'

import * as partsSelectors from 'builder/build/parts/selectors'
import WithInformation from 'common/components/WithInformation'
import EditIcon from 'icons/bold/01-Interface Essential/22-Edit/pencil-write-alternate.svg'

import TypeIcons from '../TypeIcons'
import MaskMenu from './MaskMenu'

const { masksSelector } = partsSelectors

export type Props = {
  partId?: string
  entityMasks: string[]
  isDisabled?: boolean
  maskMessage: string
  onApply: (masks: string[]) => void
}

const MaskSection = ({ partId, entityMasks, isDisabled, maskMessage, onApply }: Props) => {
  const masks = useSelector(masksSelector)

  const editButton = usePopover({
    placement: 'left',
    shiftConfig: { mainAxis: true, crossAxis: false, padding: 16 },
  })

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center justify-between" role="heading">
        <WithInformation tooltip={maskMessage}>
          <label className="panel__field-label">Mask</label>
        </WithInformation>
        <IconButton {...editButton.referenceProps} disabled={isDisabled} role="button" Icon={EditIcon} />
        <Popover isOpen={editButton.isOpen} {...editButton.floatingProps} className="w-[252px] builder z-40">
          <MaskMenu
            masks={masks.filter(mask => mask.id !== partId)}
            initialSelectedMasks={entityMasks}
            onApply={maskIds => {
              editButton.close()
              onApply(maskIds)
            }}
          />
        </Popover>
      </div>
      {entityMasks.map(maskId => {
        const mask = masks.find(mask => mask.id === maskId)

        if (!mask) return null

        return (
          <div className="flex space-x-2" role="row" key={maskId}>
            <TypeIcons entity={mask} />
            <div>{mask.name}</div>
          </div>
        )
      })}
    </div>
  )
}

export default MaskSection
