import React from 'react'

import classMerge from '../../../utils/classMerge'
import CheckboxButtonGroupOption from './CheckboxButtonGroupOption'

const CheckboxButtonGroup = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      className={classMerge('flex border border-solid border-neutral-100 shadow-xs rounded-md', className)}
      {...props}
    >
      {children}
    </div>
  )
}

CheckboxButtonGroup.Option = CheckboxButtonGroupOption

export default CheckboxButtonGroup
