import React from 'react'

import Button from '../button/Button'
import ModalActions from './ModalActions'

export interface ModalStepperFooterProps {
  next: () => void
  skip: () => void
}

const ModalStepperStartFooter = ({ skip, next }: ModalStepperFooterProps) => {
  return (
    <ModalActions>
      <Button variant="subtle" onClick={skip}>
        skip
      </Button>
      <Button variant="primary" onClick={next}>
        Let's go
      </Button>
    </ModalActions>
  )
}

export default ModalStepperStartFooter
