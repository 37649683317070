import { Button } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

import CheckCircleIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/check-circle-1.svg'
import ThemeIcon from 'icons/bold/12-Design/05-Colors-Painting/color-painting-palette.svg'

export interface SelectThemePopoverProps {
  baseUrl: string
  isCompleted: boolean
}

const SelectThemePopover = ({ baseUrl, isCompleted }: SelectThemePopoverProps) => {
  const popover = usePopover({ placement: 'right', offsetConfig: 32, useArrow: true })

  return (
    <>
      <button
        {...popover.referenceProps}
        className={classNames('font-p2 flex items-center justify-between h-4', {
          'text-tertiary-green-300': isCompleted,
          'text-primary-600 font-bold': popover.isOpen,
        })}
        disabled={isCompleted}
      >
        2. Select a theme
        {isCompleted && <CheckCircleIcon className="w-4 h-4 fill-tertiary-green-300" aria-label="completed" />}
      </button>

      <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="!w-80 shadow-lg">
        <div>
          <div className="flex flex-col p-4 space-y-4">
            <div className="w-[45px] h-[45px] rounded-lg bg-neutral-75 p-2">
              <ThemeIcon />
            </div>
            <span className="text-xs font-medium text-neutral-600">
              Pick a theme and tweak it to make it fit with your branding!
            </span>
          </div>
          <div className="px-4 py-3 flex justify-end border-t border-t-neutral-75">
            <Link to={`${baseUrl}/theme`}>
              <Button type="button" variant="primary" className="px-4">
                Select theme
              </Button>
            </Link>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default SelectThemePopover
