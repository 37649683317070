import { Button, ScrollToTopButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { useFormikContext } from 'formik'
import React from 'react'

import { InventoryItemWithStock } from 'common/variants/types/variant'

import InventoryUpdateCount from '../InventoryUpdateCount'
import { InventoryTableFooterProps } from './InventoryTableFooter'

const KickflipInventoryTableFooter = ({ scrollToTopButtonProps, horizontalScrollProps }: InventoryTableFooterProps) => {
  const formik = useFormikContext<Record<string, InventoryItemWithStock>>()

  return (
    <>
      <div
        {...horizontalScrollProps.outerComponentProps}
        style={{ ...horizontalScrollProps.outerComponentProps.style, position: 'sticky', bottom: '81px' }}
      >
        <div {...horizontalScrollProps.innerComponentProps} />
      </div>
      <Card.StickyFooter className="z-[2]">
        <ScrollToTopButton {...scrollToTopButtonProps} />

        <div className="flex flex-grow justify-btween px-6 py-3">
          <InventoryUpdateCount formik={formik} />
          <div className="flex ml-auto">
            <Button
              type="reset"
              className="mr-2"
              disabled={!formik.dirty || formik.isSubmitting}
              onClick={() => formik.resetForm()}
            >
              Discard
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => formik.submitForm()}
              isLoading={formik.isSubmitting}
              disabled={!formik.dirty || formik.isSubmitting}
            >
              Save all
            </Button>
          </div>
        </div>
      </Card.StickyFooter>
    </>
  )
}

export default KickflipInventoryTableFooter
