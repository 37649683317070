import { BlankState } from '@packages/sk8/blank-state'
import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import { OnlineStoreStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import Page from 'cms/layout/page/Page'
import SettingsHeader from 'cms/layout/SettingsHeader'
import SettingsSideMenu from 'cms/layout/SettingsSideMenu'
import AddOnlineStoreButton from 'cms/onlineStores/components/AddOnlineStoreButton'
import ECommerceLogo from 'cms/onlineStores/components/ECommerceLogo'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import CustomStoreLogo from 'icons/custom/custom-store.svg'

import { isCustomStore, isShopify } from '../utils'
import useOnlineStoreService from './../hooks/useOnlineStoreService'
import OnlineStoreConnectionStatus from './OnlineStoreConnectionStatus'

const statusTagClass: { [key in OnlineStoreStatus]: string } = {
  [OnlineStoreStatus.Installed]: 'text-tertiary-green-500 bg-tertiary-green-75',
  [OnlineStoreStatus.NotInstalled]: 'text-neutral-400 bg-neutral-75',
  [OnlineStoreStatus.Uninstalled]: 'text-secondary-orange-500 bg-secondary-orange-75',
}

const OnlineStores = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const onlineStoreService = useOnlineStoreService()

  const {
    data: onlineStores = [],
    isLoading,
    isFetching,
  } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll)

  const isOnlineStoresEmpty = onlineStores.length === 0

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />
      <Page>
        <Page.Header>
          <h1>Online stores</h1>
          <AddOnlineStoreButton
            aria-label="Add online store"
            variant="primary"
            icon={<AddIcon className="w-3 h-3 fill-white" />}
          >
            Online store
          </AddOnlineStoreButton>
        </Page.Header>

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell className="w-2/12">
                <span>Platform</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-3/12">
                <span>Name</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-4/12">
                <span>URL</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-1/12">
                <span>Currency</span>
              </Table.HeaderCell>
              <Table.HeaderCell className="w-2/12 pr-2">
                <span>Status</span>
              </Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>
          {isLoading && <Table.BodyLoader numberOfColumns={5} numberOfRows={15} />}

          {!isLoading && !isOnlineStoresEmpty && (
            <Table.Body>
              {isFetching && <Table.Loader colSpan={5} />}
              {onlineStores.map(onlineStore => {
                const shopUrl =
                  isShopify(onlineStore) || isCustomStore(onlineStore) ? onlineStore.domain : onlineStore.url
                return (
                  <Table.Row
                    key={onlineStore.id}
                    className="cursor-pointer"
                    onClick={() => history.push(`${match.url}/${onlineStore.id}`)}
                  >
                    <Table.Cell className="flex items-center">
                      <ECommerceLogo eCommerce={onlineStore.eCommerce} className="w-5 h-5" />
                      <OnlineStoreConnectionStatus onlineStore={onlineStore} />
                    </Table.Cell>
                    <Table.Cell className="min-w-fit pl-1">{onlineStore.name}</Table.Cell>
                    <Table.Cell className="min-w-fit pl-1">
                      <div>{shopUrl}</div>
                    </Table.Cell>
                    <Table.Cell className="min-w-fit pl-1">{onlineStore.currency}</Table.Cell>
                    <Table.Cell className="'min-w-fit pl-1">
                      <Tag className={statusTagClass[onlineStore.status]}>{onlineStore.status}</Tag>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          )}

          {!isLoading && isOnlineStoresEmpty && (
            <Table.Body>
              <Table.Row className="hover:bg-white">
                <Table.Cell colSpan={5} noFocus>
                  <div className="flex items-center justify-center py-32">
                    <BlankState>
                      <BlankState.Icon Icon={CustomStoreLogo} />
                      <BlankState.Title>There are no online stores added, yet</BlankState.Title>
                      <BlankState.Details>
                        Add your online store to our app and start selling your products.
                      </BlankState.Details>
                      <div className="mt-4">
                        <AddOnlineStoreButton aria-label="Add online store">Add online store</AddOnlineStoreButton>
                      </div>
                    </BlankState>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Page>
    </main>
  )
}

export default OnlineStores
