import { Alert } from '@packages/sk8/alert'
import { Button } from '@packages/sk8/button'
import { HelperText, Input, InputField, Label, PasswordInput } from '@packages/sk8/input'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import useAdminUserInviteService from 'cms/admin/users/hooks/useAdminUserInviteService'
import TrackerContext from 'cms/tracking/components/TrackerContext'
import type { CreateInvitedUserFormValues } from 'cms/users/types/form'
import { NetworkError } from 'common/api/types/error'
import PublicFormFooter from 'common/components/PublicFormFooter'
import TenantContext from 'common/tenant/TenantContext'
import useAnotherUserService from 'common/users/hooks/useUserService'
import { getAdminAppUrl, getRegisterAppUrl } from 'utils/getAppUrl'
import getCallbackUrl from 'utils/getCallbackUrl'

import AuthFormContainer from './AuthFormContainer'
import AuthFormTitle from './AuthFormTitle'

interface RouteParams {
  token: string
}

const formValidation = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  password: yup.string().min(5, 'Password must be at least 5 characters long').required('Please enter a password'),
  passwordConfirmation: yup
    .string()
    .required('Please confirm your password')
    .equals([yup.ref('password')], 'The specified password does not match'),
})

const CreateAdminAccountForm = ({ match, history }: RouteComponentProps<RouteParams>) => {
  const { token } = match.params

  const anotherUserService = useAnotherUserService()
  const adminUserInviteService = useAdminUserInviteService()
  const queryClient = useQueryClient()
  const tenant = useContext(TenantContext)
  const tracker = useContext(TrackerContext)

  const { mutate: createUser, isError } = useMutation<string, NetworkError, CreateInvitedUserFormValues, unknown>(
    values => adminUserInviteService.acceptInvite(token, values),
    {
      onSuccess: async token => {
        localStorage.setItem('bearer', token)
        queryClient.invalidateQueries(anotherUserService.fetchCurrent.queryKeys)
        if (tenant) {
          document.cookie = `brand=${tenant}; domain=${COOKIE_DOMAIN}; path=/`
        }
        tracker.send('user_login_success')

        const registerAppUrl = getRegisterAppUrl()
        const adminAppUrl = getAdminAppUrl()
        const redirectUrl = `${adminAppUrl}/brands`
        const callbackUrl = getCallbackUrl(registerAppUrl, token, redirectUrl)

        location.replace(callbackUrl)
      },
    }
  )
  const { isSuccess } = useQuery(
    adminUserInviteService.validateInviteToken.queryKeys,
    () => adminUserInviteService.validateInviteToken(token),
    {
      onError: () => history.replace('/login/createaccount-error'),
      retry: false,
    }
  )

  const formik = useFormik<CreateInvitedUserFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: formValidation,
    onSubmit: (values, { setSubmitting }) => {
      createUser(values, { onSettled: () => setSubmitting(false) })
    },
  })

  if (!isSuccess) {
    return null
  }

  return (
    <AuthFormContainer>
      <AuthFormTitle>Create your account</AuthFormTitle>

      {isError && (
        <Alert variant="error" className="mb-8">
          <Alert.Body>
            <Alert.Title>Oops… Something went wrong.</Alert.Title>
            <Alert.Details>
              <span>
                Try to refresh the page. If the problem persists, please contact our
                <a className="ml-1 text-secondary-teal-dark" href="mailto:help@gokickflip.com" target="_blank">
                  customer support team
                </a>
                .
              </span>
            </Alert.Details>
          </Alert.Body>
        </Alert>
      )}

      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="flex space-x-4">
          <div className="flex-1">
            <InputField className="mb-6">
              <Label htmlFor="firstName">First name</Label>
              <Input
                id="firstName"
                name="firstName"
                placeholder="Enter first name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={formik.touched.firstName && formik.errors.firstName != null}
              />
              {formik.touched.firstName && formik.errors.firstName != null && (
                <HelperText hasError>{formik.errors.firstName}</HelperText>
              )}
            </InputField>
          </div>
          <div className="flex-1">
            <InputField className="mb-6">
              <Label htmlFor="lastName">Last name</Label>
              <Input
                id="lastName"
                name="lastName"
                placeholder="Enter last name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={formik.touched.lastName && formik.errors.lastName != null}
              />
              {formik.touched.lastName && formik.errors.lastName != null && (
                <HelperText hasError>{formik.errors.lastName}</HelperText>
              )}
            </InputField>
          </div>
        </div>
        <InputField className="mb-6">
          <Label htmlFor="password">Password</Label>
          <PasswordInput
            id="password"
            name="password"
            placeholder="Enter password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.password && formik.errors.password != null}
          />
          {formik.touched.password && formik.errors.password != null && (
            <HelperText hasError>{formik.errors.password}</HelperText>
          )}
        </InputField>
        <InputField className="mb-6">
          <Label htmlFor="passwordConfirmation">Confirm password</Label>
          <PasswordInput
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder="Enter password"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null}
          />
          {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation != null && (
            <HelperText hasError>{formik.errors.passwordConfirmation}</HelperText>
          )}
        </InputField>
        <PublicFormFooter>
          <Button
            id="submit-button"
            isLoading={formik.isSubmitting}
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Create
          </Button>
          <Link to="/login" className="text-secondary-teal-dark ml-1 font-p1 mb-4 lg:mb-0">
            Back to login
          </Link>
        </PublicFormFooter>
      </form>
    </AuthFormContainer>
  )
}

export default CreateAdminAccountForm
