import { Question, QuestionInputType } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React, { Suspense, useState } from 'react'

import Panel from 'builder/build/common/components/panels/Panel'
import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import useThemeSection from 'builder/build/core/useThemeSection'
import { useDispatch } from 'cms/hooks'

import { patchQuestion } from '../actions'
import AnswerListSection from './sections/AnswerListSection'
import DescriptionSection from './sections/DescriptionSection'
import DisplayTypeSection from './sections/displayTypeSection/DisplayTypeSection'
import HeaderSection from './sections/HeaderSection'
import InputColorPickerAnswerSection from './sections/InputColorPickerAnswerSection'
import InputFileAnswerSection from './sections/InputFileAnswerSection'
import InputTextAnswerSection from './sections/InputTextAnswerSection'
import OrderSheetSettingsSection from './sections/OrderSheetSettingsSection'
import OutputFormatSection from './sections/OutputFormatSection'
import QuestionSettingsSection from './sections/QuestionSettingsSection'
import SwitchViewSection from './sections/SwitchViewSection'
import TitleSection from './sections/TitleSection'

export interface QuestionPanelProps {
  question: Question
}

const QuestionPanel = ({ question }: QuestionPanelProps) => {
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')

  const flags = useFlags(['audio_video_uploader'])

  const dispatch = useDispatch()

  const updateQuestion = (field: keyof Question, value: any) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  const handleChangeOutputFormat = (value: Question['outputFormat']) => {
    dispatch(patchQuestion(question.id, { outputFormat: value }))
  }

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} />
      <div className="overflow-y-auto max-h-full h-full">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="border-neutral-100" />
            {question.inputType !== QuestionInputType.Text && (
              <>
                <AnswerListSection question={question} />
                <hr className="border-neutral-100" />
              </>
            )}
            {question.inputType === QuestionInputType.Text && (
              <>
                <div className="relative px-4 py-6">
                  <PatchTextInput
                    placeholder="Enter placeholder"
                    value={question.placeholder || ''}
                    onChange={e => updateQuestion('placeholder', e.target.value)}
                  />
                </div>
                <hr className="border-neutral-100" />
              </>
            )}
            {InputSection && (
              <Suspense fallback={null}>
                <InputSection
                  question={question}
                  settings={
                    question.inputType === QuestionInputType.Text ? (
                      <InputTextAnswerSection question={question} updateQuestion={updateQuestion} />
                    ) : question.inputType === QuestionInputType.ColorPicker ? (
                      <InputColorPickerAnswerSection question={question} updateQuestion={updateQuestion} />
                    ) : flags.audio_video_uploader.enabled && question.inputType === QuestionInputType.File ? (
                      <InputFileAnswerSection question={question} updateQuestion={updateQuestion} />
                    ) : null
                  }
                />
              </Suspense>
            )}
            <hr className="border-neutral-100" />
            <DisplayTypeSection question={question} />
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />
            <hr className="border-neutral-100" />
            {ThemeSettingsSection && question.inputType !== QuestionInputType.ColorPicker && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="border-neutral-100" />
              </>
            )}
            <SwitchViewSection entity={question} />
            <hr className="border-neutral-100" />
            <QuestionSettingsSection question={question} />
            <hr className="border-neutral-100" />
            {question.inputType === QuestionInputType.ColorPicker && (
              <>
                <OutputFormatSection question={question} onChange={handleChangeOutputFormat} />
                <hr className="border-neutral-100" />
              </>
            )}
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default QuestionPanel
