import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import React from 'react'

interface ArchiveProductModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  productName: string
  onArchive: () => void
  onClose: () => void
}

const ArchiveProductModal = ({ productName, onArchive, onClose, ...modalProps }: ArchiveProductModalProps) => {
  return (
    <Modal onBackdropClick={onClose} {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Archive product</Modal.Title>
      <Modal.Details>
        Are you sure you want to archive <em>{productName}</em>? This means this product's customizer won't be available
        in online stores.
      </Modal.Details>
      <Modal.Actions>
        <Button variant="default" className="px-4" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="px-4"
          aria-label="confirm archive"
          onClick={() => {
            onArchive()
            onClose()
          }}
        >
          Archive
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ArchiveProductModal
