import { Question } from '@packages/types'
import React from 'react'

import ActivatableTextInput from 'builder/build/common/components/ActivatableTextInput'
import { useDispatch } from 'cms/hooks'
import Switch from 'common/components/Switch'

import * as questionsActions from '../../actions'

const { patchQuestion } = questionsActions

interface OrderSheetSettingsSectionProps {
  question: Question
}

const OrderSheetSettingsSection = ({ question }: OrderSheetSettingsSectionProps) => {
  const dispatch = useDispatch()

  const updateQuestion = (field: string, value: string | boolean) => {
    dispatch(patchQuestion(question.id, { [field]: value }))
  }

  return (
    <div className="relative px-4 py-6 flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <label className="font-medium">Show in order sheet</label>
        <Switch checked={question.isInOrdersheet} onChange={e => updateQuestion('isInOrdersheet', e.target.checked)} />
      </div>
      {question.isInOrdersheet && (
        <ActivatableTextInput
          id="question-production-code"
          title="Production code"
          value={question.code!}
          isActive={question.hasCode}
          onChange={e => updateQuestion('code', e.target.value)}
          onActivationChange={checked => updateQuestion('hasCode', checked)}
        />
      )}
    </div>
  )
}

export default OrderSheetSettingsSection
