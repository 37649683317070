import { Popover, usePopover } from '@packages/sk8/popover'
import { UniversalSearchInput } from '@packages/sk8/universal-search'
import React, { useState } from 'react'

import SearchResults from './SearchResults'
import useBuilderUniversalSearch from './useBuilderUniversalSearch'

const BuilderUniversalSearch = () => {
  const [query, setQuery] = useState('')
  const groupedResults = useBuilderUniversalSearch(query)
  const popover = usePopover({ placement: 'bottom-end', offsetConfig: 8, autoUpdateOnAnimationFrame: true })

  const handleResultClick = () => {
    popover.close()
    setQuery('')
  }

  return (
    <>
      <UniversalSearchInput
        ref={popover.referenceProps.ref}
        name="builder search"
        value={query}
        onChange={setQuery}
        onFocus={popover.open}
      />
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <SearchResults query={query} groupedResults={groupedResults} onResultClick={handleResultClick} />
      </Popover>
    </>
  )
}

export default BuilderUniversalSearch
