import { ImagePart, Question, QuestionType } from '@packages/types'
import React, { useState, Suspense } from 'react'

import * as answersSelectors from 'builder/build/answers/selectors'
import Panel from 'builder/build/common/components/panels/Panel'
import useThemeSection from 'builder/build/core/useThemeSection'
import DisplaySettingsSection from 'builder/build/parts/components/DisplaySettingsSection'
import { useSelector } from 'cms/hooks'

import AddQuestionButton from './sections/AddQuestionButton'
import AnswerListSection from './sections/AnswerListSection'
import DescriptionSection from './sections/DescriptionSection'
import DisplayTypeSection from './sections/displayTypeSection/DisplayTypeSection'
import HeaderSection from './sections/HeaderSection'
import OrderSheetSettingsSection from './sections/OrderSheetSettingsSection'
import QuestionSettingsSection from './sections/QuestionSettingsSection'
import SwitchViewSection from './sections/SwitchViewSection'
import TitleSection from './sections/TitleSection'

export interface ImageQuestionPanelProps {
  question: Question
  part: ImagePart
}

const ImageQuestionPanel = ({ question, part }: ImageQuestionPanelProps) => {
  const [tab, setTab] = useState<'question' | 'settings'>('question')
  const materialAnswer = useSelector(state => answersSelectors.answerByIdSelector(state, { id: part?.material }))

  const ThemeSettingsSection = useThemeSection('settings')
  const InputSection = useThemeSection('input')

  return (
    <Panel side="right">
      <HeaderSection selectedTab={tab} setTab={setTab} question={question} />
      <div className="overflow-y-auto h-full">
        {tab === 'question' && (
          <>
            <TitleSection question={question} />
            <hr className="border-neutral-100" />
            <AnswerListSection question={question} allowImport={false} />
            <hr className="border-neutral-100" />

            {InputSection && (
              <Suspense fallback={null}>
                <InputSection question={question} />
              </Suspense>
            )}
            <hr className="border-neutral-100" />
            <DisplayTypeSection question={question} settings={<DisplaySettingsSection part={part} />} />
            {materialAnswer && (
              <>
                <hr className="border-neutral-100" />
                <div className="py-6">
                  <div className="font-medium mb-4 mx-4">Create</div>
                  <div className="mx-2 flex flex-col space-y-4">
                    <AddQuestionButton
                      part={part}
                      type={QuestionType.Material}
                      menuPosition="bottom-end"
                      fromDefaultAnswer={false}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {tab === 'settings' && (
          <>
            <DescriptionSection question={question} />
            <hr className="border-neutral-100" />
            {ThemeSettingsSection && (
              <>
                <Suspense fallback={null}>
                  <ThemeSettingsSection step={question} />
                </Suspense>
                <hr className="border-neutral-100" />
              </>
            )}
            <SwitchViewSection entity={question} />
            <hr className="border-neutral-100" />
            <QuestionSettingsSection question={question} />
            <hr className="border-neutral-100" />
            <OrderSheetSettingsSection question={question} />
          </>
        )}
      </div>
    </Panel>
  )
}

export default ImageQuestionPanel
