import { Group } from '@packages/types'
import React, { Suspense } from 'react'
import { useDispatch } from 'react-redux'

import ActivatableTextInput from 'builder/build/common/components/ActivatableTextInput'
import useThemeSection from 'builder/build/core/useThemeSection'
import SwitchViewSection from 'builder/build/questions/components/sections/SwitchViewSection'

import { patchGroup } from '../../actions'

export interface FolderSettingsTabProps {
  group: Group
}

const FolderSettingsTab = ({ group }: FolderSettingsTabProps) => {
  const dispatch = useDispatch()
  const ThemeSettingsSection = useThemeSection('settings')

  const updateGroup = (field: string, value: unknown) => dispatch(patchGroup(group.id, { [field]: value }))

  return (
    <>
      <div className="relative px-4 py-6">
        <ActivatableTextInput
          id="group-description"
          isRichText
          titleClassName="font-medium"
          title="Description"
          value={group.description}
          isActive={group.showDescription}
          onChange={e => updateGroup('description', e.target.value)}
          onActivationChange={checked => updateGroup('showDescription', checked)}
          textarea
        />
      </div>

      <hr className="border-neutral-100" />

      {ThemeSettingsSection && (
        <>
          <Suspense fallback={null}>
            <ThemeSettingsSection step={group} />
          </Suspense>
          <hr className="border-neutral-100" />
        </>
      )}
      <SwitchViewSection entity={group} />
    </>
  )
}
export default FolderSettingsTab
