import { Card } from '@packages/sk8/card'
import React from 'react'

const ProductCardBone = () => {
  return (
    <Card className="flex-wrap bg-white hover:shadow-sm w-[240px] h-[232px] mr-3 mb-3" aria-label="skeleton card">
      <span className="w-full  h-[163px] animate-pulse bg-neutral-100" />
      <Card.Separator className="border-white w-full" />
      <div className="flex w-full h-[69px] justify-between p-4">
        <div className="flex flex-col w-full space-y-1">
          <span
            className="text-neutral-800 text-sm text-ellipsis overflow-hidden rounded bg-neutral-100 w-[40%] h-4 animate-pulse"
            aria-label="Product name"
          />
          <span className="text-neutral-300 text-xs text-ellipsis overflow-hidden rounded bg-neutral-100 w-[60%] h-3 animate-pulse" />
        </div>
      </div>
    </Card>
  )
}

export default ProductCardBone
