import React from 'react'

import CheckIcon from '../icons/bold/01-Interface Essential/33-Form-Validation/check-1.svg'
import classMerge from '../utils/classMerge'

interface CheckListProps {
  items: string[]
  className?: string
}

const CheckList = ({ items, className }: CheckListProps) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          <span key={index} className={classMerge('flex items-center pb-3 font-p1', className)}>
            <CheckIcon className="w-2 h-2 mr-[10px] fill-tertiary-green-500 stroke-tertiary-green-500" />
            {item}
          </span>
        )
      })}
    </>
  )
}

export { CheckList }
