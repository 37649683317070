import { Card } from '@packages/sk8/card'
import { AddonId } from '@packages/types'
import React from 'react'

import AddonButton from './AddonButton'

export type AddonCardVariants = 'default' | 'small'

export interface AddonCardProps {
  title: string
  description: string
  context?: string
  rightContent?: React.ReactElement
  addonId: AddonId
  variant?: AddonCardVariants
}

const titleClassNames = {
  default: 'text-lg font-medium text-white drop-shadow',
  small: 'text-sm font-medium text-white mb-0 drop-shadow',
}

const wrapperClassNames = {
  default: 'shrink-0 max-w-sm xl:max-w-lg flex flex-col space-y-6 mr-24',
  small: 'max-w-sm xl:max-w-lg flex flex-col space-y-2',
}

const sectionClassNames = {
  default: 'flex flex-col space-y-4 w-full',
  small: 'flex flex-col p-3',
}

const descriptionClassNames = {
  default: 'leading-5 text-white/90',
  small: 'text-xs text-white/90',
}

const cardClassNames = {
  default: 'w-full shadow bg-gradient-to-r from-tertiary-red-300 via-woocommerce to-primary-400/80 text-white',
  small: 'w-full shadow bg-gradient-to-r from-tertiary-red-300 to-woocommerce text-white',
}

const AddonCard = ({ title, description, context, addonId, rightContent, variant = 'default' }: AddonCardProps) => {
  return (
    <Card className={cardClassNames[variant]}>
      <Card.Section className={sectionClassNames[variant]}>
        <div className="w-full flex justify-between">
          <div className={wrapperClassNames[variant]}>
            <h3 className={titleClassNames[variant]}>{title}</h3>
            <div className={descriptionClassNames[variant]}>{description}</div>
            {variant === 'default' && context && <div className="text-xs text-white/80">{context}</div>}
            <div>
              <AddonButton addonId={addonId} small={variant === 'small'} />
            </div>
          </div>
          {variant === 'default' && (
            <div className="flex flex-grow h-0 relative ml-auto">{rightContent && rightContent}</div>
          )}
        </div>
      </Card.Section>
    </Card>
  )
}

export default AddonCard
