import { IconButton } from '@packages/sk8/button'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Tooltip } from '@packages/sk8/tooltip'
import { NormalizedCustomizerProduct, Question, Rule, RuleThenType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import PencilIcon from 'icons/bold/01-Interface Essential/22-Edit/pencil-write-2-alternate.svg'
import BinIcon from 'icons/regular/01-Interface Essential/23-Delete/bin.svg'
import DuplicateIcon from 'icons/regular/01-Interface Essential/51-Paginate/paginate-filter-plus.svg'

import { deleteRule } from '../actions'
import doesRuleContainsArchivedAnswers from '../doesRuleContainsArchivedAnswers'
import isValidRule from '../isValidRule'
import { duplicateRule } from '../utils'
import RuleBuilder from './RuleBuilder/RuleBuilder'
import RuleWarning, { RuleWarningType } from './RuleWarning'

export interface ReadOnlyRuleProps {
  rule: Rule
  customizerProduct: NormalizedCustomizerProduct
  question: Question
  isEditingARule: boolean
  isAddingNewRule: boolean
  setDuplicatedRuleId: React.Dispatch<React.SetStateAction<string | undefined>>
  setNewRule: React.Dispatch<React.SetStateAction<Rule | undefined>>
  setEditedRuleId: React.Dispatch<React.SetStateAction<string | undefined>>
  questionsToHighlight: string[]
  answersToHighlight: string[]
}

const ReadOnlyRule = ({
  question,
  rule,
  customizerProduct,
  isEditingARule,
  isAddingNewRule,
  setDuplicatedRuleId,
  setNewRule,
  setEditedRuleId,
  questionsToHighlight,
  answersToHighlight,
}: ReadOnlyRuleProps) => {
  const dispatch = useDispatch()
  const { openToast } = useToast()
  const showError = !isValidRule({ rule, groups: customizerProduct.groups, questions: customizerProduct.questions })
  const showArchived = doesRuleContainsArchivedAnswers(rule, customizerProduct.answers)
  const showWarning = !!question?.linkedQuestionId && rule.then[0].type !== RuleThenType.DisableQuestion

  const handleDuplicateRule = () => {
    setDuplicatedRuleId(rule.id)
    setNewRule(duplicateRule(rule))
  }

  const handleEditRule = () => setEditedRuleId(rule.id)

  const handleDeleteRule = () => {
    dispatch(deleteRule(rule.id))
    openToast('Rule was successfully deleted!', ToastType.success)
  }

  return (
    <div className={classNames('flex', { 'opacity-30': isEditingARule || isAddingNewRule })}>
      <div className="absolute flex left-[-42px] w-[42px] h-full items-center justify-around">
        {showArchived && (
          <RuleWarning message="This rule contains one or many archived answers." type={RuleWarningType.Info} />
        )}

        {showError && (
          <RuleWarning
            message="This rule is invalid, a value is missing, has been deleted or moved outside a bulk order."
            type={RuleWarningType.Error}
          />
        )}

        {!showError && showWarning && (
          <RuleWarning
            message="This rule is applied to a linked question and won't work until the question is unlinked from the main question."
            type={RuleWarningType.Warning}
          />
        )}
      </div>

      <div className="group w-full text-sm flex flex-row flex-nowrap items-center min-h-[64px] h-auto relative pl-0 py-4">
        <RuleBuilder
          readonly
          rule={rule}
          customizerProduct={customizerProduct}
          warning={showWarning}
          questionsToHighlight={questionsToHighlight}
          answersToHighlight={answersToHighlight}
        />

        <div
          className={classNames(
            'flex flex-row flex-nowrap opacity-0 pointer-events-none absolute -my-3 right-0 icon-button__group mr-2',
            { 'group-hover:opacity-100 group-hover:pointer-events-auto': !isEditingARule && !isAddingNewRule }
          )}
        >
          <Tooltip content="Duplicate">
            <IconButton aria-label="Duplicate rule" onClick={handleDuplicateRule} Icon={DuplicateIcon} />
          </Tooltip>
          <Tooltip content="Edit">
            <IconButton aria-label="Edit rule" onClick={handleEditRule} Icon={PencilIcon} />
          </Tooltip>
          <Tooltip content="Delete">
            <IconButton aria-label="Delete rule" onClick={handleDeleteRule} Icon={BinIcon} iconClassName="w-4 h-4" />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default ReadOnlyRule
