import { Checkbox } from '@packages/sk8/input'
import { Question, QuestionType } from '@packages/types'
import { uniq } from 'lodash'
import React, { useMemo } from 'react'

import HorizontalField from 'builder/build/common/components/HorizontalField'
import Separator from 'builder/build/common/components/Separator'
import { FileType, EXTENSIONS_BY_FILE_TYPE } from 'common/assets/utils/getFileType'
import WithInformation from 'common/components/WithInformation'

export interface InputFileAnswerSectionProps {
  question: Question
  updateQuestion: (field: keyof Question, value: Question['allowedExtensionsByType']) => void
}

const InputFileAnswerSection = ({ question, updateQuestion }: InputFileAnswerSectionProps) => {
  const currentAllowedExtensionsByType = question.allowedExtensionsByType || {
    [FileType.Image]: EXTENSIONS_BY_FILE_TYPE[FileType.Image],
  }
  const checkedTypes = useMemo(
    () => Object.keys(currentAllowedExtensionsByType).filter(key => currentAllowedExtensionsByType[key].length > 0),
    [currentAllowedExtensionsByType]
  )

  const handleUpdate = (type: FileType, value: boolean) => {
    if (!value && checkedTypes.length === 1 && checkedTypes[0] === type) {
      return
    }

    updateQuestion('allowedExtensionsByType', {
      ...currentAllowedExtensionsByType,
      [type]: value ? EXTENSIONS_BY_FILE_TYPE[type] : [],
    })
  }

  const handleUpdateExtension = (type: FileType, extension: string, value: boolean) => {
    updateQuestion('allowedExtensionsByType', {
      ...currentAllowedExtensionsByType,
      [type]: uniq(
        value
          ? [...currentAllowedExtensionsByType[type], extension]
          : currentAllowedExtensionsByType[type].filter(ext => ext !== extension)
      ),
    })
  }

  const isTypeCheckboxDisabled = (type: FileType) => {
    return checkedTypes.length === 1 && checkedTypes[0] === type
  }

  const isExtensionCheckboxDisabled = (type: FileType, extension: string) => {
    return (
      isTypeCheckboxDisabled(type) &&
      currentAllowedExtensionsByType[type].length === 1 &&
      currentAllowedExtensionsByType[type][0] === extension
    )
  }

  const getSupportedExtensions = (type: FileType) => {
    return (
      (currentAllowedExtensionsByType[type]?.length || 0) > 0 &&
      EXTENSIONS_BY_FILE_TYPE[type].map(extension => {
        return (
          <HorizontalField className="px-4 py-6" key={`${type}-ext-${extension}`}>
            <label htmlFor={`${type}-ext-${extension}`}>{extension.toUpperCase()}</label>
            <Checkbox
              id={`${type}-ext-${extension}`}
              checked={currentAllowedExtensionsByType[type].includes(extension)}
              onChange={e => handleUpdateExtension(type, extension, e.target.checked)}
              disabled={isExtensionCheckboxDisabled(type, extension)}
            />
          </HorizontalField>
        )
      })
    )
  }

  return (
    <div className="overflow-y-scroll max-h-[60vh]">
      <HorizontalField className="px-4 py-6">
        <label htmlFor="image-file">
          <WithInformation tooltip="Allows your customers to upload image files through the file uploader">
            Image files
          </WithInformation>
        </label>
        <Checkbox
          id="image-file"
          checked={!!currentAllowedExtensionsByType.image?.length}
          onChange={e => handleUpdate(FileType.Image, e.target.checked)}
          disabled={isTypeCheckboxDisabled(FileType.Image)}
        />
      </HorizontalField>
      {getSupportedExtensions(FileType.Image)}
      <Separator />
      <HorizontalField className="px-4 py-6">
        <label htmlFor="video-file">
          <WithInformation tooltip="Allows your customers to upload video files through the file uploader.">
            Video files
          </WithInformation>
        </label>
        <Checkbox
          id="video-file"
          checked={!!currentAllowedExtensionsByType.video?.length}
          onChange={e => handleUpdate(FileType.Video, e.target.checked)}
          disabled={isTypeCheckboxDisabled(FileType.Video) || question.type === QuestionType.Logo}
        />
      </HorizontalField>
      {getSupportedExtensions(FileType.Video)}
      <Separator />
      <HorizontalField className="px-4 py-6">
        <label htmlFor="audio-file">
          <WithInformation tooltip="Allows your customers to upload audio files through the file uploader.">
            Audio files
          </WithInformation>
        </label>
        <Checkbox
          id="audio-file"
          checked={!!currentAllowedExtensionsByType.audio?.length}
          onChange={e => handleUpdate(FileType.Audio, e.target.checked)}
          disabled={isTypeCheckboxDisabled(FileType.Audio) || question.type === QuestionType.Logo}
        />
      </HorizontalField>
      {getSupportedExtensions(FileType.Audio)}
    </div>
  )
}

export default InputFileAnswerSection
