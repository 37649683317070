import React from 'react'

import classMerge from '../../utils/classMerge'
import Button from '../button/Button'

interface ToastActionProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  children: React.ReactNode
}

const ToastAction = ({ onClick, className, children }: ToastActionProps) => {
  return (
    <Button onClick={onClick} className={classMerge('ml-4', className)}>
      {children}
    </Button>
  )
}

export default ToastAction
