import { Button } from '@packages/sk8/button'
import { Answer, Question } from '@packages/types'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import CardList, { ReorderDetails } from 'builder/build/common/components/cards/CardList'
import { useDispatch } from 'cms/hooks'

import { moveAnswer } from '../../actions'
import { questionAnswersSelector, defaultAnswerIdSelector } from '../../selectors'
import AnswerCard from './AnswerCard'

interface AnswerListProps {
  question: Question
  className?: string
  isClosing?: boolean
}

const AnswerList = ({ question, className, isClosing = false }: AnswerListProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const answerIdFromSearch = new URLSearchParams(location.search).get('answer')
  const defaultAnswer: string | string[] | undefined = useSelector(state =>
    (defaultAnswerIdSelector as any)(state, question.id)
  )
  const answers: Answer[] = useSelector(state => (questionAnswersSelector as any)(state, question))
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null)
  const isLinkedQuestion = question.linkedQuestionId

  const handleReorder = ({ sourceIndex, destinationIndex }: ReorderDetails) => {
    dispatch(moveAnswer(question.id, question.answers[sourceIndex], destinationIndex))
  }

  useEffect(() => {
    if (!isClosing && answerIdFromSearch) {
      setSelectedAnswerId(answerIdFromSearch)
      history.replace(`/questions/${question.id}`)
    }
  }, [isClosing, answerIdFromSearch])

  return (
    <>
      {isLinkedQuestion ? (
        <>
          <div className="text-xs text-neutral-300 px-4 mt-2">Answers are determined by another question.</div>
          <Button
            className="mx-2 !text-primary-600"
            variant="subtle"
            onClick={() => history.push(`/questions/${question.linkedQuestionId}`)}
          >
            Go to main question
          </Button>
        </>
      ) : answers.length > 0 ? (
        <CardList
          CardComponent={AnswerCard}
          onReorder={handleReorder}
          className={classNames(className, 'space-y-2')}
          items={answers.map(answer => ({
            id: answer.id,
            props: {
              onClick: () => setSelectedAnswerId(answer.id),
              isSelected: !isClosing && selectedAnswerId === answer.id,
              closePanel: () => setSelectedAnswerId(null),
              answer,
              defaultAnswer,
              question,
            },
          }))}
        />
      ) : null}
    </>
  )
}

export default AnswerList
