import currencies from '@packages/data/currencies.json'
import { Button } from '@packages/sk8/button'
import { useNavigationBlockingModal } from '@packages/sk8/modal'
import { OnlineStore, NormalizedCustomizerProduct } from '@packages/types'
import { Form, useFormikContext } from 'formik'
import React, { useContext } from 'react'

import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import UnsavedChangesModal from 'common/components/UnsavedChangesModal'

import { PricingFormValues } from '../types'
import BulkPricingCard from './BulkPricingCard'
import PricingCard from './PricingCard'

interface PricingFormProps {
  onlineStore: OnlineStore
  customizerProduct: NormalizedCustomizerProduct
}

const PricingForm = ({ onlineStore, customizerProduct }: PricingFormProps) => {
  const { history } = useContext(GlobalRouterContext)
  const formik = useFormikContext<PricingFormValues>()
  const unsavedChangesModal = useNavigationBlockingModal(history, [formik.dirty])
  const currencyKey = (onlineStore.currency || 'USD') as keyof typeof currencies
  const currency = currencies[currencyKey]
  const currenciesInputProps = currencyKey === 'USD' ? { leftAddon: currency.symbol } : { rightAddon: currency.symbol }

  return (
    <Form id="pricing-form" className="w-full mb-16">
      <div className="flex">
        <PricingCard
          currenciesInputProps={currenciesInputProps}
          currencyKey={currencyKey}
          customizerProduct={customizerProduct}
        />

        <BulkPricingCard currenciesInputProps={currenciesInputProps} customizerProduct={customizerProduct} />
      </div>

      <div className="fixed w-full flex justify-end align-center bottom-0 left-0 z-10  border-t border-t-neutral-100  bg-white py-2 px-36">
        <Button type="reset" form="pricing-form" className="mr-2" disabled={!formik.dirty || formik.isSubmitting}>
          Discard
        </Button>
        <Button
          type="submit"
          form="pricing-form"
          variant="primary"
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          isLoading={formik.isSubmitting}
        >
          Save
        </Button>
      </div>

      {unsavedChangesModal.isVisible && (
        <UnsavedChangesModal
          onLeaveClick={() => {
            unsavedChangesModal.close()
            unsavedChangesModal.forceNavigate()
          }}
          onStayClick={unsavedChangesModal.close}
          {...unsavedChangesModal.modalProps}
        />
      )}
    </Form>
  )
}

export default PricingForm
