import { useRef } from 'react'

import isMainButtonClick from '../utils/isMainButtonClick'

const ignoredTags = ['TEXTAREA', 'INPUT', 'SELECT']

export interface DocumentMoveHandlersOptions {
  onMoveStart?: (e: React.MouseEvent | React.TouchEvent) => void
  onMove?: (deltas: { deltaX: number; deltaY: number }, e: MouseEvent | TouchEvent) => void
  onMoveEnd?: (e: MouseEvent | TouchEvent) => void
}

const useDocumentMoveHandlers = ({ onMoveStart, onMove, onMoveEnd }: DocumentMoveHandlersOptions = {}) => {
  const isMoving = useRef(false)
  const lastPositionRef = useRef<{ x: number; y: number } | null>(null)
  const lastTouchIdRef = useRef<number | null>(null)

  const onMouseDown = (e: React.MouseEvent) => {
    if (ignoredTags.includes((e.target as HTMLElement).tagName)) return
    if (!isMainButtonClick(e.nativeEvent)) return

    isMoving.current = true

    e.stopPropagation()
    e.preventDefault()

    lastPositionRef.current = { x: e.pageX, y: e.pageY }
    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('mouseup', onMouseUp, false)
    onMoveStart?.(e)
  }

  const onMouseMove = (e: MouseEvent) => {
    if (!lastPositionRef.current || !isMainButtonClick(e)) return

    onMove?.({ deltaX: e.pageX - lastPositionRef.current.x, deltaY: e.pageY - lastPositionRef.current.y }, e)
    lastPositionRef.current = { x: e.pageX, y: e.pageY }
  }

  const onMouseUp = (e: MouseEvent) => {
    if (!isMainButtonClick(e)) return

    isMoving.current = false

    lastPositionRef.current = null
    window.removeEventListener('mousemove', onMouseMove, false)
    window.removeEventListener('mouseup', onMouseUp, false)
    onMoveEnd?.(e)
  }

  const onTouchStart = (e: React.TouchEvent) => {
    if (e.changedTouches.length === 0 || !!lastTouchIdRef.current) return

    isMoving.current = true

    e.stopPropagation()
    e.preventDefault()

    const { pageX, pageY, identifier } = e.changedTouches[0]
    lastPositionRef.current = { x: pageX, y: pageY }
    lastTouchIdRef.current = identifier

    window.addEventListener('touchmove', onTouchMove, false)
    window.addEventListener('touchend', onTouchEnd, false)
    window.addEventListener('touchcancel', onTouchEnd, false)
    onMoveStart?.(e)
  }

  const onTouchMove = (e: TouchEvent) => {
    if (!lastPositionRef.current) return

    const touchId = [...e.changedTouches].findIndex(({ identifier }) => identifier === lastTouchIdRef.current)

    if (touchId >= 0) {
      const { pageX, pageY } = e.changedTouches[touchId]
      onMove?.({ deltaX: pageX - lastPositionRef.current.x, deltaY: pageY - lastPositionRef.current.y }, e)
      lastPositionRef.current = { x: pageX, y: pageY }
    }
  }

  const onTouchEnd = (e: TouchEvent) => {
    const touch = [...e.changedTouches].findIndex(({ identifier }) => identifier === lastTouchIdRef.current)

    isMoving.current = false

    if (touch >= 0) {
      lastTouchIdRef.current = null
      lastPositionRef.current = null
      window.removeEventListener('touchmove', onTouchMove, false)
      window.removeEventListener('touchend', onTouchEnd, false)
      window.removeEventListener('touchcancel', onTouchEnd, false)
      onMoveEnd?.(e)
    }
  }

  return { onMouseDown, onTouchStart, isMoving }
}

export default useDocumentMoveHandlers
