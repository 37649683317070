import React from 'react'

import classMerge from '../../utils/classMerge'

const TablePagination = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div {...rest} className={classMerge('flex items-center justify-end mt-4', className)}>
    {children}
  </div>
)

export default TablePagination
