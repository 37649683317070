import { Button } from '@packages/sk8/button'
import { Modal } from '@packages/sk8/modal'
import { OnlineStore } from '@packages/types'
import { capitalize } from 'lodash'
import React from 'react'

interface DisconnectModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  productId: string
  startingPointId: string
  onlineStore: OnlineStore
  onDisconnect: () => void
  onClose: () => void
  isDisconnecting: boolean
}

const DisconnectModal = ({
  onlineStore,
  onClose,
  onDisconnect,
  isDisconnecting,
  ...modalProps
}: DisconnectModalProps) => {
  return (
    <Modal {...modalProps} onBackdropClick={onClose}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Stop selling?</Modal.Title>
      <Modal.Details>This will delete your product on {capitalize(onlineStore.eCommerce)}.</Modal.Details>
      <Modal.Actions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="error"
          onClick={() => {
            onClose()
            onDisconnect()
          }}
          isLoading={isDisconnecting}
          disabled={isDisconnecting}
        >
          Disconnect
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default DisconnectModal
