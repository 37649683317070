import React from 'react'

import classMerge from '../../utils/classMerge'
import Tab from './Tab'

const Tabs = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div role="tablist" className={classMerge(className, 'flex space-x-3 h-6 border-b border-neutral-100')} {...rest}>
      {children}
    </div>
  )
}

Tabs.Tab = Tab

export default Tabs
