import { ScrollToTopButton } from '@packages/sk8/button'
import { InventoryManagementStrategy } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React from 'react'

import KickflipInventoryTableFooter from './KickflipInventoryTableFooter'
import ShopifyInventoryTableFooter from './ShopifyInventoryTableFooter'

export interface InventoryTableFooterProps {
  scrollToTopButtonProps: React.ComponentProps<typeof ScrollToTopButton>
  horizontalScrollProps: {
    outerComponentProps: React.ComponentProps<'div'>
    innerComponentProps: React.ComponentProps<'div'>
  }
}

const InventoryTableFooter = (props: InventoryTableFooterProps) => {
  const flags = useFlags(['inventory_management'])

  if (flags.inventory_management.enabled && flags.inventory_management.value === InventoryManagementStrategy.Shopify)
    return <ShopifyInventoryTableFooter {...props} />

  return <KickflipInventoryTableFooter {...props} />
}

export default InventoryTableFooter
