import React from 'react'

import WarningIcon from '../../icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'
import InfoIcon from '../../icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'
import SuccessIcon from '../../icons/bold/01-Interface Essential/33-Form-Validation/check-circle-1.svg'
import { ToastType } from './types'

interface ToastIconProps {
  type: ToastType
}

const iconByTypes: Record<ToastType, JSX.Element> = {
  default: <InfoIcon className="mr-4 fill-neutral-300" width={16} height={16} aria-label="Info icon" />,
  success: <SuccessIcon className="mr-4 fill-success-default" width={16} height={16} aria-label="Success icon" />,
  warning: <WarningIcon className="mr-4 fill-warning-default" width={16} height={16} aria-label="Warning icon" />,
}

const ToastIcon = ({ type }: ToastIconProps) => {
  return iconByTypes[type]
}

export default ToastIcon
