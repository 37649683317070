import { ProgressBar } from '@packages/sk8/progress-bar'
import { useToast } from '@packages/sk8/toast'
import { ActivityLogType, ECommerce } from '@packages/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import useShopifyProxyService from 'cms/onlineStores/hooks/useShopifyProxyService'
import TrackerContext from 'cms/tracking/components/TrackerContext'
import ProductLimitReachedToast from 'common/components/toast/ProductLimitReachedToast'
import { trpc } from 'common/hooks/trpc'
import useProductService from 'common/products/hooks/useProductService'

import ActivateAppEmbed from './ActivateAppEmbed'
import CompletedProgressCenter from './CompletedProgressCenter'
import CreateProductPopover from './CreateProductPopover'
import InstallPopover from './InstallPopover'
import SelectThemePopover from './SelectThemePopover'

export interface ProgressCenterProps {
  baseUrl: string
}

const ProgressCenter = ({ baseUrl }: ProgressCenterProps) => {
  const { openCustomToast, openGenericErrorToast } = useToast()
  const history = useHistory()
  const tracker = useContext(TrackerContext)
  const onlineStoreService = useOnlineStoreService()
  const productService = useProductService()
  const trpcContext = trpc.useContext()
  const shopifyProxyService = useShopifyProxyService()

  const { data: onlineStores = [], isFetched: areOnlineStoresFetched } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll
  )

  const shopifyOnlineStore = onlineStores.find(store => store.eCommerce === ECommerce.Shopify)

  const { data: app, isFetched: appFetched } = useQuery(
    [...shopifyProxyService.fetchApp.queryKeys, shopifyOnlineStore?.id],
    () => shopifyProxyService.fetchApp(shopifyOnlineStore!.id),
    { enabled: !!shopifyOnlineStore }
  )

  const isAppFetched = !!shopifyOnlineStore ? appFetched : true

  const { data: activityLogs, isFetched: areActivityLogsFetched } = trpc.activityLog.listLatest.useQuery(
    [
      ActivityLogType.SelectTheme,
      ActivityLogType.PublishTheme,
      ActivityLogType.PublishCustomizerProduct,
      ActivityLogType.AddStartingPointToStore,
      ActivityLogType.CompleteOnboarding,
      ActivityLogType.EditAppEmbed,
    ],
    { initialData: [] }
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcContext.activityLog.listLatest.invalidate(),
  })

  const { mutate: autoConnect } = useMutation((productId: string) => productService.autoConnect(productId))

  const { mutate: createProduct } = useMutation(productService.create, {
    onSuccess(product) {
      autoConnect(product.id)
      history.push(`${baseUrl}/products/${product.id}/builder`)
      tracker.send('account_product_added')
      trpcContext.product.list.invalidate()
    },
    onError(error: AxiosError & { data?: { status: number; name: string } }) {
      if (error.data?.status == 403 && error.data?.name === 'MaximumActiveProductsException') {
        return openCustomToast(props => (
          <ProductLimitReachedToast
            onUpgradePlan={() => history.push(`${baseUrl}/account/subscription/change-plan`)}
            {...props}
          />
        ))
      }
      openGenericErrorToast('Your product was not created.')
    },
  })

  const hasCompletedOnboarding = !!activityLogs.find(log => log.type === 'completeOnboarding')

  if (!areActivityLogsFetched || !areOnlineStoresFetched || !isAppFetched || hasCompletedOnboarding) return null

  const isLegacyApp = !app || app.isLegacy

  const isInstallCompleted = onlineStores.length > 0
  const isSelectAThemeCompleted = !!activityLogs.find(log => ['selectTheme', 'publishTheme'].includes(log.type))
  const isPublishProductCompleted = !!activityLogs.find(log => log.type === 'publishCustomizerProduct')
  const isAppEmbedActivationCompleted = !!activityLogs.find(log => log.type === 'editAppEmbed')
  const stepStatuses = [
    isInstallCompleted,
    isSelectAThemeCompleted,
    isPublishProductCompleted,
    ...(!isLegacyApp ? [isAppEmbedActivationCompleted] : []),
  ]

  const progressPercentage = (stepStatuses.filter(Boolean).length / stepStatuses.length) * 100

  if (!stepStatuses.includes(false)) {
    return <CompletedProgressCenter onClose={() => createActivityLog({ type: ActivityLogType.CompleteOnboarding })} />
  }

  return (
    <>
      <div className="flex flex-col border-neutral-100 border-t p-4 pb-8">
        <h2>Start selling in a few steps!</h2>
        <ProgressBar percentage={progressPercentage} containerClassName="bg-neutral-200" />

        <div className="flex flex-col space-y-3">
          <InstallPopover isCompleted={isInstallCompleted} />
          <SelectThemePopover baseUrl={baseUrl} isCompleted={isSelectAThemeCompleted} />
          <CreateProductPopover isCompleted={isPublishProductCompleted} onCreateProduct={createProduct} />
          {!isLegacyApp && (
            <ActivateAppEmbed
              isCompleted={isAppEmbedActivationCompleted}
              onlineStoreUrl={`${baseUrl}/onlinestores/${shopifyOnlineStore!.id}`}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ProgressCenter
