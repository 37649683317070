import { Button } from '@packages/sk8/button'
import { ToastType, useToast } from '@packages/sk8/toast'
import { Addon, CustomPlan, Plan, StripeSubscription } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormikHelpers, useFormik } from 'formik'
import { isEqual } from 'lodash'
import React, { useState } from 'react'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import { ActivateResponse, ActivateSubscriptionBody } from 'cms/subscription/types/service'
import { NetworkError } from 'common/api/types/error'

interface StripeUpdatePlanCardFormValues {
  plan: Plan | CustomPlan
  addons: Addon[]
}

export type StripeChangePlanCardProps = {
  subscription: StripeSubscription
  plan: Plan | CustomPlan
  addons: Addon[]
  onSuccess: () => void
}

const StripeChangePlanCard = ({ subscription, plan, addons, onSuccess }: StripeChangePlanCardProps) => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { openGenericErrorToast, openToast } = useToast()
  const subscriptionService = useSubscriptionService()

  const hasPlanChanged = subscription.plan.name !== plan.name
  const hasAddonsChanged = !isEqual(
    subscription.addons.map(({ name }) => name),
    addons.map(({ name }) => name)
  )

  const isDirty = hasPlanChanged || hasAddonsChanged

  const { mutate: changePlan } = useMutation<ActivateResponse, NetworkError, ActivateSubscriptionBody>(
    subscriptionService.changePlan,
    {
      onSuccess: () => {
        openToast('Your plan was changed.', ToastType.success)
        queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
        onSuccess()
      },
    }
  )

  const handleSubmit = (
    values: StripeUpdatePlanCardFormValues,
    { setSubmitting }: FormikHelpers<StripeUpdatePlanCardFormValues>
  ) => {
    setHasSubmitted(true)

    return changePlan(values, {
      onSettled: () => setSubmitting(false),
      onError: () => {
        setHasSubmitted(false)
        openGenericErrorToast('Your plan was not changed.')
      },
    })
  }

  const formik = useFormik<StripeUpdatePlanCardFormValues>({
    initialValues: { addons, plan },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Button
        type="submit"
        variant="primary"
        className="w-full"
        isLoading={formik.isSubmitting || hasSubmitted}
        disabled={formik.isSubmitting || !isDirty}
      >
        Change plan
      </Button>
    </form>
  )
}

export default StripeChangePlanCard
