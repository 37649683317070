import React from 'react'

import classMerge from '../../utils/classMerge'

interface ToastMessageProps {
  className?: string
  children: React.ReactNode | string
}

const ToastMessage = ({ className, children }: ToastMessageProps) => {
  return <p className={classMerge('w-fit text-sm my-3', className)}>{children}</p>
}

export default ToastMessage
