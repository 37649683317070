import { Button } from '@packages/sk8/button'
import { Answer } from '@packages/types'
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'

import MovablePanel from 'builder/build/common/components/panels/MovablePanel/MovablePanel'
import MovablePanelHeader from 'builder/build/common/components/panels/MovablePanel/MovablePanelHeader'
import useCustomizerDispatch from 'builder/build/customizer/hooks/useCustomizerDispatch'
import * as customizationActions from 'customizer/customization/actions'

import AnswerPanelFactory from './AnswerPanelFactory'

import './AnswerPanel.scss'

export interface AnswerPanelProps {
  answer: Answer
  close: () => void
}

const AnswerPanel = ({ answer, close }: AnswerPanelProps) => {
  const [dragRef, setDragRef] = useState<HTMLDivElement>()
  const customizerDispatch = useCustomizerDispatch()
  const doneButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    customizerDispatch(customizationActions.selectAnswerWhereAvailable(answer.id))
  }, [answer])

  const handleClose: MouseEventHandler<HTMLButtonElement> = () => {
    doneButtonRef.current?.focus()
    close()
  }

  return (
    <MovablePanel dragRef={dragRef}>
      <div className="flex flex-col h-full">
        <MovablePanelHeader setDragRef={element => setDragRef(element)}>
          <div className="popper-menu-header-section__title">{answer.name}</div>
          <Button onClick={handleClose} ref={doneButtonRef} data-testid="header-close-button" className="!h-7">
            Done
          </Button>
        </MovablePanelHeader>
        <div className="overflow-y-auto">
          <AnswerPanelFactory answer={answer} />
        </div>
      </div>
    </MovablePanel>
  )
}

export default AnswerPanel
