import { Placement } from '@floating-ui/react-dom'
import { Tooltip } from '@packages/sk8/tooltip'
import { QuestionType, QuestionInputType, GroupType, EntityType } from '@packages/types'
import { useTransition } from '@react-spring/web'
import classNames from 'classnames'
import React, { useState } from 'react'

import TypeIcons from 'builder/build/common/components/TypeIcons'
import * as constants from 'common/customizerProducts/constants'
import usePrevious from 'common/hooks/usePrevious'
import font from 'videos/controller/controller.font.webm'
import fontSize from 'videos/controller/controller.fontsize.webm'
import imageSwitch from 'videos/controller/controller.image.webm'
import logo from 'videos/controller/controller.logo.webm'
import outline from 'videos/controller/controller.outlinecolor.webm'
import colorSwitch from 'videos/controller/controller.solidcolor.webm'
import textProduct from 'videos/controller/controller.text.webm'
import textColor from 'videos/controller/controller.textcolor.webm'

import QuestionMenuCard from './QuestionMenuCard'
import VideoPreviewModal from './VideoPreviewModal'

const RESTRICTED_TYPES = constants.questions.restrictedTypesByInputType

interface DisplayType {
  type: QuestionType
  label: string
  preview: string | null
  SecondaryIcon?: React.ComponentType
}

const displayTypes: DisplayType[] = [
  { type: QuestionType.Value, label: 'None', preview: null },
  { type: QuestionType.Image, label: 'Image', preview: imageSwitch },
  { type: QuestionType.Material, label: 'Color', preview: colorSwitch },
  {
    type: QuestionType.Logo,
    label: 'Logo',
    preview: logo,
    SecondaryIcon: () => (
      <Tooltip
        content="Can be added to a print area that will generate a PDF file for each designs"
        autoUpdateOnAnimationFrame
        containerClassName="absolute right-1 top-1 rounded w-6 h-6"
      >
        <TypeIcons entity={{ entityType: EntityType.PrintArea }} />
      </Tooltip>
    ),
  },
  {
    type: QuestionType.Text,
    label: 'Text',
    preview: textProduct,
    SecondaryIcon: () => (
      <Tooltip
        content="Can be added to a print area that will generate a PDF file for each designs"
        autoUpdateOnAnimationFrame
        containerClassName="absolute right-1 top-1 rounded w-6 h-6"
      >
        <TypeIcons entity={{ entityType: EntityType.PrintArea }} />
      </Tooltip>
    ),
  },
  { type: QuestionType.Font, label: 'Font', preview: font },
  { type: QuestionType.FontSize, label: 'Font size', preview: fontSize },
  { type: QuestionType.Color, label: 'Text color', preview: textColor },
  { type: QuestionType.Outline, label: 'Text outline', preview: outline },
]

interface DisplayTypesStepProps {
  selectedQuestionType: QuestionInputType | GroupType | null
  selectedDisplayType: QuestionType | null
  title: string
  onSelect: (type: QuestionType) => void
  gifPlacement: Placement
}

const DisplayTypesStep = ({
  selectedQuestionType,
  selectedDisplayType,
  title,
  onSelect,
  gifPlacement,
}: DisplayTypesStepProps) => {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [hoveredItemPreview, setHoveredItemPreview] = useState<string | null>(null)

  const isGroup = selectedQuestionType === GroupType.Folder || selectedQuestionType === GroupType.BulkOrder

  const items = isGroup
    ? []
    : displayTypes.filter(
        displayType =>
          !selectedQuestionType ||
          !RESTRICTED_TYPES[selectedQuestionType as QuestionInputType]?.includes(displayType.type)
      )

  const previousSelectedQuestionType = usePrevious(selectedQuestionType)
  const previousItemsCount = usePrevious(items.length)

  const numberOfColumns = 3

  const transitions = useTransition(
    items.map((itemProps, i) => {
      return {
        ...itemProps,
        y: Math.floor(i / numberOfColumns) * 52,
        x: (i - Math.floor(i / numberOfColumns) * numberOfColumns) * 172.5,
        opacity: 1,
      }
    }),
    {
      key: (item: DisplayType) => item.type,
      from: { opacity: 0 },
      leave: { opacity: 0 },
      enter: ({ x, y }) => ({ x, y, opacity: 1 }),
      update: ({ x, y, opacity }) => ({ x, y, opacity }),
      delay: isGroup ? 250 : previousItemsCount < items.length ? 125 : 0,
      immediate: previousSelectedQuestionType === GroupType.Folder || CURRENT_ENV === 'test',
    }
  )

  return (
    <div
      className={classNames('new-question-menu__step', {
        'new-question-menu__step--disabled': !selectedQuestionType,
        'new-question-menu__step--hidden': isGroup,
      })}
      style={{ height: isGroup ? 0 : `${Math.ceil(items.length / numberOfColumns) * 52 + 90}px` }}
    >
      <div className="new-question-menu__step-container">
        <span className="new-question-menu__title">{title}</span>
        <div className="new-question-menu__grid" ref={setContainerRef}>
          <VideoPreviewModal
            key={hoveredItemPreview}
            src={hoveredItemPreview}
            containerRef={containerRef}
            placement={gifPlacement}
          />
          {transitions((style, item) => (
            <QuestionMenuCard
              style={{ position: 'absolute', ...style }}
              item={item}
              entityType="DISPLAY_TYPE"
              onSelect={onSelect}
              setHoveredItemPreview={setHoveredItemPreview}
              isSelected={item.type === selectedDisplayType}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default DisplayTypesStep
