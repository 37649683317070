import { InventoryManagementStrategy } from '@packages/types'
import { useFlags } from 'flagsmith/react'
import React from 'react'

import { VariantsTableProps } from './common/useVariantsTable'
import KickflipVariantsTable from './kickflip/KickflipVariantsTable'
import ShopifyVariantsTable from './shopify/ShopifyVariantsTable'

const VariantsTable = (props: VariantsTableProps) => {
  const flags = useFlags(['inventory_management'])

  if (flags.inventory_management.enabled && flags.inventory_management.value === InventoryManagementStrategy.Shopify)
    return <ShopifyVariantsTable {...props} />

  return <KickflipVariantsTable {...props} />
}

export default VariantsTable
