import { useInfiniteTableAddons, useStickyHeader } from '@packages/sk8/table'
import { NormalizedCustomizerProduct } from '@packages/types'
import React, { MutableRefObject, useCallback, useRef } from 'react'

import { InventoryDataTable } from 'builder/variants/types/dataTable'
import useDoubleScrollbar from 'common/hooks/useDoubleScrollbar'
import useScrollRight from 'common/hooks/useScrollRight'

import { FormikVariants } from '../types'

export interface useVariantsTableProps {
  scrollParentRef: MutableRefObject<null | HTMLDivElement>
  variantCount: number
}

export interface VariantsTableProps {
  formik: FormikVariants
  locationId: string
  variantsCombination: string[]
  customizerProduct: NormalizedCustomizerProduct
  onChangeCombinationClick: (e: React.MouseEvent) => void
  scrollParentRef: MutableRefObject<null | HTMLDivElement>
  fetchMore: () => void
  dataTable: InventoryDataTable
  variantIds: string[]
  isFetching: boolean
}

const useVariantsTable = ({ scrollParentRef, variantCount }: useVariantsTableProps) => {
  const cardRef: MutableRefObject<HTMLElement | null> = useRef(null)

  const { StickyHeader, StickyHeaderContainer, setStickyScrollerRef } = useStickyHeader(101)
  const { tableProps, stickyFilterContainerProps, scrollToTopButtonProps } = useInfiniteTableAddons({ scrollParentRef })
  const {
    componentProps: { ref: setComponentRef },
    outerComponentProps,
    innerComponentProps,
  } = useDoubleScrollbar()
  const [notScrolled, fullyScrolled, { ref: setScrollRightRef, ...scrollProps }] = useScrollRight()

  const setHorizontalScrollRef = (el: HTMLDivElement) => {
    setScrollRightRef(el)
    setComponentRef(el)
    setStickyScrollerRef(el)
    cardRef.current = el
  }

  const Scroller = useCallback(
    (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
      <div className="overflow-x-auto w-full">
        <div
          className="overflow-y-hidden"
          {...props}
          {...scrollProps}
          ref={setHorizontalScrollRef}
          style={{ ...props.style, height: variantCount === 0 ? '300px' : props.style?.height }}
        />
      </div>
    ),
    [variantCount === 0]
  )

  return {
    tableProps,
    stickyFilterContainerProps,
    scrollToTopButtonProps,
    StickyHeader,
    StickyHeaderContainer,
    outerComponentProps,
    innerComponentProps,
    notScrolled,
    fullyScrolled,
    Scroller,
  }
}

export default useVariantsTable
