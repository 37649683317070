import { Card } from '@packages/sk8/card'
import { ClearFiltersButton, FilterContainer } from '@packages/sk8/filter'
import { useNavigationBlockingModal } from '@packages/sk8/modal'
import { Table, useInfiniteTableAddons, useStickyHeader } from '@packages/sk8/table'
import { Variant } from '@packages/types'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import { flatten } from 'lodash'
import React, { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { TableVirtuoso } from 'react-virtuoso'

import UnsavedChangesModal from 'common/components/UnsavedChangesModal'
import useBulkSelection from 'common/hooks/useBulkSelection'
import useDoubleScrollbar from 'common/hooks/useDoubleScrollbar'
import useScrollRight from 'common/hooks/useScrollRight'
import type { CombinationFilters, InventoryItemWithStock, VariantIdsByCombination } from 'common/variants/types/variant'

import useInfiniteFormReset from '../../hooks/useInfiniteFormReset'
import FiltersBlankState from '../blankStates/FiltersBlankState'
import type { InventoryDataTable } from '../types/dataTable'
import type { GetVariantsInput } from '../types/queries'
import { getClearAllFilters, getHasNoAppliedFilters, variantsToVirtuosoData } from '../utils'
import InventoryTableFilterList from './InventoryTableFilterList/InventoryTableFilterList'
import InventoryTableFooter from './InventoryTableFooter/InventoryTableFooter'
import useInventoryColumns from './useInventoryColumns'

export interface InventoryTableProps {
  locationId: string
  variants: Variant[]
  loadMore: () => void
  isFetching: boolean
  dataTable: InventoryDataTable
  combinationFiltersData: CombinationFilters
  variantIdsByCombination: VariantIdsByCombination
  getVariantsInput: GetVariantsInput
}

const InventoryTable = ({
  locationId,
  variants,
  combinationFiltersData,
  variantIdsByCombination,
  dataTable,
  loadMore,
  isFetching,
  getVariantsInput,
}: InventoryTableProps) => {
  const [focusedSku, setFocusedSku] = useState<string | undefined>()

  const { tableProps, stickyFilterContainerProps, scrollToTopButtonProps } = useInfiniteTableAddons({
    useWindowScroll: true,
  })

  const { StickyHeader, StickyHeaderContainer, setStickyScrollerRef } = useStickyHeader(101)

  const variantIds = useMemo(() => flatten(variantIdsByCombination.map(({ ids }) => ids)), [variantIdsByCombination])

  const history = useHistory()
  const bulkSelection = useBulkSelection(variantIds)
  const cardRef: MutableRefObject<HTMLElement | null> = useRef(null)

  const formik = useFormikContext<Record<string, InventoryItemWithStock>>()
  const unsavedChangesModal = useNavigationBlockingModal(history, [formik.dirty])

  const [notScrolled, fullyScrolled, { ref: setScrollRightRef, ...scrollProps }] = useScrollRight()
  const columns = useInventoryColumns(locationId, getVariantsInput, notScrolled, fullyScrolled)

  const {
    componentProps: { ref: setComponentRef },
    outerComponentProps,
    innerComponentProps,
  } = useDoubleScrollbar()

  const setHorizontalScrollRef = (el: HTMLDivElement) => {
    setScrollRightRef(el)
    setComponentRef(el)
    setStickyScrollerRef(el)
    cardRef.current = el
  }

  const clearAllFilters = getClearAllFilters(dataTable)

  const FiltersBlankStateWithClearFilters = useCallback(() => {
    return <FiltersBlankState handleClearFiltersClick={clearAllFilters} />
  }, [dataTable.clearFilters])

  useInfiniteFormReset(locationId, variants, formik)

  const [rows] = variantsToVirtuosoData(variants, variantIdsByCombination)

  const hasNoAppliedFilters = getHasNoAppliedFilters(dataTable)

  return (
    <>
      <Card className="w-full flex-col overflow-visible animate-form-show bg-white mb-8">
        <div className="w-full rounded-xl">
          <FilterContainer
            variant="card"
            className={classNames('sticky top-[52px] z-[2] bg-white', {
              'rounded-t-xl': stickyFilterContainerProps.isScrollAtTop,
            })}
          >
            <InventoryTableFilterList
              formik={formik}
              dataTable={dataTable}
              combinationFiltersData={combinationFiltersData}
            />
            <ClearFiltersButton disabled={hasNoAppliedFilters || formik.dirty} onClick={clearAllFilters} />
          </FilterContainer>
          <StickyHeaderContainer />
          <div
            className={classNames('w-full overflow-x-auto rounded-xl', {
              'h-[300px]': variants.length === 0 && !hasNoAppliedFilters,
            })}
            {...scrollProps}
            ref={setHorizontalScrollRef}
          >
            <TableVirtuoso
              {...tableProps}
              useWindowScroll
              totalCount={variants.length}
              endReached={loadMore}
              overscan={200}
              atBottomThreshold={20}
              components={{
                EmptyPlaceholder: FiltersBlankStateWithClearFilters,
                Table: Table,
                TableBody: Table.Body,
                TableRow: Table.Row,
                TableHead: StickyHeader,
              }}
              fixedHeaderContent={() => {
                return (
                  <>
                    <Table.HeaderRow className="bg-white">
                      {columns.map(column => {
                        return (
                          <Table.HeaderCell key={column.key} className={column.className}>
                            <span>{column.title(formik, bulkSelection, variants)}</span>
                          </Table.HeaderCell>
                        )
                      })}
                    </Table.HeaderRow>
                    {isFetching && <Table.Loader colSpan={6} className="absolute z-[2] top-[36px] w-full" />}
                  </>
                )
              }}
              itemContent={index => {
                const rowData = rows[index]

                return columns.map((column, columnIndex) => {
                  return (
                    <Table.Cell
                      className={classNames(column.cellClassName, {
                        'bg-tertiary-green-50': rowData.isNewFromPublish,
                      })}
                      key={`row-${index}-col-${columnIndex}`}
                      onFocus={() =>
                        setFocusedSku(rowData.inventoryItem.sku === '' ? undefined : rowData.inventoryItem.sku)
                      }
                      onBlur={() => setFocusedSku(sku => (sku === rowData.inventoryItem.sku ? undefined : sku))}
                    >
                      {column.render(rowData, formik, bulkSelection, focusedSku)}
                    </Table.Cell>
                  )
                })
              }}
            />
          </div>
        </div>
        <InventoryTableFooter
          scrollToTopButtonProps={scrollToTopButtonProps}
          horizontalScrollProps={{ outerComponentProps, innerComponentProps }}
        />
      </Card>
      {unsavedChangesModal.isVisible && (
        <UnsavedChangesModal
          onLeaveClick={() => {
            unsavedChangesModal.close()
            unsavedChangesModal.forceNavigate()
          }}
          onStayClick={unsavedChangesModal.close}
          {...unsavedChangesModal.modalProps}
        />
      )}
    </>
  )
}

export default InventoryTable
