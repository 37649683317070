import { Button } from '@packages/sk8/button'
import { InputField, RadioGroup } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { CancellationStrategy } from '@packages/types'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import { trpc } from 'common/hooks/trpc'

export interface EditSubscriptionModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
}

type EditSubscriptionFormValues = {
  editionAction: 'restore' | 'cancel'
}

const editionActionOptions = [
  {
    label: 'Continue at the end of this cycle',
    description: "Your subscription won't be cancelled at the end of this billing cycle",
    value: 'restore',
  },
  {
    label: 'Cancel now',
    description: 'Your customizers will be unavailable immediately',
    value: 'cancel',
    className: 'text-error-default',
  },
]

const EditSubscriptionModal = ({ onClose, ...modalProps }: EditSubscriptionModalProps) => {
  const { openToast } = useToast()
  const queryClient = useQueryClient()
  const subscriptionService = useSubscriptionService()
  const { mutate: restoreSubscription } = trpc.billingAndSubscription.restore.useMutation({
    onSuccess: () => openToast('The subscription was successfully updated!', ToastType.success),
    onError: () => openToast('An error occurred while updating the subscription.', ToastType.warning),
  })
  const { mutate: cancelSubscription } = trpc.billingAndSubscription.cancel.useMutation({
    onSuccess: () => openToast('The subscription was successfully cancelled!', ToastType.success),
    onError: () => openToast('An error occurred while cancelling the subscription.', ToastType.warning),
  })

  const formik = useFormik<EditSubscriptionFormValues>({
    initialValues: { editionAction: 'restore' },
    onSubmit: (values, { setSubmitting }) => {
      const options = {
        onSettled: () => setSubmitting(false),
        onSuccess: () => {
          queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
          onClose()
        },
      }

      if (values.editionAction === 'restore') {
        restoreSubscription({}, options)
      } else if (values.editionAction === 'cancel') {
        cancelSubscription({ cancellationStrategy: CancellationStrategy.Cancel }, options)
      }
    },
    enableReinitialize: true,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal {...modalProps} onBackdropClick={handleClose}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.CloseButton onClick={handleClose} />
        <Modal.Title>Editing your subscription?</Modal.Title>

        <Modal.Details className="space-y-4">
          <InputField>
            <RadioGroup
              name="editionAction"
              options={editionActionOptions.map(option => ({
                ...option,
                id: option.value,
                checked: option.value === formik.values.editionAction,
                onChange: e => {
                  formik.handleChange(e)
                  formik.setFieldTouched('editionAction', true, false)
                },
              }))}
            />
          </InputField>
          <div>
            Not sure yet? You can{' '}
            <a
              aria-label="open intercom"
              className="text-primary-600 cursor-pointer"
              onClick={e => {
                e.preventDefault()
                window.Intercom('show')
              }}
            >
              reach our support team
            </a>{' '}
            at any time.
          </div>
        </Modal.Details>
        <Modal.Actions>
          <>
            <Button type="button" variant="default" onClick={() => handleClose()} disabled={formik.isSubmitting}>
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              isLoading={formik.isSubmitting}
              disabled={!formik.isValid || formik.isSubmitting}
            >
              Confirm
            </Button>
          </>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default EditSubscriptionModal
