import { Select } from '@packages/sk8/input'
import PropTypes from 'prop-types'
import React from 'react'
import { components } from 'react-select'

const buildPhrase = (values, options, logicalOperator) => {
  return values
    .map(v => options.find(o => o.value === v))
    .reduce((phrase, current, index) => {
      if (!current) return phrase
      const isLast = current.selectedValue.indexOf(current.value) === current.selectedValue.length - 1
      const beforeLast = current.selectedValue.indexOf(current.value) === current.selectedValue.length - 2

      return [
        ...phrase,
        <span key={`label-${index}`} className={current.readonlyClassName}>
          {current.label}
        </span>,
        ...(isLast ? [] : beforeLast ? [<span key={`operator-${index}`}> {logicalOperator} </span>] : [', ']),
      ]
    }, [])
}

const ReadonlyMultiValues = ({ values, options, className, emptyReadonlyPlaceholder, logicalOperator }) => {
  if (!values) return emptyReadonlyPlaceholder

  const choicesPhrase = buildPhrase(values, options, logicalOperator)

  return (
    <span className={`${className} autoresize-select--readonly-multichoices`}>
      {choicesPhrase || emptyReadonlyPlaceholder}
    </span>
  )
}

ReadonlyMultiValues.propTypes = {
  values: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  logicalOperator: PropTypes.string,
}

export default class AutoresizeSelect extends React.Component {
  static defaultProps = {
    clearable: false,
  }

  constructor(props) {
    super(props)

    this.autoresizeSelectRef = React.createRef()

    this.placeholder = React.createRef()
    this.selectValue = React.createRef()
    this.selectValueContainer = React.createRef()
    this.renderSingleValue = this.renderSingleValue.bind(this)
    this.renderValueContainer = this.renderValueContainer.bind(this)
  }

  renderSingleValue(props) {
    return (
      <div
        className="single-value select-value select__single-value"
        style={props.getStyles('singleValue', props)}
        ref={this.selectValue}
      >
        {props.children}
      </div>
    )
  }

  renderValueContainer({ children, ...props }) {
    return (
      <components.ValueContainer {...props}>
        <components.Placeholder {...props}>{props.selectProps.placeholder}</components.Placeholder>

        <span className="value-wrapper" ref={this.selectValueContainer}>
          {React.Children.map(children, child => {
            return child && child.type !== components.Placeholder ? child : null
          })}
        </span>
      </components.ValueContainer>
    )
  }

  render() {
    const {
      readonly,
      className,
      value,
      options,
      onChange,
      placeholder,
      defaultValue,
      isMulti,
      emptyReadonlyPlaceholder,
      logicalOperator,
      styles,
      ...rest
    } = this.props

    const extraClassName = className || ''

    if (readonly && isMulti) {
      return (
        <ReadonlyMultiValues
          className={extraClassName}
          values={Array.isArray(value) ? value : []}
          options={options}
          emptyReadonlyPlaceholder={emptyReadonlyPlaceholder}
          logicalOperator={logicalOperator}
        />
      )
    } else if (readonly) {
      let label = null
      let readonlyClassName = ''
      if (value?.value) {
        label = value.value
      } else {
        const option = options.find(o => o.value === value)
        option && (label = option.label)
        option && (readonlyClassName = option.readonlyClassName)
      }

      return (
        <div className={`autoresize-select--readonly ${extraClassName} ${readonlyClassName}`}>
          {label ? label : emptyReadonlyPlaceholder}
        </div>
      )
    }

    const valueFromOptions = Array.isArray(value)
      ? options.filter(o => value.indexOf(o.value) !== -1)
      : value != null
        ? options.find(o =>
            o.options
              ? o.options.find(nestedOption => nestedOption.value === value.value || nestedOption.value === value)
              : o.value === value.value || o.value === value
          ) || ''
        : ''

    return (
      <Select
        className={this.props.className}
        innerRef={this.autoresizeSelectRef}
        isMulti={isMulti}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        value={valueFromOptions}
        defaultValue={defaultValue || ''}
        disabled={readonly}
        isMenuOpen
        isClearable={this.props.isClearable || false}
        arrowRenderer={null}
        autosize
        menuPortalTarget={document.body}
        open
        menuPlacement="auto"
        styles={styles}
        {...rest}
      />
    )
  }
}

AutoresizeSelect.propTypes = {
  readonly: PropTypes.bool,
  isMulti: PropTypes.bool,
  floatingPlaceholder: PropTypes.bool,
  className: PropTypes.string,
  emptyReadonlyPlaceholder: PropTypes.node,
  logicalOperator: PropTypes.string,
}

AutoresizeSelect.defaultProps = {
  autoresize: false,
  readonly: false,
  isMulti: false,
  floatingPlaceholder: true,
  logicalOperator: 'or',
}
