import { BoxTransformer } from '@packages/sk8/editor'
import { Part } from '@packages/types'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import type { TextOnPathNode } from 'customizer/2dDisplayer/types/node'

import { applyBezierTextEdition, applyPrintAreaBezierTextEdition } from '../../actions'
import TargetClipContainer from '../containers/TargetClipContainer'
import SingleLineText from '../nodes/SingleLineText'
import NodeInfo from './NodeInfo'
import { EditorProps } from './types'
import usePathEditor from './usePathEditor'

export interface SingleLineTextEditorProps {
  node: TextOnPathNode
  part: Part
  currentView: number
}

const SingleLineTextEditor = ({ node, entity, currentView }: EditorProps<TextOnPathNode>) => {
  const { targetRef, getTransformedBezier, onDrag, onResize, onRotate, bezier, scale } = usePathEditor({
    bezier: node.position.bezier,
    scale: node.scale,
  })

  const dispatch = useDispatch()

  const handleTransformEnd = () => {
    const bezier = getTransformedBezier()
    entity && 'printArea' in entity && entity.printArea
      ? dispatch(applyPrintAreaBezierTextEdition(entity.id, bezier))
      : dispatch(applyBezierTextEdition(entity.id, bezier, currentView))
  }

  return (
    <>
      <TargetClipContainer>
        <SingleLineText
          targetRef={targetRef}
          bezier={bezier}
          fontFamily={node.font.family}
          fontAssets={node.font.assets}
          fontType={node.font.fontType}
          color={node.color.hex}
          text={node.text.value}
          textAlign={node.position.textAlign as 'left' | 'center' | 'right'}
          outlineColor={node.outline?.hex}
          outlineWidth={node.outline?.width ? parseFloat(node.outline.width) * scale : 0}
          fontSize={parseFloat(node.font.size) * scale}
        />
      </TargetClipContainer>
      <BoxTransformer
        target={targetRef}
        renderDirections={['e', 'w']}
        onDrag={onDrag}
        onRotate={onRotate}
        onResize={onResize}
        onTransformEnd={handleTransformEnd}
        info={<NodeInfo entity={entity} node={node} />}
      />
    </>
  )
}

export default SingleLineTextEditor
