import { Checkbox } from '@packages/sk8/input'
import { Table } from '@packages/sk8/table'
import { NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import React, { useMemo } from 'react'

import { BulkSelection } from 'common/hooks/useBulkSelection'

import { FormikVariants, VariantFormValue } from '../types'

export type ColumnDef = {
  key: string
  title: (
    formik: FormikVariants,
    bulkSelection: BulkSelection,
    customizerProduct: NormalizedCustomizerProduct,
    filteredVariants: VariantFormValue[]
  ) => React.ReactNode
  style?: React.CSSProperties
  className?: string
  cellClassName?: string
  render: (
    variant: VariantFormValue,
    formik: FormikVariants,
    bulkSelection: BulkSelection,
    customizerProduct: NormalizedCustomizerProduct,
    focusedSku?: string
  ) => React.ReactNode
  isSortable?: boolean
}

const useCommonVariantsColumns = (
  locationId: string,
  variantsCombination: string[],
  notScrolled: boolean,
  fullyScrolled: boolean,
  BulkPopover: React.FC<{ formik: FormikVariants; locationId: string; bulkSelection: BulkSelection }>
) => {
  return useMemo((): ColumnDef[] => {
    return [
      {
        key: 'selected',
        title: (formik, bulkSelection, _customizerProduct, filteredVariants) => {
          return (
            <Table.BulkSelectionHeader bulkSelection={bulkSelection} disabled={filteredVariants.length === 0}>
              <BulkPopover formik={formik} locationId={locationId} bulkSelection={bulkSelection} />
            </Table.BulkSelectionHeader>
          )
        },
        style: {},
        className: classNames('pl-6 w-[58px] min-w-[58px] sticky left-0 bg-white', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:right-0 after:h-full before:h-full before:top-0 before:right-0 before:w-2 before:absolute before:shadow-[6px_0px_12px_rgba(0,0,0,0.05)]':
            notScrolled !== true,
        }),
        cellClassName: classNames('pl-6 sticky left-0 bg-white h-[41px]', {
          'after:absolute after:bg-neutral-100 after:w-[1px] after:bottom-0 after:right-0 after:h-full before:h-full before:top-0 before:right-0 before:w-2 before:absolute before:shadow-[6px_0px_12px_rgba(0,0,0,0.05)]':
            notScrolled !== true,
        }),
        render: (variant, _formik, bulkSelection, _customizerProduct) => {
          return (
            <Checkbox
              aria-label="Select row"
              tabIndex={-1}
              checked={bulkSelection.isSelected(variant.id)}
              onChange={e => {
                if (e.target.checked) return bulkSelection.select([variant.id])

                bulkSelection.deselect([variant.id])
              }}
            />
          )
        },
      },
      ...variantsCombination.map(
        (questionId): ColumnDef => ({
          key: questionId,
          title: (_formik, _bulkSelection, customizerProduct) => customizerProduct.questions[questionId].name,
          className: 'w-[180px] min-w-[180px] max-w-[180px] whitespace-nowrap overflow-hidden text-ellipsis',
          cellClassName: 'max-w-[180px] overflow-hidden whitespace-nowrap text-ellipsis',
          render: (variant, _formik, _bulkSelection, customizerProduct) => {
            const answerRef = variant.answersRefs.find(answerRef => answerRef.questionId === questionId)

            if (!answerRef) return <Table.EmptyCellIndicator />

            return (
              <span title={customizerProduct.answers[answerRef.answerId].name}>
                {customizerProduct.answers[answerRef.answerId].name}
              </span>
            )
          },
        })
      ),
      { key: 'spacing', title: () => '', className: '!p-0', cellClassName: '!p-0', render: () => null },
    ]
  }, [locationId, variantsCombination, notScrolled, fullyScrolled])
}

export default useCommonVariantsColumns
