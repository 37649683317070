import { Button, IconButton } from '@packages/sk8/button'
import { Modal, useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import { ToastType, useToast } from '@packages/sk8/toast'
import { ActivityLogType, DenormalizedQuestion, Part, QuestionType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { groupParentExceptRootSelector } from 'builder/build/customizerProducts/selectors'
import { deletePartAndMainQuestion, duplicatePart } from 'builder/build/parts/actions'
import { getPartEntitiesId } from 'builder/build/parts/utils'
import { deleteQuestionAndReplaceWithDefaultAnswer, duplicateQuestion } from 'builder/build/questions/actions'
import { useSelector, useDispatch } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'
import { trpc } from 'common/hooks/trpc'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import CarretRight from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'

import {
  moveNextTo,
  moveBehindTheScene,
  moveToQuestionPanel,
  addChild,
  AddInputTypeNoneToSceneException,
  AddLinkedQuestionToSceneException,
} from '../../actions'
import { bulkOrderGroupSelector, parentSelector } from '../../selectors'
import { inputTypeNoneWarning, linkedQuestionWarning } from '../ToastTexts'

export interface QuestionActionsPopoverProps {
  question: DenormalizedQuestion
  part?: Part
  isMainQuestion: boolean
}

const QuestionActionsPopover = ({ question, part, isMainQuestion }: QuestionActionsPopoverProps) => {
  const history = useHistory()
  const match = useRouteMatch<{ questionId?: string; id?: string }>()

  const parent = useSelector(state => parentSelector(state, question.id))
  const dispatch = useDispatch()
  const dropdownButton = usePopover({ placement: 'bottom-start' })
  const duplicateSubMenu = usePopover({ placement: 'right-start', trigger: 'hover' })
  const deleteQuestionModal = useModal()
  const { openToast } = useToast()
  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()
  const bulkOrderGroup = useSelector(bulkOrderGroupSelector)

  const isBehindTheScene = parent == null

  const handleDelete = () => {
    deleteQuestionModal.close()
    dispatch((dispatch, getState) => {
      const deletedEntityIds = isMainQuestion ? getPartEntitiesId(part!) : [question.id]
      const pathEntityId = (match.params?.questionId || match.params?.id)!

      if (deletedEntityIds.includes(pathEntityId)) {
        if (isMainQuestion || question.type === QuestionType.Value) {
          const parent = groupParentExceptRootSelector(getState(), question.id)
          history.push(parent?.id ? `/groups/${parent.id}` : '/')
        } else {
          const mainQuestionId = part?.[constants.parts.mainQuestionFields[part?.type]]
          if (mainQuestionId) history.push(`/questions/${mainQuestionId}`)
        }
      }

      setTimeout(() => {
        if (isMainQuestion) {
          dispatch(deletePartAndMainQuestion(part!.id))
        } else {
          dispatch(deleteQuestionAndReplaceWithDefaultAnswer(question))
        }
      }, 500)
    })
  }

  const handleDeleteClick = () => {
    dropdownButton.close()
    deleteQuestionModal.open()
  }

  const handleDuplicateClick = (shouldCopyAnswers: boolean) => {
    const newQuestionId = isMainQuestion
      ? dispatch(duplicatePart(part!.id, shouldCopyAnswers))
      : dispatch(duplicateQuestion(question.id, shouldCopyAnswers))

    if (!isBehindTheScene) dispatch(moveNextTo(newQuestionId, question.id))

    createActivityLog({ type: ActivityLogType.CreateQuestion })
    history.push(`/questions/${newQuestionId}`)
    duplicateSubMenu.close()
    dropdownButton.close()
  }

  const handleMoveBehindTheSchene = () => dispatch(moveBehindTheScene(question.id))

  const handleMoveToQuestionPanel = () => {
    try {
      dispatch(moveToQuestionPanel(question.id))
    } catch (error) {
      if (error instanceof AddInputTypeNoneToSceneException) {
        openToast(inputTypeNoneWarning, ToastType.warning)
      } else if (error instanceof AddLinkedQuestionToSceneException) {
        openToast(linkedQuestionWarning, ToastType.warning)
      } else {
        openToast('Unable to move the item to the question panel', ToastType.warning)
      }
    }
  }

  return (
    <>
      <div
        className={classNames(
          'ml-auto h-full items-center flex-shrink-0 flex-auto flex group-hover/item:scale-x-100 group-hover/item:w-auto group-hover/item group-hover/item:visible',
          {
            'w-0 scale-x-0 invisible': !dropdownButton.isOpen,
          }
        )}
      >
        <IconButton
          aria-label={`${question.name} actions`}
          variant="subtle"
          small
          Icon={MoreIcon}
          {...dropdownButton.referenceProps}
        />
        <Popover {...dropdownButton.floatingProps} isOpen={dropdownButton.isOpen}>
          {isBehindTheScene ? (
            <Popover.Action key="move-to-question-panel" onClick={handleMoveToQuestionPanel}>
              Move to Question panel
            </Popover.Action>
          ) : (
            <Popover.Action key="move-behind-the-scene" onClick={handleMoveBehindTheSchene}>
              Move behind the scene
            </Popover.Action>
          )}
          <Popover.Action className="flex items-center justify-between" {...duplicateSubMenu.referenceProps}>
            <span>Duplicate</span>
            <CarretRight className="w-2 fill-current text-current" />
            <Popover {...duplicateSubMenu.floatingProps} isOpen={duplicateSubMenu.isOpen}>
              <Popover.Action className="flex flex-col" onClick={() => handleDuplicateClick(false)}>
                <span>Import answers</span>
                <span className="text-xs text-neutral-600">Link the answers to the originals</span>
              </Popover.Action>
              <Popover.Action className="flex flex-col" onClick={() => handleDuplicateClick(true)}>
                <span>Copy answers</span>
                <span className="text-xs text-neutral-600">Separate the answers from the originals</span>
              </Popover.Action>
            </Popover>
          </Popover.Action>
          {bulkOrderGroup && !bulkOrderGroup.children.includes(question.id) && (
            <Popover.Action
              key="add-to-bulk"
              onClick={() => {
                dropdownButton.close()
                dispatch(addChild(bulkOrderGroup.id, question.id, bulkOrderGroup.children.length))
              }}
            >
              Add to bulk order
            </Popover.Action>
          )}
          <Popover.Action key="delete" className="text-tertiary-red-700" onClick={handleDeleteClick}>
            Delete
          </Popover.Action>
        </Popover>
      </div>
      {deleteQuestionModal.isVisible && (
        <Modal
          onBackdropClick={deleteQuestionModal.close}
          {...deleteQuestionModal.modalProps}
          aria-label="Delete question"
        >
          <Modal.CloseButton onClick={deleteQuestionModal.close} />
          <Modal.Title>Delete {question.name}</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete the question {question.name}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button type="button" variant="default" className="px-4" onClick={deleteQuestionModal.close}>
              Cancel
            </Button>
            <Button type="button" variant="error" className="px-4" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default QuestionActionsPopover
