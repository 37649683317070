import { Button, IconButton } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { Price } from '@packages/sk8/price'
import { Tag } from '@packages/sk8/tag'
import { Design, DesignType, ECommerce } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

import DesignCardAssetsSection from 'cms/designs/components/DesignCardAssetsSection'
import DesignCardSummarySection from 'cms/designs/components/DesignCardSummarySection'
import Header from 'cms/layout/Header'
import Page from 'cms/layout/page/Page'
import SideMenu from 'cms/layout/SideMenu'
import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import { defaultPersistenceState } from 'cms/orders/utils'
import PrintText from 'icons/bold/01-Interface Essential/30-Print/print-picture.svg'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import RightArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import classMerge from 'utils/classMerge'
import { formatFullDate } from 'utils/dateUtils'

import { useFetchOrder } from './../hooks/useFetchOrder'
import type { DenormalizedOrderItem, OrderItem } from './../types/order'
import OrderCardFooter from './OrderCardFooter'
import OrderCardHeader from './OrderCardHeader'
import { PAYMENT_STATUS_FILTERS, STATUS_FILTERS, TEST_FILTER } from './OrdersFilterList'

const Order = () => {
  const { id: orderId, brandName } = useParams<{ id: string; brandName?: string }>()
  const location = useLocation<{ persistence?: typeof defaultPersistenceState }>()
  const history = useHistory()
  const baseUrl = brandName ? `/brands/${brandName}` : ''
  const persistence = location.state?.persistence
  const onlineStoreService = useOnlineStoreService()

  const { order, designs, handleGoBack, isLoadingOrders, isFetchingOrders, orderSiblings, isLoadingDesigns } =
    useFetchOrder({ persistence, orderId, baseUrl })

  const { data: onlineStore } = useQuery(
    [...onlineStoreService.fetch.queryKeys, order?.store],
    () => onlineStoreService.fetch(order!.store),
    { enabled: !!order?.store }
  )

  const status = STATUS_FILTERS.find(filter => filter.name === order?.status)
  const paymentStatus = PAYMENT_STATUS_FILTERS.find(filter => filter.name === order?.paymentStatus)

  const orderItems = order?.items || ([] as Array<DenormalizedOrderItem | OrderItem>)
  const totalQuantity =
    orderItems.reduce((total, item) => {
      const itemQuantity = item.quantity - (item.cancelled || 0)
      const designId = typeof item.design === 'string' ? `${item.design}` : `${item.design.id}`
      const design: Design | undefined = designs?.find(design => design.id === designId)

      if (design?.type === DesignType.BulkOrderDesign) {
        const bulkOrderQuantity = design.designs.reduce((quantity, design) => design.quantity + quantity, 0)
        return total + itemQuantity * bulkOrderQuantity
      }

      return total + itemQuantity
    }, 0) || 0

  return (
    <main>
      <Header />
      <SideMenu />
      <Page>
        <Page.Header>
          <div className="flex">
            <IconButton Icon={LeftArrow} onClick={handleGoBack} className="print:hidden mr-2" aria-label="Go back" />
            <h1>
              {!isLoadingOrders && (
                <div className="flex-col">
                  <div className="flex items-center mb-2">
                    <div className="pl-2 pr-3 border-neutral-100 flex items-center">
                      {`Order #${order?.orderId}`}
                      {order?.isTest && (
                        <span className="ml-3 pt-0 flex">
                          <Tag className={classNames(TEST_FILTER[0]?.className)}>Test</Tag>
                        </span>
                      )}
                    </div>
                    <div className="pl-3 pr-3 border-r border-l border-neutral-100 text-sm font-medium text-neutral-600">
                      {totalQuantity} item(s)
                    </div>

                    {!!order?.totalPrice && (
                      <>
                        <div className="text-sm font-medium text-neutral-600 ml-3">Total :</div>
                        <Price
                          amount={order?.totalPrice}
                          currency={order?.currency ?? 'USD'}
                          className="font-medium text-sm pl-1"
                        />
                      </>
                    )}
                    <Tag className={classNames(paymentStatus?.className, 'ml-3 mx-1')}>{paymentStatus?.text}</Tag>
                    <Tag className={classNames(status?.className, 'mx-1')}>{status?.text}</Tag>
                  </div>
                  <div className="flex divide-x divide-neutral-100">
                    {order?.eCommerceOrderId && onlineStore && onlineStore?.eCommerce !== ECommerce.CustomStore && (
                      <div className="text-xs font-medium text-neutral-600 px-3">
                        Ecommerce order&nbsp;
                        {onlineStore?.eCommerce === ECommerce.Shopify && (
                          <a
                            className="text-primary-600"
                            href={`https://${onlineStore?.domain}/admin/orders/${order!.eCommerceOrderId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {order.eCommerceOrderName || order.eCommerceOrderId}
                          </a>
                        )}
                        {onlineStore?.eCommerce !== ECommerce.Shopify && (
                          <>{order.eCommerceOrderName || order.eCommerceOrderId}</>
                        )}
                      </div>
                    )}
                    {order?.date && (
                      <div className="text-xs font-medium text-neutral-600 px-3">{formatFullDate(order.date)}</div>
                    )}
                  </div>
                </div>
              )}
              {isLoadingOrders && <div className="animate-pulse h-8 bg-neutral-75 rounded max-w-xs w-64" />}
            </h1>
          </div>

          <div className="flex">
            <Button
              icon={<PrintText className="w-4 h-4" />}
              variant="default"
              className="mx-3 print:hidden"
              onClick={window.print}
            >
              Print order
            </Button>
            {!!persistence && (
              <div className="print:hidden flex flex-[2] pl-2 border-l border-neutral-100">
                <IconButton
                  Icon={LeftArrow}
                  variant="default"
                  className="mx-1 print:hidden"
                  onClick={() =>
                    orderSiblings.previous &&
                    history.replace(`${baseUrl}/orders/${orderSiblings.previous.id}`, location.state)
                  }
                  disabled={isFetchingOrders || !orderSiblings.previous}
                  isLoading={isFetchingOrders}
                  aria-label="previous"
                />
                <IconButton
                  Icon={RightArrow}
                  variant="default"
                  className="mx-1 print:hidden"
                  onClick={() =>
                    orderSiblings.next && history.replace(`${baseUrl}/orders/${orderSiblings.next.id}`, location.state)
                  }
                  disabled={isFetchingOrders || !orderSiblings.next}
                  isLoading={isFetchingOrders}
                  aria-label="next"
                />
              </div>
            )}
          </div>
        </Page.Header>
        <div className="block" aria-label="design section">
          {order && (
            <Card className="w-full flex flex-col flex-1 print:shadow-[none] print:block">
              {order.items.map((item, _id, items) => {
                const designId = typeof item.design === 'string' ? `${item.design}` : `${item.design.id}`
                const design: Design | undefined = designs?.find(design => design.id === designId)

                if (design?.type === DesignType.BulkOrderDesign) {
                  const bulkDesigns = design.designs
                  return (
                    <>
                      {bulkDesigns.map(({ design, quantity }, index) => (
                        <div
                          key={design.id}
                          className={classMerge(
                            'print:clear-both print:border print:border-neutral-100 print:rounded-xl',
                            { 'print:break-after-page': items.length - 1 != _id || bulkDesigns.length - 1 != index }
                          )}
                        >
                          <OrderCardHeader
                            design={design}
                            isLoading={isLoadingDesigns}
                            isMultiDesign={totalQuantity > 1}
                            quantity={(item.quantity - (item.cancelled || 0)) * quantity}
                            currency={order.currency ?? 'USD'}
                            discounts={item.discounts}
                            orderTotal={order.totalPrice}
                          />

                          <Card.Separator />

                          <div className="flex flex-row print:flex-col">
                            <DesignCardAssetsSection designId={design.id} />
                            <DesignCardSummarySection design={design} isLoading={isLoadingDesigns} />
                          </div>
                        </div>
                      ))}
                    </>
                  )
                }

                return (
                  <div
                    key={designId}
                    className={classMerge('print:clear-both print:border print:border-neutral-100 print:rounded-xl', {
                      'print:break-after-page': items.length - 1 != _id,
                    })}
                  >
                    <OrderCardHeader
                      design={design}
                      isLoading={isLoadingDesigns}
                      isMultiDesign={items.length > 1}
                      quantity={item.quantity - (item.cancelled || 0)}
                      currency={order?.currency ?? 'USD'}
                      discounts={item.discounts}
                      orderTotal={order?.totalPrice ?? 0}
                    />
                    <Card.Separator />
                    <div className="flex flex-row print:flex-col">
                      <DesignCardAssetsSection designId={designId} />
                      <DesignCardSummarySection design={design} isLoading={isLoadingDesigns} />
                    </div>
                  </div>
                )
              })}
              {order && order.items.length > 1 && (
                <>
                  <Card.Separator className="print:hidden" />
                  <OrderCardFooter
                    totalPrice={order.totalPrice}
                    currency={order.currency ?? 'USD'}
                    isLoading={isLoadingOrders}
                  />
                </>
              )}
            </Card>
          )}
        </div>
      </Page>
    </main>
  )
}

export default Order
