import { Card } from '@packages/sk8/card'
import { FullPageModal, Modal } from '@packages/sk8/modal'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'

import ActivateOrChangePlanCard from '../cards/ActivateOrChangePlanCard'
import SubscriptionCheckList from '../SubscriptionCheckList'
import SubscriptionQuestionAndAnswer from '../SubscriptionQuestionAndAnswer'
import useSubscriptionService from './../../hooks/useSubscriptionService'
import AddonsList from './../cards/AddonsList'
import PlanCard from './../cards/PlanCard'
import { adminTools, customizationExperiencesAdvantages, questionAnswerTuples } from './subscriptionPageData'

interface FullPageBillingPlansProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
}

const ActivateCustomPlanModal = ({ onClose, ...modalProps }: FullPageBillingPlansProps) => {
  const subscriptionService = useSubscriptionService()
  const onlineStoreService = useOnlineStoreService()

  const { data: subscription, refetch: refetchSubscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne
  )
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })

  const handleClose = () => {
    refetchSubscription()
    onClose()
  }

  if (!subscription || !onlineStores) return null

  return (
    <FullPageModal className="flex" onClose={handleClose} {...modalProps}>
      <div className="flex max-w-4xl items-start w-[410px] md:w-auto">
        <div className="flex-col">
          <div className="flex items-start space-x-6 mt-4">
            <div className="w-full flex flex-col">
              <div className="w-full items-center md:items-start md:flex-row flex md:space-x-6 md:space-y-0 flex-col space-y-6">
                <PlanCard
                  plan={subscription.plan}
                  addonsSubscribed={[]}
                  addons={
                    <AddonsList availableAddons={[]} subscriptionAddons={[]} onAdd={() => {}} onRemove={() => {}} />
                  }
                />
                <div className="basis-1/2 shrink-0">
                  <ActivateOrChangePlanCard
                    onlineStores={onlineStores}
                    plan={subscription.plan}
                    subscription={subscription}
                    addons={[]}
                    onWixSuccess={handleClose}
                    onStripeActivateSuccess={handleClose}
                    onStripeChangePlanSuccess={handleClose}
                  />
                  <div className="flex flex-col space-y-3 pt-8 px-4 font-p2">
                    <span>* All prices are in US dollars</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-8 space-y-16">
            <Card shadow={false} className="flex-col border border-neutral-100 w-full">
              <Card.Section>
                <h1>What's included</h1>
              </Card.Section>
              <Card.Section className="pt-2">
                <div className="grid grid-cols-2 gap-12 w-full">
                  <SubscriptionCheckList
                    list={customizationExperiencesAdvantages.checklist}
                    title={customizationExperiencesAdvantages.title}
                  />
                  <SubscriptionCheckList list={adminTools.checklist} title={adminTools.title} />
                </div>
              </Card.Section>
            </Card>
            <div>
              <h1 className="pb-5">Frequently asked questions</h1>
              <div className="grid grid-cols-2 gap-12 pb-20">
                {questionAnswerTuples.map((item, index) => {
                  return <SubscriptionQuestionAndAnswer key={index} question={item.question} answer={item.answer} />
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullPageModal>
  )
}

export default ActivateCustomPlanModal
