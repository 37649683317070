import React from 'react'

import classMerge from '../../utils/classMerge'

export interface ProgressBarProps {
  containerClassName?: string
  className?: string
  percentage: number
  onClick?: () => void
}

const ProgressBar = ({ containerClassName, className, percentage, onClick }: ProgressBarProps) => (
  <div
    className={classMerge('w-full rounded-full h-2 my-4 bg-neutral-100', containerClassName)}
    role="progressbar"
    onClick={onClick}
  >
    <div
      className={classMerge('bg-secondary-orange-200 rounded-full h-2', className)}
      style={{ width: `${percentage}%` }}
    ></div>
  </div>
)

export default ProgressBar
