import { Question, Group, DenormalizedGroup, DenormalizedQuestion, EntityType, PrintArea, Part } from '@packages/types'
import React from 'react'

import TypeIcon from './TypeIcon'

export interface TypeIconsProps {
  entity: Partial<Question | Group | Part | DenormalizedQuestion | DenormalizedGroup | PrintArea>
}

const TypeIcons = ({ entity }: TypeIconsProps) => {
  if (entity.entityType === EntityType.Question)
    return (
      <div className="flex group/icons">
        <TypeIcon
          entityType={EntityType.Question}
          specificType={entity.inputType}
          className="rounded-[4px] h-5 flex items-center overflow-hidden w-[5px] py-1 px-0 group-hover/icons:w-5 group-hover/icons:p-1 mr-1 transition-all"
          iconClassName="!min-w-[0.75rem] -translate-x-full opacity-0 group-hover/icons:opacity-100 group-hover/icons:translate-x-0 transition-all"
        />
        <TypeIcon entityType="DISPLAY_TYPE" specificType={entity.type} className="w-5 h-5 p-1 rounded-[4px]" />
      </div>
    )

  if (entity.entityType === EntityType.Group)
    return (
      <div className="flex">
        <TypeIcon entityType={EntityType.Group} specificType={entity.type} className="w-5 h-5 p-1 rounded-[4px]" />
      </div>
    )

  if (entity.entityType === EntityType.Part)
    return (
      <div className="flex">
        <TypeIcon entityType="DISPLAY_TYPE" specificType={entity.type} className="w-5 h-5 p-1 rounded-[4px]" />
      </div>
    )

  return (
    <div className="flex">
      <TypeIcon entityType={EntityType.PrintArea} className="w-5 h-5 p-1 rounded-[4px]" />
    </div>
  )
}

export default TypeIcons
