import { Asset, Answer, supportedImageExtensions } from '@packages/types'
import React from 'react'

import ActivatableTextInput from 'builder/build/common/components/ActivatableTextInput'
import FileUploader from 'common/assets/components/FileUploader'

type GeneralAnswerFields = keyof Pick<Answer, 'hasCode' | 'code' | 'showDescription' | 'description' | 'thumbnail'>

interface GeneralSettingsSection {
  answer: Answer
  onUpdate: <T extends GeneralAnswerFields>(field: T, value: Answer[T]) => void
}

const GeneralSettingsSection = ({ answer, onUpdate }: GeneralSettingsSection) => {
  return (
    <>
      <div className="relative px-4 py-6">
        <div className="flex flex-col space-y-4">
          <div className="font-medium">Thumbnail</div>
          <FileUploader
            file={answer.thumbnail}
            dropText="Drop image, or"
            onChange={value => onUpdate('thumbnail', value as Asset | null)}
            extensions={supportedImageExtensions}
          />
        </div>
      </div>
      <hr className="border-neutral-100" />
      <div className="relative px-4 py-6">
        <ActivatableTextInput
          isRichText
          id="text-description"
          title="Description"
          value={answer.description}
          isActive={answer.showDescription}
          onChange={e => onUpdate('description', e.target.value)}
          titleClassName="font-medium"
          onActivationChange={checked => onUpdate('showDescription', checked)}
          textarea
        />
      </div>
      <hr className="border-neutral-100" />
      <div className="relative px-4 py-6">
        <ActivatableTextInput
          title="Production code"
          id="production-code"
          value={answer.code || ''}
          isActive={!!answer.hasCode}
          titleClassName="font-medium"
          onChange={e => onUpdate('code', e.target.value)}
          onActivationChange={checked => onUpdate('hasCode', checked)}
        />
      </div>
    </>
  )
}

export default GeneralSettingsSection
