import { Button, IconButton } from '@packages/sk8/button'
import { CheckboxFilter } from '@packages/sk8/filter'
import { Checkbox, Input, InputField } from '@packages/sk8/input'
import { useModal } from '@packages/sk8/modal'
import { Tabs } from '@packages/sk8/tab'
import { Table } from '@packages/sk8/table'
import { Tag } from '@packages/sk8/tag'
import { Tooltip } from '@packages/sk8/tooltip'
import { Equation, EquationLineType, NormalizedCustomizerProduct, QuestionInputType } from '@packages/types'
import { FieldArrayRenderProps, useFormikContext } from 'formik'
import { findLastIndex, get, uniq } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { default as useQuestionsAnswersFilters } from 'builder/common/hooks/useQuestionsAnswersFilters'
import useBulkSelection from 'common/hooks/useBulkSelection'
import AlertIcon from 'icons/bold/01-Interface Essential/14-Alerts/alert-circle.svg'
import EditIcon from 'icons/bold/01-Interface Essential/22-Edit/pencil-write-alternate.svg'
import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import Remove from 'icons/bold/01-Interface Essential/43-Remove-Add/remove.svg'
import EqualIcon from 'icons/custom/equals.svg'
import EquationIcon from 'icons/custom/equation.svg'
import ExtraPriceIcon from 'icons/custom/extraprice.svg'
import OperatorAddIcon from 'icons/custom/operation-add.svg'
import TrashIcon from 'icons/regular/01-Interface Essential/23-Delete/bin.svg'

import { ExtraPrice, PricingFormValues } from '../types'
import AddExtraPricePopover from './AddExtraPricePopover'
import BulkExtraPriceButton from './BulkExtraPriceButton'
import DeleteAdditionnalPricingModal from './DeleteAdditionnalPricingModal'
import EmptyAdditionnalPricing from './EmptyAdditionnalPricing'
import EquationModal from './equation/Modal'
import { operatorOptions } from './equation/utils'

export interface AdditionalPricingProps {
  name: string
  currenciesInputProps: { leftAddon?: string; rightAddon?: string }
  customizerProduct: NormalizedCustomizerProduct
  extraPricesFieldArrayHelpers: FieldArrayRenderProps
  equationsFieldArrayHelpers: FieldArrayRenderProps
}

const AdditionalPricing = ({
  name,
  currenciesInputProps,
  customizerProduct,
  extraPricesFieldArrayHelpers,
  equationsFieldArrayHelpers,
}: AdditionalPricingProps) => {
  const { values, errors, handleBlur, handleChange } = useFormikContext<PricingFormValues>()
  const [tab, setTab] = useState('extraprices')

  const deleteModal = useModal()
  const [deleteModalIndex, setDeleteModalIndex] = useState<number>()

  const equationModal = useModal()
  const [equationBaseData, setEquationBaseData] = useState<Equation | undefined>()

  const isExtraPriceShared = (extraPrice: ExtraPrice) => {
    return values.equations.some(equation => equation.lines.some(line => line.answersMap?.[extraPrice.answerId]))
  }

  const isEquationShared = (equation: Equation) => {
    return values.extraPrices.some(extraPrice => equation.lines.some(line => line.answersMap?.[extraPrice.answerId]))
  }

  const isExtraPriceInvalid = (extraPrice?: ExtraPrice) => {
    if (extraPrice == null) return false
    return !customizerProduct.questions[extraPrice.questionId]?.answers.includes(extraPrice.answerId)
  }

  const isEquationInvalid = (equation?: Equation) => {
    if (equation == null) return false

    return equation.lines.some(line => {
      if (line.type === EquationLineType.Constant) return false
      if (!customizerProduct.questions[line.value]) return true
      if (line.answersMap) {
        const { answers } = customizerProduct.questions[line.value]
        return Object.keys(line.answersMap).some(answerId => !answers.includes(answerId))
      }
      return false
    })
  }

  const addExtraPrice = ({ questionId, answerId, price }: ExtraPrice) => {
    const index = findLastIndex(values.extraPrices, (extraPrice: ExtraPrice) => questionId === extraPrice.questionId)

    extraPricesFieldArrayHelpers.insert(index >= 0 ? index : values.extraPrices.length, { questionId, answerId, price })
  }

  const saveEquation = (eq: Equation) => {
    if (equationBaseData) {
      const index = values.equations.findIndex(equation => equation === equationBaseData)
      equationsFieldArrayHelpers.replace(index, eq)
    } else {
      equationsFieldArrayHelpers.push(eq)
    }
  }

  const questionIdsInExtraPrices = uniq(
    values.extraPrices
      .map(extraPrice => extraPrice.questionId)
      .filter(questionId => !!customizerProduct.questions[questionId])
  )

  const answerIdsInExtraPrices = uniq(
    values.extraPrices.map(extraPrice => extraPrice.answerId).filter(answerId => !!customizerProduct.answers[answerId])
  )

  const questionIdsInEquations = uniq(
    values.equations.flatMap(eq =>
      eq.lines
        .filter(line => line.type === EquationLineType.Question)
        .map(line => line.value)
        .filter(questionId => !!customizerProduct.questions[questionId])
    )
  )

  const answerIdsInEquations = uniq(
    values.equations.flatMap(eq =>
      eq.lines
        .filter(line => line.type === EquationLineType.Question)
        .flatMap(line => Object.keys(line?.answersMap || {}))
        .filter(answerId => !!customizerProduct.answers[answerId])
    )
  )

  const { questionFilterProps, answerFilterProps, clearAllFilters } = useQuestionsAnswersFilters(
    uniq([...questionIdsInExtraPrices, ...questionIdsInEquations]),
    uniq([...answerIdsInExtraPrices, ...answerIdsInEquations]),
    customizerProduct
  )

  useEffect(() => {
    clearAllFilters()
  }, [values.id])

  const extraPricesIds = useMemo(() => {
    const filteredExtraPrices = values.extraPrices.filter(
      extraPrice =>
        (questionFilterProps.activeFilters.length === 0 ||
          questionFilterProps.activeFilters.includes(extraPrice.questionId)) &&
        (answerFilterProps.activeFilters.length === 0 || answerFilterProps.activeFilters.includes(extraPrice.answerId))
    )
    return filteredExtraPrices.map(extraPrice => `${extraPrice.questionId}/${extraPrice.answerId}`)
  }, [values.extraPrices, questionFilterProps.activeFilters, answerFilterProps.activeFilters])

  const bulkSelection = useBulkSelection(extraPricesIds)

  const areFiltersEmpty = questionFilterProps.activeFilters.length === 0 && answerFilterProps.activeFilters.length === 0

  const filterExtraPrice = (extraPrice: ExtraPrice) =>
    (questionFilterProps.activeFilters.length == 0 ||
      questionFilterProps.activeFilters.includes(extraPrice.questionId)) &&
    (answerFilterProps.activeFilters.length == 0 || answerFilterProps.activeFilters.includes(extraPrice.answerId))

  const filterEquation = (equation: Equation) =>
    (questionFilterProps.activeFilters.length === 0 ||
      equation.lines.some(line => questionFilterProps.activeFilters.includes(line.value))) &&
    (answerFilterProps.activeFilters.length === 0 ||
      equation.lines.some(line => {
        const answers = Object.keys(line?.answersMap || {})
        return answers.some(answer => answerFilterProps.activeFilters.includes(answer))
      }))

  const formatPriceConstant = (price: string | number) => {
    let str = String(price || 0)
    if (currenciesInputProps.leftAddon) {
      str = currenciesInputProps.leftAddon + ' ' + str
    }
    if (currenciesInputProps.rightAddon) {
      str = str + ' ' + currenciesInputProps.rightAddon
    }
    return str
  }

  const ActiveBtn =
    !tab || tab === 'extraprices' ? (
      <AddExtraPricePopover
        existingExtraPrices={values.extraPrices ?? []}
        customizerProduct={customizerProduct}
        addExtraPrice={addExtraPrice}
        currenciesInputProps={currenciesInputProps}
      />
    ) : (
      <>
        <Button
          icon={<AddIcon className="w-2.5 h-2.5" />}
          type="button"
          onClick={() => {
            setEquationBaseData(undefined)
            equationModal.open()
          }}
        >
          Add equation
        </Button>
        {equationModal.isVisible && (
          <EquationModal
            {...equationModal.modalProps}
            onClose={equationModal.close}
            saveEquation={saveEquation}
            baseData={equationBaseData}
            customizerProduct={customizerProduct}
            currenciesInputProps={currenciesInputProps}
            questionFilterOptions={question =>
              (question.inputType === QuestionInputType.Text && question.inputCharacters === 'number') ||
              [
                QuestionInputType.Number,
                QuestionInputType.Thumbnail,
                QuestionInputType.LargeThumbnail,
                QuestionInputType.Dropdown,
                QuestionInputType.SmallRadio,
                QuestionInputType.Checkbox,
                QuestionInputType.Label,
                QuestionInputType.File,
                QuestionInputType.ColorPicker,
              ].includes(question.inputType)
            }
          />
        )}
      </>
    )

  const InvalidWarning = ({ error, label }: { error: string; label: string }) => {
    return (
      <Tooltip containerClassName="h-min -ml-4 -mr-[4px]" content={error}>
        <AlertIcon aria-label={label} className="w-3 h-3 fill-error-default" />
      </Tooltip>
    )
  }

  const SharedWarning = ({ error }: { error: string }) => {
    return (
      <Tooltip containerClassName="h-min" content={error}>
        <Tag className="text-secondary-orange-300 bg-secondary-orange-50 gap-0.5 items-center">
          <ExtraPriceIcon aria-label="Warning shared extra price" className="fill-secondary-orange-300 w-3 h-3" />
          <OperatorAddIcon aria-label="+" className="fill-secondary-orange-300 scale-[0.6]" />
          <EquationIcon aria-label="Warning shared equation" className="fill-secondary-orange-300 w-3 h-3" />
        </Tag>
      </Tooltip>
    )
  }

  return (
    <div className="p-6 border-t border-neutral-100">
      <div className="flex justify-between mb-4">
        <div className="font-medium">
          Additional pricing
          <span className="h-8 flex items-center text-neutral-400 text-xs font-normal">
            Non-published questions and answers won't be available.
          </span>
        </div>
      </div>

      <Tabs className="border-b-0 !h-10">
        <Tabs.Tab onClick={() => setTab('extraprices')} isSelected={!tab || tab === 'extraprices'}>
          Extra prices
          {values.extraPrices && values.extraPrices.length > 0 && (
            <Tag className="text-[10px] bg-neutral-75 text-neutral-300 ml-2 !px-[5px]">{values.extraPrices.length}</Tag>
          )}
        </Tabs.Tab>
        <Tabs.Tab onClick={() => setTab('equations')} isSelected={tab === 'equations'}>
          Equations
          {values.equations && values.equations.length > 0 && (
            <Tag className="text-[10px] bg-neutral-75 text-neutral-300 ml-2 !px-[5px]">{values.equations.length}</Tag>
          )}
        </Tabs.Tab>
        <div className="!ml-auto">{ActiveBtn}</div>
      </Tabs>

      <div className="flex flex-col">
        <div className="flex justify-between border-neutral-100 border-b border-t items-center pt-2">
          <div className="flex items-center flex-wrap">
            <div className="mr-2 mb-2">
              <CheckboxFilter {...questionFilterProps} />
            </div>
            <div className="mr-2 mb-2">
              <CheckboxFilter {...answerFilterProps} />
            </div>
          </div>
          <div className="mb-2 ml-auto flex items-center">
            <Button
              variant="text"
              onClick={clearAllFilters}
              disabled={areFiltersEmpty}
              icon={<Remove className="w-2.5 h-2.5 fill-current" />}
            >
              Clear filters
            </Button>
          </div>
        </div>
        {tab === 'extraprices' && (
          <div className="flex justify-between border-neutral-100 border-b items-center">
            <div className="flex w-full">
              <span className="basis-8 flex items-center mt-3 mb-3">
                <Table.BulkSelectionHeader bulkSelection={bulkSelection}>
                  <BulkExtraPriceButton bulkSelection={bulkSelection} customizerProduct={customizerProduct} />
                </Table.BulkSelectionHeader>
              </span>
              <span className="text-neutral-500 !font-normal flex items-center mt-3 mb-3 overflow-hidden whitespace-nowrap text-ellipsis">
                Select All
              </span>
            </div>
          </div>
        )}
        <div role="list">
          {!tab || tab === 'extraprices' ? (
            values.extraPrices.filter(filterExtraPrice).length > 0 ? (
              values.extraPrices.map(
                (extraPrice, i) =>
                  filterExtraPrice(extraPrice) && (
                    <div
                      key={`extraPrices-${i}`}
                      className="last:border-b-0 border-b border-neutral-100 flex"
                      role="listitem"
                    >
                      <div className="flex w-full items-center min-h-[4rem] py-2 gap-2">
                        {isExtraPriceInvalid(extraPrice) && (
                          <InvalidWarning
                            error="The question or answer for this extra price has been deleted"
                            label="Warning deleted question or answer"
                          />
                        )}
                        <span className="w-[24px]">
                          <Checkbox
                            aria-label="Select row"
                            tabIndex={-1}
                            checked={bulkSelection.isSelected(`${extraPrice.questionId}/${extraPrice.answerId}`)}
                            onChange={e => {
                              if (e.target.checked)
                                return bulkSelection.select([`${extraPrice.questionId}/${extraPrice.answerId}`])

                              bulkSelection.deselect([`${extraPrice.questionId}/${extraPrice.answerId}`])
                            }}
                          />
                        </span>
                        <span className="basis-48 flex items-center font-medium">
                          {customizerProduct.questions[extraPrice.questionId]?.name}
                        </span>
                        <span className="basis-32 flex items-center">
                          {customizerProduct.answers[extraPrice.answerId]?.name}
                        </span>
                        <div className="ml-auto flex gap-2 items-center justify-end basis-24">
                          {isExtraPriceShared(extraPrice) && (
                            <SharedWarning error="This answer is also used in an equation" />
                          )}
                          <InputField className="min-w-[80px]">
                            <Input
                              small
                              name={`${name}.${i}.price`}
                              id={`${name}.${i}.price`}
                              value={get(values, `${name}.${i}.price`)}
                              inputClassName="text-right pr-2"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              hasError={get(errors, `${name}.${i}.price`) != null}
                              {...currenciesInputProps}
                              {...(get(errors, `${name}.${i}.price`) != null
                                ? {
                                    rightAddon: (
                                      <Tooltip
                                        className="bg-error-default text-white"
                                        content={get(errors, `${name}.${i}.price`)}
                                        placement="bottom"
                                      >
                                        <AlertIcon className="w-3 h-3 fill-error-default" />
                                      </Tooltip>
                                    ),
                                  }
                                : {})}
                            />
                          </InputField>
                          <div>
                            <IconButton
                              small
                              aria-label="Delete extra price"
                              onClick={() => {
                                deleteModal.open()
                                setDeleteModalIndex(i)
                              }}
                              Icon={TrashIcon}
                              type="button"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )
            ) : (
              <EmptyAdditionnalPricing tab="extraprices" isReallyEmpty={values.extraPrices.length === 0} />
            )
          ) : tab === 'equations' ? (
            values.equations.filter(filterEquation).length > 0 ? (
              values.equations.map(
                (equation, i) =>
                  filterEquation(equation) && (
                    <div
                      key={`equations-${i}`}
                      className="last:border-b-0 border-b border-neutral-100 flex"
                      role="listitem"
                    >
                      <div className="flex w-full items-center min-h-[4rem] py-2 gap-2">
                        {isEquationInvalid(equation) && (
                          <InvalidWarning
                            error="One of the questions or answers used for this equation has been deleted"
                            label="Warning deleted question or answer"
                          />
                        )}
                        <div>
                          <span className="flex flex-row flex-wrap items-center gap-y-[4px]">
                            {equation.lines.map((line, i) => {
                              const content =
                                line.type === EquationLineType.Constant
                                  ? formatPriceConstant(line.value)
                                  : customizerProduct.questions[line.value]?.name +
                                    (line.answersMap && Object.keys(line.answersMap).length
                                      ? ` (${Object.keys(line.answersMap).length})`
                                      : '')

                              return (
                                <React.Fragment key={i}>
                                  <span className="font-medium">{content}</span>
                                  {i < equation.lines.length - 1 && (
                                    <span className="mx-2 opacity-40">
                                      {operatorOptions.find(option => option.value === line.operator)?.label ||
                                        line.operator}
                                    </span>
                                  )}
                                </React.Fragment>
                              )
                            })}
                            {equation?.options?.min || equation?.options?.max ? (
                              <>
                                <EqualIcon className="w-3 h-3 mx-3 fill-neutral-300" />
                                {equation.options?.min && (
                                  <Tag className="bg-neutral-75 text-neutral-300 mr-2">
                                    Min: {formatPriceConstant(equation.options.min)}
                                  </Tag>
                                )}
                                {equation.options?.max && (
                                  <Tag className="bg-neutral-75 text-neutral-300 mr-2">
                                    Max: {formatPriceConstant(equation.options.max)}
                                  </Tag>
                                )}
                              </>
                            ) : null}
                          </span>
                        </div>

                        <div className="ml-auto flex gap-2 items-center">
                          {isEquationShared(equation) && (
                            <SharedWarning error="There is one or more answer(s) used as extra price in this equation" />
                          )}
                          <IconButton
                            small
                            aria-label="Edit equation"
                            onClick={() => {
                              setEquationBaseData(equation)
                              equationModal.open()
                            }}
                            Icon={EditIcon}
                            type="button"
                          />
                          <IconButton
                            small
                            aria-label="Delete equation"
                            onClick={() => {
                              deleteModal.open()
                              setDeleteModalIndex(i)
                            }}
                            Icon={TrashIcon}
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                  )
              )
            ) : (
              <EmptyAdditionnalPricing tab="equations" isReallyEmpty={values.equations.length === 0} />
            )
          ) : (
            <EmptyAdditionnalPricing />
          )}
        </div>

        {deleteModal.isVisible && (
          <DeleteAdditionnalPricingModal
            type={tab === 'extraprices' ? 'extraprice' : 'equation'}
            onDelete={() => {
              if (deleteModalIndex == null) return

              if (tab === 'extraprices') {
                extraPricesFieldArrayHelpers.remove(deleteModalIndex)
              } else {
                equationsFieldArrayHelpers.remove(deleteModalIndex)
              }

              deleteModal.close()
            }}
            onClose={deleteModal.close}
            {...deleteModal.modalProps}
          />
        )}
      </div>
    </div>
  )
}

export default AdditionalPricing
