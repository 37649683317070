import { Button, IconButton } from '@packages/sk8/button'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

import { PreviewMode } from 'common/preview/types/previewMode'
import DownArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'
import DesktopIcon from 'icons/core-solid/computer-devices/computer-screen-1.svg'
import ProductIcon from 'icons/core-solid/money-shopping/catergories-shirt.svg'
import MobileIcon from 'icons/core-solid/phone/phone-mobile-phone.svg'

const iconsByMode = {
  [PreviewMode.DESKTOP]: DesktopIcon,
  [PreviewMode.MOBILE]: MobileIcon,
  [PreviewMode.PRODUCT]: ProductIcon,
}

const labelsByMode = {
  [PreviewMode.DESKTOP]: 'Select preview current desktop',
  [PreviewMode.MOBILE]: 'Select preview current mobile',
  [PreviewMode.PRODUCT]: 'Select preview current product',
}

interface PreviewModeControlProps {
  selectedMode: PreviewMode
  availableModes: PreviewMode[]
  disabledModes?: PreviewMode[]
  onChange: (mode: PreviewMode) => void
  disabled?: boolean
}

const PreviewModeControl = ({
  selectedMode,
  availableModes,
  disabledModes = [],
  onChange,
  disabled,
}: PreviewModeControlProps) => {
  const controlPopover = usePopover({ placement: 'bottom' })
  const IconComponent = iconsByMode[selectedMode]

  const handlePreviewModeClick = (previewMode: PreviewMode) => {
    onChange(previewMode)
    controlPopover.close()
  }

  return (
    <>
      <Button
        {...controlPopover.referenceProps}
        disabled={disabled}
        icon={<IconComponent className="w-3.5 fill-current" aria-label={labelsByMode[selectedMode]} />}
        variant="subtle"
        type="button"
      >
        <DownArrow className="w-2 fill-current" />
      </Button>
      <Popover {...controlPopover.floatingProps} isOpen={controlPopover.isOpen} className="!min-w-0">
        <div className="flex">
          {availableModes.includes(PreviewMode.DESKTOP) && (
            <IconButton
              variant="subtle"
              onClick={() => handlePreviewModeClick(PreviewMode.DESKTOP)}
              disabled={disabled || disabledModes.includes(PreviewMode.DESKTOP)}
              key="preview-desktop"
              Icon={DesktopIcon}
              aria-label="Preview desktop"
            />
          )}
          {availableModes.includes(PreviewMode.MOBILE) && (
            <IconButton
              variant="subtle"
              onClick={() => handlePreviewModeClick(PreviewMode.MOBILE)}
              disabled={disabled || disabledModes.includes(PreviewMode.MOBILE)}
              Icon={MobileIcon}
              aria-label="Preview mobile"
            />
          )}
          {availableModes.includes(PreviewMode.PRODUCT) && (
            <IconButton
              variant="subtle"
              onClick={() => handlePreviewModeClick(PreviewMode.PRODUCT)}
              disabled={disabled || disabledModes.includes(PreviewMode.PRODUCT)}
              Icon={ProductIcon}
              aria-label="Preview product"
            />
          )}
        </div>
      </Popover>
    </>
  )
}

export default PreviewModeControl
