import React, { forwardRef } from 'react'

import classMerge from '../../utils/classMerge'

interface ToolbarButtonProps extends React.ComponentProps<'button'> {
  children: React.ReactNode
  onMouseDown: React.MouseEventHandler<HTMLButtonElement>
  isActive?: boolean
}

const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  ({ children, onMouseDown, isActive, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className={classMerge(
          'flex items-center p-1 mx-1 text-neutral-800 rounded [&>svg]:w-3 [&>svg]:h-3 [&>svg]:fill-neutral-400',
          {
            'bg-neutral-100': isActive,
          }
        )}
        onMouseDown={onMouseDown}
        aria-pressed={isActive ? 'true' : 'false'}
        {...rest}
      >
        {children}
      </button>
    )
  }
)

export default ToolbarButton
