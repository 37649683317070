import { CheckboxFilter } from '@packages/sk8/filter'
import React from 'react'

import { trpc } from 'common/hooks/trpc'

type DesignsProductFilterProps = {
  filteredProducts: string[] | undefined
  toggleProducts: (name: string) => void
  clearFilters: () => void
}

const DesignsProductFilter = ({ filteredProducts, toggleProducts, clearFilters }: DesignsProductFilterProps) => {
  const { data: products } = trpc.product.list.useQuery(
    { sortKey: 'name', sortOrder: 'ascending' },
    { select: data => data?.results }
  )

  const isEmpty = !products?.length
  if (isEmpty) return null
  return (
    <CheckboxFilter
      searchable
      name="Products"
      filters={products?.map(product => ({ name: product.id, text: product.name })) || []}
      activeFilters={filteredProducts || []}
      toggleFilter={toggleProducts}
      clearFilter={clearFilters}
    />
  )
}

export default DesignsProductFilter
