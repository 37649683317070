import { BoxTransformer } from '@packages/sk8/editor'
import React from 'react'

import { useDispatch } from 'cms/hooks'
import type { MultilineTextNode } from 'customizer/2dDisplayer/types/node'

import { applyMultilineTextEdition, applyPrintAreaMultilineTextEdition } from '../../actions'
import TargetClipContainer from '../containers/TargetClipContainer'
import MultilineText from '../nodes/MultilineText'
import NodeInfo from './NodeInfo'
import { EditorProps } from './types'
import useBoxEditor from './useBoxEditor'

const BoxTextEditor = ({ node, entity, currentView }: EditorProps<MultilineTextNode>) => {
  const { targetRef, boundingBox, Target, getTransformedBoundingBox, onDrag, onResize, onRotate, scale } = useBoxEditor(
    {
      width: node.position.boundingBox.maxWidth!,
      height: node.position.boundingBox.maxHeight!,
      x: node.position.x,
      y: node.position.y,
      scale: node.scale,
      rotation: node.position.rotation ?? 0,
    }
  )

  const dispatch = useDispatch()

  const handleTransformEnd = () => {
    const boundingBox = getTransformedBoundingBox()

    'printArea' in entity && entity.printArea
      ? dispatch(applyPrintAreaMultilineTextEdition(entity.id, boundingBox))
      : dispatch(applyMultilineTextEdition(entity.id, boundingBox, currentView))
  }

  return (
    <>
      <TargetClipContainer>
        <Target>
          <MultilineText
            text={node.text.value}
            color={node.color.hex}
            fontFamily={node.font.family}
            fontSize={parseFloat(node.font.size) * scale}
            fontAssets={node.font.assets}
            fontType={node.font.fontType}
            textAlign={node.position.textAlign}
            verticalAlign={node.position.verticalAlign}
            outlineColor={node.outline.hex}
            outlineWidth={`${parseFloat(node.outline.width) * scale}`}
            boundingBox={{
              maxHeight: boundingBox.height,
              maxWidth: boundingBox.width,
            }}
            overflowBehavior={node.position.overflowBehavior}
          />
        </Target>
      </TargetClipContainer>
      <BoxTransformer
        target={targetRef}
        onDrag={onDrag}
        onResize={onResize}
        onRotate={onRotate}
        onTransformEnd={handleTransformEnd}
        info={<NodeInfo entity={entity} node={node} />}
      />
    </>
  )
}

export default BoxTextEditor
