import { Card } from '@packages/sk8/card'
import { WixOnlineStore, WixSubscription } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import React from 'react'

import useOnlineStoreService from 'cms/onlineStores/hooks/useOnlineStoreService'
import OpenLinkIcon from 'icons/custom/open-link.svg'

import PaymentCardSkeleton from '../skeletons/PaymentCardSkeleton'

interface WixPaymentCardProps {
  subscription?: WixSubscription
}

const WixPaymentCard = ({ subscription }: WixPaymentCardProps) => {
  const onlineStoreService = useOnlineStoreService()

  const { data: subscribedOnlineStore } = useQuery(
    onlineStoreService.fetch.queryKeys,
    () => onlineStoreService.fetch(subscription!.subscribedOnlineStore!),
    {
      enabled: !!subscription?.subscribedOnlineStore,
      select: data => data as WixOnlineStore,
    }
  )

  if (!subscription) return <PaymentCardSkeleton />

  return (
    <Card className="flex flex-1 flex-col">
      <Card.Section className="space-x-6">
        <div className="flex flex-col flex-grow space-y-4">
          <span className="text-neutral-400 text-xs leading-5">eCommerce platform</span>
          <span className="text-neutral-900 text-sm">{capitalize(subscription.paymentStrategy)}</span>
        </div>
      </Card.Section>
      {subscription.subscribedOnlineStore && (
        <>
          <Card.Separator />
          <Card.Section className="space-x-6">
            <div className="flex flex-col flex-grow space-y-4">
              <span className="text-neutral-400 text-xs leading-5">Online store</span>
              <a
                className="flex items-center"
                href={subscribedOnlineStore?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mr-2 text-sm text-primary-600">{subscribedOnlineStore?.url}</span>
                <OpenLinkIcon className="w-3 h-3" />
              </a>
            </div>
          </Card.Section>
        </>
      )}
    </Card>
  )
}

export default WixPaymentCard
