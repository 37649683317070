import { Part, TextPart } from '@packages/types'
import React from 'react'

import * as partsActions from 'builder/build/parts/actions'
import { useDispatch } from 'cms/hooks'
import Switch from 'common/components/Switch'
import WithInformation from 'common/components/WithInformation'

const { toggleMultiply, toggleTextNeon } = partsActions

export interface BlendingOptionSectionProps {
  part: Part
  isDisabled: boolean
  multiplyMessage: string
  allowNeon?: boolean
  neonMessage?: string
}

const BlendingOptionSection = ({
  part,
  isDisabled,
  multiplyMessage,
  allowNeon = false,
  neonMessage,
}: BlendingOptionSectionProps) => {
  const dispatch = useDispatch()

  const handleMultiplyToggle = () => dispatch(toggleMultiply(part.id))

  const handleTextNeonToggle = () => dispatch(toggleTextNeon(part.id))

  return (
    <div className="relative px-4 py-6 flex flex-col space-y-4">
      <div className="flex items-center justify-between">
        <WithInformation tooltip={multiplyMessage}>
          <label>Multiply</label>
        </WithInformation>
        <Switch
          disabled={isDisabled}
          onChange={handleMultiplyToggle}
          checked={part.modifiers?.blendMode === 'multiply'}
        />
      </div>
      {allowNeon && (
        <div className="flex items-center justify-between">
          <WithInformation tooltip={neonMessage!}>
            <label>Neon effect</label>
          </WithInformation>
          <Switch disabled={isDisabled} onChange={handleTextNeonToggle} checked={(part as TextPart).modifiers?.neon} />
        </div>
      )}
    </div>
  )
}

export default BlendingOptionSection
