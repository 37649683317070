import { Placement } from '@floating-ui/react-dom'
import { Popover, usePopover } from '@packages/sk8/popover'
import { Part, QuestionType, Question, ActivityLogType } from '@packages/types'
import React from 'react'

import MultiIconsButton from 'builder/build/common/components/MultiIconsButton'
import TypeIcon from 'builder/build/common/components/TypeIcon'
import * as partsActions from 'builder/build/parts/actions'
import useSelectPartAnswer from 'builder/build/parts/hooks/useSelectPartAnswer'
import * as questionsSelectors from 'builder/build/questions/selectors'
import { useDispatch, useSelector } from 'cms/hooks'
import * as constants from 'common/customizerProducts/constants'
import { trpc } from 'common/hooks/trpc'

import useCreateQuestion from './../../hooks/useCreateQuestion'
import useCreateQuestionFromDefaultAnswer from './../../hooks/useCreateQuestionFromDefaultAnswer'
import SelectOrCreateQuestionMenu from './SelectOrCreateQuestionMenu'

export interface AddQuestionButtonProps {
  part: Part
  type: QuestionType
  onClick?: () => void
  menuPosition?: Placement
  fromDefaultAnswer?: boolean
}

const getQuestionField = (part: Part, type: QuestionType) =>
  constants.parts.questionFields[part.type].find(fieldData => fieldData.type === type)!.field

const AddQuestionButton = ({
  onClick = () => {},
  part,
  type,
  menuPosition = 'bottom',
  fromDefaultAnswer = true,
}: AddQuestionButtonProps) => {
  const dispatch = useDispatch()
  const questions = useSelector(questionsSelectors.questionsAsArraySelector)
  const addQuestionButton = usePopover({ placement: menuPosition })
  const [answer, customizerSelectedAnswer] = useSelectPartAnswer(part[getQuestionField(part, type)] as string)
  const createQuestion = useCreateQuestion(part, type)

  const createQuestionFromDefaultAnswer = useCreateQuestionFromDefaultAnswer(
    part,
    answer != null ? answer.id : customizerSelectedAnswer?.id,
    type
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation()

  const handleCreateQuestion = () => {
    fromDefaultAnswer ? createQuestionFromDefaultAnswer() : createQuestion()
    createActivityLog({ type: ActivityLogType.CreateQuestion })
    if (onClick) onClick()
  }

  const handleSelectQuestion = (question: Question) => {
    dispatch(partsActions.assignQuestionToADifferentPart(part, question))
    onClick()
  }

  const selectableQuestions = questions.filter(question => question.type === type)

  return (
    <>
      <MultiIconsButton
        {...(selectableQuestions.length === 0 ? { onClick: handleCreateQuestion } : addQuestionButton.referenceProps)}
        Icon={<TypeIcon entityType="DISPLAY_TYPE" specificType={type} className="w-5 h-5 p-1 rounded" />}
        variant="subtle"
      >
        {constants.questions.questionTypeNames[type]} question
      </MultiIconsButton>
      <Popover {...addQuestionButton.floatingProps} className="builder" isOpen={addQuestionButton.isOpen}>
        <SelectOrCreateQuestionMenu
          questionType={type}
          questions={selectableQuestions}
          onSelectQuestion={handleSelectQuestion}
          onCreateQuestion={handleCreateQuestion}
        />
      </Popover>
    </>
  )
}

export default AddQuestionButton
