import { Button } from '@packages/sk8/button'
import { DatePickerInput, HelperText, InputField, Label } from '@packages/sk8/input'
import { Modal } from '@packages/sk8/modal'
import { ToastType, useToast } from '@packages/sk8/toast'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import * as yup from 'yup'

import { trpc } from 'common/hooks/trpc'
import TenantContext from 'common/tenant/TenantContext'

import useSubscriptionService from './../../hooks/useSubscriptionService'

const formValidation = yup.object().shape({ freeTrialEndsOn: yup.string().required('Please select a date') })

export interface ExtendFreeTrialModalProps extends Omit<React.ComponentProps<typeof Modal>, 'children'> {
  onClose: () => void
}

interface ExtendFreeTrialFormValues {
  freeTrialEndsOn: string
}

const ExtendFreeTrialModal = ({ onClose, ...modalProps }: ExtendFreeTrialModalProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const subscriptionService = useSubscriptionService()
  const tenant = useContext(TenantContext)

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const { data: subscription } = trpc.billingAndSubscription.get.useQuery()

  const { mutate: extendFreeTrial } = trpc.billingAndSubscription.extendFreeTrial.useMutation({
    onSuccess: () => {
      openToast('Free trial was successfully extended', ToastType.success)
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      handleClose()
    },
    onError: () => {
      openGenericErrorToast('The free trial has not been extended.')
    },
  })

  const formik = useFormik<ExtendFreeTrialFormValues>({
    initialValues: { freeTrialEndsOn: subscription?.freeTrialEndsOn || '' },
    onSubmit: (values, { setSubmitting }) =>
      extendFreeTrial(values.freeTrialEndsOn, { onSettled: () => setSubmitting(false) }),
    validationSchema: formValidation,
    enableReinitialize: true,
  })

  return (
    <Modal {...modalProps} onBackdropClick={handleClose}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.CloseButton onClick={handleClose} />
        <Modal.Title>Extend {tenant}’s free trial?</Modal.Title>

        <Modal.Details>
          <div className="flex flex-col space-y-6">
            <InputField className="flex-1">
              <Label htmlFor="freeTrialEndsOn">Pick a new end date for the free trial.</Label>
              <DatePickerInput
                autoComplete="off"
                className="z-10"
                id="freeTrialEndsOn"
                name="freeTrialEndsOn"
                popperProps={{ strategy: 'fixed' }}
                selected={formik.values.freeTrialEndsOn ? new Date(formik.values.freeTrialEndsOn) : undefined}
                onValueChange={value => formik.setFieldValue('freeTrialEndsOn', value ? value.toISOString() : '')}
                hasError={formik.touched.freeTrialEndsOn && formik.errors.freeTrialEndsOn != null}
              />
              {formik.touched.freeTrialEndsOn && formik.errors.freeTrialEndsOn != null && (
                <HelperText hasError>{formik.errors.freeTrialEndsOn}</HelperText>
              )}
            </InputField>
          </div>
        </Modal.Details>

        <Modal.Actions>
          <Button type="button" variant="default" className="px-4" onClick={handleClose} disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="px-4"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Extend
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default ExtendFreeTrialModal
