import { Select } from '@packages/sk8/input'
import {
  InputCharacters,
  Question,
  TextAnswer,
  TextModifier,
  TextQuestion,
  ValueAnswer,
  ValueQuestion,
} from '@packages/types'
import React from 'react'

import HorizontalField from 'builder/build/common/components/HorizontalField'
import PatchTextInput from 'builder/build/common/components/PatchTextInput'
import VerticalField from 'builder/build/common/components/VerticalField'
import VerticalFields from 'builder/build/common/components/VerticalFields'
import * as customizerHooks from 'builder/build/customizer/hooks/index'
import * as customizerSelectors from 'builder/build/customizer/selectors'
import Switch from 'common/components/Switch'
import * as customizationActions from 'customizer/customization/actions'
import * as customizationUtils from 'customizer/customization/utils'
import isNullOrEmpty from 'utils/isNullOrEmpty'
import { nullableMin } from 'utils/validators'

const textModifierOptions = [
  { label: 'None', value: 'none' },
  { label: 'Uppercase', value: 'uppercase' },
  { label: 'Lowercase', value: 'lowercase' },
] as const

const inputCharactersOptions = [
  { label: 'Allow all', value: 'text' },
  { label: 'Letters only', value: 'letter' },
  { label: 'Numbers only', value: 'number' },
] as const

const lettersAndSpacesOnlyPattern = /^$|^[A-Za-z\s]+$/
const numbersPattern = /^[0-9]*$/

interface InputTextAnswerSectionProps {
  question: TextQuestion | ValueQuestion
  updateQuestion: (field: keyof Question, value: any) => void
}

const InputTextAnswerSection = ({ question, updateQuestion }: InputTextAnswerSectionProps) => {
  const customizerDispatch = customizerHooks.useCustomizerDispatch()
  const selectedAnswersByQuestionId = customizerHooks.useCustomizerSelector(state =>
    customizerSelectors.selectedAnswersByQuestionIdsSelector(state, [question.id])
  )
  const selectedAnswer = customizerHooks.useCustomizerSelector(state =>
    customizerSelectors.getAnswerFromId(state, selectedAnswersByQuestionId[question.id]?.id)
  ) as TextAnswer | ValueAnswer

  const questionTextModifiers = question.textModifiers || []
  const textModifier = questionTextModifiers.find(modifier => ['lowercase', 'uppercase'].includes(modifier.name))

  const handleInputCharactersChange = (value: InputCharacters) => {
    updateQuestion('inputCharacters', value)

    if (
      typeof selectedAnswer?.value === 'object' ||
      (value === 'letter' && !lettersAndSpacesOnlyPattern.test(selectedAnswer?.value || '')) ||
      (value === 'number' && !numbersPattern.test(selectedAnswer?.value || ''))
    )
      customizerDispatch(customizationActions.updateText(question.id, selectedAnswer.id, ''))
  }

  const handleTextModifiersChange = (value: TextModifier | 'none') => {
    const updatedTextModifiers = questionTextModifiers.filter(
      modifier => !['lowercase', 'uppercase'].includes(modifier.name)
    )

    if (value !== 'none') updatedTextModifiers.push({ name: value })

    updateQuestion('textModifiers', updatedTextModifiers)

    if (
      selectedAnswer?.isPersonalisation &&
      !isNullOrEmpty(selectedAnswer?.value) &&
      value !== textModifier?.name &&
      value !== 'none'
    ) {
      const newText = customizationUtils.applyTextModifiers([{ name: value }], selectedAnswer.value)
      customizerDispatch(customizationActions.updateText(question.id, selectedAnswer.id, newText))
    }
  }

  const handleDecimalsChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    updateQuestion('allowDecimals', e.target.checked)
    if (!e.target.checked) customizerDispatch(customizationActions.updateText(question.id, selectedAnswer.id, ''))
  }

  return (
    <div className="p-4">
      <VerticalFields>
        <HorizontalField>
          <label htmlFor="text-max-length">Text max length</label>
          <PatchTextInput
            id="text-max-length"
            inputClassName="placeholder:text-xl"
            value={question.maxLength || ''}
            onChange={e => updateQuestion('maxLength', e.target.value ? parseInt(e.target.value) : null)}
            type="number"
            min="0"
            placeholder="∞"
            validator={nullableMin(0)}
          />
        </HorizontalField>
        <HorizontalField>
          <label>Show char. count</label>
          <Switch
            checked={question.showCharacterCount}
            onChange={e => updateQuestion('showCharacterCount', e.target.checked)}
          />
        </HorizontalField>
        <VerticalField>
          <label>Characters</label>
          <Select
            aria-label="input-characters"
            menuPosition="fixed"
            className="w-full"
            value={inputCharactersOptions.find(option => option.value === (question.inputCharacters ?? 'text'))}
            options={inputCharactersOptions}
            onChange={option => handleInputCharactersChange(option!.value)}
          />
        </VerticalField>
        {question.inputCharacters !== 'number' && (
          <VerticalField>
            <label className="panel__field-label">Text modifier</label>
            <Select
              aria-label="text-modifier"
              menuPosition="fixed"
              className="w-full"
              options={textModifierOptions}
              onChange={option => handleTextModifiersChange(option!.value)}
              value={textModifierOptions.find(option => option.value === (textModifier?.name ?? 'none'))}
            />
          </VerticalField>
        )}
        {question.inputCharacters === 'number' && (
          <HorizontalField>
            <label>Allow decimals</label>
            <Switch checked={question.allowDecimals} onChange={handleDecimalsChange} />
          </HorizontalField>
        )}
      </VerticalFields>
    </div>
  )
}

export default InputTextAnswerSection
