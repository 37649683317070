import { Button } from '@packages/sk8/button'
import { PaymentStrategy, SubscriptionStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import { differenceInDays } from 'date-fns'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import useSubscriptionService from 'cms/subscription/hooks/useSubscriptionService'
import StarsIcon from 'icons/custom/beta-stars.svg'
import classMerge from 'utils/classMerge'

const FreeTrialIndicator = () => {
  const params = useParams<{ brandName: string }>()
  const baseUrl = params.brandName ? `/brands/${params.brandName}` : ''

  const subscriptionService = useSubscriptionService()
  const { data: subscription, isLoading } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne
  )

  const stripeFreeTrialActivated =
    subscription?.status === SubscriptionStatus.FreeTrial &&
    subscription.paymentStrategy === PaymentStrategy.Stripe &&
    !!subscription.stripePaymentMethodId

  if (isLoading || subscription?.status !== SubscriptionStatus.FreeTrial || stripeFreeTrialActivated) return null

  const daysDiff = differenceInDays(new Date(subscription.freeTrialEndsOn).getTime(), new Date().getTime())

  const cardBackgroundColorClassName = classMerge(
    'w-full shadow-xs text-white p-3 rounded-lg shadow-md bg-gradient-to-r',
    {
      'from-tertiary-red-500 to-tertiary-red-500/70': daysDiff <= 0,
      'from-secondary-orange-300 to-secondary-orange-300/70': daysDiff >= 1 && daysDiff <= 4,
      'from-primary-500 to-primary-300/70': daysDiff > 4,
    }
  )

  return (
    <div className={cardBackgroundColorClassName} aria-label="free trial indicator">
      <h3 className="font-medium flex items-center">
        <StarsIcon className="w-3 h-3 mr-2 fill-white" />
        {daysDiff < 0 && <>Trial has ended</>}
        {daysDiff > 0 && (
          <>
            Trial ends in&nbsp;<span>{daysDiff + 1}</span>&nbsp;days
          </>
        )}
        {daysDiff === 0 && <>Trial ends today</>}
      </h3>
      <div className="mt-2 mb-4 text-xs">Activate your subscription before the trial ends to keep using Kickflip.</div>
      <Link to={baseUrl ? `${baseUrl}/subscription` : '/subscription'}>
        <Button className="w-full">Activate</Button>
      </Link>
    </div>
  )
}

export default FreeTrialIndicator
