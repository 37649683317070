import React from 'react'

import classMerge from '../../utils/classMerge'

export interface ModalTitleProps {
  id?: string
  children: React.ReactNode
  className?: string
}

const ModalTitle = ({ id, children, className }: ModalTitleProps) => (
  <div className={classMerge('flex p-6 pb-3 pt-[22px]', className)}>
    <h2 id={id}>{children}</h2>
  </div>
)

export default ModalTitle
