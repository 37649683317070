import { Popover, usePopover } from '@packages/sk8/popover'
import classNames from 'classnames'
import React from 'react'

import AddOnlineStoreButton from 'cms/onlineStores/components/AddOnlineStoreButton'
import CheckCircleIcon from 'icons/bold/01-Interface Essential/33-Form-Validation/check-circle-1.svg'
import ECommerceIcon from 'icons/bold/09-Shopping-Ecommerce/02-E-Commerce/e-commerce-shop.svg'

export interface InstallPopoverProps {
  isCompleted: boolean
}

const InstallPopover = ({ isCompleted }: InstallPopoverProps) => {
  const popover = usePopover({ placement: 'right', offsetConfig: 32, useArrow: true })

  return (
    <>
      <button
        {...popover.referenceProps}
        className={classNames('font-p2 flex items-center justify-between h-4', {
          'text-tertiary-green-300': isCompleted,
          'text-primary-600 font-bold': popover.isOpen,
        })}
        disabled={isCompleted}
      >
        1. Install Kickflip app
        {isCompleted && <CheckCircleIcon className="w-4 h-4 fill-tertiary-green-300" aria-label="completed" />}
      </button>

      <Popover
        {...popover.floatingProps}
        isOpen={popover.isOpen}
        className="!w-80 shadow-lg"
        containerClassName="z-[50]"
      >
        <div>
          <div className="flex flex-col p-4 space-y-4">
            <div className="w-[45px] h-[45px] rounded-lg bg-neutral-75 p-2">
              <ECommerceIcon />
            </div>
            <span className="text-xs font-medium text-neutral-600">
              Install our app on your eCommerce and manage your products from your store.
            </span>
          </div>
          <div className="px-4 py-3 flex justify-end border-t border-t-neutral-75">
            <AddOnlineStoreButton type="button" variant="primary" className="px-4">
              Add store
            </AddOnlineStoreButton>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default InstallPopover
