import { Select, Option } from '@packages/sk8/input'
import { EntityType, Group, Question } from '@packages/types'
import { times } from 'lodash'
import React from 'react'
import { SingleValue } from 'react-select'

import * as coreSelectors from 'builder/build/core/selectors'
import * as customizerProductsSelectors from 'builder/build/customizerProducts/selectors'
import * as groupsActions from 'builder/build/groups/actions'
import { useSelector, useDispatch } from 'cms/hooks'
import WithInformation from 'common/components/WithInformation'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import { patchQuestion } from '../../actions'

interface SwitchViewSectionProps {
  entity: Question | Group
}

const SwitchViewSection = ({ entity }: SwitchViewSectionProps) => {
  const dispatch = useDispatch()

  const views = useSelector(coreSelectors.viewsSelector)
  const firstAncestor = useSelector(state => customizerProductsSelectors.firstLevelAncestorSelector(state, entity?.id))
  if (!firstAncestor) return null

  const isFirstAncestor = firstAncestor.id === entity.id

  const handleForceViewChange = (option: SingleValue<Option<string | number>>) => {
    const updatedForceView =
      option!.value === 'none' ? null : typeof option!.value === 'string' ? parseInt(option!.value) : option!.value

    if (entity.entityType === EntityType.Question) dispatch(patchQuestion(entity.id, { forceView: updatedForceView }))

    if (entity.entityType === EntityType.Group)
      dispatch(groupsActions.patchGroup(entity.id, { forceView: updatedForceView }))
  }

  const toolTipMessage = 'The selected view will be displayed when the end-user opens this question.'

  return (
    <div className="relative px-4 py-6">
      <div className="font-medium mb-4">
        {isFirstAncestor ? <WithInformation tooltip={toolTipMessage}>Switch view</WithInformation> : 'Switch view'}
      </div>
      <Select
        id="force-view"
        value={{
          value: entity.forceView == null ? 'none' : entity.forceView,
          label: entity.forceView == null ? 'None' : `View ${entity.forceView + 1}`,
        }}
        onChange={handleForceViewChange}
        options={[
          {
            label: 'None',
            value: 'none',
          },
          ...times(views, index => ({
            label: `View ${index + 1}`,
            value: index,
          })),
        ]}
      />

      {!isFirstAncestor && isNullOrEmpty(entity.forceView) && (
        <div className="flex text-xs text-neutral-300 my-4">
          The parent group switch view overwrites questions set to None.
        </div>
      )}
    </div>
  )
}

export default SwitchViewSection
