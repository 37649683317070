import type { Quote } from '@packages/types'
import { createContext } from 'react'

import { useFetchQuote } from './hooks/useFetchQuote'

export type QuoteContextType = Omit<ReturnType<typeof useFetchQuote>, 'quote'> & {
  isEditing: boolean
  isSaving: boolean
  setIsEditing: (isEditing: boolean) => void
  isRevision: boolean
  quote?: Quote
  currenciesInputProps?: { rightAddon?: string; leftAddon?: string }
}

const QuoteContext = createContext<QuoteContextType>({
  isEditing: false,
  isSaving: false,
  setIsEditing: () => {},
  isRevision: false,
  currenciesInputProps: {},
  quote: undefined,
  ...({} as Omit<ReturnType<typeof useFetchQuote>, 'quote'>),
})

export default QuoteContext
