import {
  autoUpdate,
  offset,
  Placement,
  shift,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  arrow,
  useHover,
  safePolygon,
  flip,
  Strategy,
} from '@floating-ui/react'
import { useRef, useState } from 'react'

import classMerge from '../../utils/classMerge'

const transformOriginByPlacement: Record<Placement, string> = {
  top: 'bottom center',
  'top-start': 'bottom left',
  'top-end': 'bottom right',
  bottom: 'top center',
  'bottom-start': 'top left',
  'bottom-end': 'top right',
  right: 'center left',
  'right-start': 'top left',
  'right-end': 'bottom left',
  left: 'center right',
  'left-start': 'top right',
  'left-end': 'bottom right',
}

export interface usePopoverProps {
  placement?: Placement
  positionStrategy?: Strategy
  trigger?: 'click' | 'hover'
  forceOpen?: boolean
  shiftConfig?: { mainAxis: boolean; crossAxis: boolean; padding: number }
  offsetConfig?:
    | {
        mainAxis?: number
        crossAxis?: number
        alignmentAxis?: number | null
      }
    | number
  useArrow?: boolean
  autoUpdateOnAnimationFrame?: boolean
  delay?:
    | number
    | Partial<{
        open: number
        close: number
      }>
    | undefined
  restMs?: number
}

const usePopover = ({
  placement = 'bottom',
  trigger = 'click',
  forceOpen,
  shiftConfig = { mainAxis: true, crossAxis: true, padding: 16 },
  offsetConfig = 0,
  useArrow = false,
  autoUpdateOnAnimationFrame = false,
  delay,
  restMs = 1,
  positionStrategy,
}: usePopoverProps = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef<HTMLDivElement | null>(null)

  const autoUpdateWithOptions = autoUpdateOnAnimationFrame
    ? (...args: Parameters<typeof autoUpdate>) => {
        return autoUpdate(args[0], args[1], args[2], { animationFrame: true })
      }
    : autoUpdate

  const {
    x,
    y,
    refs,
    strategy,
    context,
    middlewareData,
    placement: finalPlacement,
  } = useFloating({
    strategy: positionStrategy,
    open: forceOpen || isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      flip({ crossAxis: false }),
      shift(shiftConfig),
      offset(offsetConfig),
      ...(useArrow ? [arrow({ element: arrowRef })] : []),
    ],
    whileElementsMounted: autoUpdateWithOptions,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context),
    useClick(context, { enabled: trigger === 'click' }),
    // restMs will prevent a sub-menu to be triggered twice
    useHover(context, { delay, enabled: trigger === 'hover', handleClose: safePolygon(), restMs: restMs }),
    useDismiss(context),
  ])

  const referenceProps = getReferenceProps()

  const placementDirection = finalPlacement.split('-')[0]

  const staticSide = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' }[placementDirection] as string

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    return (referenceProps as React.HTMLAttributes<HTMLElement>).onClick?.(e)
  }

  return {
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    isOpen: forceOpen || isOpen,
    referenceProps: {
      ...referenceProps,
      ref: refs.setReference,
      onClick,
    },
    floatingProps: {
      ...getFloatingProps(),
      ref: refs.setFloating,
      style: {
        position: strategy,
        top: y ?? '',
        left: x ?? '',
        transformOrigin: transformOriginByPlacement[finalPlacement],
      },
      ...(useArrow
        ? {
            arrowProps: {
              ref: arrowRef,
              style: {
                left: middlewareData.arrow?.x,
                top: middlewareData.arrow?.y,
              },
              className: classMerge(
                'flex items-center justify-center overflow-hidden absolute z-20 pointer-events-none',
                {
                  '-bottom-8 w-8 h-8': staticSide === 'bottom',
                  '-top-8 w-12 h-8': staticSide === 'top',
                  '-right-8 w-8 h-12': staticSide === 'right',
                  '-left-8 w-8 h-12': staticSide === 'left',
                }
              ),
              innerClassName: classMerge('absolute z-10 rotate-45 w-4 h-4 bg-white shadow', {
                '-top-3': staticSide === 'bottom',
                '-bottom-3': staticSide === 'top',
                '-left-3': staticSide === 'right',
                '-right-3': staticSide === 'left',
              }),
            },
          }
        : {}),
    },
  }
}

export default usePopover
