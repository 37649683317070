import { TopBar } from '@packages/sk8/top-bar'
import React, { useContext } from 'react'

import GlobalRouterContext from 'builder/common/GlobalRouterContext'

const TopBarBackButton = () => {
  const { match, history } = useContext(GlobalRouterContext)
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''

  const handleClick = () => history.push(`${baseUrl}/products`)

  return <TopBar.BackButton onBackClick={handleClick} buttonClassNames="!mr-0" />
}

export default TopBarBackButton
