import { Button } from '@packages/sk8/button'
import { Card } from '@packages/sk8/card'
import { useNavigationBlockingModal } from '@packages/sk8/modal'
import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'

import type { TranslationData, TranslationText } from 'cms/translations/types/translation'
import UnsavedChangesModal from 'common/components/UnsavedChangesModal'

import { formValuesToTranslations, translationsToFormValues } from '../utils'
import TranslationListItem from './TranslationListItem'

interface TranslationFormProps {
  texts: Record<string, TranslationText>
  translations: Record<string, TranslationData>
  onSubmit: (values: Record<string, TranslationData>, formikHelpers: FormikHelpers<object>) => void
}

const TranslationForm = ({ texts, translations, onSubmit }: TranslationFormProps) => {
  const history = useHistory()

  const formik = useFormik({
    initialValues: translationsToFormValues(texts, translations),
    enableReinitialize: true,
    onSubmit: (values, formikHelpers) => onSubmit(formValuesToTranslations(texts, values), formikHelpers),
  })

  const unsavedChangesModal = useNavigationBlockingModal(history, [formik.dirty])

  return (
    <form className="w-full" onSubmit={formik.handleSubmit} noValidate>
      <Card className="w-full overflow-visible mb-8">
        <div className="flex flex-col grow relative">
          <ul role="list" className="flex-1 divide-y divide-neutral-100">
            {Object.values(texts).map(text => (
              <TranslationListItem
                key={text.keys[0]}
                text={text}
                value={formik.values[text.keys[0] as keyof typeof formik.values]}
                onChange={newValue => formik.setFieldValue(text.keys[0], newValue)}
              />
            ))}
          </ul>
          <Card.StickyFooter className="z-10">
            <Button
              type="reset"
              className="mr-2"
              onClick={formik.handleReset}
              disabled={!formik.dirty || formik.isSubmitting}
            >
              Discard
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              isLoading={formik.isSubmitting}
            >
              Save
            </Button>
          </Card.StickyFooter>
        </div>
      </Card>

      {unsavedChangesModal.isVisible && (
        <UnsavedChangesModal
          onLeaveClick={() => {
            unsavedChangesModal.close()
            unsavedChangesModal.forceNavigate()
          }}
          onStayClick={unsavedChangesModal.close}
          {...unsavedChangesModal.modalProps}
        />
      )}
    </form>
  )
}

export default TranslationForm
