import { Button } from '@packages/sk8/button'
import { useModal } from '@packages/sk8/modal'
import { Popover, usePopover } from '@packages/sk8/popover'
import React from 'react'

import { trpc } from 'common/hooks/trpc'
import { BulkSelection } from 'common/hooks/useBulkSelection'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

import { FormikVariants } from '../types'
import ShopifyConnectModal from './ShopifyConnectModal'
import ShopifyDisconnectModal from './ShopifyDisconnectModal'

export interface ShopifyVariantsBulkPopoverProps {
  bulkSelection: BulkSelection
  locationId: string
  formik: FormikVariants
}

const ShopifyVariantsBulkPopover = ({ bulkSelection }: ShopifyVariantsBulkPopoverProps) => {
  const popover = usePopover({ placement: 'bottom-start' })
  const connectModal = useModal()
  const disconnectModal = useModal()
  const withClose = (callback: () => void) => () => {
    callback()
    popover.close()
  }

  const trpcContext = trpc.useContext()

  const handleSaveSuccess = () => {
    trpcContext.variant.get.invalidate()
    trpcContext.variant.getByIds.invalidate()
  }

  return (
    <>
      <Button
        tabIndex={-1}
        variant="subtle"
        small
        icon={<MoreIcon className="w-2.5 h-2.5" />}
        {...popover.referenceProps}
      >
        Bulk action
      </Button>
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        <Popover.Action onClick={withClose(connectModal.open)}>Connect to Shopify product</Popover.Action>
        <Popover.Action onClick={withClose(disconnectModal.open)}>Disconnect from Shopify product</Popover.Action>
      </Popover>
      {connectModal.isVisible && (
        <ShopifyConnectModal
          modalProps={connectModal.modalProps}
          handleClose={connectModal.close}
          ids={bulkSelection.selectedIds}
          onSuccess={handleSaveSuccess}
        />
      )}
      {disconnectModal.isVisible && (
        <ShopifyDisconnectModal
          modalProps={disconnectModal.modalProps}
          handleClose={disconnectModal.close}
          ids={bulkSelection.selectedIds}
          onSuccess={handleSaveSuccess}
        />
      )}
    </>
  )
}

export default ShopifyVariantsBulkPopover
