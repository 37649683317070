import { Button } from '@packages/sk8/button'
import Tagify, { TagData } from '@yaireo/tagify'
import { MixedTags } from '@yaireo/tagify/dist/react.tagify'
import React, { useRef, useMemo, useEffect } from 'react'

import type { TranslationText } from 'cms/translations/types/translation'
import ArrowRight from 'icons/regular/01-Interface Essential/40-Keyboard/keyboard-arrow-right.svg'

import { getPlaceholders } from '../utils'

import '@yaireo/tagify/dist/tagify.css'
import './TranslationRowWithPlaceholders.css'

interface TranslationRowWithPlaceholdersProps {
  text: TranslationText
  value: string
  onChange: (value: string) => void
}

const convertToTagifyValue = (text: string, placeholders: string[]) => {
  return placeholders.reduce((result, placeholder) => {
    return result.replace(placeholder, JSON.stringify([[{ value: placeholder.replace('{{', '').replace('}}', '') }]]))
  }, text)
}

const getTags = (placeholders: string[]) => {
  return placeholders.reduce(
    (placeholders: { id: number; value: string }[], placeholder: string, i) => [
      ...placeholders,
      { id: i, value: placeholder.replace('{{', '').replace('}}', '') },
    ],
    []
  )
}

const TranslationRowWithPlaceholders = React.forwardRef<HTMLLabelElement, TranslationRowWithPlaceholdersProps>(
  ({ text, value, onChange }, ref) => {
    const tagify = useRef<Tagify>()
    const placeholders = useMemo(() => getPlaceholders(text.text), [])
    const tags = useMemo(() => getTags(placeholders), [])
    const from = useMemo(() => convertToTagifyValue(text.text, placeholders), [])
    const initialValue = useMemo(() => convertToTagifyValue(value, placeholders), [])

    const handleChange = (e: CustomEvent<Tagify.ChangeEventData<Tagify.TagData>>) => {
      const cleanedValue = e.detail.tagify.getCleanValue().reduceRight((text: string, tag: TagData) => {
        return text.replace(JSON.stringify([[tag]]), `{{${tag.value}}}`)
      }, e.detail.value)

      onChange(cleanedValue)
    }

    const handleTagButtonClick = (value: string) => {
      if (!tagify.current) return

      const tagElem = tagify.current.createTagElem({ value })
      tagify.current.injectAtCaret(tagElem)
      const elem = tagify.current.insertAfterTag(tagElem, ' ')
      tagify.current.placeCaretAfterNode(elem)
    }

    useEffect(() => {
      const tagifyValue = convertToTagifyValue(value, placeholders)

      if (tagifyValue !== tagify.current!.DOM.originalInput.value) {
        tagify.current!.DOM.originalInput.value = tagifyValue
      }
    }, [value])

    return (
      <li className="flex flex-col md:flex-row py-4 px-6">
        <label
          className="flex-1 text-sm font-medium text-neutral-400 pt-1 mb-4 md:mb-0"
          htmlFor={text.keys[0]}
          ref={ref}
        >
          <MixedTags
            readOnly
            value={from}
            className="tagify--readonly h-auto border-none [&>*]:!p-0 [&>*]:pointer-events-none"
          />
        </label>
        <ArrowRight className="h-4 ml-4 mr-4 text-neutral-500 mt-2 hidden md:visible" />
        <div className="flex-1">
          <MixedTags
            name={text.keys[0]}
            tagifyRef={tagify}
            defaultValue={initialValue}
            onChange={handleChange}
            settings={{
              pattern: /@/,
              dropdown: {
                enabled: 0,
                position: 'text',
              },
              enforceWhitelist: true,
              whitelist: tags,
              onChangeAfterBlur: false,
              editTags: false,
              placeholder: 'Enter your translation here',
            }}
          />
          <div className="flex">
            {tags.map(tag => (
              <Button
                type="button"
                key={tag.id}
                className="mt-2 mr-2"
                onClick={() => handleTagButtonClick(tag.value)}
                disabled={value.includes(`{{${tag.value}}}`)}
              >
                {tag.value}
              </Button>
            ))}
          </div>
        </div>
      </li>
    )
  }
)

export default TranslationRowWithPlaceholders
