import React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

import ArrowDown from '../../icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-down-1.svg'
import ArrowUp from '../../icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-up-1.svg'
import classMerge from '../../utils/classMerge'
import Input, { InputProps } from './Input'

export interface NumberInputProps
  extends Omit<InputProps, 'type' | 'ref' | 'onChange'>,
    Omit<NumericFormatProps, 'onValueChange'> {
  onValueChange?: (value?: number) => void
  inputClassName?: string
  defaultValue?: string | number
  value?: number | string
  increment?: number
  showArrows?: boolean
}

const NumberInput = React.forwardRef(
  (
    {
      className,
      disabled,
      max,
      min,
      value,
      onValueChange,
      increment = 1,
      onKeyUp,
      showArrows = true,
      rightAddon,
      ...props
    }: NumberInputProps,
    ref
  ) => {
    const isIncrementDisabled = value == null || disabled || (max !== undefined && value >= max)
    const isDecrementDisabled = value == null || disabled || (min !== undefined && value <= min)

    const handleIncrement = () => onValueChange?.(parseFloat((+(value ?? 0) + increment).toFixed(2)))

    const handleDecrement = () => onValueChange?.(parseFloat((+(value ?? 0) - increment).toFixed(2)))

    return (
      <NumericFormat
        {...props}
        disabled={disabled}
        className={className}
        getInputRef={ref}
        autoComplete="off"
        min={min}
        max={max}
        customInput={Input}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'ArrowUp') handleIncrement()
          if (e.key === 'ArrowDown') handleDecrement()
          onKeyUp?.(e)
        }}
        value={value}
        onValueChange={({ floatValue }) => onValueChange?.(floatValue)}
        rightAddon={
          showArrows ? (
            <div className="flex flex-col -m-3">
              <button
                type="button"
                tabIndex={-1}
                disabled={isIncrementDisabled}
                className={classMerge('hover:bg-neutral-50 px-2 pt-1.5 pb-0.5', {
                  'fill-neutral-200': isIncrementDisabled,
                  'fill-neutral-800': !isIncrementDisabled,
                })}
                onClick={handleIncrement}
              >
                <ArrowUp width={8} />
              </button>
              <button
                type="button"
                tabIndex={-1}
                disabled={isDecrementDisabled}
                className={classMerge('hover:bg-neutral-50 px-2 pb-1.5 pt-0.5', {
                  'fill-neutral-200': isDecrementDisabled,
                  'fill-neutral-800': !isDecrementDisabled,
                })}
                onClick={handleDecrement}
              >
                <ArrowDown width={8} />
              </button>
            </div>
          ) : (
            rightAddon
          )
        }
      />
    )
  }
)

export default NumberInput
