import { Input } from '@packages/sk8/input'
import classNames from 'classnames'
import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'

export interface PatchTextInputHandle {
  reset: () => void
}

export type PatchTextInputChangeEvent =
  | React.ChangeEvent<HTMLInputElement>
  | (FocusEvent & {
      target: HTMLInputElement
    })

export interface PatchTextInputProps extends React.ComponentProps<typeof Input> {
  onChange: (e: PatchTextInputChangeEvent) => void
  textarea?: boolean
  autoFocus?: boolean
  autoSelect?: boolean
  validator?: (value: string | number) => boolean
  value: string | number
  placeholder?: string
  className?: string
  type?: string
  autoComplete?: string
  min?: string
  role?: React.AriaRole
}

const PatchTextInput = forwardRef<PatchTextInputHandle, PatchTextInputProps>(
  (
    { value, placeholder, onChange, textarea, autoFocus, autoSelect, className, validator, ...inputProps },
    outsideRef
  ) => {
    const ref = useRef<HTMLInputElement>(null)
    const [internalValue, setInternalValue] = useState(value ?? '')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setInternalValue(e.target.value)

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement> | FocusEvent) => {
      if (!e.target || e.target.constructor !== HTMLInputElement) return

      const target = e.target

      const isValid = validator ? validator(inputProps.type === 'number' ? target.valueAsNumber : target.value) : true

      isValid
        ? onChange(
            e as typeof e extends FocusEvent
              ? FocusEvent & {
                  target: HTMLInputElement
                }
              : React.ChangeEvent<HTMLInputElement>
          )
        : setInternalValue(value)
    }

    const onBlurRef = useRef<(e: React.ChangeEvent<HTMLInputElement> | FocusEvent) => void>(handleBlur)

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
      if (!textarea && e.key === 'Enter') handleBlur(e as any)
    }

    useImperativeHandle(outsideRef, () => ({ reset: () => setInternalValue(value) }))

    useEffect(() => {
      setInternalValue(value ?? '')
    }, [value])

    useEffect(() => {
      if (autoFocus) setTimeout(() => ref.current?.focus(), 210)
      if (autoSelect) setTimeout(() => ref.current?.select(), 210)
    }, [autoFocus, autoSelect])

    useEffect(() => {
      setInternalValue(value ?? '')
    }, [value])

    React.useEffect(() => {
      if (ref?.current != null) {
        if (onBlurRef.current != null) {
          ref.current.removeEventListener('blur', onBlurRef.current)
        }
        ref.current.addEventListener('blur', handleBlur)
        onBlurRef.current = handleBlur
      }
    }, [ref, handleBlur])

    return (
      <Input
        aria-label="patch text input"
        className={classNames(className)}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={internalValue}
        ref={ref as any}
        role="input"
        placeholder={placeholder}
        {...inputProps}
      />
    )
  }
)

export default PatchTextInput
